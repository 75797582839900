import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Row, Col } from 'third-party/mdbreact/dist/mdbreact.js';

class DashboardPromotions extends React.Component {
	onClick = event => {
		this.props.outsideEvent({ category: `dashboard_placement`, action: event.target.parentNode.dataset.tag });
	};

	render() {
		const { className, translate, config } = this.props;
		const classProps = classNames('dashboard-promo', className);

		return (
			<div className={classProps}>
				{translate('Dashboard.numberOfPromotions') === '1' && (
					<Row className="no-gutters">
						<Col md="12">
							<a
								onClick={this.onClick}
								rel="noopener noreferrer"
								target="_blank"
								href={translate('Dashboard.promotion1')}
								data-tag={translate('Dashboard.promotion1-tag')}
							>
								<img
									className="Di_ShowLargePromo"
									src={`${config.cdn_basePath}/mydinova/Promotions/one-lv.png`}
								/>
								<img
									className="Di_ShowSmallPromo"
									src={`${config.cdn_basePath}/mydinova/Promotions/one-sv.png`}
								/>
							</a>
						</Col>
					</Row>
				)}
				{translate('Dashboard.numberOfPromotions') === '2' && (
					<Row className="no-gutters">
						<Col md="6" sm="12">
							<a
								onClick={this.onClick}
								href={translate('Dashboard.promotion2')}
								data-tag={translate('Dashboard.promotion2-tag')}
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									className="Di_ShowLargePromo"
									src={`${config.cdn_basePath}/mydinova/Promotions/two-lv.png`}
								/>
								<img
									className="Di_ShowSmallPromo"
									src={`${config.cdn_basePath}/mydinova/Promotions/two-sv.png`}
								/>
							</a>
						</Col>
						<Col md="6" sm="12">
							<a
								onClick={this.onClick}
								href={translate('Dashboard.promotion3')}
								data-tag={translate('Dashboard.promotion3-tag')}
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									className="Di_ShowLargePromo"
									src={`${config.cdn_basePath}/mydinova/Promotions/three-lv.png`}
								/>
								<img
									className="Di_ShowSmallPromo"
									src={`${config.cdn_basePath}/mydinova/Promotions/three-sv.png`}
								/>
							</a>
						</Col>
					</Row>
				)}
				{translate('Dashboard.numberOfPromotions') === '3' && (
					<Row className="no-gutters">
						<Col md="12" sm="12" className="order-sm-2 order-md-1">
							<a
								onClick={this.onClick}
								href={translate('Dashboard.promotion1')}
								data-tag={translate('Dashboard.promotion1-tag')}
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									className="Di_ShowLargePromo"
									src={`${config.cdn_basePath}/mydinova/Promotions/one-lv.png`}
								/>
								<img
									className="Di_ShowSmallPromo"
									src={`${config.cdn_basePath}/mydinova/Promotions/one-sv.png`}
								/>
							</a>
						</Col>
						<Col md="6" sm="12" className="no-margin order-sm-1 order-md-2">
							<a
								onClick={this.onClick}
								href={translate('Dashboard.promotion2')}
								data-tag={translate('Dashboard.promotion2-tag')}
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									className="Di_ShowLargePromo"
									src={`${config.cdn_basePath}/mydinova/Promotions/two-lv.png`}
								/>
								<img
									className="Di_ShowSmallPromo"
									src={`${config.cdn_basePath}/mydinova/Promotions/two-sv.png`}
								/>
							</a>
						</Col>
						<Col md="6" sm="12" className="no-margin order-3">
							<a
								onClick={this.onClick}
								href={translate('Dashboard.promotion3')}
								data-tag={translate('Dashboard.promotion3-tag')}
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									className="Di_ShowLargePromo"
									src={`${config.cdn_basePath}/mydinova/Promotions/three-lv.png`}
								/>
								<img
									className="Di_ShowSmallPromo"
									src={`${config.cdn_basePath}/mydinova/Promotions/three-sv.png`}
								/>
							</a>
						</Col>
					</Row>
				)}
			</div>
		);
	}
}

DashboardPromotions.propTypes = {
	className: PropTypes.string,
	config: PropTypes.object.isRequired,
	outsideEvent: PropTypes.func,
	translate: PropTypes.func.isRequired
};

export default DashboardPromotions;
