import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Row, Col } from 'third-party/mdbreact/dist/mdbreact.js';

class SocialShare extends React.Component {
	handleClick = id => {
		this.trackEvent({ category: `${this.props.parent} - REFER-A-COWORKER PAGE`, action: `LINK - ${id}` });
	};

	trackEvent = event => {
		this.props.outsideEvent(event);
	};

	render() {
		const { className, link, translate } = this.props;
		const classProps = classNames('social-share', className);
		return (
			<div>
				<Row className={'mt-5 mb-5 darker'}>
					<Col className="d-flex justify-content-center">{translate('ReferACoWorker.sharecopy')}</Col>
				</Row>
				<Row className={classProps}>
					<Col />
					<Col className="d-flex justify-content-between social" xs="12" md="8">
						<div>
							<a
								href={`https://twitter.com/home?status=${translate(
									'ReferACoWorker.social.twitter.text'
								)} - ${link}`}
								id="twitter"
								rel="noopener noreferrer"
								target="_blank"
								onClick={() => this.handleClick('twitter')}
							>
								<i className="fab fa-twitter" />
							</a>
						</div>
						<div>
							<a
								href={`https://www.facebook.com/sharer/sharer.php?u=${link}&p&quote=${translate(
									'ReferACoWorker.social.facebook.text'
								)} - ${link}`}
								id="facebook"
								rel="noopener noreferrer"
								target="_blank"
								onClick={() => this.handleClick('facebook')}
							>
								<i className="fab fa-facebook-f" />
							</a>
						</div>
						<div>
							<a
								href={`mailto:?&subject=${translate(
									'ReferACoWorker.social.mail.subject'
								)}&body=${translate('ReferACoWorker.social.mail.text')} - ${link}`}
								id="mail"
								rel="noopener noreferrer"
								target="_blank"
								onClick={() => this.handleClick('email')}
							>
								<i className="far fa-envelope" />
							</a>
						</div>
					</Col>
					<Col />
				</Row>
			</div>
		);
	}
}

SocialShare.propTypes = {
	className: PropTypes.string,
	link: PropTypes.string,
	translate: PropTypes.func,
	outsideEvent: PropTypes.func,
	parent: PropTypes.string.isRequired
};

export default SocialShare;
