// "babel-core": "^6.26.3",
import axios from 'axios';
import { isBoolean, merge } from 'lodash';
import { getVal, hasProp, buildObjWithKeyValue } from 'utils/object';
import { basePath_restaurant } from 'actions/utils';

/**
 * Fetch restaurants from the API. - tested
 * @param  {string|number}  lat          Latitude to search for restaurants near.
 * @param  {string|number}  lng          Longitude to search for restaurants near.
 * @param  {string}         [uom='mi' }] Unit of measure to use in restuarant search (required if distance/radius is provided).
 * @return {Promise}                     Returns a promise that resolves with found restaurants or rejects with a fail message.
 */
export const fetchRestuarants = async ({ lat, lng, uom = 'mi', keywords, radius }) => {
	// TODO: uom
	var URL = `${basePath_restaurant()}/restaurants/search?location=${lat},${lng}&uom=${uom}`;

	if (keywords && keywords.length > 0) {
		URL += `&keywords=${keywords}`;
	}

	if (radius && radius.length > 0) {
		URL += `&radius=${radius}`;
	}

	var results = await axios.get(URL);

	if (results.data.response.code === 200) {
		return results.data.restaurants;
	} else {
		return new Error({ error: results.data.response.code, message: results.data.response.message });
	}
};

export const addFilter = (filter, currentFilters) => {
	if (filter.forEach) {
		let updatedFilters = currentFilters;
		filter.forEach(f => {
			updatedFilters = addFilter(f, updatedFilters);
		});

		return updatedFilters;
	} else {
		if (!filter.selected) {
			filter.selected = true;
		}
		const newMasterFilter = buildObjWithKeyValue(filter.dataAccr.replace('entertainment.', ''), filter);
		const updatedFilters = merge({}, currentFilters, newMasterFilter);
		updatedFilters[filter.dataAccr.split('.')[0]].activeCount += 1;

		return updatedFilters;
	}
};

export const removeFilter = (filter, currentFilters) => {
	const newMasterFilter = buildObjWithKeyValue(filter.dataAccr.replace('entertainment.', ''), filter);
	let currentFilter = getVal(currentFilters, filter.dataAccr);
	currentFilter = buildObjWithKeyValue(filter.dataAccr, { ...currentFilter, selected: false });

	const userFilters = merge({}, currentFilters, currentFilter, newMasterFilter);
	let masterKey = filter.dataAccr.split('.')[0];
	userFilters[masterKey].activeCount -= 1;
	return userFilters;
};

export const filterRestaurants = (filters, restuarants) => {
	let filteredResults = [],
		hasCuisineFilter = filters.cuisine.activeCount > 0,
		hasAmenityFilter = filters.amenities.activeCount > 0,
		hasDietaryFilter = filters.dietaryPreferences.activeCount > 0,
		hasRecommendationFilter = filters.recommendations.activeCount > 0;

	if (!hasCuisineFilter && !hasAmenityFilter && !hasDietaryFilter && !hasRecommendationFilter) {
		console.warn('No active filters, returning originally passed restaurants');
		return restuarants;
	}

	restuarants.forEach(r => {
		const passes = {
			cuisine: false,
			amenities: false,
			dietaryPreferences: false,
			recommendations: false
		};

		Object.values(filters).forEach(value => {
			Object.values(value).forEach(cValue => {
				if (cValue.selected) {
					let [masterKey] = cValue.dataAccr.split('.');
					let dataAccr = cValue.dataAccr;

					if (masterKey === 'cuisine') {
						let cuisine = getVal(r, masterKey);
						if (cuisine) {
							if (cuisine.name === cValue.value) {
								passes[masterKey] = true;
							}
						}
					} else if (masterKey === 'amenities') {
						let amenities = getVal(r, dataAccr);
						if (amenities) {
							passes[masterKey] = true;
						}
					} else if (masterKey === 'dietaryPreferences') {
						let dietaryPreferences = getVal(r, masterKey);
						if (dietaryPreferences) {
							let valid = false;
							let i = 0;
							while (!valid && i < dietaryPreferences.length) {
								if (dietaryPreferences[i].name === cValue.value) {
									valid = true;
								}
								i++;
							}
							if (valid) {
								passes[masterKey] = valid;
							}
						}
					} else if (masterKey === 'recommendations') {
						let recommendations = getVal(r, masterKey);
						recommendations = Object.keys(recommendations.positive.categories);

						if (recommendations) {
							if (recommendations.indexOf(cValue.id + '') > -1) {
								passes[masterKey] = true;
							}
						}
					}
				}
			});
		});
		const fail = Object.values(passes).filter(p => p).length === 0;
		if (!fail) {
			const hasOneFilterType =
				[hasCuisineFilter, hasAmenityFilter, hasDietaryFilter, hasRecommendationFilter].filter(v => v)
					.length === 1;
			if (hasOneFilterType) {
				/* eslint-disable-next-line no-unused-vars */
				const filterType = Object.keys(
					[
						{ cuisine: hasCuisineFilter },
						{ amenities: hasAmenityFilter },
						{ dietaryPreferences: hasDietaryFilter },
						{ recommendations: hasRecommendationFilter }
						/* eslint-disable-next-line no-unused-vars */
					].filter(v => Object.entries(v)[0][1])[0]
				)[0];
				if (passes[filterType]) {
					filteredResults.push(r);
				}
			} else {
				const filterTypes = [
					{ cuisine: hasCuisineFilter },
					{ amenities: hasAmenityFilter },
					{ dietaryPreferences: hasDietaryFilter },
					{ recommendations: hasRecommendationFilter }
				]
					.filter(v => Object.values(v)[0])
					.map(object => Object.keys(object)[0]);
				let passedMultiCheck = [];
				filterTypes.forEach(type => {
					passedMultiCheck.push(passes[type]);
				});

				if (passedMultiCheck.indexOf(false) === -1) {
					filteredResults.push(r);
				}
			}
		}
	});

	return filteredResults;
};

/**
 * IFilterUpdate
 * @interface IFilterUpdate
 * @property {string}  dataAccr   Ex: 'amenities.entertainment'.
 * @property {boolean} [selected]
 */

/**
 * Update a single or multiple IFilterConfig objects.  Uses filterUpdate.selected if provided, otherwise just toggles originalFilters[filterUpdate.dataAccr].selected
 * @param  {object}                        originalFilters {amenities: {}, cuisines: {}, dietaryPreferences: {}}
 * @param  {IFilterUpdate|[IFilterUpdate]} filterUpdates
 * @return {object}                        Returns a new object of originalFilters merged with updated filters.
 */
export const updateFilters = (originalFilters, filterUpdates) => {
	if (filterUpdates.length) {
		let updatedFilters = merge({}, originalFilters);

		filterUpdates.forEach((u, i) => {
			updatedFilters = updateFilters(updatedFilters, filterUpdates[i]);
		});

		return merge({}, updatedFilters);
	} else {
		let originalFilter = getVal(originalFilters, filterUpdates.dataAccr),
			selected = isBoolean(filterUpdates.selected) ? filterUpdates.selected : !originalFilter.selected,
			activeCount = getActiveFilterCount(originalFilter, selected),
			filterTemplate = buildObjWithKeyValue(filterUpdates.dataAccr, { ...originalFilter, selected }),
			masterFilterKey = filterUpdates.dataAccr.split('.')[0],
			updatedFilters = merge({}, originalFilters, filterTemplate, { [masterFilterKey]: { activeCount } });

		return updatedFilters;
	}
};

/**
 * IFilterConfig
 * @interface IFilterConfig
 * @property {boolean} selected If this filter is applied.
 * @property {string}  value    The name of the filter.
 * @property {string}  [id]     The id of the filter.
 * @property {string}  dataAccr The key path to find this specific filter on the main filter onject. Ex: 'amenities.entertainment'
 */

const getActiveFilterCount = (filter, selected) => {
	let activeCount = filter.activeCount || 0;
	activeCount += selected ? 1 : -1;

	if (activeCount < 0) {
		activeCount = 0;
	}

	return activeCount;
};

const filtereDataDeconstructors = {
	amenities: data => {
		let keys = [];
		Object.keys(data).forEach(key => {
			if (key === 'entertainment') {
				keys.push(...Object.keys(data[key]).map(k => `entertainment.${k}`));
			} else {
				keys.push(key);
			}
		});

		return keys;
	},
	cuisine: data => [data],
	dietaryPreferences: data => data || [],
	recommendations: (data, recOptions = {}) => {
		if (!hasProp(data, 'positive.categories')) {
			return [];
		}
		let keys = [];
		Object.keys(data.positive.categories).forEach(key => {
			recOptions.forEach(opt => {
				opt.categories.forEach(cat => {
					if (parseInt(cat.id) === parseInt(key)) {
						keys.push({ ...cat });
					}
				});
			});
		});

		return keys;
	}
};

// Data Factory Map - Transforms API restuarant fitler category data into filter config object.
// Map keys correspond to restaurant data object keys that we use to filter by.
const generateFilterFuncMap = {
	amenities: category => {
		if (category.indexOf('.') > -1) {
			return {
				[category.split('.')[1]]: { resultsCount: 0, selected: false, dataAccr: `amenities.${category}` }
			};
		} else {
			return { [category]: { resultsCount: 0, selected: false, dataAccr: `amenities.${category}` } };
		}
	},
	cuisine: category => {
		if (!category || !category.name) return false;
		return {
			[category.name]: {
				resultsCount: 0,
				selected: false,
				value: category.name,
				id: category.id,
				dataAccr: `cuisine.${category.name}`
			}
		};
	},
	dietaryPreferences: category => ({
		[category.name]: {
			resultsCount: 0,
			selected: false,
			value: category.name,
			id: category.id,
			dataAccr: `dietaryPreferences.${category.name}`
		}
	}),
	recommendations: category => {
		return {
			[category.name]: {
				resultsCount: 0,
				selected: false,
				value: category.name,
				id: category.id,
				dataAccr: `recommendations.${category.name}`
			}
		};
	}
};

const buildFilterKey = (
	key,
	restaurant,
	restuarantIndex,
	currentFilters,
	recOptions,
	dataDeconstructor = filtereDataDeconstructors
) => {
	let category = dataDeconstructor[key](restaurant[key], recOptions);
	let filter = {};

	if (category && category.length) {
		category.forEach(cat => {
			if (cat) {
				let newFilter = generateFilterFuncMap[key](cat);

				if (!filter[key]) {
					filter[key] = {};
				}

				let childDataAccr = cat; // default for amenitiess
				if (['cuisine', 'dietaryPreferences', 'recommendations'].indexOf(key) > -1) {
					childDataAccr = cat.name;
				}

				childDataAccr = childDataAccr.replace('entertainment.', '');
				if (hasProp(currentFilters, `${key}.${childDataAccr}`)) {
					let currentFilter = getVal(currentFilters, `${key}.${childDataAccr}`);
					newFilter[childDataAccr].resultsCount = currentFilter.resultsCount;
					newFilter[childDataAccr].resultsCount += 1;
				} else {
					let dataAccr = Object.keys(newFilter)[0];
					newFilter[dataAccr].resultsCount += 1;
				}

				filter[key] = { ...filter[key], ...newFilter };
			}
		});
	}

	return filter;
};

export const buildRestaurantFilters = (restaurants, recOptions, didRun = false) => {
	let filters = {
		amenities: { activeCount: 0 },
		cuisine: { activeCount: 0 },
		dietaryPreferences: { activeCount: 0 },
		recommendations: { activeCount: 0 }
	};

	let masterFilter = restaurants.reduce((accum, restaurant, i) => {
		let recommendationFilters = buildFilterKey('recommendations', restaurant, i, accum, recOptions);
		let amenityFilters = buildFilterKey('amenities', restaurant, i, accum, recOptions);
		let cuisineFilters = buildFilterKey('cuisine', restaurant, i, accum, recOptions);
		let dietaryFilters = buildFilterKey('dietaryPreferences', restaurant, i, accum, recOptions);

		return merge(filters, amenityFilters, cuisineFilters, dietaryFilters, recommendationFilters);
	}, filters);

	if (Object.keys(masterFilter.recommendations) === 1 && !didRun) {
		return buildRestaurantFilters(restaurants, recOptions, true);
	}

	return masterFilter;
};

/**
 * Use while adding a new amenity filter.
 * @param  {[object]} newFilters   An array of the new filters we want to set.
 * @param  {[object]} userFilters  An array of the filters that were previously set.
 * @return {[object]}              The merged array of new and previously selected filters.
 */
export const mergeSelectedAmenities = (newFilters, userFilters) => {
	let selectedAmenities = Object.values(userFilters.amenities).filter(val => val.selected);
	selectedAmenities.forEach(amenityFilter => {
		let dataKey = amenityFilter.dataAccr.replace('entertainment.', '').split('.')[1];
		if (!newFilters.amenities[dataKey]) {
			newFilters.amenities[dataKey] = userFilters.amenities[dataKey];
		}
		newFilters.amenities[dataKey].selected = true;

		newFilters.amenities.activeCount += 1;
	});
	return newFilters;
};

/**
 * Use while adding a new dietary filter.
 * @param  {[object]} newFilters   An array of the new filters we want to set.
 * @param  {[object]} userFilters  An array of the filters that were previously set.
 * @return {[object]}              The merged array of new and previously selected filters.
 */
export const mergeSelectedDietaryPreferences = (newFilters, userFilters) => {
	let selectedCuisine = Object.values(userFilters.dietaryPreferences).filter(val => val.selected);
	selectedCuisine.forEach(dietaryFilter => {
		let dataKey = dietaryFilter.dataAccr.split('.')[1];
		if (!newFilters.dietaryPreferences[dataKey]) {
			// newFilters.dietaryPreferences[dataKey] = { ...dietaryFilter, resultsCount: 1 };
		} else {
			newFilters.dietaryPreferences[dataKey].selected = true;
			newFilters.dietaryPreferences.activeCount += 1;
		}
	});
	return newFilters;
};

/**
 * Use while adding a new dietary filter.
 * @param  {[object]} newFilters   An array of the new filters we want to set.
 * @param  {[object]} userFilters  An array of the filters that were previously set.
 * @return {[object]}              The merged array of new and previously selected filters.
 */
export const mergeSelectedCuisine = (newFilters, userFilters) => {
	let selectedCuisine = Object.values(userFilters.cuisine).filter(val => val.selected);
	selectedCuisine.forEach(cuisineFilter => {
		let dataKey = cuisineFilter.dataAccr.split('.')[1];
		if (!newFilters.cuisine[dataKey]) {
			// newFilters.cuisine[dataKey] = { ...cuisineFilter, resultsCount: 1 };
		} else {
			newFilters.cuisine[dataKey].selected = true;
			newFilters.cuisine.activeCount += 1;
		}
	});
	return newFilters;
};

export const mergeSelectedRecommendation = (newFilters, userFilters) => {
	let selectedCuisine = Object.values(userFilters.recommendations).filter(val => val.selected);
	selectedCuisine.forEach(recommendationsFilter => {
		let dataKey = recommendationsFilter.dataAccr.split('.')[1];
		if (!newFilters.recommendations[dataKey]) {
			// newFilters.recommendations[dataKey] = { ...recommendationsFilter, resultsCount: 1 };
		} else {
			newFilters.recommendations[dataKey].selected = true;
			newFilters.recommendations.activeCount += 1;
		}
	});
	return newFilters;
};

export const extractFilter = (key, filters) => {
	return { [key]: { ...filters[key] } };
};

export const recomputeFilterOptions = (userFilters, results, recOptions) => {
	let amenityFilters = extractFilter('amenities', userFilters);
	let cuisineFilters = extractFilter('cuisine', userFilters);
	let dietaryFilters = extractFilter('dietaryPreferences', userFilters);
	let recommendationFilters = extractFilter('recommendations', userFilters);

	// new amenity
	let newAmenityFilters = recomputeFilterTypeOptions(
		'amenities',
		[cuisineFilters, dietaryFilters, recommendationFilters],
		results,
		recOptions
	);
	newAmenityFilters = mergeSelectedAmenities(newAmenityFilters, userFilters);

	// new dietary
	let newDietaryFilters = recomputeFilterTypeOptions(
		'dietaryPreferences',
		[amenityFilters, cuisineFilters, recommendationFilters],
		results,
		recOptions
	);
	newDietaryFilters = mergeSelectedDietaryPreferences(newDietaryFilters, userFilters);

	// new cuisine
	let newCuisineFilters = recomputeFilterTypeOptions(
		'cuisine',
		[amenityFilters, dietaryFilters, recommendationFilters],
		results,
		recOptions
	);
	newCuisineFilters = mergeSelectedCuisine(newCuisineFilters, userFilters);

	// new recommendations
	let newRecommendationFilters = recomputeFilterTypeOptions(
		'recommendations',
		[amenityFilters, dietaryFilters, cuisineFilters],
		results,
		recOptions
	);
	newRecommendationFilters = mergeSelectedRecommendation(newRecommendationFilters, userFilters);

	const NEW_FILTERS = Object.assign(
		{},
		newAmenityFilters,
		newCuisineFilters,
		newDietaryFilters,
		newRecommendationFilters
	);
	return NEW_FILTERS;
};

export const recomputeFilterTypeOptions = (filterType, filters = [], results, recOptions) => {
	let resultsToBuildFiltersFrom = filterRestaurants(
		Object.assign({}, { [filterType]: { activeCount: 0 } }, ...filters),
		results,
		recOptions
	);
	let newFilters = extractFilter(filterType, buildRestaurantFilters(resultsToBuildFiltersFrom, recOptions));

	return newFilters;
};
