import * as t from '../actions/easteregg/types';

/**
 * Redux_EasterEggState
 * @interface Redux_HomeState
 * @property {string} [darylSearch=false]
 */
const easterEggDefaultState = {
	darylSearch: false
};

export default (state = easterEggDefaultState, action) => {
	switch (action.type) {
		case t.DARYLS_EASTEREGG: {
			return { ...state, darylSearch: action.payload };
		}

		default:
			return state;
	}
};
