// utils that work with DOM elements.

/**
 * Add a className to the body element.
 * @param {string} className The className to add to the body tag.
 */
export const addBodyClassName = className => {
	document.getElementsByTagName('body')[0].classList.add(className);
};

/**
 * Remove a className to the body element.
 * @param {string} className The className to remove to the body tag.
 */
export const removeBodyClassName = className => {
	document.getElementsByTagName('body')[0].classList.remove(className);
};

/**
 * Use a boolean to determine if add or remove className from the body tag.
 * @param {string}  className The className to toggle on the body tag.
 * @param {boolean} add       If to add the className, if false, removes the className.
 */
export const toggleBodyClassName = (className, add) => {
	if (add) {
		addBodyClassName(className);
	} else {
		removeBodyClassName(className);
	}
};
