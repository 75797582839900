import Social from './ui-component';
import { connect } from 'react-redux';

const mapStateToProps = state => {
	const socials = state.details.restaurant && state.details.restaurant.social ? state.details.restaurant.social : {};

	return { socials };
};

export default connect(mapStateToProps)(Social);
