import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Text, Title } from 'components/atoms';
import { getTranslate } from 'react-localize-redux';

/*
	Header for FAQ page.
 */
const HelpHeader = ({ translate }) => {
	return (
		<div className="help-header">
			<Title>{translate('HelpFAQ.header')}</Title>
			<Text align="center">{translate('HelpFAQ.main')}</Text>
		</div>
	);
};

HelpHeader.defaultProps = {};

HelpHeader.propTypes = {
	translate: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	translate: getTranslate(state.locale)
});

export default connect(mapStateToProps)(HelpHeader);
