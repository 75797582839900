import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Text } from 'components/atoms';

// import './style.scss';

const GridHeader = ({ isSV, isMV, labels, type, withBorder }) => {
	const classProps = classnames('grid-header row', withBorder && 'border-bottom', type && type);

	return (
		<div className={classProps}>
			{labels.map(l => {
				return l.hidden && l.hidden(isSV) ? null : (
					<div key={l.text} className={l.colClass}>
						<Text className="grid-header-item" size="sm" align={l.align || 'center'}>
							{isSV && l.svText ? l.svText : isMV && l.mvText ? l.mvText : l.text}
						</Text>
					</div>
				);
			})}
		</div>
	);
};

GridHeader.defaultProps = {
	withBorder: true
};

GridHeader.propTypes = {
	labels: PropTypes.array,
	isSV: PropTypes.bool,
	isMV: PropTypes.bool,
	withBorder: PropTypes.bool,
	type: PropTypes.string
};

export default GridHeader;
