import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Text } from 'components/atoms';

const SearchPageViewToggles = ({
	IMAGE_BASE_URL,
	isSV,
	selectedFilters,
	showFilter,
	showList,
	toggleShowFilter,
	toggleShowList,
	translate
}) => {
	const toogleListImgName = showList && isSV ? 'map' : 'list',
		toggleListLabel = (showList ? 'Search.hideList' : 'Search.showList') + (isSV ? 'Short' : ''),
		toggleListImg = `${IMAGE_BASE_URL}/icons/icon-${toogleListImgName}-green.svg`,
		toggleListClassProps = classnames(toogleListImgName + '-icon', 'icon');

	return (
		<div className="search-page-view-toggles">
			<div className="title">
				{isSV && selectedFilters.length > 0 && <div className="filter-count">{selectedFilters.length}</div>}
				{isSV && (
					<Text
						dataCy="mobile-show-filters-toggle"
						size="sm"
						weight="bold"
						onClick={toggleShowFilter.bind(this, !showFilter)}
					>
						{translate('FilterModal.title')}
					</Text>
				)}
			</div>

			<div className="toggle-list-btn" onClick={toggleShowList.bind(this, !showList)}>
				<img className={toggleListClassProps} src={toggleListImg} />
				<Text size="sm" weight="bold" className="label">
					{translate(toggleListLabel)}
				</Text>
				{/*<span className="label">{translate(toggleListLabel)}</span>*/}
			</div>
		</div>
	);
};

SearchPageViewToggles.defaultProps = {
	selectedFilters: []
};

SearchPageViewToggles.propTypes = {
	IMAGE_BASE_URL: PropTypes.string.isRequired,
	isSV: PropTypes.bool.isRequired,
	selectedFilters: PropTypes.array.isRequired,
	showFilter: PropTypes.bool.isRequired,
	showList: PropTypes.bool.isRequired,
	toggleShowFilter: PropTypes.func.isRequired,
	toggleShowList: PropTypes.func.isRequired,
	translate: PropTypes.func.isRequired
};

export default SearchPageViewToggles;
