import SearchTabs from './ui-component';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setActiveTab } from 'actions/home';
import { hasProp } from 'utils/object';
import BusinessSearchTab from 'components/molecules/BusinessSearchTab';
import CateringSearchTab from 'components/molecules/CateringSearchTab';

export const SEARCH_TYPES = [
	{ key: 'businessTab', component: BusinessSearchTab },
	// { key: 'privateTab' },
	{ key: 'cateringTab', component: CateringSearchTab }
];

const mapStateToProps = state => ({
	activeTab: hasProp(state, 'home.activeTab') ? state.home.activeTab : ''
});

const mapDispatchToProps = dispatch => ({
	setActiveTab: tab => dispatch(setActiveTab(tab))
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(SearchTabs)
);
