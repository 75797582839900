import RecentSearches from './ui-component';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { setSearchSuggestion } from 'actions/home';

const mapStateToProps = state => ({
	hasGeolocation: state.general.hasGeolocation,
	translate: getTranslate(state.locale)
});

const mapDispatchToProps = dispatch => ({
	setSearchSuggestion: value => dispatch(setSearchSuggestion(value))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RecentSearches);
