import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import StepCount from 'components/molecules/StepCount';

const StepIndicators = props => {
	const { className, registration } = props;
	const classProps = classNames('step-indicators', className);

	return (
		<div className={classProps}>
			{registration.steps.map((step, index) => (
				<StepCount
					key={index}
					stepNumber={index + 1}
					stateObj={registration}
					stepTitle={step.title}
					stepText={step.text}
					currentStep={registration.currentStep}
				/>
			))}
		</div>
	);
};

StepIndicators.propTypes = {
	className: PropTypes.string,
	registration: PropTypes.object
};

export default StepIndicators;
