import { findIndex, has, isNull, isUndefined } from 'lodash';
import { STORE } from 'components/environment/App/redux_store.js';

export const translationExists = id => {
	const locale = STORE.getState().locale;
	const translations = locale.translations;

	let currLangIndex = findIndex(locale.languages, { active: true });
	return (
		has(translations, id) &&
		!isNull(translations[id][currLangIndex]) &&
		!isUndefined(translations[id][currLangIndex])
	);
};
