import SimpleMapMarker from './ui-component';
import { connect } from 'react-redux';
import withSizes from 'react-sizes';
import { isSV } from 'utils/sizes';
import { saveSearchState } from 'actions/search_page';

const mapStateToProps = store => ({
	IMAGE_BASE_URL: store.config.cdn_basePath,
	isDarylSearch: store.easterEggs.darylSearch
});

const mapDispatchToProps = dispatch => ({
	saveSearchState: () => dispatch(saveSearchState())
});

const mapSizesToProps = sizes => ({
	isSV: isSV(sizes)
});

export default withSizes(mapSizesToProps)(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(SimpleMapMarker)
);
