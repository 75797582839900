import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { InputGroup, InputGroupAddon, Button } from 'reactstrap';
import { isFunction, isEmpty, range } from 'lodash';
import { geocodeByAddress, geocodeByPlaceId } from 'react-places-autocomplete';
import { LocationAutocompleteDropdown, LocationAutocompleteInput } from 'components/molecules';
import { history } from 'utils/router';
import {
	CalendarDropdown,
	CateringServiceNotice,
	SearchDropdown,
	Select,
	ViewCateringDashboard
} from 'components/molecules';
import { CateringUnavailableModal } from 'components/organisms';
import { fetchReverseGeoLocation } from 'utils/reverse_geo_location';
import { LOCAL_STORAGE_RECENT_SEARCH_KEY } from 'utils/search';

const LOCAL_STORAGE_KEY = `catering${LOCAL_STORAGE_RECENT_SEARCH_KEY}`;

class CateringSearchTab extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showSearchDropdown: false,
			address: '',
			googleAddress: undefined,
			ready: false,
			hasError: false,
			errorMessage: undefined,
			hasSubmitted: false
		};

		this.listCount = this.props.translate('Home.cateringSearch.typeInput.list.count');
		this.items = range(this.listCount).map(index => ({
			key: this.props.translate(`Home.cateringSearch.typeInput.list.items.${index}.key`),
			value: this.props.translate(`Home.cateringSearch.typeInput.list.items.${index}.value`)
		}));
	}

	componentDidMount() {
		const { users, getProfile } = this.props;
		const showLoading = false;
		users.authenticated && getProfile(showLoading);
		document.addEventListener('mousedown', this.handleClickOutside);
		// document.addEventListener('keydown', this.enterPress, false);
		this.setState({ hasSubmitted: false });
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
		// document.removeEventListener('keydown', this.enterPress, false);
	}

	componentDidUpdate(oldProps, oldState) {
		const newProps = this.props;
		const newState = this.state;
		if (oldProps.catering.serviceType !== newProps.catering.serviceType) {
			// console.log(oldProps.catering.serviceType, newProps.catering.serviceType);
			window.zupplerBridge && this.saveService();
		}
		if (oldProps.catering.date !== newProps.catering.date) {
			// console.log('old:new:' + oldProps.catering.date + ':' + newProps.catering.date);
			window.zupplerBridge && this.saveOrderDate();
		}
		if (oldProps.catering.address !== newProps.catering.address) {
			window.zupplerBridge && this.saveAddress();
		}

		if (oldState.ready === false && newState.ready === true) {
			// console.log('ready', oldState.ready, newState.ready);
			window.zupplerBridge && this.saveService().then(this.saveOrderDate);
		}
	}

	handleChange = address => {
		this.setState({
			address
		});
	};

	// /**
	//  * ZupplerBridge methods
	//  */

	saveAddress = () => {
		if (this.props.catering.address.geometry) {
			if (isFunction(this.props.catering.address.geometry.location.lat)) {
				return window.zupplerBridge.setAddressFromGoogle(this.props.catering.address);
			} else {
				const self = this;
				return new Promise(function(resolve) {
					geocodeByPlaceId(self.props.catering.address.place_id).then(results => {
						resolve(window.zupplerBridge.setAddressFromGoogle(results[0]));
					});
				});
			}
		}
	};

	saveService = () => {
		return window.zupplerBridge.setServiceType(this.props.catering.serviceType.toUpperCase(), 'Catering');
	};

	saveOrderDate = () => {
		return window.zupplerBridge.setOrderDate(this.props.catering.date);
	};

	resetFilters = () => {
		return window.zupplerBridge.resetFilters();
	};

	setPage = page => {
		return window.zupplerBridge.setPage(page);
	};

	// /**
	//  * End ZupplerBridge methods
	//  */

	/**
	 * Autocomplete Address Dropdown select option handler.
	 */
	handleSelect = async address => {
		this.setState({ address });
		this.props.setBusinessSearch(address);
		// this.props.placeAutocomplete(address);
		await geocodeByAddress(address)
			.then(results => {
				this.handleResponse(results[0]);
				return results;
			})
			.catch(error => console.error('Error', error));
	};

	handleResponse = googleAddress => {
		this.props.setAddress(googleAddress);
		this.setState({ showSearchDropdown: false });
	};

	/**
	 * Autocomplete Address Input change handler.
	 */
	onChange = e => {
		// console.log('e:', e.target);
		if (!e.target.value.length) {
			this.clearLocationSearch();
		} else {
			this.setState({ address: e.target.value });
		}
	};

	/**
	 * Reset all location state from component and redux.
	 */
	clearLocationSearch = () => {
		this.setState({ address: '', showSearchDropdown: true });
		this.props.setBusinessSearch();
		this.props.setAddress(null);
		this.props.setLocationAutocompleteSearch();
	};

	/**
	 * Autocomplete Address Dropdown select option handler.
	 */
	onSelect = async address => {
		this.setState({ showSearchDropdown: false });
		await this.handleSelect(address);
	};

	closeCateringServiceNotice = () => {
		this.props.setShowNoServiceNotice(false);
	};

	render() {
		const { className, geoLocationDenied, translate } = this.props;
		const { showSearchDropdown } = this.state;

		const autoCompleteValue = this.state.address
			? this.state.address
			: this.props.catering.address.formatted_address;

		const classProps = classNames(
			'search-tab catering-search-tab',
			className,
			geoLocationDenied && !autoCompleteValue && 'hasError'
		);

		const locationInputClassProps = classNames(
			'location-input-wrapper',
			geoLocationDenied && !autoCompleteValue && 'error'
		);
		const searchBtnClassProps = classNames('search-btn', !autoCompleteValue && geoLocationDenied && 'disabled');

		return (
			<React.Fragment>
				<CateringUnavailableModal
					show={this.props.showCateringZoneUnavailable}
					onClose={this.closeCateringServiceNotice}
				/>
				{this.props.isSV && <CateringServiceNotice />}
				{/*this.state.hasError && <PageError message={this.state.errorMessage} />*/}
				<div className={classProps}>
					<InputGroup className="search-group">
						<div className="type-input-wrapper">
							<span className="input-label">{translate('Home.cateringSearch.typeInput.label')}</span>
							<Select
								className="catering-type-select"
								items={this.items}
								onSelect={this.props.setServiceType}
							/>
						</div>
						<div className={locationInputClassProps}>
							<span className="input-label">
								{translate('Home.cateringSearch.locationInput.label')}{' '}
								<span className="required">*</span>
							</span>
							<SearchDropdown
								forwardRef={this.setSearchRef}
								show={showSearchDropdown}
								type="catering"
								recent="near"
								handleGetLocation={this.handleGetCurrentLocationAddress}
								recentCallback={async data => {
									this.props.setLocationAutocompleteSearch(data.near);
									await this.handleSelect(data.near);
									this.toggleSearchDropdown();
								}}
								autoComplete={{
									input: LocationAutocompleteInput,
									inputValue: autoCompleteValue,
									dropdown: LocationAutocompleteDropdown,
									onSelect: this.handleSelect,
									onChange: this.onChange
								}}
							/>
							{geoLocationDenied && !autoCompleteValue && (
								<span className="error">
									{this.props.translate('ModifyModal.provideLocationError')}
								</span>
							)}
						</div>
						<div className="date-input-wrapper">
							<span className="input-label">
								{translate('Home.cateringSearch.dateInput.label')} <span className="required">*</span>
							</span>
							<CalendarDropdown />
						</div>
						<InputGroupAddon addonType="prepend" className="search-btn-wrapper">
							<Button color="primary" className={searchBtnClassProps} onClick={this.onSearch}>
								{translate('Home.businessSearch.searchBtn')}
							</Button>
						</InputGroupAddon>
					</InputGroup>

					<div className="search-tab-footer">
						<img className="splash" src={`${this.props.imageBaseUrl}/graphics/salad.png`} />
						{!this.props.isSV && <CateringServiceNotice />}
						<ViewCateringDashboard />
					</div>
				</div>
			</React.Fragment>
		);
	}

	/**
	 * Set the search input group ref
	 */
	setSearchRef = node => {
		this.searchRef = node;
	};

	handleClickOutside = event => {
		if (this.props.isActive) {
			if (this.searchRef && !this.searchRef.contains(event.target)) {
				this.toggleSearchDropdown(false);
			} else {
				this.toggleSearchDropdown(true);
			}
		}
	};

	onSearch = async () => {
		this.setState({ hasSubmitted: true });
		let pos;

		if (!isEmpty(this.state.address)) {
			// TODO: this triggers a geolocation to get a google object before passing to Zuppler
			// If Zuppler allows us to pass a string, we can remove this and replace with the new string method.
			await this.handleSelect(this.state.address);

			const { location } = this.props.catering.address.geometry;

			pos = {
				lat: location.lat(),
				lng: location.lng()
			};
		} else if (isEmpty(this.props.catering.address)) {
			let { location } = this.props;
			if (location.lat === 0) {
				const loc = await this.handleGetCurrentLocationAddress(),
					{ latitude, longitude } = loc.geoLocation.coords;
				pos = { lat: latitude, lng: longitude };
			} else {
				pos = { lat: location.lat, lng: location.lng };
				const address = await fetchReverseGeoLocation(pos, results => {
					return new Promise(resolve => {
						resolve(results[0]);
					});
				});
				this.props.setAddress(address);
			}
		} else {
			// if catering search input
			const { location } = this.props.catering.address.geometry;

			pos = {
				lat: location.lat(),
				lng: location.lng()
			};
		}

		// fetch if zones exist for location search.
		const zones = await this.props.getZones(pos);

		if (zones.length > 0) {
			this.updateRecentSearches();
			this.submitSearch();
			this.props.setShowNoServiceNotice(false);
		} else {
			this.props.setShowNoServiceNotice(true);
		}
	};

	updateRecentSearches = () => {
		let newSearch = {
			near: this.props.catering.address.formatted_address,
			date: this.props.catering.date,
			serviceType: this.props.catering.serviceType
		};
		let currentSearches = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY)) || [];
		currentSearches.push(newSearch);
		window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(currentSearches));
	};

	submitSearch = () => {
		try {
			this.saveRequestThenRedirect();
		} catch (err) {
			this.setState({ hasSubmitted: false });
		}
	};

	handleGetCurrentLocationAddress = async () => {
		let geoLocation;
		if (!this.props.geoLocationDenied) {
			try {
				geoLocation = await this.props.getLocation();
			} catch (err) {
				geoLocation = false;
			}
		} else {
			geoLocation = false;
		}

		if (!geoLocation) {
			return this.reverseGeoLocationFail('geocode-failed-permissions');
		} else {
			return { geoLocation, address: await this.fetchReverseGeoLocation(geoLocation) };
		}
	};

	fetchReverseGeoLocation = async geoLocation => {
		const { latitude, longitude } = geoLocation.coords;
		const pos = { lat: latitude, lng: longitude };
		this.props.setAddress(pos);

		return await fetchReverseGeoLocation(pos, (results, status) => {
			if (status === 'OK') {
				if (results[0]) {
					return this.reverseGeoLocationSuccess(results);
				} else {
					return this.reverseGeoLocationFail('geocode-no-results');
				}
			} else {
				return this.reverseGeoLocationFail('geocode-failed-other');
			}
		});
	};

	reverseGeoLocationSuccess = results => {
		this.props.isLoading(false);

		if (results[0]) {
			// console.log('blah', results[0]);
			this.setState({ address: results[0].formatted_address });
			this.props.setBusinessSearch(results[0].formatted_address);
			this.handleResponse(results[0]);
			return results[0].formatted_address;
		}
	};

	reverseGeoLocationFail = messageKey => {
		// console.log('reverse fail');
		this.props.isLoading(false);

		this.setState({
			hasError: true,
			errorMessage: this.props.translate(`Catering.errors.${messageKey}`)
		});

		return false;
	};

	saveRequestThenRedirect = () => {
		const me = this;
		me.resetFilters().then(() => {
			me.saveAddress().then(() => {
				me.saveService().then(() => {
					me.saveOrderDate().then(() => {
						window.zupplerBridge.setPage('/_');
						history.push('/catering/#/');
					});
				});
			});
		});
	};

	toggleSearchDropdown = (show = false) => {
		this.setState({ showSearchDropdown: show });
	};

	onInputChange = event => {
		const newValue = event.target.value;
		this.props.setBusinessSearch(newValue);
		this.props.placeAutocomplete(newValue);
	};

	enterPress = event => {
		if (this.props.isActive) {
			if (event.key === 'Enter') {
				// do a search
				this.onSearch();
			}
		}
	};
}

CateringSearchTab.defaultProps = {
	className: ''
};
CateringSearchTab.propTypes = {
	businessSearch: PropTypes.string,
	catering: PropTypes.object,
	className: PropTypes.string,
	config: PropTypes.object,
	getProfile: PropTypes.func,
	home: PropTypes.object,
	isActive: PropTypes.bool,
	imageBaseUrl: PropTypes.string,
	getLocation: PropTypes.func.isRequired,
	geoLocationDenied: PropTypes.bool,
	isLoading: PropTypes.func,
	isLV: PropTypes.bool,
	isSV: PropTypes.bool,
	location: PropTypes.object,
	placeAutocomplete: PropTypes.func,
	setBusinessSearch: PropTypes.func,
	setAddress: PropTypes.func,
	setDate: PropTypes.func,
	setLocationAutocompleteSearch: PropTypes.func.isRequired,
	setServiceType: PropTypes.func,
	translate: PropTypes.func,
	users: PropTypes.object,
	getZones: PropTypes.func,
	setShowNoServiceNotice: PropTypes.func,
	showCateringZoneUnavailable: PropTypes.bool
};

export default CateringSearchTab;
