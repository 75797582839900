import Promise from 'bluebird';
import * as t from './types';
import { isLoading } from 'actions/general';

/**
 * Async method to fetch user's navigator.geolocation
 * @param  {function} dispatch Redux dispatch func
 * @return {Promise}           Returns a promise that resolves to either a google.Position object or a google.PositionError obejct.
 * @modifies state.geoLocation - lat, lng, wasDenied, isLoading, hasGeoLocation
 */
export const getLocation = dispatch => {
	dispatch({ type: t.FETCH_GEO_LOCATION_START });
	return new Promise((resolve, reject) => {
		const geolocation = navigator.geolocation;
		dispatch(isLoading(true, 'Getting Geolocation'));
		// init location services
		geolocation.getCurrentPosition(
			pos => {
				dispatch({
					type: t.FETCH_GEO_LOCATION,
					payload: pos
				});
				dispatch(isLoading(false));
				resolve(pos);
			},
			err => {
				dispatch({
					type: t.FETCH_GEO_LOCATION,
					payload: err
				});
				dispatch(isLoading(false));
				reject(new Error({ error: err, message: err }));
			},
			{
				timeout: 15000,
				enableHighAccuracy: false,
				maximumAge: 50000
			}
		);
	});
};
