import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Row, CardBody } from 'third-party/mdbreact/dist/mdbreact.js';
import { Col } from 'reactstrap';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import { connect } from 'react-redux';
import metaTagConfig from './meta_tags/home-meta-tags';
import { MetaTags } from 'components/molecules';
import { updateHeader } from '../../../actions/general';

class Home extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isOpen: false
		};
	}

	componentDidMount() {
		this.props.updateHeader(false, true);
	}
	componentWillUnmount() {
		this.props.updateHeader(true, true);
	}

	render() {
		const { config, history, translate } = this.props;
		return (
			<div className="Di_SubHead Di_HomePic" onClick={void 0}>
				<MetaTags tags={metaTagConfig} />
				<div className="container-fluid Di_HeadSpace">
					<Row>
						<div className="Di_PageLimit mx-auto float-none animated fadeInUp">
							<CardBody>
								<div className="container-fluid">
									<Row>
										<Col>
											<div className="Di_MyDinovaLogo">
												<img
													src={`${config.cdn_basePath}/logos/myDinova_logo_final.svg`}
													alt={translate('home.myDinovaLogoAlt')}
													width="256px"
												/>
											</div>
										</Col>
									</Row>
									<Row>
										<Col>
											<p className="WhatAreRewards">{translate('home.headline')}</p>
											<p className="WhatAreRewards2">{translate('home.headlinesub')}</p>
											<div className="Di_HomeText">{translate('home.body')}</div>
										</Col>
									</Row>
									<Row className="Di_mt5px Di_ActionBttns">
										<Col xs="12">
											<Button
												className="z-depth-0"
												color="primary"
												onClick={e => {
													e.preventDefault();
													history.push('/login?redirect=/mydinova');
												}}
											>
												{translate('home.bttn1')}
											</Button>

											<Button
												className="z-depth-0"
												onClick={() => {
													history.push('/users/register');
												}}
											>
												{translate('home.bttn2')}
											</Button>
										</Col>
									</Row>
								</div>
							</CardBody>
						</div>
					</Row>
				</div>
			</div>
		);
	}
}

Home.propTypes = {
	config: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	translate: PropTypes.func.isRequired,
	updateHeader: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	translate: getTranslate(state.locale),
	currentLanguage: getActiveLanguage(state.locale).code,
	config: state.config
});

const mapDispatchToProps = dispatch => ({
	updateHeader: (useMyDinovaLogo, showMenu) => dispatch(updateHeader(useMyDinovaLogo, showMenu))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Home);
