import React from 'react';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const AreaSearchBtn = ({ className, onClick, translate }) => {
	const classProps = classNames('area-search-btn modify-btn', className);

	return (
		<Button className={classProps} color="primary" onClick={onClick}>
			{translate('Search.areaSearchBtn')}
		</Button>
	);
};

AreaSearchBtn.defaultProps = {
	className: ''
};

AreaSearchBtn.propTypes = {
	// Required
	onClick: PropTypes.func.isRequired,
	translate: PropTypes.func.isRequired,

	// Optional
	className: PropTypes.string
};

export default AreaSearchBtn;
