import React from 'react';
import PropTypes from 'prop-types';
import { ContactItem } from 'components/atoms';

/**
 * Used in {@link DetailsPage} to display contact information.
 * @param {ContactInfoProps} props
 */
const ContactInfo = ({ contacts }) => (
	<div className="details-contact">
		<div className="contact-info">
			{contacts.map((contact, i) => {
				return <ContactItem key={`contact-info-${i}`} contact={contact} />;
			})}
		</div>
	</div>
);

ContactInfo.defaultProps = {
	contacts: [
		{
			icon: null,
			label: 'Main',
			title: '555-555-5555',
			url: 'tel:555-555-5555'
		}
	]
};

/**
 * {@link ContactInfo} Props
 * @interface Props_ContactInfo
 * @property {IContact[]}
 */
ContactInfo.propTypes = {
	// Optional
	contacts: PropTypes.array
};

export default ContactInfo;
