/**
 * Size can define for mobile, tablet, and desktop.  For each view port provide tallRect, wideRect, and square props.
 * @interface ISize
 * @example {
mobile: {
	tallRect: {
		height: '120px',
		width: '120px',
		whiteSpace: '10px' // optional - if provided will add padding to the component.
	}
}
}
 */

/**
 * Takes an object with height and width properties.  Determines if height and width make a tall rect, wide rect, or square.  Based on shape, returns an object with height and width sizes appropriate for a logo.
 * @param  {string}     height css size with unit.
 * @param  {string}     width  css size with unit.
 * @param  {BreakPoint} currentBreakPoint  css size with unit.
 * @param  {ISize}       sizes  css size with unit. Note: sizes must be defined for square, tallRect, and wideRect.
 * @return {object}
 */
export const configureLogoImageSize = ({ height, width }, currentBreakPoint, sizes) => {
	const tallRect = width < height;
	const wideRect = width > height;

	const viewPort = currentBreakPoint.tag;

	// defaults to square;
	let style = sizes[viewPort].square;

	if (tallRect) {
		style = sizes[viewPort].tallRect;
	} else if (wideRect) {
		style = sizes[viewPort].wideRect;
	}

	return style;
};
