import React from 'react';
import PropTypes from 'prop-types';
import { Link, Text } from 'components/atoms';

const RecommendationCountMessage = ({ count, didRecommend, onClick, translate }) => {
	const translateKey = count === 0 ? 'notRecommendedYet' : 'recommendedCount';
	let msg = translate(`Recommendations.${translateKey}`).replace('{count}', count);

	if (didRecommend && count === 0) {
		msg = translate('Recommendations.submitThankYou');
	}

	return (
		<Link className="recommendation-count" onClick={count > 0 ? onClick : undefined} dataCy="recommendations-count">
			<Text size="sm" dataCy="recommendations-count">
				{msg}
			</Text>
		</Link>
	);
};

RecommendationCountMessage.defaultProps = {
	count: 0
};

RecommendationCountMessage.propTypes = {
	count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	didRecommend: PropTypes.bool.isRequired,
	onClick: PropTypes.func.isRequired,
	translate: PropTypes.func.isRequired
};

export default RecommendationCountMessage;
