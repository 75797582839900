export const GET_CURRENT_GEOLOCATION = 'GET_CURRENT_GEOLOCATION';
export const IS_LOADING = 'IS_LOADING';
export const PAGE_VIEW = 'PAGE_VIEW';
export const OUTSIDE_EVENT = 'OUTSIDE_EVENT';
export const REQUEST_NAV = 'REQUEST_NAV'; // Note: is this used?
export const REQUEST_STRINGS = 'REQUEST_STRINGS';
export const RESET_STATE = 'RESET_STATE';
export const SET_ANDROID = 'SET_ANDROID';
export const SET_CAMPAIGN_ID = 'SET_CAMPAIGN_ID';
export const SET_COMPANY = 'SET_COMPANY';
export const SET_COMPANY_CODE = 'SET_COMPANY_CODE';
export const SET_COMPANY_NAME = 'SET_COMPANY_NAME';
export const SET_ERROR = 'SET_ERROR';
export const SET_FEED_TYPE = 'SET_FEED_TYPE';
export const SET_PREFERRED_CC_TYPE = 'SET_PREFERRED_CC_TYPE';
export const SET_PREFERRED_CONNECTION_ID = 'SET_PREFERRED_CONNECTION_ID';
export const SET_PREFERRED_EMPID_TYPE = 'SET_PREFERRED_EMPID_TYPE';
export const STRINGS_FETCHED_DATA = 'STRINGS_FETCHED_DATA';
export const STRINGS_FETCHED_ERROR = 'STRINGS_FETCHED_ERROR';
export const STRINGS_FETCHED_SUCCESS = 'STRINGS_FETCHED_SUCCESS';
export const TOGGLE_NAV = 'TOGGLE_NAV';
export const UPDATE_HEADER = 'UPDATE_HEADER';
