import React from 'react';
import PropTypes from 'prop-types';
import { StepTitle } from 'components/atoms';

const StepCount = props => {
	const { currentStep, stepNumber, stepTitle } = props;

	const currentStepStyle = (currentStep, stepNumber) => {
		if (stepNumber === currentStep) {
			return 'active';
		} else if (stepNumber > currentStep) {
			return 'incomplete';
		} else {
			return 'completed';
		}
	};

	return (
		<div className={currentStepStyle(currentStep, stepNumber) + ' count'}>
			<span className="step-number">{stepNumber}</span>
			<StepTitle stepTitle={stepTitle} />
		</div>
	);
};

StepCount.propTypes = {
	currentStep: PropTypes.string,
	stepNumber: PropTypes.string,
	stepTitle: PropTypes.string
};

export default StepCount;
