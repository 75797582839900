import * as t from './types';

/**
 * Sets the current business dinning search.
 * @param {String} [value=''] The user's search string.
 */
export const setBusinessSearch = (value = '') => {
	return { type: t.SET_BUSINESS_SEARCH, value };
};

/**
 * Sets the current business dinning search by name.
 * @param {String} [value=''] The name of the resturant being searched for.
 */
export const setFindBusinessSearch = (value = '') => {
	return { type: t.SET_FIND_BUSINESS_SEARCH, value };
};

/**
 * Sets the current business dinning search by location.
 * @param {String} [value=''] The location to search for nearby restaurants.
 */
export const setNearBusinessSearch = (value = '') => {
	return { type: t.SET_NEAR_BUSINESS_SEARCH, value };
};

// NOTE: Not sure what this is doing.
export const setSearchSuggestion = (value = '') => {
	return { type: t.SET_SEARCH_SUGGESTION, value };
};

/**
 * Set the active search tab.
 * @param {string} tab The tab to set active. Avialable tabs are "businessSearch", 'cateringSearch', and 'privateDining'.
 */
export const setActiveTab = tab => {
	return { type: t.SET_ACTIVE_TAB, value: tab };
};
