export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_USER = 'AUTH_USER';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR';
export const MIGRATE_PASSWORD = 'MIGRATE_PASSWORD';
export const REFRESH_TIME = 'REFRESH_TIME';
export const REFRESH_USER = 'REFRESH_USER';
export const REFRESH_USER_RUNNING = 'REFRESH_USER_RUNNING';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SET_USER = 'SET_USER';
export const SET_USER_TRANSACTIONS = 'SET_USER_TRANSACTIONS';
