import React from 'react';
import PropTypes from 'prop-types';
import { Title } from 'components/atoms';
import { FAQ } from 'components/molecules';

/**
 * FAQ config
 * @interface FAQConfig
 * @property {string} title The FAQ question.
 * @property {string} text The FAQ answer.
 */
/**
 * Given an {@link FAQConfig}, build and return a FAQ UI component.
 * @param  {FAQConfig} config [description]
 * @return {node}
 */
const buildFAQs = config => {
	let faqs = [];
	let i = 1;
	while (i <= config.faqs.count) {
		faqs.push(<FAQ key={`faq-${i}`} text={config.faqs.text(i + '')} title={config.faqs.title(i + '')} />);
		i++;
	}
	return faqs;
};

const FAQs = ({ config }) => {
	return (
		<div className="container faqs">
			<Title align="left" transform="none">
				{config.title}
			</Title>
			{buildFAQs(config)}
		</div>
	);
};

FAQs.defaultProps = {
	config: {
		title: 'FAQs',
		faqs: [
			{
				title: 'FAQ',
				text: 'This is an FAQ'
			}
		]
	}
};

FAQs.propTypes = {
	config: PropTypes.shape({
		title: PropTypes.string,
		faqs: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string.isRequired,
				text: PropTypes.string.isRequired
			})
		)
	})
};

export default FAQs;
