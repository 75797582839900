import * as t from './types';

/**
 * Sets the suggested autocomplete values returned from google autocomplete service.
 * @param {Array} [value=[]] The list of suggested autocomplete objects returned by googles autocomplete service.
 */
export const setLocationAutocompleteSuggestions = (value = []) => {
	return { type: t.SET_AUTOCOMPLETE_SUGGESTIONS, value };
};

/**
 * Sets the value of the location autocomplete input.
 * @param {String} [value=''] The input value.
 */
export const setLocationAutocompleteSearch = (value = '') => {
	return { type: t.SET_LOCATION_AUTOCOMPLETE_SEARCH, value: decodeURIComponent(value) };
};

/**
 * Set location autocomplete loading state.
 * @param {Boolean} [value=false] Is autocomplete loading.
 */
export const setLocationAutocompleteLoading = (value = false) => {
	return { type: t.SET_LOCATION_AUTOCOMPLETE_LOADING, value };
};

/**
 * Set location autocomplete ready state.
 * @param {Boolean} [value=false] Is autocomplete ready.
 */
export const setLocationAutocompleteReady = (value = false) => {
	return { type: t.SET_LOCATION_AUTOCOMPLETE_READY, value };
};
