import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Text, Title } from 'components/atoms';
import { ContactInfo } from 'components/molecules';
import { getTranslate } from 'react-localize-redux';
import CONTACTS from './dinova_contact_info';

/**
 * Displays Dinova contact information to allow the user easy access to custom support.
 * @constructor
 * @param {MoreHelpProps} props
 */
const MoreHelp = ({ dataKey, imageBaseUrl, translate, title, help }) => {
	return (
		<div className="more-help">
			{title && (
				<Title align="left" transform="none">
					{title}
				</Title>
			)}
			{help && (
				<Text align="left" paragraph>
					{help}
				</Text>
			)}
			<ContactInfo contacts={CONTACTS(imageBaseUrl, translate)[dataKey]} />
		</div>
	);
};

MoreHelp.defaultProps = {
	dataKey: 'business'
};

/**
 * MoreHelp Props
 * @interface MoreHelpProps
 * @property {string} dataKey The data accesor used to fetch contact data.
 * @property {string} imageBaseUrl
 * @property {function} translate
 */
MoreHelp.propTypes = {
	dataKey: PropTypes.string,
	imageBaseUrl: PropTypes.string.isRequired,
	translate: PropTypes.func.isRequired,
	title: PropTypes.string,
	help: PropTypes.string
};

const mapStateToProps = state => ({
	imageBaseUrl: state.config.imageBaseUrl,
	translate: getTranslate(state.locale)
});

export default connect(mapStateToProps)(MoreHelp);
