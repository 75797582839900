import * as t from './types';
import { basePath, token } from 'actions/utils';
import { addTranslationForLanguage } from 'react-localize-redux';
import axios from 'axios';
import { isNaN, merge } from 'lodash';
import { STORE } from 'components/environment/App/redux_store.js';
// import { getPosition } from 'utils/search';

/**
 * Toggles the open/close state of the mobile hamburger nav menu.
 */
export const toggleMobileNav = () => {
	return {
		type: t.TOGGLE_NAV
	};
};

/**
 * Sets the show/hide state and the message string of the loading mask.
 * @param {boolean} loading If to show the loading mask.
 * @param {string} message  The text to show in the loading mask.
 */
export const isLoading = (loading, message = '') => {
	return {
		type: t.IS_LOADING,
		payload: { loading, message }
	};
};

/**
 * Set which header to use, desktop or tablet/mobile.
 * @param  {boolean} useMyDinovaLogo If to show the dinova logo. Shown when in desktop view.
 * @param  {boolean} showMenu        If to show hamburger menu. Shown when in tablet/mobile view.
 */
export const updateHeader = (useMyDinovaLogo, showMenu) => {
	return {
		type: t.UPDATE_HEADER,
		payload: { useMyDinovaLogo, showMenu }
	};
};

/**
 * When localization strings are loaded, set localization loading states to complete.
 * @param  {object} strings An object of localized strings.
 */
// eslint-disable-next-line no-unused-vars
export const stringsFetchDataSuccess = strings => {
	return {
		type: t.STRINGS_FETCHED_SUCCESS,
		isBusy: false,
		error: undefined
	};
};

/**
 * When localization string fetch fails, set localization loading state to failed.
 * @param  {object} error The error from the failed API request.
 */
export const stringFetchedDataError = error => {
	return {
		type: t.STRINGS_FETCHED_ERROR,
		isBusy: false,
		error
	};
};

/**
 * Request for fetching localization strings.
 * @param  {string} url Where to fetch the localization strings from.
 */
export const requestStrings = url => {
	return {
		type: t.REQUEST_STRINGS,
		url
	};
};

/**
 * Handle the data returned from requesting localization strings.
 * @param  {object} strings An object of localized strings.
 */
export const stringsFetchedData = strings => {
	return {
		type: t.STRINGS_FETCHED_DATA,
		strings
	};
};

/**
 * Handle get geolocation success.
 * @param  {object} geolocation The browsers geolocation object.
 */
// const geolocationSuccess = geolocation => ({ type: t.GET_CURRENT_GEOLOCATION, hasGeolocation: true, geolocation });
/**
 * Handle get geolocation failure.
 */
// const geolocationFailed = () => ({ type: t.GET_CURRENT_GEOLOCATION, hasGeolocation: false, geolocation: null });
/**
 * Kick off getting user's location from browser.
 */
// export const getCurrentLocation = () => async dispatch => {
// 	try {
// 		// check geolocation
// 		const geolocation = await getPosition();
// 		// console.log('current location is available', geolocation);
// 		return dispatch(geolocationSuccess(geolocation));
// 	} catch (err) {
// 		// console.warn('current location is unavailable', err.message);
// 		return dispatch(geolocationFailed());
// 	}
// };

// thunk action creators
/**
 * Kick off request to fetch localization strings.
 */
export const startFetchStrings = () => async dispatch => {
	dispatch(isLoading(true));
	const response = await getLanguageStrings();

	dispatch(addTranslationForLanguage(response.data.en, 'en'));
	dispatch(stringsFetchDataSuccess(response.data));
};

/**
 * IPageView
 * @interface IPageView
 * @property {string} page     The name of the page.
 * @property {string} location The URL of the page.
 * @property {string} title    The title of the page.
 *
 * @example {
 	page: '/dashboard',
 	location: '/dashboard',
 	title: 'myDinova - Dashboard'
 }
 */
/**
 * Set the page currently being viewed.
 * @param  {IPageView} location The browser url.
 */
export const pageView = location => ({
	type: t.PAGE_VIEW,
	location
});

/**
 * IOutsideEvent
 * @interface IOutsideEvent
 * @property {string} category The classification for this event.
 * @property {string} action   The action for this event.
 */
/**
 * @param  {IOutsideEvent} payload The category and action of this outside event.
 */
export const outsideEvent = payload => ({
	type: t.OUTSIDE_EVENT,
	payload
});

/**
 * Sets API response error code to global error state.
 * @param {string|number} payload The API response error code. Ex: 500, 404.
 */
export const setError = payload => ({ type: t.SET_ERROR, payload });

/**
 * Sets the campaign id.
 * @param {string} payload The campain id.
 */
export const setCampaignId = payload => ({ type: t.SET_CAMPAIGN_ID, payload });

/**
 * Sets the client code.
 * @param {string} payload The client code.
 */
export const setClientCode = payload => ({ type: t.SET_COMPANY_CODE, payload });

/**
 * Sets the user's company.
 * @param {object} payload The company object returned from the API.
 */
export const setCompany = payload => ({ type: t.SET_COMPANY, payload });

/**
 * Sets the user's company name.
 * @param {string} payload The company name.
 */
export const setCompanyName = payload => ({ type: t.SET_COMPANY_NAME, payload });

/**
 * Sets the user's preferred cretid card type.
 * @param {number} payload The id of the credit card type.
 */
export const setPreferredCCType = payload => {
	if (!isNaN(payload)) {
		switch (payload) {
			case 1:
				payload = 'amex';
				break;
			case 2:
				payload = 'visa';
				break;
			case 3:
				payload = 'mastercard';
				break;
			default:
				break;
		}
	}
	return { type: t.SET_PREFERRED_CC_TYPE, payload };
};

/**
 * Sets the employee id type.  Can be "email" or "empId".
 * @param {sting} payload The employee id type.
 */
export const setPreferredEmployeeIDType = payload => ({ type: t.SET_PREFERRED_EMPID_TYPE, payload });

/**
 * Sets the user's preferred card id.
 * @param {sting} payload The id of the selected credit card.  Id comes from API after user saves a credit card.
 */
export const setPreferredConnectionID = payload => ({ type: t.SET_PREFERRED_CONNECTION_ID, payload });

// NOTE: This setFeedType func isn't used.
export const setFeedType = payload => ({ type: t.SET_FEED_TYPE, payload });

/**
 * Set if user is using an android device.
 * @param {boolean} payload If user is using android device or not.
 */
export const setAndroid = payload => ({ type: t.SET_ANDROID, payload });

/**
 * Resets state.general to default state.
 */
export const resetState = () => ({ type: t.RESET_STATE });

/**
 * Set's the user's company code.
 * @param {sring} companyCode The code for the user's company.
 */
export const setCompanyCode = companyCode => async dispatch => {
	dispatch(setClientCode(companyCode));
	companyCode && dispatch(getConnectionType(companyCode));
};

/**
 * Fetches company data from API.
 * @param {sring} clientCode The code for the company that is being requested.
 */
export const getCompany = (clientCode, callback) => async dispatch => {
	try {
		dispatch(isLoading(true, 'Fetching client information'));
		let url = `${basePath()}/clients/${clientCode}`;

		const res = await axios.get(url, {
			headers: {
				Authorization: token(),
				'Content-type': 'application/json'
			}
		});
		switch (res.data.response.code) {
			case 200: {
				let client = res.data.clients[0];
				callback(client);
				break;
			}
			default:
				dispatch(setError(res.data.response.code));
				break;
		}
	} catch (e) {
		dispatch(setError(e.response.status));
	} finally {
		dispatch(isLoading(false));
	}
};

/**
 * Fetches the credit card types that are available for a company.  After fetch success, sets state.general.companyName and state.general.clientCode.
 * @param  {string} clientCode The code for the company who's card types you would like to fetch.
 */
export const getConnectionType = clientCode => async dispatch => {
	try {
		dispatch(isLoading(true, 'Fetching connection information'));
		let url = `${basePath()}/clients/${clientCode}`;

		const response = await axios.get(url, {
			headers: {
				Authorization: token(),
				'Content-type': 'application/json'
			}
		});
		switch (response.data.response.code) {
			case 200: {
				let client = response.data.clients[0];
				dispatch(setCompanyName(client.name));
				dispatch(setClientCode(client.code));
				break;
			}
			default:
				dispatch(setError(response.data.code));
				break;
		}
	} catch (e) {
		dispatch(setError(e.response.data.response.code));
	} finally {
		dispatch(isLoading(false));
	}
};

/**
 * Create GET requests for each langauge string content path.  Wait for all promises to resolve.  Merge together response data.  Return merged object.
 * @return {object} API data response object. response.data will contain language strings.
 */
const getLanguageStrings = async () => {
	const urls = contentPaths();
	let fetchers = [];

	urls.forEach(async url => {
		fetchers.push(axios.get(url));
	});

	const res = await Promise.all(fetchers);
	const data = merge({}, ...res);

	return data;
};

/**
 * Retrives the URLs to fetch the localization strings from.
 * @return {string[]} An array of URLs where localization files can be fetched.
 */
const contentPaths = () => {
	let config = STORE.getState().config;
	let contentPath = config[`content_paths`];
	return contentPath;
};
