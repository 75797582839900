export const TOGGLE_SHOW_RECOMMENDATIONS_MODAL = 'TOGGLE_SHOW_RECOMMENDATIONS_MODAL';
export const TOGGLE_SHOW_RECOMMENDATIONS_TY_MODAL = 'TOGGLE_SHOW_RECOMMENDATIONS_TY_MODAL';
export const APPLY_RECOMMENDATION = 'APPLY_RECOMMENDATION';
export const FETCH_RECOMMENDATIONS_OPTIONS_START = 'FETCH_RECOMMENDATIONS_OPTIONS_START';
export const FETCH_RECOMMENDATIONS_OPTIONS_SUCCESS = 'FETCH_RECOMMENDATIONS_OPTIONS_SUCCESS';
export const SUBMIT_RECOMMENDATION_START = 'SUBMIT_RECOMMENDATION_START';
export const SUBMIT_RECOMMENDATION_SUCCESS = 'SUBMIT_RECOMMENDATION_SUCCESS';
export const SUBMIT_RECOMMENDATION_FAILURE = 'SUBMIT_RECOMMENDATION_FAILURE';
export const SHOW_RECOMMENDATIONS_MODAL_PREFILLED = 'SHOW_RECOMMENDATIONS_MODAL_PREFILLED';
export const FETCH_RECOMMENDATION_TYPES_START = 'FETCH_RECOMMENDATION_TYPES_START';
export const FETCH_RECOMMENDATIONS_OPTIONS_FAILURE = 'FETCH_RECOMMENDATIONS_OPTIONS_FAILURE';
export const SUBMIT_NONE_SELECTED = 'SUBMIT_NONE_SELECTED';
