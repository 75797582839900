import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const LoadingBase = ({ className, message }) => {
	const classProps = classnames('loading-base', className);
	if (message) {
		message = message.indexOf('...') > -1 ? message : message + '...';
	} else {
		message = '';
	}

	return (
		<div className={classProps}>
			<h4 className="dnLoader--message">{message}</h4>
			<div className="dnSpinner dnLoader--spinner">
				<div className="bounce1" />
				<div className="bounce2" />
				<div className="bounce3" />
				<div className="bounce4" />
			</div>
		</div>
	);
};

LoadingBase.defaultProps = {
	message: 'Loading...'
};

LoadingBase.propTypes = {
	message: PropTypes.string,
	className: PropTypes.string
};

export default LoadingBase;
