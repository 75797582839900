import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, CardBody } from 'third-party/mdbreact/dist/mdbreact.js';
import { Button } from 'reactstrap';
import { Col } from 'reactstrap';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import { connect } from 'react-redux';
import metaTagConfig from './meta_tags/reset-meta-tags';
import { MetaTags } from 'components/molecules';
import { InputField } from 'components/organisms';
import PageError from '../utilities/PageError';

class ResetPassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hasErrors: false,
			hasErrorsEmail: false,
			email: undefined
		};
	}

	onChange = e => {
		this.setState({
			hasErrors: false,
			hasErrorsEmail: false,
			[e.target.name]: e.target.value
		});
	};

	onSubmit = e => {
		e.preventDefault();

		var x = this.state.email;
		if (x === undefined || x === '') {
			this.setState({ hasErrors: true, hasErrorsEmail: true });
			return false;
		}
	};

	render() {
		const { translate } = this.props;

		return (
			<div className="Di_SubHead Di_LoginPic">
				<MetaTags tags={metaTagConfig} />
				<form onSubmit={this.onSubmit}>
					<Container className="Di_HeadSpace">
						<Row>
							<div className="Di_PageLimit mx-auto float-none animated fadeInUp">
								<CardBody>
									<Container>
										{this.state.hasErrors ? (
											<PageError message="Error on Page - You simply must be more carefull." />
										) : null}
										<Row>
											<Col xs="12">
												<h1>{translate('forgotten.headline')}</h1>
											</Col>
										</Row>
										<Row>
											<Col xs="12">{translate('forgotten.body')}</Col>
										</Row>
										<Row>
											<Col xs="12">
												<div className="Di_RequiredHeader">
													<span>*</span>
													{translate('forgotten.required')}
												</div>
											</Col>
										</Row>
										{/* Email */}
										<Row id="Fld_Email">
											<Col xs="12">
												<InputField
													type="email"
													label={translate('forgotten.emailLabel')}
													value={this.state.email}
													onChange={this.onChange}
													name="email"
													required
													hasError={this.state.hasErrorsEmail}
													error={translate('forgotten.emailerror')}
												/>
											</Col>
											<Col xs="12" className="Di_ActionBttns">
												<Button className="z-depth-0" color="primary" type="submit">
													{translate('forgotten.bttn1')}
												</Button>

												<Button
													className="z-depth-0"
													onClick={() => {
														this.props.history.goBack();
													}}
												>
													{translate('forgotten.bttn2')}
												</Button>
											</Col>
										</Row>
									</Container>
								</CardBody>
							</div>
						</Row>
					</Container>
				</form>
			</div>
		);
	}
}

ResetPassword.propTypes = {
	history: PropTypes.object.isRequired,
	translate: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	translate: getTranslate(state.locale),
	currentLanguage: getActiveLanguage(state.locale).code
});

export default connect(mapStateToProps)(ResetPassword);
