import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import _ from 'lodash';
import { Distance } from 'components/atoms';

/**
 * Using redux state.details, show the restaurant location in a map, show the address of the restaurant, provide a button to link user to get directions to the restaurant.
 * @extends Component
 *
 * @param {AddressAndDirectionsProps} props
 */
class AddressAndDirections extends Component {
	/**
	 * Configure how to render Google Map.
	 * @type {Object}
	 * @property {boolean} scrollwheel Disables users ability to zoom on sroll.
	 * @property {boolean} disableDoubleClickZoom Disables users ability to zoom on double click.
	 * @property {boolean} draggable Disables users ability to move the map.
	 * @property {boolean} fullscreenControl Disables users ability to enter map in fullscreen.
	 * @property {boolean} scaleControl Disables users ability scale.
	 * @property {boolean} zoomControl Prevents zoom in/out buttons from rendering.
	 */
	mapOptions = {
		scrollwheel: false,
		disableDoubleClickZoom: true,
		draggable: false,
		fullscreenControl: false,
		scaleControl: false,
		zoomControl: false
	};

	/**
	 * Creates get directions link to map.google.com.
	 * @return {string} Google maps get directions link.
	 */
	getDirectionsLink = () => {
		const { location } = this.props.details;
		return `https://maps.google.com/?saddr=Current+Location&daddr=${location.lat},${location.lon}`;
	};

	/**
	 * Creates the dinova icon to be used as the map marker indicating the restaurants location.
	 * @return {object} React Component.
	 */
	mapMarker = () => {
		const { details } = this.props,
			icon_url = encodeURI('https://dinova-images-dev.imgix.net/icons/icon-selected-map-marker-64.png');

		return (
			<div className="map-marker" lat={details.location.lat} lng={details.location.lon} text="My Marker">
				<img src={icon_url} />
				<p className="map-marker-label">{details.title}</p>
			</div>
		);
	};

	/**
	 * Creates the address component.
	 * @return {object} React Component.
	 */
	addressComponent = () => {
		const { details } = this.props;

		return (
			<div className="addresses column">
				{_.map(details.addresses, (address, i) => (
					<span key={`address-${i}`} className="address">
						{address}
					</span>
				))}
			</div>
		);
	};

	/**
	 * Creates the get directions button.
	 * @return {object} React Component.
	 */
	directionsButton = () => {
		return (
			<a className="direction-btn" href={this.getDirectionsLink()} target="_blank" rel="noopener noreferrer">
				{this.props.translate('Details.direction')}
			</a>
		);
	};

	render() {
		const { API_KEY, details, location } = this.props,
			distance = _.has(location, 'state.distance') ? location.state.distance : null,
			center = {
				lat: details.location.lat,
				lng: details.location.lon
			};

		return (
			<div className="details-location">
				<div className="map-wrapper">
					<GoogleMapReact
						bootstrapURLKeys={{ key: API_KEY }}
						center={center}
						zoom={14}
						options={this.mapOptions}
					>
						{this.mapMarker()}
					</GoogleMapReact>
				</div>
				<div className="map-info flex justify-between">
					{this.addressComponent()}
					<div className="right-column column align-end">
						{distance && <Distance distance={distance} />}
						{this.directionsButton()}
					</div>
				</div>
			</div>
		);
	}
}

AddressAndDirections.defaultProps = {
	details: {
		location: {
			lat: 0,
			lon: 0
		},
		addresses: []
	}
};

/**
 * {@link AddressAndDirections} Props
 * Note: This component only utilizes state.details.location and state.details.addresses.
 *
 * @interface Props_AddressAndDirections
 * @property {string} API_KEY The api key for google maps.
 * @property {object} location React router location object.
 * @property {Redux_RestaurantDetails} details {@link RestaurantDetails} The redux state.details restaurant.
 */
AddressAndDirections.propTypes = {
	// Required
	API_KEY: PropTypes.string.isRequired,
	location: PropTypes.object.isRequired, // React Router
	translate: PropTypes.func.isRequired,

	// Optional
	details: PropTypes.object
};

export default AddressAndDirections;
