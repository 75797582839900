import Promise from 'bluebird';
let geocoder;
// story book google maps polyfill
if (window.location.href.indexOf('amazonaws') === -1 && window.location.href.indexOf('6006') === -1) {
	geocoder = new window.google.maps.Geocoder();
} else {
	geocoder = {
		geocoder: (location, cb) => {
			const results = [{ formatted_address: 'Atlanta' }];
			const status = 'OK';

			cb(results, status);
		}
	};
}

/**
 * Given a latitude and longitude, find a string address.  Uses google maps Geocoder.
 * @param  {object}   location A location object. {lat: number, lng: number}
 * @param  {Function} cb       Data resolution handler, should handler success and error responses.
 * @return {Promise}           Returns a promise that resolves with reverse geolocation data if status === 'OK'.  If status !== 'OK', rejects with reverse geolocation data.
 */
export const fetchReverseGeoLocation = (location, cb) => {
	return new Promise((resolve, reject) => {
		geocoder.geocode({ location }, (res, status) => {
			if (status === 'OK') {
				resolve(cb(res, status));
			} else {
				reject(cb(res, status));
			}
		});
	});
};
