import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Input } from 'third-party/mdbreact/dist/mdbreact.js';

const CheckButton = ({ checked, className, disabled, hasError, id, inversion, onClick, label, small }) => {
	const classProps = classNames('check-btn', inversion && 'inversion', small && 'small', className);
	const inputClass = classNames(hasError && 'error');

	return (
		<Input
			onClick={onClick}
			checked={checked}
			label={label}
			type="checkbox"
			id={id}
			containerClass={classProps}
			className={inputClass}
			disabled={disabled}
			filled
		/>
	);
};

CheckButton.defaultProps = {
	checked: false,
	className: '',
	disabled: false,
	inversion: false,
	small: false
};

CheckButton.propTypes = {
	checked: PropTypes.bool,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	hasError: PropTypes.bool, // hasError prop added to allow storybook to show error state UI
	id: PropTypes.string.isRequired,
	inversion: PropTypes.bool,
	small: PropTypes.bool,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	onClick: PropTypes.func
};

export default CheckButton;
