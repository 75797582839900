import React from 'react';
import PropTypes from 'prop-types';
import { LogoImage } from 'components/molecules';

/**
 * A splash image container.  Sets image by using a div with backgroundImage: props.bannerImgUrl
 * Allows for an optional logoImg to be displayed ontop of the splash image.  logoImg is renered in an img.
 * @param {JumbotronProps} props
 */
const Jumbotron = ({ bannerImg, logoImg, withoutLogo }) => {
	if (!bannerImg || bannerImg === null || bannerImg === 'null') {
		return null;
	}

	const bannerImgUrl = `url("${bannerImg}")`;

	return (
		<div className="jumobotron">
			<div className="banner-img" style={{ backgroundImage: bannerImgUrl }} />
			{withoutLogo !== true && logoImg && <LogoImage className="logo-img" src={logoImg} />}
		</div>
	);
};

Jumbotron.defaultProps = {
	logoImg: '',
	withoutLogo: false
};

/**
 * {@link Jumbotron} Props
 * @interface Props_Jumbotron
 * @property {string} bannerImg            The src for the splash image.
 * @property {string} [logoImg]            The src for the logo image.
 * @property {boolean} [withoutLogo=false] If to show a logo image.
 */
Jumbotron.propTypes = {
	// Required
	bannerImg: PropTypes.string.isRequired,
	// Optional
	logoImg: PropTypes.string,
	withoutLogo: PropTypes.bool
};

export default Jumbotron;
