import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const ProfilePreferenceItem = props => {
	const { className, item, section, translate } = props;
	const classProps = classNames('profile-preference-item', !item.checked && 'unchecked', className);

	return (
		<div className={classProps}>
			<i className={item.checked ? 'fa fa-check' : 'fa fa-ban fa-rotate-90'} aria-hidden="true" />
			<span className="item-label">{translate(`Profile.${section}.labels.${item.key}`)}</span>
		</div>
	);
};

ProfilePreferenceItem.defaultProps = {
	className: ''
};

ProfilePreferenceItem.propTypes = {
	className: PropTypes.string,
	item: PropTypes.object.isRequired,
	section: PropTypes.oneOf(['personal', 'payment', 'preferences', 'security']).isRequired,
	translate: PropTypes.func.isRequired
};

export default ProfilePreferenceItem;
