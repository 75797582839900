import Catering from './ui-component';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import { connect } from 'react-redux';
import { loadRestaurant } from 'actions/details';
import { update, login, getProfile } from 'actions/users';
import { getPoints } from 'actions/catering';
import { isLoading } from 'actions/general';

const mapStateToProps = state => ({
	hasGeoLocation: state.geoLocation.hasGeoLocation,
	geoLocationLoading: state.geoLocation.isLoading,
	translate: getTranslate(state.locale),
	currentLanguage: getActiveLanguage(state.locale).code,
	catering: state.catering,
	users: state.users,
	details: state.details,
	config: state.config
});

const mapDispatchToProps = dispatch => ({
	getPoints: (cart, callback) => dispatch(getPoints(cart, callback)),
	isLoading: (loading, message) => dispatch(isLoading(loading, message)),
	loadRestaurant: data => dispatch(loadRestaurant(data)),
	update: (user, callback) => dispatch(update(user, callback)),
	login: (user, callback) => dispatch(login(user, callback)),
	getProfile: () => dispatch(getProfile())
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Catering);
