import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/**
 * Show the currently selected restaurant name with a dropdown that allows
 * the user to select a near by location of the same restaurant.
 *
 * Currently selected restaurant corresponds to redux state - state.details.
 * @param {LocationsBarProps} props
 */
const LocationsBar = ({ title }) => {
	return (
		<div className="locations-bar">
			<h3>{title}</h3>
		</div>
	);
};

LocationsBar.defaultProps = {
	title: 'Restaurant Name'
};

/**
 * {@link LocationsBar} Props
 * @interface Props_LocationsBar
 * @property {string} title The text to be shown as the name of the selected resturant.
 */
LocationsBar.propTypes = {
	title: PropTypes.string
};

const mapStateToProps = state => ({
	title: state.details.title
});

export default connect(mapStateToProps)(LocationsBar);
