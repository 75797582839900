import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// import './styles/main.scss';

/**
 * Used inside of InputField - displays the label for an input.
 * @param {props}  InputLabel_Props
 *
 * @example <InputLabel htmlFor="username" hasError={false} optionLabel={true} text="Option" type="radio" required={true}/>
 */
const InputLabel = ({ children, dataCy, htmlFor, hasError, onClick, optionLabel, text, type, required }) => {
	const classProps = classnames(
		'input-label',
		hasError && 'error',
		required && 'required',
		type,
		optionLabel && 'sub'
	);

	if (children) {
		return text ? (
			<label onClick={onClick} data-cy={`${dataCy}-label`} htmlFor={htmlFor} className={classProps}>
				{children}
			</label>
		) : null;
	}

	return text ? (
		<label
			onClick={onClick}
			data-cy={`${dataCy}-label`}
			htmlFor={htmlFor}
			className={classProps}
			dangerouslySetInnerHTML={{ __html: text }}
		/>
	) : null;
};

InputLabel.defaultProps = {
	htmlFor: null,
	hasError: false,
	children: null,
	optionLabel: false,
	required: false,
	text: null,
	type: 'text'
};

/**
 * Input Label Props
 * Used inside of InputField - displays the label for an input.
 * @interface InputLabel_Props
 * @param {string}  [htmlFor]     The input this label is associated with, for label click = input focus.
 * @param {Boolean} [hasError]    If this input has an error.
 * @param {string} [children]     Additional label text, can be component to allow for special styles.
 * @param {Boolean} optionLabel   If this label is a 'sub' label, for radio and checkbox options.
 * @param {string}  text          The title/text of this label.
 * @param {string}  type          The input type, Ex: 'text', 'select', 'radio'...
 * @param {Boolean} [required]    If this is a required input.
 */
InputLabel.propTypes = {
	children: PropTypes.string,
	dataCy: PropTypes.string,
	htmlFor: PropTypes.string,
	hasError: PropTypes.bool,
	onClick: PropTypes.func,
	optionLabel: PropTypes.bool, // if label is for a radio or checkbox item
	required: PropTypes.bool,
	text: PropTypes.string,
	type: PropTypes.string
};

export default InputLabel;
