import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'reactstrap';
import { history } from 'utils/router';

class PlaceAutocomplete extends Component {
	render() {
		const { className, foundPlaces } = this.props;
		const classProps = classNames('place-autocomplete', className);

		const list = foundPlaces.map((item, key) => (
			<Button
				key={key}
				className="item"
				color="link"
				data-near={unescape(item.description)}
				onClick={this.handleRecentSearches}
			>
				{unescape(item.description)}
			</Button>
		));

		return foundPlaces.length > 0 ? <div className={classProps}>{list}</div> : null;
	}

	handleRecentSearches = event => {
		let near = encodeURIComponent(event.target.dataset.near || '');

		this.props.setBusinessSearch(near);
		this.props.setSearchSuggestion();
		history.push(`/search?&near=${near}`);
	};
}

PlaceAutocomplete.defaultProps = {
	className: ''
};

PlaceAutocomplete.propTypes = {
	className: PropTypes.string,
	setBusinessSearch: PropTypes.func,
	setSearchSuggestion: PropTypes.func,
	foundPlaces: PropTypes.array
};

export default PlaceAutocomplete;
