import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Title } from 'components/atoms';

const PageHeaderTitle = ({ align, children, className, dataCy }) => {
	const classProps = classNames('page-title', align && `text-${align}`, className);

	return (
		<Title dataCy={dataCy} className={classProps}>
			{children}
		</Title>
	);
};

PageHeaderTitle.defaultProps = {
	className: '',
	children: ''
};

PageHeaderTitle.propTypes = {
	align: PropTypes.oneOf(['left', 'center', 'right']),
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
	className: PropTypes.string,
	dataCy: PropTypes.string
};

export default PageHeaderTitle;
