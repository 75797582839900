import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { PulseLoader } from 'react-spinners';
import PropTypes from 'prop-types';
import { CollapseWithClose } from 'components/molecules';
import { InputField } from 'components/organisms';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

class Autocomplete extends Component {
	constructor(props) {
		super(props);
		this.outsideClickListner = null;
		this.wrapper = React.createRef();
		this.key = props.id.replace('input-', '');
		this.help = props.help;
		this.state = {
			accordion: props.accordion,
			showHelp: false,
			passwordIsVisible: false,
			value: props.value,
			activeSuggestion: -1,
			filteredSuggestions: props.filteredSuggestions,
			showSuggestions: false,
			userInput: '',
			inputFocused: false
		};
	}

	componentDidMount() {
		this.initClickListener();
	}

	componentWillUnmount() {
		this.destroyListeners();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.accordion !== this.props.accordion) {
			this.setState({
				accordion: this.props.accordion
			});
		}
	}

	initClickListener = () => {
		this.outsideClickListner = window.addEventListener('click', this.handleOutsideClick, false);
	};

	destroyListeners = () => {
		window.removeEventListener('click', this.outsideClickListner, false);
	};

	handleOutsideClick = e => {
		if (this.wrapper.contains) {
			const isInsideClick = this.wrapper.contains(e.target),
				isOpen = this.state.showSuggestions;

			if (!isInsideClick && isOpen) {
				this.setState({ showSuggestions: false });
			}
		}
	};

	onHelpClick = e => {
		e.preventDefault();
		let targetId = e.currentTarget.id;
		let state = '';
		if (this.state.accordion !== targetId) {
			state = targetId;
		} else {
			state = undefined;
		}
		this.setState({ accordion: state });
		this.props.onHelpClick && this.props.onHelpClick(state);
	};

	onChange = e => {
		this.props.onChange(e);
		this.props.onChoice('');
		this.setState({
			value: e.target.value,
			activeSuggestion: -1,
			showSuggestions: true,
			userInput: e.currentTarget.value
		});
	};

	onClick = e => {
		e.stopPropagation();
		this.setState({
			activeSuggestion: -1,
			filteredSuggestions: [],
			showSuggestions: false,
			value: e.currentTarget.innerText
		});
		this.props.onChoice(e.currentTarget.innerText);
	};

	onKeyDown = e => {
		if (this.state.showSuggestions) {
			if (e.key === 'Enter') {
				this.handleEnterPress();
			} else if (e.key === 'ArrowDown') {
				this.handleArrowDownPress();
			} else if (e.key === 'ArrowUp') {
				this.handleArrowUpPress();
			}
		}
	};

	handleEnterPress = () => {
		const { activeSuggestion } = this.state,
			{ filteredSuggestions } = this.props,
			userInput = filteredSuggestions[activeSuggestion];

		this.props.onChoice(userInput);
		this.setState({ activeSuggestion: 0, showSuggestions: false, userInput, value: userInput });
	};

	handleArrowUpPress = () => {
		const { filteredSuggestions } = this.props;

		let activeSuggestion = this.state.activeSuggestion - 1;
		if (activeSuggestion < 0) {
			activeSuggestion = filteredSuggestions.length - 1;
		}

		this.setState({ activeSuggestion });
	};

	handleArrowDownPress = () => {
		const { filteredSuggestions } = this.props;

		let activeSuggestion = this.state.activeSuggestion + 1;
		if (activeSuggestion > filteredSuggestions.length - 1) {
			activeSuggestion = 0;
		}

		this.setState({ activeSuggestion });
	};

	onMouseEnter = activeSuggestion => {
		this.setState({ activeSuggestion });
	};

	render() {
		const { className, mark, filteredSuggestions, example, translate } = this.props;
		const { inputFocused } = this.state;
		const {
			onClick,
			state: { activeSuggestion, showSuggestions, userInput }
		} = this;
		const markClass = mark === 'none' ? undefined : mark + '-mark';
		const classProps = classNames(
			'wrapper textbox-input autocomplete',
			inputFocused && 'focused',
			markClass,
			className
		);

		let suggestionsListComponent;
		if (showSuggestions && userInput) {
			if (filteredSuggestions.length) {
				suggestionsListComponent = (
					<ul className="suggestions">
						{filteredSuggestions.map((suggestion, index) => {
							let className;

							// Flag the active suggestion with a class
							if (index === activeSuggestion) {
								className = 'suggestion-active';
							}

							return (
								<li
									className={className}
									key={suggestion}
									onClick={onClick}
									onMouseOver={this.onMouseEnter.bind(this, index)}
								>
									{suggestion}
								</li>
							);
						})}
					</ul>
				);
			} else {
				suggestionsListComponent = (
					<div className="no-suggestions">
						<PulseLoader sizeUnit={'px'} size={10} color={'#1c871c'} loading={this.props.loading} />
						{!this.props.loading && <em>{translate('ConnectToPoints.no-suggestions')}</em>}
					</div>
				);
			}
		}

		return (
			<div className={classProps}>
				<div className={this.props.errorText ? 'Di_HasSideIcon Di_HasError col' : 'Di_HasSideIcon col'}>
					<InputField
						value={this.state.value}
						onChange={this.onChange}
						onKeyDown={this.onKeyDown}
						name={this.props.name}
						id={this.props.id}
						label={this.props.label}
						help={this.props.help}
						error={this.props.errorText}
						hasError={this.props.errorText ? true : false}
						type="typeahead"
						onHelpClick={this.props.onHelpClick}
						showHelp={this.props.showHelp}
						requried
					>
						{suggestionsListComponent}
					</InputField>
					{!isEmpty(example) && example.length > 0 && <div className="example-label">{example}</div>}
					<CollapseWithClose
						styleName="di_TintGreen"
						isOpen={this.state.accordion}
						content={this.help}
						onClick={this.onHelpClick}
					/>
				</div>
			</div>
		);
	}

	handleFocus = e => {
		let { showSuggestions } = this.state;
		if (this.props.filteredSuggestions) {
			showSuggestions = true;
		}
		this.setState({ inputFocused: true, showSuggestions });
		if (this.props.onFocus) {
			this.props.onFocus(e);
		}
	};

	handleBlur = e => {
		this.setState({ inputFocused: false });
		if (this.props.onBlur) {
			this.props.onBlur(e);
		}
	};
}

Autocomplete.defaultProps = {
	className: '',
	mark: 'asterisk',
	example: '',
	suggestions: []
};

Autocomplete.propTypes = {
	accordion: PropTypes.string,
	className: PropTypes.string,
	color: PropTypes.string,
	config: PropTypes.object,
	defaultValue: PropTypes.string,
	errorText: PropTypes.string,
	example: PropTypes.string,
	filteredSuggestions: PropTypes.array,
	help: PropTypes.string,
	id: PropTypes.string,
	label: PropTypes.string,
	languageNode: PropTypes.string,
	loading: PropTypes.bool,
	mark: PropTypes.oneOf(['none', 'asterisk', 'cross']),
	maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	name: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func.isRequired,
	onChoice: PropTypes.func.isRequired,
	onFocus: PropTypes.func,
	onHelpClick: PropTypes.func.isRequired,
	showHelp: PropTypes.bool,
	styleName: PropTypes.string,
	suggestions: PropTypes.array,
	translate: PropTypes.func.isRequired,
	value: PropTypes.string
};

const mapStateToProps = state => ({
	translate: getTranslate(state.locale)
});

export default connect(mapStateToProps)(Autocomplete);
