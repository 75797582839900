import React from 'react';
import classnames from 'classnames';
import { TabPanelHeader, TabPanelBody } from 'components/molecules';
import { TabPanelInstance } from 'components/molecules/TabPanel';
import { MoreHelp } from 'components/molecules';
import { Row, Col } from 'third-party/mdbreact/dist/mdbreact.js';
import { Ads } from 'components/organisms';
import { FAQReferACoworker } from 'components/organisms';

class HelpFAQRouteTabPanel extends TabPanelInstance {
	constructor(props) {
		super(props);
	}

	contactInfoKey = () => {
		const { match } = this.props;
		const isCateringPage = match.url.indexOf('catering') > -1;
		const isPrivateDiningPage = match.url.indexOf('private_dining') > -1;
		const isRewardsPage = match.url.indexOf('rewards') > -1;

		if (isCateringPage) {
			return 'catering';
		} else if (isPrivateDiningPage) {
			return 'privateDining';
		} else if (isRewardsPage) {
			return 'myDinova';
		} else {
			return 'business';
		}
	};

	adDataKey = () => {
		return `HelpFAQ.${this.contactInfoKey()}FAQ`;
	};

	render() {
		const { match, tabs, translate } = this.props,
			referralClassProps = classnames(match.url.indexOf('business') === -1 && 'hidden'),
			adsClassProps = classnames(match.url.indexOf('business') > -1 && 'hidden');

		return (
			<div className="helpfaq tab-panel">
				<TabPanelHeader tabs={tabs} tabStyle="center primary" />
				<Row>
					<Col sm="12" lg="8">
						<TabPanelBody tabs={tabs} />
					</Col>
					<Col sm="12" lg="4">
						<MoreHelp
							dataKey={this.contactInfoKey()}
							title={translate(`HelpFAQ.${this.contactInfoKey()}FAQ.moreHelp.title`)}
							help={translate(`HelpFAQ.${this.contactInfoKey()}FAQ.moreHelp.help`)}
						/>
						<FAQReferACoworker className={referralClassProps} />
						<Ads dataKey={this.adDataKey()} className={adsClassProps} />
					</Col>
				</Row>
			</div>
		);
	}
}

HelpFAQRouteTabPanel.defaultProps = {};

HelpFAQRouteTabPanel.propTypes = {};

export default HelpFAQRouteTabPanel;
