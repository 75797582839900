const _ = require('lodash');
const commonConfig = require('./config.common');

// eslint-disable-next-line no-unused-vars
module.exports = function(options) {
	console.log('zuppler-uat config');
	return _.merge({}, commonConfig(options), {
		// prod config here
		api_basePath_myDinova: 'https://uat.dinova.com/api-mydinova/v1',
		api_basePath_restaurant: 'https://uat.dinova.com/api-restaurant/v1/',
		api_basePath_zuppler: '//zuppler-web5-staging.netlify.com',
		zuppler_dataChannelPath: 'http://api.biznettechnologies.com/v3/channels/dinovauat.json',
		cdn_basePath: 'https://dinova-images-uat.imgix.net',
		imageBaseUrl: 'https://dinova-images-uat.imgix.net',
		content_paths: [
			'https://s3.amazonaws.com/dinova-public-assets-uat/mydinova/mydinova_copy.json',
			'https://s3.amazonaws.com/dinova-public-assets-uat/mydinova/search_dinova_copy.json'
		],
		web_basePath: 'https://zuppler.uat.dinova.com/',
		currentEnv: 'zuppler_uat',
		color: 'green',
		ga_key: 'UA-47151707-7',
		api_key: 'AIzaSyATqkHo5uKUghS2bcvTt0phbi2J8mM2xqI',
		gtm_key: 'GTM-576P6RK',
		header_logo_link: 'https://www.dinova.com/'
	});
};
