import createSagaMiddleware from 'redux-saga';
import reduxMulti from 'redux-multi';
import { createLogger } from 'redux-logger';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from 'reducers';
import moment from 'moment';
import Cookies from 'universal-cookie';
import { refresh } from 'actions/users';
import isNodeEnv from 'utils/isNodeEnv';

export const LOGGER = createLogger({ collapsed: true });
export const SAGA = createSagaMiddleware();

export const checkToken = store => next => action => {
	if (
		action.type !== 'REFRESH_USER_RUNNING' &&
		action.type !== 'REFRESH_TIME' &&
		action.type !== 'REFRESH_USER' &&
		action.type !== 'AUTH_USER'
	) {
		const tokenStart = store.getState().users.authenticated_time;
		const duration = moment().diff(tokenStart, 'minutes');
		let cookies = new Cookies();
		if (tokenStart) {
			if (duration >= 10) {
				if (!store.getState().users.refreshingUser) {
					if (cookies.get('refresh')) {
						store.dispatch(refresh(cookies.get('refresh')));
					}
				}
			}
		}
	}

	let result = next(action);
	return result;
};

// <--- Redux Beacon
const store = isNodeEnv('development')
	? composeWithDevTools(applyMiddleware(thunk, reduxMulti, SAGA, /*this.logger,*/ checkToken))
	: applyMiddleware(thunk, reduxMulti, SAGA, /*this.logger,*/ checkToken);
// create store with dev tools and middleware
export const STORE = createStore(rootReducer, store);
