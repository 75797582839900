import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { InputLabel } from 'components/atoms';
import { InputError, RadioInputOption } from 'components/molecules';

// import './styles/main.scss';

/**
 * Radio Input Group - shows multiple radio buttons.
 * @constructor
 * @param {RadioInput_Props} props
 */
const RadioInput = ({
	alignOptions,
	className,
	dataCy,
	error,
	hasError,
	inputAlign,
	label,
	onChange,
	options,
	type,
	required,
	value
}) => {
	const classProps = classnames(type, hasError && 'error', required && 'required', inputAlign, className);
	const containerClassProps = classnames('input-container', classProps);
	const optionsWrapperClassProps = classnames('radio-options', alignOptions);

	return (
		<div className={containerClassProps} data-cy={`${dataCy}-radio-input-wrapper`}>
			<div>
				<InputLabel dataCy={dataCy} hasError={hasError} text={label} type={type} required={required} />
				{hasError && inputAlign !== 'inline' && <InputError dataCy={dataCy} text={error} />}
				<div className={optionsWrapperClassProps} data-cy={`${dataCy}-radio-options-wrapper`}>
					{options.map((o, i) => {
						let checked = o.checked;
						if (o.checked === undefined) {
							checked = value === o.value;
						}
						return (
							<RadioInputOption
								dataCy={dataCy}
								key={`radio-${o.id}-${i}`}
								index={i}
								option={o}
								checked={checked}
								onChange={onChange}
							/>
						);
					})}
				</div>
			</div>
			{hasError && inputAlign === 'inline' && <InputError dataCy={dataCy} text={error} />}
		</div>
	);
};

RadioInput.defaultProps = {
	alignOptions: 'vertical',
	className: '',
	error: null,
	hasError: false,
	inputAlign: null,
	label: 'Label',
	onChange: () => {},
	options: [],
	required: false,
	type: 'radio',
	value: ''
};

/**
 * RadioInput Props
 * @interface {RadioInput_Props}
 * @param {string}    alignOptions How to align radio options.  Can be 'vertical' or 'horizontal'. Will change the way the radaio button and input label align.
 * @param {string}    className    Additional classname to add to the radio input wrapper.
 * @param {string}    error        The error message text.
 * @param {boolean}   hasError     If this input has an error.
 * @param {string}    inputAlign   How the input should align itself.  If 'inline' will show the label and options horizontally.  If null, will show vertically.
 * @param {string}    label        The text to use as this input's label.
 * @param {function}  onChange     When radio option clicked handler.
 * @param {array}     options      An array of radio option data config objects.
 * @param {string}    type         The input type, defaults to "radio".
 * @param {boolean}   required     If this is a required input.
 * @param {string}    value        The current value of the input.
 */
RadioInput.propTypes = {
	alignOptions: PropTypes.string,
	className: PropTypes.string,
	dataCy: PropTypes.string,
	error: PropTypes.string,
	hasError: PropTypes.bool,
	inputAlign: PropTypes.oneOf(['inline']),
	label: PropTypes.string,
	onChange: PropTypes.func,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			displayValue: PropTypes.string,
			id: PropTypes.string.isRequired,
			name: PropTypes.string,
			value: PropTypes.string.isRequired
		})
	),
	required: PropTypes.bool,
	type: PropTypes.string,
	value: PropTypes.string
};

export default RadioInput;
