import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import AddressAndDirections from './ui-component';

const mapStateToProps = state => {
	let details = state.details;
	details.location = details.location || { lat: 0, lon: 0 };

	return {
		API_KEY: state.config.apiKey,
		details: details,
		translate: getTranslate(state.locale)
	};
};

export default withRouter(connect(mapStateToProps)(AddressAndDirections));
