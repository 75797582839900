import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { each } from 'lodash';

class PageHeader extends React.Component {
	// links
	leftComponent = null;
	rightComponent = null;

	// title
	titleComponent = null;
	setComponent = component => {
		switch (component.type.name) {
			case 'PageHeaderTitle':
				this.titleComponent = component;
				break;
			default:
				break;
		}
	};

	render() {
		// prepare all classes
		const { className, bannerImg, children, config, useCrop } = this.props;
		const componentClasses = classNames('container', 'page-header', className);

		if (children && children.type) {
			this.setComponent(children);
		} else if (children) {
			each(children, component => {
				this.setComponent(component);
			});
		}

		const imgSrc = useCrop ? `${bannerImg}?w=1400&crop=edges&h=200&fit=crop` : `${bannerImg}?w=1400&h=200`;

		return (
			<div className={componentClasses}>
				{bannerImg && (
					<div className="row">
						<div className="col">
							<img
								className="page-banner"
								src={
									bannerImg.indexOf('static') > -1
										? bannerImg
										: `${config.cdn_basePath}/graphics/${imgSrc}`
								}
							/>
						</div>
					</div>
				)}
				{this.showHeaderBar && this.titleComponent && (
					<div className="row">
						<div className="col page-header-col">
							<header className="header-bar">{this.titleComponent}</header>
						</div>
					</div>
				)}
				{children}
			</div>
		);
	}
}

PageHeader.defaultProps = {
	className: '',
	bannerImg: null,
	useCrop: true
};

PageHeader.propTypes = {
	className: PropTypes.string,
	bannerImg: PropTypes.string,
	children: PropTypes.element,
	config: PropTypes.object,
	useCrop: PropTypes.bool
};

const mapStateToProps = store => ({
	config: store.config
});

export default connect(mapStateToProps)(PageHeader);
