import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'third-party/mdbreact/dist/mdbreact.js';
import { Button } from 'reactstrap';
import { DashboardGetConnectedButtons, PointsRing } from 'components/molecules';

const DashboardPoints = props => {
	const {
		points,
		primaryButtonText,
		secondaryButtonText,
		singleButtonText,
		history,
		ringType,
		onClick,
		androidVersion,
		translate
	} = props;

	const handleClick = e => {
		e.preventDefault();

		if (window.Android) {
			window.Android.showSearch();
		} else {
			history.push('/');
		}
	};

	return (
		<div className="container-fluid">
			<Row>
				<Col md="12">
					<PointsRing points={points} ringType={ringType} />
				</Col>
			</Row>
			{ringType() === 'getConnected' && <DashboardGetConnectedButtons translate={translate} />}
			{ringType() === 'getPoints' && (
				<Row className="Di_mt5px Di_ActionBttns">
					<Col md="12" xs="12" className="text-center">
						<Button className="z-depth-0" color="primary" onClick={handleClick}>
							{singleButtonText}
						</Button>
					</Col>
				</Row>
			)}
			{ringType() === 'points' && (
				<Row className="Di_mt5px Di_ActionBttns">
					<Col xs="12">
						<Button className="z-depth-0" color="primary" onClick={() => history.push('/rewards')}>
							{primaryButtonText}
						</Button>

						<Button className="z-depth-0" onClick={() => history.push('/history')}>
							{secondaryButtonText}
						</Button>
					</Col>
				</Row>
			)}
			{ringType() === 'connections' && (
				<Row className="Di_mt5px Di_ActionBttns">
					<Col md="12" xs="12" className="text-center">
						<Button className="z-depth-0" color="primary" onClick={onClick}>
							{singleButtonText}
						</Button>
					</Col>
				</Row>
			)}
			{androidVersion && (
				<Row className="Di_mt5px Di_ActionBttns">
					<Col md="12" xs="12" className="text-center">
						<Button className="z-depth-0" onClick={() => history.push('/profile')}>
							Profile
						</Button>
					</Col>
				</Row>
			)}
		</div>
	);
};

DashboardPoints.propTypes = {
	points: PropTypes.string.isRequired,
	primaryButtonText: PropTypes.string.isRequired,
	secondaryButtonText: PropTypes.string.isRequired,
	singleButtonText: PropTypes.string.isRequired,
	history: PropTypes.object.isRequired,
	ringType: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	androidVersion: PropTypes.string.isRequired,
	translate: PropTypes.func.isRequired
};

export default DashboardPoints;
