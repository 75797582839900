import uuidv4 from 'uuid/v4';
import { isNull } from 'lodash';
import app from 'components/environment/App';

export const sessionToken = () => {
	let sessionToken = localStorage.getItem('sessionToken');

	if (isNull(sessionToken)) {
		sessionToken = uuidv4();
		localStorage.setItem('sessionToken', sessionToken);
	}

	return sessionToken;
};

export const token = () => {
	const { cookies, store } = app;
	const users = store.getState().users;

	if (cookies.get('token')) {
		return cookies.get('token');
	} else if (users.authenticated) {
		return users.authenticated;
	}

	return null;
};
