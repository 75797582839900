import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// NOTE: This component className does not have any styles associated.  Do we need this component?  It's just a div.
const BodyContent = ({ className, children }) => {
	const classProps = classNames('body-content', className);

	return <div className={classProps}>{children}</div>;
};

BodyContent.defaultProps = {
	className: ''
};

BodyContent.propTypes = {
	children: PropTypes.array,
	className: PropTypes.string
};

export default BodyContent;
