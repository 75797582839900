import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Text = ({ className, align, children, dataCy, onClick, paragraph, size, transform, weight }) => {
	const classProps = classNames(
		'text',
		paragraph && 'd-block',
		align && `text-${align}`,
		className,
		size && size,
		weight && weight,
		transform && `text-${transform}`
	);

	return (
		<p className={classProps} onClick={onClick} data-cy={`${dataCy}-text`}>
			{children}
		</p>
	);
};

Text.defaultProps = {
	align: null,
	className: '',
	children: '',
	paragraph: false,
	size: 'md',
	transform: 'none',
	weight: 'normal'
};

Text.propTypes = {
	align: PropTypes.oneOf(['left', 'center', 'right']),
	className: PropTypes.string,
	children: PropTypes.string,
	dataCy: PropTypes.string,
	onClick: PropTypes.func,
	paragraph: PropTypes.bool,
	size: PropTypes.oneOf(['sm', 'md', 'lg']),
	transform: PropTypes.oneOf(['none', 'capitalize', 'lowercase', 'uppercase']),
	weight: PropTypes.oneOf(['bold', 'normal', 'thin'])
};

export default Text;
