import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { OptionItem } from 'components/atoms';
/**
 * A list of {@link OptionItem}.  Used in {@link DetailsPage} for amenities, and dietaryPrefs.
 * @param {OptionsListProps} props
 */
const OptionsList = ({ className, options }) => {
	const classProps = classNames('options-list', className);

	return options.length > 0 ? (
		<ul className={classProps}>
			{options.map((option, index) => (
				<OptionItem key={`option-item-${index}`} option={option} />
			))}
		</ul>
	) : null;
};

OptionsList.defaultProps = {
	className: '',
	options: []
};

/**
 * {@link OptionsList} Props
 * @interface Props_OptionsList
 * @property {string} [className=''] Additional classNames to be applied to the options list wrapper.
 * @property {string[]} [options=[]] An array of options.
 */
OptionsList.propTypes = {
	className: PropTypes.string,
	options: PropTypes.arrayOf(PropTypes.string)
};

export default OptionsList;
