import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'third-party/mdbreact/dist/mdbreact.js';
import { Col } from 'reactstrap';
import { IconError, IconWarning } from 'components/atoms';

const PageError = ({ message, type }) => (
	<Row id="Di_PageError" className="align-items-center justify-content-center">
		<Col className="Di_PageErrorBox">
			<div className="Di_ErrorIcon">
				{type === 'warning' && <IconWarning width="30" height="30" fill="#e10600" />}
				{type !== 'warning' && <IconError width="30" height="30" fill="#e10600" />}
			</div>
			<div className="Di_ErrorText">
				<p className="message">{message}</p>
			</div>
		</Col>
	</Row>
);

PageError.propTypes = {
	type: PropTypes.string.isRequired,
	message: PropTypes.string.isRequired
};

export default PageError;
