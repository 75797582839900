import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { handleNavLinkClick } from 'utils/router';

class IconNavItem extends React.Component {
	render() {
		const { className, hidden, icon, item, isActive, key } = this.props;
		const classProps = classNames('icon-nav-item', isActive && 'active', className);

		if (hidden) {
			return null;
		}

		return item.fullUrl === true ? (
			<a href={item.url} className={classProps} key={key}>
				<img src={icon} />
				<span>{item.iconTitle ? item.iconTitle : item.title}</span>
			</a>
		) : (
			<Link to={item.url} className={classProps} key={key} onClick={handleNavLinkClick.bind(this, item.url)}>
				<img src={icon} />
				<span>{item.iconTitle ? item.iconTitle : item.title}</span>
			</Link>
		);
	}
}

IconNavItem.defaultProps = {
	hidden: false,
	icon: '',
	isActive: false
};

IconNavItem.propTypes = {
	// Required
	history: PropTypes.object.isRequired,
	item: PropTypes.object.isRequired,

	// Optional
	className: PropTypes.string,
	hidden: PropTypes.bool,
	icon: PropTypes.string,
	isActive: PropTypes.bool,
	key: PropTypes.string
};

export default withRouter(IconNavItem);
