import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, CardBody } from 'third-party/mdbreact/dist/mdbreact.js';
import { Button } from 'reactstrap';
import { Col } from 'reactstrap';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import { connect } from 'react-redux';
import metaTagConfig from './meta_tags/forgotten-meta-tags';
import { MetaTags } from 'components/molecules';
import ModalPage from '../inline/ModalPage';
import { forgotPassword } from 'actions/users';
import { setModal, toggleModal, resetModal } from 'actions/modal';
import { outsideEvent } from 'actions/general';
import { InputField } from 'components/organisms';

class Forgotten extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hasErrors: false,
			formFields: {
				email: {
					isRequired: true,
					isValid: true,
					errorText: '',
					value: '',
					requirements: [
						{
							type: 'email',
							status: 'invalid',
							text: this.props.translate('errors.email_format')
						}
					]
				}
			}
		};
	}

	timeout = {};

	onToggle = () => {
		const isOpen = !this.props.modal.isOpen;
		this.props.toggleModal(isOpen);
		if (!isOpen) {
			this.props.resetModal();
		}
	};

	onPrimaryClick = () => {
		this.props.modal.id === 'cancel' ? this.onToggle() : this.props.history.push('/login');
	};

	onSecondaryClick = () => {
		this.props.history.goBack();
	};

	onChange = e => {
		this.setState({
			hasErrors: false,
			formFields: {
				email: {
					value: e.target.value
				}
			}
		});
	};

	isValidEmail = email => {
		return email.match(
			// eslint-disable-next-line no-useless-escape
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
	};

	setErrorState = targetNode => {
		const hasErrors = true;
		targetNode.isValid = false;
		this.setState(() => ({ hasErrors, targetNode }));
	};

	onCancel = e => {
		e.preventDefault();
		this.props.setModal({});
		this.props.setModal({
			id: this.props.translate('forgotten.modals.cancel.id'),
			header: this.props.translate('forgotten.modals.cancel.header'),
			body: this.props.translate('forgotten.modals.cancel.body'),
			primaryButtonText: this.props.translate('forgotten.modals.cancel.primaryButtonText'),
			secondaryButtonText: this.props.translate('forgotten.modals.cancel.secondaryButtonText')
		});
		this.onToggle();
	};

	onSubmit = e => {
		e.preventDefault();
		let isValid = true;
		const formField = this.state.formFields.email;

		if (formField.value === undefined || formField.value === '') {
			formField.errorText = this.props.translate('forgotten.emailerror');
			this.setErrorState(formField);
			isValid = false;
		} else if (!this.isValidEmail(formField.value)) {
			formField.errorText = this.props.translate('forgotten.pageerror');

			this.setErrorState(formField);
			isValid = false;
		}

		if (isValid) {
			// console.log('resetting pw');
			this.props.forgotPassword(
				{
					email: formField.value
				},
				(/*response*/) => {
					this.props.setModal({
						id: this.props.translate('forgotten.modals.ok.id'),
						header: this.props.translate('forgotten.modals.ok.title'),
						body: this.props.translate('forgotten.modals.ok.body'),
						primaryButtonText: this.props.translate('forgotten.modals.ok.primaryButtonText'),
						secondaryButtonText: undefined
					});
					this.onToggle();
				}
			);
		}
	};

	componentWillUnmount() {
		clearTimeout(this.timeout);
	}

	render() {
		return (
			<div className="Di_SubHead Di_HomePic">
				<MetaTags tags={metaTagConfig} />
				<ModalPage
					isOpen={this.props.modal.isOpen}
					primaryClick={this.onPrimaryClick}
					secondaryClick={this.onSecondaryClick}
				/>
				<form onSubmit={this.onSubmit}>
					<Container className="Di_HeadSpace">
						<Row>
							<div className="Di_PageLimit mx-auto float-none animated fadeInUp">
								<CardBody>
									<Container>
										<Row>
											<Col xs="12">
												<h1>{this.props.translate('forgotten.headline')}</h1>
											</Col>
											<Col xs="12">
												<p>{this.props.translate('forgotten.body')}</p>
											</Col>
											<Col xs="12">
												<div className="Di_RequiredHeader">
													<span>*</span>
													{this.props.translate('forgotten.required')}
												</div>
											</Col>
										</Row>
										{/* Email */}
										<Row>
											<Col xs="12">
												<InputField
													type="email"
													label={this.props.translate('forgotten.emailLabel')}
													onChange={this.onChange}
													name="email"
													required
													hasError={this.state.hasErrors}
													error={this.state.formFields.email.errorText}
													value={this.state.formFields.email.value}
												/>
											</Col>
											<Col xs="12" className="Di_ActionBttns">
												<Button className="z-depth-0" color="primary" type="submit">
													{this.props.translate('forgotten.bttn1')}
												</Button>

												<Button className="z-depth-0" onClick={this.onCancel}>
													{this.props.translate('forgotten.bttn2')}
												</Button>
											</Col>
										</Row>
									</Container>
								</CardBody>
							</div>
						</Row>
					</Container>
				</form>
			</div>
		);
	}
}

Forgotten.propTypes = {
	forgotPassword: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	modal: PropTypes.object.isRequired,
	resetModal: PropTypes.func.isRequired,
	setModal: PropTypes.func.isRequired,
	toggleModal: PropTypes.func.isRequired,
	translate: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
	forgotPassword: (user, callback) => dispatch(forgotPassword(user, callback)),
	setModal: modal => dispatch(setModal(modal)),
	toggleModal: toggle => dispatch(toggleModal(toggle)),
	resetModal: () => dispatch(resetModal()),
	outsideEvent: event => dispatch(outsideEvent(event))
});

const mapStateToProps = state => ({
	translate: getTranslate(state.locale),
	currentLanguage: getActiveLanguage(state.locale).code,
	modal: state.modal
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Forgotten);
