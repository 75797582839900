import * as t from './types';
import { basePath, token } from 'actions/utils';
import axios from 'axios';
import { setError } from '../registration';
import { setError as setGeneralError, isLoading, getConnectionType } from '../general';
import { STORE } from 'components/environment/App/redux_store.js';
import moment from 'moment';
import { loginWithProviderAndToken } from 'utils/catering';
import Cookies from 'universal-cookie';
import { resetState } from 'actions/general';
import setSecureCookie from 'utils/secure_cookie';

/**
 * Clear any errors.
 */
export const clearError = () => ({
	type: t.CLEAR_ERROR
});

/**
 * Fetch the logged in user's profile data.
 */
export const getProfile = (showLoading = true) => async dispatch => {
	let cookies = new Cookies();

	try {
		if (showLoading) {
			dispatch(isLoading(true, 'Fetching user details'));
		}

		const response = await axios.get(`${basePath()}/users`, {
			headers: {
				Authorization: token(),
				'Content-type': 'application/json'
			}
		});

		if (response.data.user.profile !== undefined && response.data.user.profile.companyCode != null) {
			dispatch(getConnectionType(response.data.user.profile.companyCode));
		}
		return dispatch({
			type: t.SET_USER,
			payload: response.data.user
		});
	} catch (e) {
		console.log('get profile error:', JSON.stringify(e));
		if (e.response) {
			return dispatch({
				type: t.FETCH_USER_ERROR,
				payload: e.response.data.response.code
			});
		} else {
			cookies.remove('token', {
				path: '/',
				domain: getDomain(window.location.hostname, true)
			});
			cookies.remove('refresh', {
				path: '/',
				domain: getDomain(window.location.hostname, true)
			});
			await logout(dispatch);
			window.zupplerBridge && window.zupplerBridge.logout();
			dispatch(resetState());
			return dispatch({
				type: t.FETCH_USER_ERROR,
				payload: 500
			});
		}
	} finally {
		dispatch(isLoading(false));
	}
};

/**
 * Fetch the logged in users transaction data.
 * @param  {Date}   date       The month to fetch transaction history data for.
 * @param  {Function} callback Transaction API res data handler.
 */
export const getTransactions = (date, callback) => async dispatch => {
	try {
		// dispatch(isLoading(true, 'Fetching user transactions'));
		let url = `${basePath()}/users/transactions`;
		if (date) {
			url = `${url}?date=${date}`;
		}
		const response = await axios.get(url, {
			headers: {
				Authorization: token(),
				'Content-type': 'application/json'
			}
		});
		// let id = '2cd66d81-4ed1-54dc-b6e1-648933a2140d';
		// Object.values(response.data.transactions)[0].forEach(transaction => {
		// 	if (transaction.venue.id === id) {
		// 		console.log('duplicate id');
		// 	}
		// });
		dispatch({
			type: t.SET_USER_TRANSACTIONS,
			payload: response.data.transactions
		});
		callback(response.data);
	} catch (e) {
		dispatch({
			type: t.FETCH_USER_ERROR,
			payload: e.response.data.response.code
		});
	} finally {
		// dispatch(isLoading(false));
	}
};

/**
 * Refresh the logged in user's token.
 * @param  {string} refreshToken The refresh token.
 */
export const refresh = refreshToken => async dispatch => {
	dispatch(refreshStart());
	const response = await axios.post(`${basePath()}/users/authorize`, JSON.stringify({ refreshToken: refreshToken }));
	dispatch(refreshStop());

	switch (response.data.response.code) {
		case 200:
			dispatch(refreshTime());
			setSecureCookie({
				name: 'token',
				data: response.data.tokens.access,
				path: '/',
				domain: getDomain(window.location.hostname, true)
			});
			dispatch({ type: t.REFRESH_USER, payload: response.data.tokens.access });
			break;
		default:
			break;
	}
};

/**
 * Login a user.
 * @param  {object}   user     The user to log in.
 * @param  {string}   username The user's username.
 * @param  {string}   password The user's password.
 * @param  {Function} callback User login API res data handler.
 */
export const login = (user, callback) => async dispatch => {
	// console.log('called login');
	try {
		dispatch(isLoading(true, 'Please wait'));
		const response = await axios.post(`${basePath()}/users/authorize`, JSON.stringify(user));
		switch (response.data.response.code) {
			case 200:
				dispatch(refreshTime());
				dispatch({ type: t.AUTH_USER, payload: response.data.user.tokens.access });
				// localStorage.setItem('dinova-tokens', response.data.user.tokens.access);
				setSecureCookie({
					name: 'token',
					data: response.data.user.tokens.access,
					path: '/',
					domain: getDomain(window.location.hostname, true)
				});
				setSecureCookie({
					name: 'refresh',
					data: response.data.user.tokens.refresh,
					path: '/',
					domain: getDomain(window.location.hostname, true)
				});
				break;
			case 410:
				dispatch({ type: t.RESET_PASSWORD, payload: response.data.user });
				break;
			case 412:
				dispatch({ type: t.MIGRATE_PASSWORD, payload: response.data.user });
				break;
			default:
				dispatch({ type: t.AUTH_ERROR, payload: response.data.code });
				break;
		}

		callback(response.data.response);
	} catch (e) {
		switch (e.response.data.response.code) {
			case 410:
				dispatch({ type: t.RESET_PASSWORD, payload: e.response.data.user });
				callback(e.response.data.response);
				break;
			case 412:
				dispatch({ type: t.MIGRATE_PASSWORD, payload: e.response.data.user });
				callback(e.response.data.response);
				break;
			default:
				break;
		}

		callback(e.response.data.response);

		dispatch({
			type: t.AUTH_ERROR,
			payload: e.response.data.response.code
		});
	} finally {
		dispatch(isLoading(false));
	}
};

const clearAuth = dispatch => {
	let cookies = new Cookies();
	resetRefreshTime();
	cookies.remove('token', {
		path: '/',
		domain: getDomain(window.location.hostname, true)
	});
	cookies.remove('refresh', {
		path: '/',
		domain: getDomain(window.location.hostname, true)
	});

	dispatch({
		type: t.AUTH_USER,
		payload: ''
	});
};
/**
 * Logout the logged in user.
 */
export const logout = dispatch => {
	return new Promise(resolve => {
		axios
			.get(`${basePath()}/users/logout`, {
				headers: {
					Authorization: token(),
					'Content-type': 'application/json'
				}
			})
			.then(() => {
				clearAuth(dispatch);
			})
			.catch(() => {
				clearAuth(dispatch);
			})
			.finally(() => {
				resolve();
			});
	});
};

/**
 * IUser
 * @interface IUser
 * @property {String}          userName                                The username of the user.
 * @property {String[]}        cardUsage                               An array of strings indicating how this user will use their myDinova account connection (credit card). Ex: ['catering', 'private_dining'].
 * @property {String}          password                                The password of the user.
 * @property {object}          email                                   The email addresses for the user.
 * @property {string}          email.primary                           The primary email address for the user.
 * @property {object}          preferences                             Account preferences. Ex: notification preferences.
 * @property {object}          preferences.notification                Account preferences. Ex: notification preferences.
 * @property {string}          preferences.notification.monthlySummary If the user should receive a monthly transaciton summary.
 * @property {string}          preferences.notification.specialOffers  If the user should receive special offer notifications.
 * @property {boolean}         acceptedTC
 * @property {object}          campaign
 * @property {string|number}   campaign.id                             The id of the campaign the user is associated with.
 * @property {object}          company                                 The company assiciated with the user.
 * @property {string}          company.id                              The id of the company the user is associated with.
 */

/**
 * Register a new user.  Creates a new user in the DB.
 * @param  {IUser}   user      The user to register/create.
 * @param  {Function} callback Register user API res data handler.
 */
export const register = (user, callback) => async dispatch => {
	let cookies = new Cookies();
	const saveCookie = user.catering;
	try {
		dispatch(setError());
		dispatch(isLoading(true, 'creating user'));
		const response = await axios.post(`${basePath()}/users`, JSON.stringify(user));
		dispatch({ type: t.AUTH_USER, payload: response.data.user.tokens.access });
		dispatch({
			type: t.SET_USER,
			payload: response.data.user
		});

		if (saveCookie) {
			setSecureCookie({
				name: 'token',
				data: response.data.user.tokens.access,
				path: '/',
				domain: getDomain(window.location.hostname, true)
			});
			cookies.set('startTime', moment());
		}

		loginWithProviderAndToken(response.data.user.tokens.access);

		callback(response.data);
	} catch (e) {
		dispatch(setError(e.response.data.response.code));
		callback(e.response.data.response);
	} finally {
		dispatch(isLoading(false));
	}
};

export const update = (userDetails, callback) => async dispatch => {
	try {
		dispatch(isLoading(true, 'updating user'));

		const response = await axios.patch(`${basePath()}/users`, JSON.stringify(userDetails), {
			headers: {
				Authorization: token(),
				'Content-type': 'application/json'
			}
		});
		callback(response.data.response);
	} catch (e) {
		if (e.response) {
			dispatch(setError(e.response.data.response.code));
			callback(e.response.data.response);
		} else {
			dispatch(setError(500));
		}
	} finally {
		dispatch(isLoading(false));
	}
};

/**
 * Unsubscribe a user.
 * @param  {object}   userDetails
 * @param  {string}   userDetails.usType
 * @param  {string}   userDetails.token
 * @param  {Function} callback    API res data handler.
 */
export const unsubscribe = (userDetails, callback) => async dispatch => {
	try {
		const response = await axios.post(`${basePath()}/users/unsubscribe`, JSON.stringify(userDetails));
		callback(response.data.response);
	} catch (e) {
		if (e.response) {
			dispatch(setError(e.response.data.response.code));
			callback(e.response.data.response);
		} else {
			dispatch(setError(500));
		}
	}
};

/**
 * Update a user's password.
 * @param  {object}   changedPassword
 * @param  {string}   changedPassword.old The previous password.
 * @param  {string}   changedPassword.new The new password.
 * @param  {Function} callback            Password reset API res data handler.
 */
export const changePassword = (changedPassword, callback) => async dispatch => {
	try {
		dispatch(isLoading(true, 'updating password'));

		const response = await axios.put(`${basePath()}/users/password`, JSON.stringify(changedPassword), {
			headers: {
				Authorization: token(),
				'Content-type': 'application/json'
			}
		});
		callback(response.data.response);
	} catch (e) {
		dispatch(setError(e.response.data.response.code));
		callback(e.response.data.response);
	} finally {
		dispatch(isLoading(false));
	}
};

/**
 * Add a credit card connection to a user.
 * @param  {object}        cardDetails
 * @param  {number}        cardDetails.id        The type of the credit card.
 * @param  {string}        cardDetails.firstName The first name of the card holder.
 * @param  {string}        cardDetails.lastName  The last name of the card holder.
 * @param  {string|number} cardDetails.number    The credit card number.
 * @param  {Function}      callback              Card connected API res data handler.
 */
export const connectToPoints = (cardDetails, callback) => async dispatch => {
	try {
		dispatch(isLoading(true, 'connecting user'));

		// if this is a test card
		if (currentEnv() === 'dev') {
			//cardDetails.payment.test = true;
		}

		const response = await axios.post(`${basePath()}/users/connections`, JSON.stringify(cardDetails), {
			headers: {
				Authorization: token(),
				'Content-type': 'application/json'
			}
		});

		callback(response.data.response);
	} catch (e) {
		if (e.response) {
			dispatch(setError(e.response.data.response.code));
			callback(e.response.data.response.code);
		} else {
			dispatch(setError(500));
			callback(500);
		}
	} finally {
		dispatch(isLoading(false));
	}
};

/**
 * Remove a credit card from a user.
 * @param  {string|number} cardId The DB id for the card to remove.
 * @param  {Function} callback    Card removed API res data handler.
 */
export const deletePaymentInfo = (cardId, callback) => async dispatch => {
	dispatch(isLoading(true, 'removing payment information'));

	try {
		const response = await axios.delete(`${basePath()}/users/connections/${cardId}`, {
			headers: {
				Authorization: token(),
				'Content-type': 'application/json'
			}
		});
		callback(response.data.response);
	} catch (error) {
		if (error.response) {
			dispatch(setGeneralError(error.response.data.response.code));
			callback(error.response.data.response.code);
		} else {
			dispatch(setGeneralError(500));
		}
	} finally {
		dispatch(isLoading(false));
	}
};

/**
 * Update user payment info.
 */
export const updatePaymentInfo = (data, callback) => async dispatch => {
	try {
		dispatch(isLoading(true, 'updating payment information'));

		const response = await axios.patch(
			`${basePath()}/users/connections/${data.connectionID}`,
			JSON.stringify(data.payment),
			{
				headers: {
					Authorization: token(),
					'Content-type': 'application/json'
				}
			}
		);
		callback(response.data.response);
	} catch (e) {
		if (e.response) {
			dispatch(setGeneralError(e.response.data.response.code));
			callback(e.response.data.response.code);
		} else {
			dispatch(setGeneralError(500));
		}
	} finally {
		dispatch(isLoading(false));
	}
};

/**
 * Trigger forgotPassword email flow for user.
 */
export const forgotPassword = (user, callback) => async dispatch => {
	try {
		dispatch(isLoading(true, 'requesting password'));
		const response = await axios.post(`${basePath()}/users/forgotpassword`, JSON.stringify(user));
		if (user.token) {
			dispatch({ type: t.AUTH_USER, payload: response.data.user.tokens.access });
			setSecureCookie({
				name: 'token',
				data: response.data.user.tokens.access,
				path: '/',
				domain: getDomain(window.location.hostname, true)
			});
		}
		console.log('forgot pw response:', response);
		callback(response);
	} catch (e) {
		if (e.response) {
			dispatch(setGeneralError(e.response.data.response.code));
		} else {
			dispatch(setGeneralError(500));
		}
	} finally {
		dispatch(isLoading(false));
	}
};

/**
 * Registration success handler
 */
export const registerSuccess = payload => {
	return { type: t.REGISTER_SUCCESS, payload };
};

/**
 * Registration failure handler
 */
export const registerFailure = () => {
	return { type: t.REGISTER_FAILURE };
};

/**
 * User logged in refresh start.
 */
export const refreshStart = () => {
	return { type: t.REFRESH_USER_RUNNING, payload: true };
};

/**
 * User logged in refresh stop.
 */
export const refreshStop = () => {
	return { type: t.REFRESH_USER_RUNNING, payload: false };
};

/**
 * User logged in refresh time.
 */
export const refreshTime = () => {
	return { type: t.REFRESH_TIME, payload: moment() };
};

/**
 * User logged in reset time.
 */
export const resetRefreshTime = () => {
	return { type: t.REFRESH_TIME, payload: null };
};

const currentEnv = () => {
	return STORE.getState().config.currentEnv;
};

const getDomain = (url, subdomain) => {
	subdomain = subdomain || false;

	url = url.replace(/(https?:\/\/)?(www.)?/i, '');

	if (!subdomain) {
		url = url.split('.');

		url = url.slice(url.length - 2).join('.');
	} else {
		let arr = url.split('.');
		if (arr.length == 4) {
			url = arr.slice(arr.length - 3).join('.');
		}
	}

	if (url.indexOf('/') !== -1) {
		return url.split('/')[0];
	}

	return url;
};
