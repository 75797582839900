import React from 'react';
import PropTypes from 'prop-types';
// import moment from 'moment';
import { RecommendationItem, Text } from 'components/atoms';
import { GridHeader, List } from 'components/molecules';
import { LoadingBase } from 'components/atoms';
import { RecommendationsThankYouModal } from 'components/organisms';
// import { withAccordion } from 'components/molecules';

const LABELS = [
	{ text: 'Recommend', colClass: 'col-sm-2' },
	{ text: 'Date', colClass: 'col-sm-2' },
	{ text: 'Restaurant', hidden: isSV => isSV, colClass: 'col-sm-3' },
	{ text: 'Details', colClass: 'col-sm-6 col-md-3' },
	{ text: '', colClass: 'col-sm-2 col-md-2' }
];

class RecommendationsPageList extends React.Component {
	render() {
		const { isLoading, items, translate } = this.props;
		const hasItems = items && Object.keys(items).length > 0;
		let mergedMonths = [];
		if (hasItems) {
			mergedMonths = Object.values(items).reduce((accum, item) => {
				return [...accum, ...item];
			}, []);
		}

		return (
			<div className="recomendations-page-list">
				<RecommendationsThankYouModal />
				<GridHeader labels={LABELS} withBorder={false} type="table" />
				{isLoading && <LoadingBase className="pt-5" message={translate('Recommendations.loadingMsg')} />}
				{!isLoading && hasItems && (
					// mergedMonths.map(item => {
					// return withAccordion(
					// return (
					<List key={'rec-list-items'} itemComponent={RecommendationItem} items={mergedMonths}>
						list
					</List>
				)
				// );
				// ,
				// {
				// 	expanded: true,
				// 	canCollapse: true,
				// 	title: moment(month)
				// 		.format('MMMM')
				// 		.toUpperCase(),
				// 	key: month,
				// 	className: 'table',
				// 	titleAlign: 'center'
				// }
				// );
				// })
				}
				{!isLoading && !hasItems && (
					<Text className="no-activity" size="sm">
						{translate('RecommendationsPage.no-recommendations')}
					</Text>
				)}
			</div>
		);
	}
}

RecommendationsPageList.defaultProps = {};
RecommendationsPageList.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	items: PropTypes.object,
	translate: PropTypes.func.isRequired
};

export default RecommendationsPageList;
