import * as t from './types';

export const setCenter = (dispatch, center) => {
	center = {
		lat: center.lat * 1,
		lng: center.lng * 1
	};
	return new Promise(resolve => {
		dispatch({
			type: t.SET_BUSINESS_SEARCH_MAP_CENTER,
			payload: center
		});
		resolve();
	});
};

export const setZoom = zoom => ({
	type: t.SET_BUSINESS_SEARCH_MAP_ZOOM,
	payload: zoom
});

export const setAreaSearchDisplayed = isDisplayed => ({
	type: t.SET_AREA_SEARCH_BUTTON_DISPLAYED,
	payload: isDisplayed
});

export const reCenterToGeoLocation = shouldCenter => ({
	type: t.RECENTER_TO_GEOLOCATION,
	payload: shouldCenter
});

export const setRecenterToMapMarker = (dispatch, shouldCenter) => {
	return new Promise(resolve => {
		dispatch({
			type: t.SET_RECENTER_TO_MAP_MARKER,
			payload: shouldCenter
		});
		resolve();
	});
};

export const setCenterPan = center => ({
	type: t.SET_CENTER_PAN,
	payload: center
});
