import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import moment from 'moment';
import { Title } from 'components/atoms';
import { currencyFormat } from 'utils/formatters';

const ReceiptView = ({ className, reward, translate }) => {
	const classProps = classNames('receipt-view', 'container', className),
		dateFormatted = moment().format('DD/MM/YYYY');

	return (
		<div className={classProps}>
			<div className="row">
				<div className="col">
					<img className="reward-img" src={reward.selectedReward.image} alt={reward.selectedReward.title} />
					<Title inline>{translate('OrderReceipt.dateLabel')}</Title>{' '}
					<span className="text">{dateFormatted}</span>
					<Title inline>{translate('OrderReceipt.itemLabel')}</Title>{' '}
					<span className="text">
						{reward.selectedReward.title} {translate('OrderReceipt.itemInnerText')} $
						{currencyFormat(
							Number.parseInt(
								reward.selectedReward.cards.find(
									x => parseInt(x.id) === parseInt(reward.redeemedReward.id)
								).amount
							)
						)}
					</span>
					<Title inline>{translate('OrderReceipt.pointsLabel')}</Title>{' '}
					<span className="text">
						{currencyFormat(reward.redeemedReward.value)} {translate('OrderReceipt.pointsSuffix')}
					</span>
					<Title align="left">{translate('OrderReceipt.enjoyLabel')}</Title>
					<span className="text">{translate('OrderReceipt.note')}</span>
					<div className="text-center">
						<Link to="/rewards" className="ok-btn">
							<Button color="primary">{translate('OrderReceipt.okBtn')}</Button>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

ReceiptView.defaultProps = {
	className: ''
};

ReceiptView.propTypes = {
	className: PropTypes.string,
	reward: PropTypes.object.isRequired,
	translate: PropTypes.func.isRequired
};

export default ReceiptView;
