import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import isNodeEnv from 'utils/isNodeEnv';
import classNames from 'classnames';

const HeaderLogo = ({ config, isRegisterPage, translate, users }) => {
	const wrapperCls = classNames('Di_LogoBoxLeft'),
		imgSrc = `${config.cdn_basePath}/icons/icon-dinova-logo.svg`,
		hasAuth = users.user && users.authenticated,
		userName = ` ${(users.user && users.user.name.first) || ''} • `,
		points = (users.user && Number.parseInt(users.user.points).toLocaleString() + ' points') || '';

	let logoLink = isRegisterPage ? '/' : `${config.header_logo_link}`;

	if (window.location.href.indexOf('localhost') > -1 && isNodeEnv('development')) {
		logoLink = '/';
	}

	const onClick = e => {
		e.preventDefault();
		window.location = logoLink;
	};

	const useDinovaLink = logoLink !== '/',
		useSimpleHeader =
			window.location.hash.indexOf('/checkout/') > -1 || window.location.hash.indexOf('/start/') > -1;

	return (
		<div className={wrapperCls}>
			{useDinovaLink ? (
				<a className="logo-link" href={logoLink} onClick={onClick}>
					<img src={imgSrc} alt={translate('header.dinova-alt')} />
				</a>
			) : (
				<Link className="logo-link" to={logoLink}>
					<img src={imgSrc} alt={translate('header.dinova-alt')} />
				</Link>
			)}

			{hasAuth && !useSimpleHeader && (
				<span className="welcome-phrase">
					<span className="welcome">{translate('header.welcome')}</span>
					{userName}
					{points}
				</span>
			)}
		</div>
	);
};

HeaderLogo.defaultProps = {
	general: {
		useMyDinovaLogo: true
	},
	users: {
		points: null,
		user: {
			name: {
				first: null
			}
		},
		authenticated: false
	}
};

HeaderLogo.propTypes = {
	// Required
	config: PropTypes.object.isRequired,
	translate: PropTypes.func.isRequired,

	// Optional
	general: PropTypes.object,
	isRegisterPage: PropTypes.bool,
	users: PropTypes.object
};

const mapStateToProps = state => {
	return {
		config: state.config,
		general: state.general,
		translate: getTranslate(state.locale),
		users: state.users
	};
};

export default connect(mapStateToProps)(HeaderLogo);
