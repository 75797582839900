import mockTransactions from './mock';

export default (state, user) => {
	return {
		...state,
		user: {
			...user,
			transactions: {
				...mockTransactions
			}
		}
	};
};
