import ReferaCoworker from './ui-component';
import { connect } from 'react-redux';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import { setReferralLink } from 'actions/registration';
import { getProfile } from 'actions/users';
import { hasProp } from 'utils/object';

const mapDispatchToProps = dispatch => ({
	setReferralLink: link => dispatch(setReferralLink(link)),
	getProfile: () => dispatch(getProfile())
});

const mapStateToProps = state => ({
	translate: getTranslate(state.locale),
	currentLanguage: getActiveLanguage(state.locale).code,
	users: state.users,
	link: hasProp(state, 'users.user.urls.referral') ? state.users.user.urls.referral : null
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ReferaCoworker);
