import * as t from '../actions/location_autocomplete/types';

/**
 * Redux_LocationAutocompleteState
 * @interface Redux_LocationAutocompleteState
 * @property {string}  [address='']
 * @property {boolean} [loading=false]
 * @property {array}   [suggestions=[]]
 * @property {boolean} [ready=false]
 */
const locationAutocompleteDefaultState = {
	address: '',
	loading: false,
	suggestions: [],
	ready: false
};

export default (state = locationAutocompleteDefaultState, action) => {
	switch (action.type) {
		case t.SET_AUTOCOMPLETE_SUGGESTIONS: {
			// console.log('set sugg', action.value);
			return { ...state, suggestions: action.value };
		}
		case t.SET_LOCATION_AUTOCOMPLETE_SEARCH: {
			return { ...state, address: action.value };
		}
		case t.SET_LOCATION_AUTOCOMPLETE_LOADING: {
			return { ...state, loading: action.value };
		}
		case t.SET_LOCATION_AUTOCOMPLETE_READY: {
			return { ...state, ready: action.value };
		}
		default:
			return state;
	}
};
