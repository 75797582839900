import DashboardPromotions from './ui-component';
import { connect } from 'react-redux';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import { outsideEvent } from 'actions/general';

const mapDispatchToProps = dispatch => ({
	outsideEvent: event => dispatch(outsideEvent(event))
});

const mapStateToProps = state => ({
	translate: getTranslate(state.locale),
	currentLanguage: getActiveLanguage(state.locale).code,
	config: state.config
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DashboardPromotions);
