import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * [DropdownOption description]
 * @constructor
 * @param {object} option The item to use for this dropdown item.
 * @param {number} index  The index of this option in the parent options array.
 * @param {config} config React Component props.
 *
 * @example <DropdownOption option={{displayValue: "Option"} config={className: 'option-class', onClick: () => {}} index={3}} />
 */
const DropdownOption = ({ option, config }) => (
	<p onClick={config.onClick} className={classnames('option', config.className)}>
		{option.displayValue}
	</p>
);

DropdownOption.defaultProps = {};

/**
 * DropdownOption Props
 * @interface DropdownOption_Props
 * @property {object} config Child component props.
 * @property {string} [config.className] Addtional class names for the dropdown option React component.
 * @property {func} config.onClick Handler for when option is selected.
 * @property {object} option The data object for this dropdown item.
 * @property {string} option.displayValue The text to show as the name/label of this option.
 */
DropdownOption.propTypes = {
	config: PropTypes.shape({
		className: PropTypes.string,
		onClick: PropTypes.func
	}),
	option: PropTypes.shape({
		displayValue: PropTypes.string
	})
};

export default DropdownOption;
