// import LazyCateringContainer from './CateringContainer/lazy-container.js';
import LazyDashboardPage from './DashboardPage/lazy-container.js';
import LazyDetailsAboutPage from './DetailsAboutPage/lazy-container.js';
import LazyDetailsHeader from './DetailsHeader/lazy-container.js';
import LazyDetailsPage from './DetailsPage/lazy-container.js';
import LazyEditPaymentPage from './EditPaymentPage/lazy-container.js';
import LazyEditPersonalPage from './EditPersonalPage/lazy-container.js';
import LazyEditPreferencesPage from './EditPreferencesPage/lazy-container.js';
import LazyEditSecurity from './EditSecurityPage/lazy-container.js';
import LazyFooter from './Footer/lazy-container.js';
import LazyHeader from './Header/lazy-container.js';
import LazyHomePage from './HomePage/lazy-container.js';
import Layout from './Layout';
import LazyLogoutPage from './LogoutPage/lazy-container.js';
import LazyModalLayout from './ModalLayout/lazy-container.js';
import LazyModalPageWrapper from './ModalPageWrapper/lazy-container.js';
// import LazyModifyModal from './ModifyModal/lazy-container.js';
import LazyNoResponsePage from './NoResponsePage/lazy-container';
import LazyOrderPage from './OrderPage/lazy-container.js';
import LazyOrderReceiptPage from './OrderReceiptPage/lazy-container.js';
import LazyPrivateRoute from './PrivateRoute/lazy-container.js';
import LazyProfilePage from './ProfilePage/lazy-container.js';
// import LazyPublicRoute from './PublicRoute/lazy-container.js';
import LazyRecommendationsPage from './RecommendationsPage/lazy-container.js';
import LazyReferPage from './ReferPage/lazy-container.js';
import LazyRegistrationPage from './RegistrationPage/lazy-container.js';
import LazyResetRoute from './ResetRoute/lazy-container.js';
import LazyRewardErrorPage from './RewardErrorPage/lazy-container.js';
import LazyRewardsPage from './RewardsPage/lazy-container.js';
import LazyRewardViewPage from './RewardViewPage/lazy-container.js';
import LazySearchPage from './SearchPage/lazy-container.js';
import LazySectionHeader from './SectionHeader/lazy-container.js';
import LazyShowCookie from './ShowCookie/lazy-container.js';
import LazyTransactionsPage from './TransactionsPage/lazy-container.js';
import LazyUnsubscribePage from './UnsubscribePage/lazy-container.js';

export {
	// LazyCateringContainer,
	LazyDashboardPage,
	LazyDetailsAboutPage,
	LazyDetailsHeader,
	LazyDetailsPage,
	LazyEditPaymentPage,
	LazyEditPersonalPage,
	LazyEditPreferencesPage,
	LazyEditSecurity,
	LazyFooter,
	LazyHeader,
	LazyHomePage,
	Layout,
	LazyLogoutPage,
	LazyModalLayout,
	LazyModalPageWrapper,
	// LazyModifyModal,
	LazyNoResponsePage,
	LazyOrderPage,
	LazyOrderReceiptPage,
	LazyPrivateRoute,
	LazyProfilePage,
	// LazyPublicRoute,
	LazyRecommendationsPage,
	LazyReferPage,
	LazyRegistrationPage,
	LazyResetRoute,
	LazyRewardErrorPage,
	LazyRewardViewPage,
	LazyRewardsPage,
	LazySearchPage,
	LazySectionHeader,
	LazyShowCookie,
	LazyTransactionsPage,
	LazyUnsubscribePage
};
