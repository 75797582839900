import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { InputLabel } from 'components/atoms';

import './styles/main.scss';

/**
 * RadioInputOption - A single Radio Input
 * @constructor
 * @param {RadioInputOption_Props} props
 */
const RadioInputOption = ({ className, checked, dataCy, index, onChange, option }) => {
	const classProps = classnames('radio-option-wrapper', className);

	return (
		<div className={classProps} data-cy={`${dataCy}-radio-option-wrapper`}>
			<input
				data-cy={`${dataCy}-radio-option`}
				className="radio-option"
				type="radio"
				value={option.value}
				name={option.name}
				id={option.id || `${option.name}-${index || ''}`}
				checked={checked}
				onChange={onChange.bind(this, option)}
			/>
			<InputLabel
				data-cy={`${dataCy}-radio`}
				text={option.displayValue}
				optionLabel={true}
				type={'radio'}
				htmlFor={option.id || `${option.name}-${index || ''}`}
				onClick={onChange.bind(this, option)}
			>
				{option.info && option.info}
			</InputLabel>
		</div>
	);
};

RadioInputOption.defaultProps = {};

/**
 * RadioInputOption Props
 * @interface RadioInputOption_Props
 * @param {string}   className Additional classname to add to the radio input wrapper.
 * @param {boolean}  checked   If this radio input is checked.
 * @param {number}   index     The index of this radio option in the parent array of options (if any).
 * @param {function} onChange  On radio checked change handler.
 * @param {object}   option    Data object for this radio input.
 */
RadioInputOption.propTypes = {
	checked: PropTypes.bool,
	className: PropTypes.string,
	dataCy: PropTypes.string,
	index: PropTypes.number,
	option: PropTypes.object,
	onChange: PropTypes.func
};

export default RadioInputOption;
