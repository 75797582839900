/* eslint-disable react/prop-types */
import React from 'react';

const IconError = ({ dataCy, fill, height, width }) => (
	<svg
		data-cy={`${dataCy}-errIcn`}
		xmlns="http://www.w3.org/2000/svg"
		width={width}
		height={height}
		viewBox="0 0 135 135"
	>
		<title>icon - error</title>
		<g id="Layer_2" data-name="Layer 2">
			<g id="Layer_1-2" data-name="Layer 1">
				<path
					fill={fill}
					d="M72.53,83.59V33.33a5,5,0,0,0-5-5h0a5,5,0,0,0-5,5V83.59a5,5,0,0,0,5,5h0A5,5,0,0,0,72.53,83.59Z"
				/>
				<circle fill={fill} cx="67.5" cy="100.21" r="6.48" />
				<path
					fill={fill}
					d="M67.5,135A67.5,67.5,0,1,1,135,67.5,67.58,67.58,0,0,1,67.5,135Zm0-127.29A59.79,59.79,0,1,0,127.29,67.5,59.86,59.86,0,0,0,67.5,7.71Z"
				/>
			</g>
		</g>
	</svg>
);

export default IconError;
