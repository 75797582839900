import queryString from 'query-string';
import { last, map } from 'lodash';
import { transformKeyNames } from 'utils/object';

export const fixMissingHashURL = () => {
	let url = window.location.href;
	if (url.indexOf('#') === -1) {
		if (url[url.length - 1] !== '/') {
			url += '/#/';
		} else {
			url += '#/';
		}
		window.location.replace(url);
	}
};

export const parseCoords = coordsStr => {
	const regexCoords = new RegExp('^(-?\\d+(\\.\\d+)?),(-?\\d+(\\.\\d+)?)$');

	let coords = null;
	if (regexCoords.test(coordsStr)) {
		let splittedCoords = coordsStr.split(',');
		coords = {
			lat: parseFloat(splittedCoords[0]),
			lng: parseFloat(splittedCoords[1])
		};
	}

	return coords;
};

export const queryParser = searchReq => {
	searchReq = searchReq || {};
	let search =
		window.location.search[window.location.search.length - 1] === '/'
			? window.location.search.slice(0, -1)
			: window.location.search;

	let parsed = queryString.parse(search);

	parsed.location = searchReq.location || parseCoords(parsed.location);
	parsed.keywords = searchReq.keywords || parsed.keywords || '';
	parsed.near = searchReq.near || parsed.near || '';
	parsed.zoom = searchReq.zomm || parseInt(parsed.zoom) || null;
	parsed.searchAreaZoom = searchReq.searchAreaZoom || parseInt(parsed.searchAreaZoom) || null;
	parsed.isAreaSearch = searchReq.isAreaSearch || parsed.isAreaSearch === 'true';
	parsed.activePin = searchReq.activePin || parsed.activePin || '';
	parsed.mapCardDisplayed = searchReq.mapCardDisplayed || parsed.mapCardDisplayed === 'true';
	parsed.scrollListTo = searchReq.scrollListTo || parseInt(parsed.scrollListTo, 10) || 0;
	parsed.center = searchReq.center || parseCoords(parsed.center);

	// <!-- expanded sections
	let regexSections = new RegExp('^(\\d+,?)+$');

	if (regexSections.test(parsed.expandedSections)) {
		let splittedSections = parsed.expandedSections.split(',');

		// remove last item if it's empty
		if (last(splittedSections) === '') {
			splittedSections.splice(-1, 1);
		}

		parsed.expandedSections = map(splittedSections, section => parseInt(section));
	} else {
		parsed.expandedSections = [];
	}
	// -->

	return parsed;
};

/**
 * build restaurant URL. TODO should be rewritten after 301 redirects for all the restaurants
 * @param {object} - restaurant info
 * @property {string} id - restaurant id
 * @property {string} name - restaurant name
 * @property {string} city - restaurant city
 * @property {string} state - restaurant state abbreviation
 * @returns {string} - restaurant URL
 */
export const buildAboutRestaurantURL = ({ address, id, name, city, state }) => {
	if (address) {
		city = address.city;
		state = address.state.abbreviation;
	}

	name = name.trim().replace(' ', '_');
	let addressStr = `${encodeURI(name)}-${city}-${state}`;
	return `/restaurants/${id}/${addressStr}/about_us/#/`;
};

/**
 * Maps over an array of urls with :params and replaces the :oaram with match.param[value].
 * @param  {Object}   match React router match object.
 * @param  {string[]} urls  The urls to apply params to.
 * @return {string[]}       The urls with params applied.
 * @example applyURLParams({match: {params: {id: '12'}}}, ["/resturaunts/:id"]) => ["resturaunts/12"]
 */
export const applyURLParams = (match, urls) => {
	let normalizedURLs = [...urls];

	Object.entries(match.params).forEach(([key, value]) => {
		normalizedURLs = urls.map((url, i) => {
			let normalizedURL = normalizedURLs[i];
			while (normalizedURL.indexOf(`:${key}`) > -1) {
				normalizedURL = normalizedURL.replace(`:${key}`, value);
			}
			if (normalizedURL.indexOf('#') === -1) {
				normalizedURL += '/#/';
			}
			return normalizedURL;
		});
	});

	return normalizedURLs;
};

export const isEncodedURI = str => {
	return typeof str === 'string' && decodeURIComponent(str) !== str;
};

export const getLowerCasedParams = () => {
	let searchParams = queryString.parse(window.location.search.replace('/', ''));
	searchParams = transformKeyNames(searchParams, key => key.toLowerCase());

	return searchParams;
};
