import Recommendations from './ui-component';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import withSizes from 'react-sizes';
import { isSV } from 'utils/sizes';

const mapStateToProps = state => ({
	translate: getTranslate(state.locale),
	IMAGE_BASE_URL: state.config.cdn_basePath
});

const mapSizesToProps = sizes => ({
	isSV: isSV(sizes)
});

export default withSizes(mapSizesToProps)(connect(mapStateToProps)(Recommendations));
