import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Text } from 'components/atoms';

const DashboardGetConnectedButtons = ({ translate }) => {
	return (
		<div className="center dashboard-buttons">
			<Text>
				<Link to="/rewards">{translate('Rewards.useMyPoints')}</Link>
			</Text>
			<Text>
				<Link to="/history">{translate('Rewards.viewMyHistory')}</Link>
			</Text>
		</div>
	);
};

DashboardGetConnectedButtons.defaultProps = {};

DashboardGetConnectedButtons.propTypes = {
	translate: PropTypes.func.isRequired
};

export default DashboardGetConnectedButtons;
