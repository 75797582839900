import * as t from '../actions/new_business_search_filter/types';
import * as uiT from '../actions/search_page/types';

import { addFilter, filterRestaurants, recomputeFilterOptions, removeFilter } from 'utils/search_new';
/**
 * Redux_RewardState
 * @interface Redux_RewardState
 * @property {array}  [filters=[]]
 */
const businessSearchFilter = {
	// unchanging filters - set once after initial restaurant search.
	staticFilters: {
		amenities: { activeCount: 0 },
		cuisine: { activeCount: 0 },
		dietaryPreferences: { activeCount: 0 },
		recommendations: { activeCount: 0 }
	},
	// changing filters - set once after initial restaurant search - update these when user filters, use these when rendering filtered list.
	userFilters: {
		amenities: { activeCount: 0 },
		cuisine: { activeCount: 0 },
		dietaryPreferences: { activeCount: 0 },
		recommendations: { activeCount: 0 }
	},
	// if mobile view, when filter modal opens, set current filter state to tempFilters.  If user cancels from filter modal, use tempFilters to override userFilters.
	tempFilters: {
		amenities: { activeCount: 0 },
		cuisine: { activeCount: 0 },
		dietaryPreferences: { activeCount: 0 },
		recommendations: { activeCount: 0 }
	},
	selectedFiltersCount: 0,
	selectedFilters: [],
	tempSelectedFilters: 0,
	results: [],
	filteredResults: [],
	nearSearch: '',
	findSearch: '',
	didFetch: false,
	rehydrateFromSaveState: false,
	hydrateState: null,
	recOptions: {}
};

export default (state = businessSearchFilter, action) => {
	switch (action.type) {
		case t.INIT_BUSINESS_FILTERS: {
			return {
				...state,
				selectedFilters: [],
				selectedFiltersCount: 0,
				staticFilters: action.payload.filters,
				userFilters: action.payload.filters,
				results: action.payload.restaurants,
				filteredResults: action.payload.restaurants,
				didFetch: true
			};
		}
		case t.ADD_BUSINESS_FILTER: {
			const addedFilter = action.payload;

			const alreadyHasFilter = state.selectedFilters.filter(f => f.dataAccr === addedFilter.dataAccr).length > 0;
			if (alreadyHasFilter) {
				return state;
			}

			let userFilters;
			if (addFilter.forEach) {
				addFilter.forEach(filter => {
					userFilters = addFilter(filter, state.userFilters);
				});
			} else {
				userFilters = addFilter(addedFilter, state.userFilters);
			}

			const NEW_FILTERS = recomputeFilterOptions(userFilters, state.results, state.recOptions);
			let newSelectedFilters;
			// add selected filters that were not generated in recomputeFilterOptions
			if (addedFilter.forEach) {
				newSelectedFilters = [...(state.selectedFilters || []), ...addedFilter];
				addedFilter.forEach(f => {
					const [filterType, filterCat] = f.dataAccr.split('.');
					if (!NEW_FILTERS[filterType][filterCat]) {
						NEW_FILTERS[filterType][filterCat] = { ...f, resultCount: 0 };
						NEW_FILTERS[filterType].activeCount += 1;
					}
				});
			} else {
				newSelectedFilters = [...(state.selectedFilters || []), addedFilter];
				const [filterType, filterCat] = addedFilter.dataAccr.split('.');
				if (!NEW_FILTERS[filterType][filterCat]) {
					NEW_FILTERS[filterType][filterCat] = { ...addedFilter, resultCount: 0 };
					NEW_FILTERS[filterType].activeCount += 1;
				}
			}

			const filteredResults = filterRestaurants(userFilters, state.results, state.recOptions);

			return {
				...state,
				selectedFilters: newSelectedFilters,
				selectedFiltersCount: newSelectedFilters.length,
				userFilters: NEW_FILTERS,
				filteredResults
			};
		}
		case t.REMOVE_BUSINESS_FILTER: {
			const filter = action.payload;
			if (filter.selected == true) {
				filter.selected = false;
			}

			const userFilters = removeFilter(filter, state.userFilters);
			const NEW_FILTERS = recomputeFilterOptions(userFilters, state.results, state.recOptions);
			const filteredResults = filterRestaurants(userFilters, state.results, state.recOptions);
			const newSelectedFilters = state.selectedFilters.filter(f => f.dataAccr !== filter.dataAccr);
			newSelectedFilters.forEach(f => {
				const [filterType, filterCat] = f.dataAccr.split('.');
				if (!NEW_FILTERS[filterType][filterCat]) {
					NEW_FILTERS[filterType][filterCat] = { ...f, resultCount: 0 };
					NEW_FILTERS[filterType].activeCount += 1;
				}
			});

			return {
				...state,
				selectedFilters: newSelectedFilters,
				selectedFiltersCount: newSelectedFilters.length,
				userFilters: NEW_FILTERS,
				filteredResults
			};
		}

		case t.SET_TEMP_BUSINESS_FILTERS: {
			const filters = action.payload;
			if (filters) {
				return {
					...state,
					// this might be a bug, use passed filtrs to determin selected count
					tempSelectedFilters: state.selectedFiltersCount,
					tempFilters: filters
				};
			} else {
				return {
					...state,
					tempSelectedFilters: state.selectedFiltersCount,
					tempFilters: state.userFilters
				};
			}
		}

		case t.REVERT_TO_TEMP_BUSINESS_FILTERS: {
			let filteredResults = filterRestaurants(state.tempFilters, state.results, state.recOptions);

			return {
				...state,
				filteredResults,
				selectedFiltersCount: state.tempSelectedFilters,
				userFilters: state.tempFilters
			};
		}

		case uiT.TOGGLE_SHOW_FILTER: {
			let show = action.payload;
			if (show) {
				return {
					...state,
					tempSelectedFilters: state.selectedFiltersCount,
					tempFilters: state.userFilters
				};
			} else {
				return state;
			}
		}

		case t.SET_BUSINESS_SEARCH_FIND: {
			let { find } = action.payload;
			if (find === 'undefined') {
				find = '';
			}

			return {
				...state,
				findSearch: decodeURIComponent(find)
			};
		}

		case t.SET_BUSINESS_SEARCH_NEAR: {
			let { near } = action.payload;

			return {
				...state,
				nearSearch: decodeURIComponent(near)
			};
		}

		case t.SAVE_SEARCH_PAGE_STATE: {
			return {
				...state,
				rehydrateFromSaveState: true,
				hydrateState: {
					...state
				}
			};
		}

		case t.HYDRATE_FROM_SEARCH_PAGE_SAVE_STATE: {
			return {
				...state.hydrateState,
				rehydrateFromSaveState: false,
				hydrateState: null
			};
		}

		default:
			return state;
	}
};
