import { connect } from 'react-redux';
import { hasProp } from 'utils/object';
import * as services from './services';
import Amenities from './ui-component';

const mapStateToProps = state => {
	let detailedAmenities = [];
	let briefAmenities = [];

	if (hasProp(state, 'details.restaurant.amenities')) {
		let detailed = services.mapDetailedAmenitiesFromState(state);
		let brief = services.mapBriefAmenitiesFromState(state);

		detailedAmenities = services.compileDetailedAmenities(detailed);
		briefAmenities = services.compileBriefAmenities(brief);
	}

	return {
		detailedAmenities,
		briefAmenities
	};
};

export default connect(mapStateToProps)(Amenities);
