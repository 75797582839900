import * as t from '../actions/details/types';
import { capitalize, has, map } from 'lodash';
import { getPhoneOrFax, formatPhoneNumber } from 'utils/regex';
import { STORE } from 'components/environment/App/redux_store.js';
import { hasProp } from 'utils/object';

/**
 * Redux_RestaurantDetails
 * @interface Redux_RestaurantDetails
 * @property {object}   [restaurant=null]
 * @property {array}    [actions=[]]
 * @property {array}    [dietaryPrefs=[]]
 * @property {array}    [amenities=[]]
 * @property {array}    [contactInfo=[]]
 * @property {string}   [keywords=null]
 * @property {string}   [description=null]
 * @property {string}   [cuisineAndMS=null]
 * @property {string}   [title=null]
 * @property {string}   [bannerImg=null]
 * @property {string}   [logoImg=null]
 * @property {object}   [location=null]
 * @property {string[]} [addresses=[]]
 * @property {string}   [errMsg=null]
 */
const mapDefaultState = {
	restaurant: null,
	actions: [],
	dietaryPrefs: [],
	amenities: [],
	contactInfo: [],
	keywords: null,
	description: null,
	cuisineAndMS: null,
	title: null,
	bannerImg: null,
	logoImg: null,
	location: null,
	addresses: [],
	errMsg: null,
	recommendations: []
};

const findRec = (recommendationOptions, recId) => {
	let rec = false;
	Object.keys(recommendationOptions).forEach(key => {
		if (hasProp(recommendationOptions[key], recId + '')) {
			rec = recommendationOptions[key][recId];
		}
	});

	return rec;
};

export default (state = mapDefaultState, action) => {
	switch (action.type) {
		case t.SUBMIT_RECOMMENDATION_SUCCESS: {
			if (window.location.pathname.indexOf('restaurants') > -1) {
				let recommendationType = action.payload.recommendations.recommended ? 'positive' : 'negative';
				if (recommendationType === 'positive') {
					let globalState = STORE.getState();
					let recommendationOptions = globalState.recommendationsModal[recommendationType];
					let newRecs = action.payload.recommendations.for.reduce((accum, recId) => {
						let newRec = findRec(recommendationOptions, recId);
						return [...accum, newRec];
					}, []);
					let newCats = { ...state.recommendations.categories };

					newRecs.forEach(rec => {
						let found = false;
						Object.keys(newCats).forEach(key => {
							if (parseInt(rec.id) === parseInt(key)) {
								found = true;
								newCats[key] = {
									...newCats[key],
									total: newCats[key].total + 1
								};
							}
						});
						if (!found) {
							const key = rec.id;
							newCats[key] = {
								name: rec.name,
								total: 1
							};
						}
					});

					let newState = {
						...state,
						recommendations: {
							total: state.recommendations.total + 1,
							categories: newCats
						}
					};

					return newState;
				}
			}
			return state;
		}
		case t.LOAD_RESTAURANT: {
			if (action.status === 'success') {
				const IMAGE_BASE_URL = action.response.imageBaseUrl;
				const translate = action.response.translate;
				const restaurant = action.response.restaurant;

				// <!-- build actions
				let actions = [],
					isTesting = false; // DEBUG set it to true to render all actions

				// website
				if (has(restaurant, 'contacts.general.url') || isTesting) {
					let url = isTesting ? '#' : restaurant.contacts.general.url;
					actions.push({
						icon: `${IMAGE_BASE_URL}/icons/icon-website.svg`,
						url,
						title: translate('Details.actions.website'),
						titleShort: translate('Details.actionsShort.website')
					});
				}
				// main phone
				if (has(restaurant, 'contacts.general.numbers.main') || isTesting) {
					const phoneTitle = restaurant.contacts.general.numbers.main;
					let phoneNumber = getPhoneOrFax(phoneTitle),
						url = isTesting ? '#' : `tel:${formatPhoneNumber(phoneNumber)}`;

					actions.push({
						icon: `${IMAGE_BASE_URL}/icons/icon-call.svg`,
						url,
						title: translate('Details.actions.call') + ` (${phoneTitle})`,
						titleShort: translate('Details.actionsShort.call')
					});
				}
				// reservations
				if (has(restaurant, 'amenities.reservations.url') || isTesting) {
					let url = isTesting ? '#' : restaurant.amenities.reservations.url;
					actions.push({
						icon: `${IMAGE_BASE_URL}/icons/icon-detail-reservations.svg`,
						url,
						title: translate('Details.actions.reservations'),
						titleShort: translate('Details.actionsShort.reservations')
					});
				}
				// catering
				if (has(restaurant, 'amenities.catering.url') || isTesting) {
					let url = isTesting ? '#' : restaurant.amenities.catering.url;
					actions.push({
						icon: `${IMAGE_BASE_URL}/icons/icon-catering.svg`,
						url,
						title: translate('Details.actions.catering'),
						titleShort: translate('Details.actionsShort.catering')
					});
				}
				// order
				if (has(restaurant, 'amenities.onlineOrdering.url') || isTesting) {
					let url = isTesting ? '#' : restaurant.amenities.onlineOrdering.url;
					actions.push({
						icon: `${IMAGE_BASE_URL}/icons/icon-detail-online-ordering.svg`,
						url,
						title: translate('Details.actions.ordering'),
						titleShort: translate('Details.actionsShort.ordering')
					});
				}
				// -->

				// <!-- Dietary Preferences
				let dietaryPrefs = [];

				if (has(restaurant, 'dietaryPreferences')) {
					dietaryPrefs = map(restaurant.dietaryPreferences, pref => pref.name);
				}
				// -->

				// <!-- Amenities
				let amenities = [];

				// reservations
				if (has(restaurant, 'amenities.reservations')) {
					amenities.push(translate('Details.amenities.reservations'));
				}
				// takeout
				if (has(restaurant, 'amenities.takeout')) {
					amenities.push(translate('Details.amenities.takeout'));
				}
				// delivery
				if (has(restaurant, 'amenities.delivery')) {
					amenities.push(translate('Details.amenities.delivery'));
				}
				// private dining
				if (has(restaurant, 'amenities.privateDining')) {
					amenities.push(translate('Details.amenities.privateDining'));
				}
				// catering
				if (has(restaurant, 'amenities.catering')) {
					amenities.push(translate('Details.amenities.catering'));
				}
				// alcohol
				if (has(restaurant, 'amenities.entertainment.alcohol')) {
					amenities.push(restaurant.amenities.entertainment.alcohol[0].name);
				}
				// seating
				if (has(restaurant, 'amenities.entertainment.seating')) {
					amenities.push(restaurant.amenities.entertainment.seating[0].name);
				}
				// -->

				// <!-- Contact Information
				let contactInfo = [];

				// general numbers
				if (has(restaurant, 'contacts.general.numbers')) {
					let numbers = map(restaurant.contacts.general.numbers, (title, key) => {
						let number = getPhoneOrFax(title);

						return {
							label: capitalize(key) + ':',
							title,
							url: key === 'fax' ? `fax:${number}` : `tel:${number}`
						};
					});

					contactInfo.push(...numbers);
				}
				// email
				if (has(restaurant, 'contacts.general.email')) {
					contactInfo.push({
						label: translate('Details.email'),
						title: restaurant.contacts.general.email,
						url: `mailto:${restaurant.contacts.general.email}`
					});
				}
				// reservations phone
				if (has(restaurant, 'amenities.reservations.numbers.main')) {
					const phoneTitle = restaurant.amenities.reservations.numbers.main;
					let phoneNumber = getPhoneOrFax(phoneTitle);

					contactInfo.push({
						label: translate('Details.reservationsPhone'),
						title: phoneTitle,
						url: `tel:${phoneNumber}`
					});
				}
				// reservations email
				if (has(restaurant, 'amenities.reservations.email')) {
					contactInfo.push({
						label: translate('Details.reservationsEmail'),
						title: restaurant.amenities.reservations.email,
						url: `mailto:${restaurant.amenities.reservations.email}`
					});
				}
				// ordering phone
				if (has(restaurant, 'amenities.onlineOrdering.numbers.main')) {
					const phoneTitle = restaurant.amenities.onlineOrdering.numbers.main;
					let phoneNumber = getPhoneOrFax(phoneTitle);

					contactInfo.push({
						label: translate('Details.orderingPhone'),
						title: phoneTitle,
						url: `tel:${phoneNumber}`
					});
				}
				// ordering email
				if (has(restaurant, 'amenities.onlineOrdering.email')) {
					contactInfo.push({
						label: translate('Details.orderingEmail'),
						title: restaurant.amenities.onlineOrdering.email,
						url: `mailto:${restaurant.amenities.onlineOrdering.email}`
					});
				}
				// takeout phone
				if (has(restaurant, 'amenities.takeout.numbers.main')) {
					const phoneTitle = restaurant.amenities.takeout.numbers.main;
					let phoneNumber = getPhoneOrFax(phoneTitle);

					contactInfo.push({
						label: translate('Details.takeoutPhone'),
						title: phoneTitle,
						url: `tel:${phoneNumber}`
					});
				}
				// takeout email
				if (has(restaurant, 'amenities.takeout.email')) {
					contactInfo.push({
						label: translate('Details.takeoutEmail'),
						title: restaurant.amenities.takeout.email,
						url: `mailto:${restaurant.amenities.takeout.email}`
					});
				}
				// delivery phone
				if (has(restaurant, 'amenities.delivery.numbers.main')) {
					const phoneTitle = restaurant.amenities.delivery.numbers.main;
					let phoneNumber = getPhoneOrFax(phoneTitle);

					contactInfo.push({
						label: translate('Details.deliveryPhone'),
						title: phoneTitle,
						url: `tel:${phoneNumber}`
					});
				}
				// delivery email
				if (has(restaurant, 'amenities.delivery.email')) {
					contactInfo.push({
						label: translate('Details.deliveryEmail'),
						title: restaurant.amenities.delivery.email,
						url: `mailto:${restaurant.amenities.delivery.email}`
					});
				}
				// catering phone
				if (has(restaurant, 'amenities.catering.numbers.main')) {
					const phoneTitle = restaurant.amenities.catering.numbers.main;
					let phoneNumber = getPhoneOrFax(phoneTitle);

					contactInfo.push({
						label: translate('Details.cateringPhone'),
						title: phoneTitle,
						url: `tel:${phoneNumber}`
					});
				}
				// catering email
				if (has(restaurant, 'amenities.catering.email')) {
					contactInfo.push({
						label: translate('Details.cateringEmail'),
						title: restaurant.amenities.catering.email,
						url: `mailto:${restaurant.amenities.catering.email}`
					});
				}
				// -->

				// <!-- keywords
				let keywords = null;

				if (has(restaurant, 'keywords')) {
					keywords = restaurant.keywords;
				}
				// -->

				// <!-- description, primary cuisine and MS
				let description = null,
					cuisineAndMSArray = [],
					cuisineAndMS = null;

				// description
				if (has(restaurant, 'description')) {
					description = restaurant.description;
				}

				// cuisine
				if (has(restaurant, 'cuisine.name')) {
					cuisineAndMSArray.push(restaurant.cuisine.name);
				}
				// menu specialties
				if (has(restaurant, 'menuSpecialties')) {
					let menuSpecialties = map(restaurant.menuSpecialties, item => item.name);
					cuisineAndMSArray = cuisineAndMSArray.concat(menuSpecialties);
				}

				// join everything
				if (cuisineAndMSArray.length > 0) {
					cuisineAndMS = cuisineAndMSArray.join(', ');
				}
				// -->

				// <!-- header
				let title = null,
					bannerImg = null,
					logoImg = null;

				// title
				if (has(restaurant, 'name')) {
					title = restaurant.name;
				}
				// banner image
				if (has(restaurant, 'images.main')) {
					bannerImg = restaurant.images.main;
				}
				// logo image
				if (has(restaurant, 'images.logo')) {
					logoImg = restaurant.images.logo;
				}
				// -->

				// <!-- map
				let location = null;

				if (has(restaurant, 'location')) {
					location = restaurant.location;
				}

				// addresses, city, state, postal code
				let addresses = [];

				// addresses
				if (has(restaurant, 'address.street')) {
					addresses = map(restaurant.address.street, street => street);
				}

				// last line city, state and postal code
				let lastLine = [];

				if (has(restaurant, 'address.city')) {
					lastLine.push(restaurant.address.city);
				}
				// state
				if (has(restaurant, 'address.state.abbreviation')) {
					lastLine.push(restaurant.address.state.abbreviation);
				}

				lastLine = [lastLine.join(', ')]; // use special separator to join city and state

				if (has(restaurant, 'address.postalCode')) {
					lastLine.push(restaurant.address.postalCode);
				}

				// join everything in last line and push it to addresses
				addresses.push(lastLine.join(' '));
				// -->

				return {
					...state,
					restaurant,
					actions,
					dietaryPrefs,
					amenities,
					contactInfo,
					keywords,
					description,
					cuisineAndMS,
					title,
					bannerImg,
					logoImg,
					location,
					addresses,
					// TEST DATA
					recommendations: restaurant.recommendations.positive,
					// END
					errMsg: null
				};
			} else {
				return {
					...state,
					...mapDefaultState,
					errMsg: action.error
				};
			}
		}
		default:
			return state;
	}
};
