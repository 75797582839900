import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DropdownToggle, DropdownMenu, DropdownItem, InputGroupButtonDropdown, Button } from 'reactstrap';
import { find, map } from 'lodash';

class Select extends Component {
	state = {
		dropdownOpen: false
	};

	render() {
		const { className, isSV, serviceType, items, onSelect } = this.props;
		const { dropdownOpen } = this.state;
		const classProps = classNames('select', isSV ? 'flat' : 'dropdown', className);

		const selected = find(items, ['key', serviceType]);

		let html = null;
		if (isSV) {
			html = (
				<div className={classProps}>
					{map(items, (item, index) => (
						<Button
							className={classNames('item', selected.key === item.key && 'active')}
							key={index}
							onClick={() => onSelect(item.key)}
							color="link"
						>
							{item.value}
						</Button>
					))}
				</div>
			);
		} else {
			html = (
				<InputGroupButtonDropdown
					addonType="append"
					isOpen={dropdownOpen}
					className={classProps}
					toggle={this.toggleDropdown}
				>
					<DropdownToggle caret>
						<span className="title">{selected.value}</span>
						<div className="caret-wrapper">
							<i className="caret" />
						</div>
					</DropdownToggle>
					<DropdownMenu>
						{map(items, (item, index) => (
							<DropdownItem
								className={selected.key === item.key ? 'active' : ''}
								key={index}
								onClick={() => {
									onSelect(item.key);
								}}
							>
								{item.value}
							</DropdownItem>
						))}
					</DropdownMenu>
				</InputGroupButtonDropdown>
			);
		}

		return html;
	}

	toggleDropdown = () => {
		this.setState({ dropdownOpen: !this.state.dropdownOpen });
	};
}

Select.defaultProps = {
	className: ''
};

Select.propTypes = {
	className: PropTypes.string,
	translate: PropTypes.func,
	isSV: PropTypes.bool,
	setServiceType: PropTypes.func,
	serviceType: PropTypes.string,
	items: PropTypes.arrayOf(PropTypes.object).isRequired,
	onSelect: PropTypes.func.isRequired
};

export default Select;
