import React from 'react';
import PropTypes from 'prop-types';

class FormFieldRequirements extends React.Component {
	title = () => {
		if (this.props.formFieldText) {
			return this.props.formFieldText.title;
		}
	};

	requirementList = () => {
		if (this.props.formFieldText) {
			return this.props.formFieldText.requirements.map((requirement, index) => (
				<li className={requirement.status} key={index}>
					<i className="fas fa-check" />
					{requirement.text}
				</li>
			));
		}
	};

	render() {
		return (
			<div className="form-field-requirements di_TintGrey mt-2">
				<h4>{this.title()}</h4>
				<ul className="requirement-list">{this.requirementList()}</ul>
			</div>
		);
	}
}

FormFieldRequirements.propTypes = {
	formFieldText: PropTypes.object.isRequired
};

export default FormFieldRequirements;
