/* eslint-disable no-case-declarations */
import * as t from '../actions/catering/types';

/**
 * Redux_CateringState
 * @interface Redux_CateringState
 * @property {Array}  [card=[]]
 * @property {String} [serviceType='DELIVERY']
 * @property {Object} [address={}]
 * @property {Date}   [date=new Date()]
 */

const cateringDefaultState = {
	cart: [],
	serviceType: 'delivery',
	address: {},
	date: new Date(new Date(new Date().setDate(new Date().getDate() + 1)).setHours(12, 0, 0, 0)),
	loaded: false,
	display: false,
	zones: [],
	showCateringZoneUnavailable: false,
	integration: ''
};

export default (state = cateringDefaultState, action) => {
	switch (action.type) {
		case t.SET_SHOW_NO_SERVICE: {
			return {
				...state,
				showCateringZoneUnavailable: action.payload
			};
		}
		case t.SET_CART:
			return {
				...state,
				cart: action.payload
			};
		case t.SET_SERVICE_TYPE:
			return {
				...state,
				serviceType: action.payload
			};
		case t.SET_ADDRESS:
			return {
				...state,
				address: {
					...action.payload
				}
			};
		case t.SET_DATE:
			return {
				...state,
				date: action.payload
			};
		case t.SET_SCRIPT_LOADED:
			return {
				...state,
				loaded: true
			};
		case t.SET_CATERING_DISPLAY:
			return {
				...state,
				display: action.payload
			};
		case t.FETCH_ZONE_ERROR:
			return {
				...state,
				zones: []
			};
		case t.FETCH_ZONE_SUCCESS: {
			return {
				...state,
				zones: action.payload
			};
		}
		default:
			return state;
	}
};
