import BusinessSearchFilter from './ui-component';
import { connect } from 'react-redux';
import withSizes from 'react-sizes';
import { isSV } from 'utils/sizes';
import {
	initBusinessFilters,
	addBusinessFilter,
	removeBusinessFilter,
	revertToTempBusinessFilter
} from 'actions/new_business_search_filter';
import { toggleShowFilter } from 'actions/search_page';
import { hasProp } from 'utils/object';

const mapStateToFilters = state => ({
	filters: state.businessSearchFilter.userFilters,
	filteredResults: state.businessSearchFilter.filteredResults,
	hasFilters:
		hasProp(state.businessSearchFilter.userFilters, 'amenities') ||
		hasProp(state.businessSearchFilter.userFilters, 'cuisine') ||
		hasProp(state.businessSearchFilter.userFilters, 'dietaryPreferences')
});

const mapDispatchToProps = dispatch => ({
	addFilter: async filter => await addBusinessFilter(dispatch, filter),
	initBusinessFilters: initialState => dispatch(initBusinessFilters(initialState)),
	removeFilter: async filter => await removeBusinessFilter(dispatch, filter),
	revertToTempBusinessFilter: () => dispatch(revertToTempBusinessFilter()),
	toggleShowFilter: show => dispatch(toggleShowFilter(show))
});

const mapSizesToProps = sizes => ({
	isSV: isSV(sizes)
});

export default withSizes(mapSizesToProps)(
	connect(
		mapStateToFilters,
		mapDispatchToProps
	)(BusinessSearchFilter)
);
