import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Title } from 'components/atoms';
import { ReferSharing } from 'components/organisms';
import { hasProp } from 'utils/object';

/**
 * Displays the logged in users referral link if one is available. This component is styled specifically for the HelpFAQ page.
 * @extends Component
 * @param {FAQReferACoworkerProps} props
 */
class FAQReferACoworker extends Component {
	constructor(props) {
		super(props);

		this.state = {
			referral: false
		};
	}

	componentDidMount = async () => {
		const showLoading = false;
		const profile = await this.props.getProfile(showLoading);
		const referral = hasProp(profile, 'payload.urls.referral') ? profile.payload.urls.referral : false;

		this.setState({ referral });
	};

	render() {
		const { className, translate } = this.props;
		const { referral } = this.state;
		const classProps = classnames('faq-referral', className);

		return referral ? (
			<div className={classProps}>
				<Title transform="none">{translate('Profile.referral.title')}</Title>
				<ReferSharing showLearnMore={true} link={referral} showSocial={false} />
			</div>
		) : null;
	}
}

FAQReferACoworker.defaultProps = {
	className: null,
	getProfile: () => {}
};

/**
 * FAQReferACoworker Props
 * @interface FAQReferACoworkerProps
 * @property {string} className
 * @property {func} getProfile Fetch the users profile info and update redux store.
 * @property {func} translate
 */
FAQReferACoworker.propTypes = {
	className: PropTypes.string,
	getProfile: PropTypes.func,
	translate: PropTypes.func.isRequired
};

export default FAQReferACoworker;
