import Recommendations from './ui-component';
import { connect } from 'react-redux';
import withSizes from 'react-sizes';
import { isSV /*, isMV, isLV*/ } from 'utils/sizes';
import { getTranslate } from 'react-localize-redux';

import { hasProp } from 'utils/object';

const mapStateToProps = (state, ownProps) => {
	let didRecommend = false;
	const count = hasProp(state, 'details.recommendations.total') ? state.details.recommendations.total : 0;

	if (window.location.pathname.indexOf('history') > -1) {
		didRecommend = state.userRecommendations.restaurantIdsWithRecs.indexOf(ownProps.restaurantId) > -1;
	} else {
		didRecommend = hasProp(state, 'details.restaurant.user.recommendation')
			? state.details.restaurant.user.recommendation.length > 0
			: false;
	}

	return {
		count,
		didRecommend,
		translate: getTranslate(state.locale)
	};
};

const mapSizesToProps = sizes => ({
	isSV: isSV(sizes)
});

export default withSizes(mapSizesToProps)(connect(mapStateToProps)(Recommendations));
