import CateringSearchTab from './ui-component';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import withSizes from 'react-sizes';
import { setBusinessSearch } from 'actions/home';
import { isLoading } from 'actions/general';
import { placeAutocomplete } from 'actions/map';
import { isLV, isSV, isMV } from 'utils/sizes';
import { getProfile } from 'actions/users';
import { setAddress, setDate, setServiceType, getZones, setShowNoServiceNotice } from 'actions/catering';
import { setLocationAutocompleteSearch } from 'actions/location_autocomplete';
import { getLocation } from 'actions/geo_location';

const mapStateToProps = state => ({
	location: {
		lat: state.geoLocation.lat,
		lng: state.geoLocation.lng
	},
	hasGeoLocation: state.geoLocation.hasGeoLocation,
	geoLocationLoading: state.geoLocation.isLoading,
	geoLocationDenied: state.geoLocation.wasDenied,
	translate: getTranslate(state.locale),
	businessSearch: state.home.businessSearch,
	users: state.users,
	catering: state.catering,
	config: state.config,
	home: state.home,
	imageBaseUrl: state.config.imageBaseUrl,
	showCateringZoneUnavailable: state.catering.showCateringZoneUnavailable
});

const mapDispatchToProps = dispatch => ({
	setBusinessSearch: value => dispatch(setBusinessSearch(value)),
	placeAutocomplete: value => dispatch(placeAutocomplete(value)),
	getProfile: showLoading => dispatch(getProfile(showLoading)),
	setLocationAutocompleteSearch: value => dispatch(setLocationAutocompleteSearch(value)),
	setServiceType: serviceType => dispatch(setServiceType(serviceType)),
	setAddress: address => dispatch(setAddress(address)),
	setDate: date => dispatch(setDate(date)),
	isLoading: (loading, message) => dispatch(isLoading(loading, message)),
	getLocation: async () => await getLocation(dispatch),
	getZones: location => getZones(location, dispatch),
	setShowNoServiceNotice: shouldShow => dispatch(setShowNoServiceNotice(shouldShow))
});

const mapSizesToProps = sizes => ({
	isLV: isLV(sizes),
	isSV: isSV(sizes),
	isMV: isMV(sizes)
});

export default withSizes(mapSizesToProps)(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(CateringSearchTab)
);
