import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';
import { setActiveFilterTab } from 'actions/map';
import { hasProp } from 'utils/object';
import { FilterTypes, FilterTabContent } from 'components/organisms';

export const FILTER_TYPES = [
	{ key: 'cuisineTab', name: 'cuisine' },
	{ key: 'dietaryTab', name: 'dietary' },
	{ key: 'amenityTab', name: 'amenity' }
];

class FilterTabs extends Component {
	static defaultProps = {
		className: ''
	};

	static propTypes = {
		className: PropTypes.string,
		location: PropTypes.object,
		history: PropTypes.object,
		activeTab: PropTypes.string.isRequired,
		setActiveFilterTab: PropTypes.func.isRequired,
		cuisineItems: PropTypes.arrayOf(PropTypes.object),
		dietaryItems: PropTypes.arrayOf(PropTypes.object),
		amenityItems: PropTypes.arrayOf(PropTypes.object),
		tmpCuisineItems: PropTypes.arrayOf(PropTypes.object),
		tmpDietaryItems: PropTypes.arrayOf(PropTypes.object),
		tmpAmenityItems: PropTypes.arrayOf(PropTypes.object),
		onFilter: PropTypes.func.isRequired,
		parentCallback: PropTypes.func.isRequired,
		onCopyFilters: PropTypes.func.isRequired
	};

	componentDidMount = () => {
		let tab = qs.parse(this.props.location.search).tab;
		if (tab) {
			if (tab === 'dine') {
				tab = 'cuisine';
			}
			this.props.setActiveFilterTab(`${tab}Tab`);
		}
	};
	render() {
		const {
			activeTab,
			className,
			cuisineItems,
			dietaryItems,
			amenityItems,
			onFilter,
			parentCallback,
			tmpCuisineItems,
			tmpDietaryItems,
			tmpAmenityItems,
			onCopyFilters
		} = this.props;
		const classProps = classNames('filter-tabs', className, activeTab);

		return (
			<div className={classProps}>
				<FilterTypes activeTab={activeTab} onSelectTab={this.onSelectTab} />
				<FilterTabContent
					activeTab={activeTab}
					history={this.props.history}
					cuisineItems={cuisineItems}
					dietaryItems={dietaryItems}
					amenityItems={amenityItems}
					tmpCuisineItems={tmpCuisineItems}
					tmpDietaryItems={tmpDietaryItems}
					tmpAmenityItems={tmpAmenityItems}
					onFilter={onFilter}
					parentCallback={parentCallback}
					onCopyFilters={onCopyFilters}
				/>
			</div>
		);
	}

	onSelectTab = tab => {
		if (this.props.activeTab !== tab) {
			this.props.setActiveFilterTab(tab);
		}
	};
}

const mapStateToProps = state => ({
	activeTab: hasProp(state, 'map.activeFilterTab') ? state.map.activeFilterTab : ''
});

const mapDispatchToProps = dispatch => ({
	setActiveFilterTab: tab => dispatch(setActiveFilterTab(tab))
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(FilterTabs)
);
