import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Asterisk } from 'components/atoms';

const RequiredFieldNote = ({ className, translate }) => {
	const classProps = classNames('required-field-note', className);

	return (
		<div className={classProps}>
			<Asterisk />
			{translate('required.required')}
		</div>
	);
};

RequiredFieldNote.defaultProps = {
	className: ''
};

RequiredFieldNote.propTypes = {
	className: PropTypes.string,
	translate: PropTypes.func.isRequired
};

export default RequiredFieldNote;
