import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { has } from 'lodash';
import { HamburgerIcon, NavItem } from 'components/atoms';
import { isNavItemActive } from 'utils/router';

/* Mobile Menu for SM + MD Viewports - Main Menu - Tested */
class MobileNavMenu extends Component {
	constructor(props) {
		super(props);

		this.state = {
			menu: undefined,
			didFadeIn: false
		};

		this.mobileMenuRef = React.createRef();
		this.outsideClickListner = null;
		this.animateStartListener = null;
		this.animateEndListener = null;
	}

	componentDidMount() {
		this.initClickListener();
		this.initAnimateListeners();
	}

	componentWillUnmount() {
		this.destroyListeners();
	}

	destroyListeners = () => {
		window.removeEventListener('click', this.outsideClickListner, false);
		window.removeEventListener('animationstart', this.animateStartListener, false);
		window.removeEventListener('animationend', this.animateEndListener, false);
	};

	initClickListener = () => {
		this.outsideClickListner = window.addEventListener('click', this.handleOutsideClick, false);
	};

	initAnimateListeners = () => {
		this.initAnimateStartListener();
		this.initAnimateEndListener();
	};

	initAnimateStartListener = () => {
		const me = this;
		this.animateStartListener = window.addEventListener(
			'animationstart',
			e => {
				// If menu is animating down, set didFadeIn: true.
				if (e.animationName === 'fadeInDown') {
					me.setState({ didFadeIn: true });
				}
			},
			false
		);
	};

	initAnimateEndListener = () => {
		const me = this;
		this.animateEndListener = window.addEventListener(
			'animationend',
			e => {
				if (e.animationName === 'fadeOutUp') {
					// If menu is animating up, set didFadeIn: false.
					me.setState({ didFadeIn: false });
				}
			},
			false
		);
	};

	/*
		Apply the selected menu, for now this is either "Mobile_Main" or undefined.
	*/
	setMenu = type => {
		this.setState({ menu: type });
	};

	/*
		Triggers when user clicks on hamburger or close icon.
		Used to toggle menu type and show state.
	*/
	handleHamburgerClick = (e, type) => {
		e.preventDefault();
		this.setMenu(type);
		this.props.handleClick();
	};

	/*
  	Use in window click listener.  Determines if user has clicked outside of
		the menu, and if so, closes the menu.
	*/
	handleOutsideClick = e => {
		if (this.state.menu === 'Mobile_Main' && this.state.didFadeIn) {
			this.handleHamburgerClick(e, undefined);
		}
	};

	/*
		Loops over an array of menu item configs and returns a <ul> of menu items.
		TODO: this funciton could be a utlity.
	*/
	buildNav = (items, level = 0) => {
		const { printIt } = this.props;
		const links = items.map((item, index) => {
			let isActive = isNavItemActive(item, true),
				print = printIt(item);

			return (
				<NavItem hidden={!print} item={item} isActive={isActive} key={index} disabled={item.disabled}>
					{has(item, 'items') && item.items.length > 0 && this.buildNav(item.items, level + 1)}
				</NavItem>
			);
		});

		return (
			<ul className="nav-list" style={{ paddingLeft: 20 * level }}>
				{links}
			</ul>
		);
	};

	render() {
		const { config, menuItems, show } = this.props;
		const className = classNames(
			'Di_MainPopupNav animated',
			this.state.menu === 'Mobile_Main' && 'fadeInDown',
			this.state.menu !== 'Mobile_Main' && this.state.didFadeIn && 'fadeOutUp',
			!show && 'hidden'
		);

		return [
			<HamburgerIcon handleClick={this.handleHamburgerClick} key={0} className={!show ? 'hidden' : ''} />,
			<div className={className} ref={c => (this.mobileMenuRef = c)} key={1}>
				<img
					className="close-icon"
					src={`${config.cdn_basePath}/icons/icon-close-black.svg`}
					onClick={e => this.handleHamburgerClick(e, undefined)}
				/>
				{this.buildNav(menuItems)}
			</div>
		];
	}
}

MobileNavMenu.defaultProps = {
	menuItems: [],
	show: true
};

MobileNavMenu.propTypes = {
	// Required
	config: PropTypes.object.isRequired,
	handleClick: PropTypes.func.isRequired,
	location: PropTypes.object.isRequired,
	printIt: PropTypes.func.isRequired,
	// Optional
	menuItems: PropTypes.array,
	show: PropTypes.bool
};

const mapStateToProps = state => ({
	config: state.config
});

export default withRouter(connect(mapStateToProps)(MobileNavMenu));
