import RecommendationsPageList from './ui-component';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

const mapStateToProps = state => ({
	translate: getTranslate(state.locale),
	isLoading: state.userRecommendations.isLoading
});

export default connect(mapStateToProps)(RecommendationsPageList);
