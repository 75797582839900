import SearchModify from './ui-component';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import withSizes from 'react-sizes';
import { isLV, isSV } from 'utils/sizes';

const mapSizesToProps = sizes => ({
	isLV: isLV(sizes),
	isSV: isSV(sizes)
});

const mapStateToProps = state => ({
	translate: getTranslate(state.locale)
});

export default withSizes(mapSizesToProps)(connect(mapStateToProps)(SearchModify));
