import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { SearchPageViewToggles } from 'components/molecules';
import BusinessSearchTab from 'components/molecules/BusinessSearchTab';

class SearchTopBar extends Component {
	state = {
		dropdownWidth: 925,
		dropdownLeft: 25
	};

	componentDidMount() {
		this.updateDropdownPosition();
		window.addEventListener('resize', this.updateDropdownPosition);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateDropdownPosition);
	}

	render() {
		const { className, hasGeoLocation, isSV, onSearch, translate, searchErrType } = this.props;
		const classProps = classNames('search-top-bar', className);

		return (
			<div className={classProps}>
				<div className="inner-wrapper" ref={el => (this.innerWrapper = el)}>
					<BusinessSearchTab
						onSearch={onSearch}
						nearBySearch={this.props.nearBySearch}
						isActive
						findPlaceholder={translate('SearchTopBar.findPlaceholder')}
						nearPlaceholder={
							hasGeoLocation
								? translate('SearchTopBar.nearPlaceholder')
								: translate('Home.cateringSearch.locationInput.label')
						}
					/>
					{searchErrType && <div className="search-error-message">{this.props.translate(searchErrType)}</div>}
					<div className="bottom-bar">
						{this.props.children}
						{!isSV && <SearchPageViewToggles />}
					</div>
				</div>
			</div>
		);
	}

	updateDropdownPosition = () => {
		if (this.innerWrapper) {
			this.setState({
				dropdownWidth: this.innerWrapper.offsetWidth,
				dropdownLeft: this.innerWrapper.offsetLeft
			});
		}
	};
}

SearchTopBar.defaultProps = {
	className: ''
};

SearchTopBar.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	hasGeoLocation: PropTypes.bool.isRequired,
	nearBySearch: PropTypes.func.isRequired,
	translate: PropTypes.func,
	isSV: PropTypes.bool,
	searchErrType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	onSearch: PropTypes.func.isRequired
};

export default SearchTopBar;
