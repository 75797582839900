import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * List item to render inside of {@link OptionsList}
 * @param {OptionItemProps} props
 */
const OptionItem = ({ className, option }) => {
	const classProps = classNames('option-item', className);

	return <li className={classProps}>{option}</li>;
};

OptionItem.defaultProps = {
	className: '',
	option: ''
};

/**
 * {@link OptionItem} Props
 * @interface PropsOptionItem
 * @property {string} [className=""] Additional classNames to add to the list item.
 * @property {string} [option=""] The text to display as the option.
 */
OptionItem.propTypes = {
	className: PropTypes.string,
	option: PropTypes.string
};

export default OptionItem;
