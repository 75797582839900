import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Text, Title } from 'components/atoms';
import { WithHelp } from 'components/molecules';

const ProfileSectionItem = props => {
	const { className, item, section, translate } = props;
	const classProps = classNames(
		'profile-section-item',
		!item.value && !item.isDesc && 'disabled',
		className,
		item.help && 'has-help'
	);
	if (item.isDesc) {
		return (
			<div className={classProps}>
				<Text size="xs">{translate(`Profile.${section}.description`)}</Text>
			</div>
		);
	}
	return (
		<div className={classProps}>
			{item.help && <WithHelp id={`profile-${section}-help`} help={item.help} />}
			<Title inline size="h3" align="left">
				{translate(`Profile.${section}.labels.${item.key}`)}
				{item.required && <span className="required">*</span>}:
			</Title>
			<span id={`${item.key}-field`} className="item-value">
				{item.value}
			</span>
		</div>
	);
};

ProfileSectionItem.defaultProps = {
	className: ''
};

ProfileSectionItem.propTypes = {
	className: PropTypes.string,
	item: PropTypes.object.isRequired,
	section: PropTypes.oneOf(['personal', 'payment', 'preferences', 'security']).isRequired,
	translate: PropTypes.func.isRequired
};

export default ProfileSectionItem;
