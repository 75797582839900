import Bowser from 'bowser';

const browser = Bowser.getParser(window.navigator.userAgent).getBrowser();

export const isIE = () => {
	return browser.name.indexOf('Internet Explorer') !== -1;
};

export const isEdge = () => {
	return browser.name.indexOf('Microsoft Edge') !== -1;
};

export const isFireFox = () => {
	return browser.name.indexOf('Firefox') !== -1;
};
