/* eslint-disable no-case-declarations */
import * as t from '../actions/business_search_map/types';

/**
 * Redux_RewardState
 * @interface Redux_RewardState
 * @property {array}  [availableRewards=[]]
 * @property {object} [selectedReward={}]
 * @property {object} [redeemedReward=undefined]
 */
const businessSearchMapDefaultState = {
	areaSearchDisplayed: false,
	center: {
		lat: null,
		lng: null
	},
	centerPan: {
		lat: null,
		lng: null
	},
	centerToGeoLocation: false,
	centerToMapMarker: false,
	zoom: 10,
	rehydrateFromSaveState: false,
	hydrateState: null
};

export default (state = businessSearchMapDefaultState, action) => {
	switch (action.type) {
		case t.SET_BUSINESS_SEARCH_MAP_CENTER: {
			return {
				...state,
				center: action.payload
			};
		}

		case t.SET_BUSINESS_SEARCH_MAP_ZOOM: {
			return {
				...state,
				zoom: action.payload
			};
		}

		case t.SET_AREA_SEARCH_BUTTON_DISPLAYED: {
			return {
				...state,
				areaSearchDisplayed: action.payload
			};
		}

		case t.RECENTER_TO_GEOLOCATION: {
			return {
				...state,
				centerToGeoLocation: action.payload
			};
		}

		case t.SET_RECENTER_TO_MAP_MARKER: {
			return {
				...state,
				centerToMapMarker: action.payload
			};
		}

		case t.SET_CENTER_PAN: {
			return {
				...state,
				centerPan: action.payload
			};
		}

		case t.SAVE_SEARCH_PAGE_STATE: {
			return {
				...state,
				rehydrateFromSaveState: true,
				hydrateState: {
					...state
				}
			};
		}

		case t.HYDRATE_FROM_SEARCH_PAGE_SAVE_STATE: {
			return {
				...state.hydrateState,
				center: state.hydrateState.centerPan,
				rehydrateFromSaveState: false,
				hydrateState: null
			};
		}

		default:
			return state;
	}
};
