import React from 'react';
import PropTypes from 'prop-types';

class WithInput extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			hasFocus: false,
			showPassword: false
		};
	}

	onFocus = e => {
		this.setState({ hasFocus: true });
		if (this.props.onFocus) {
			this.props.onFocus(e);
		}
	};

	onBlur = e => {
		this.setState({ hasFocus: false });
		if (this.props.onBlur) {
			this.props.onBlur(e);
		}
	};

	togglePassword = () => {
		this.setState({ showPassword: !this.state.showPassword });
	};
}

WithInput.defaultProps = {};

WithInput.propTypes = {
	onBlur: PropTypes.func,
	onFocus: PropTypes.func,
	type: PropTypes.string
};

export default WithInput;
