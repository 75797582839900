import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { history } from 'utils/router';
import { MapCard } from 'components/molecules';
import { isSV } from 'utils/sizes';
import { buildAboutRestaurantURL } from 'utils/url';

class SimpleMapMarker extends Component {
	constructor(props) {
		super(props);
		this.icon = React.createRef();
		this.card = React.createRef();
		this.state = {
			isAcitve: false
		};
	}

	onMapCardClick = item => {
		this.props.saveSearchState();
		history.push(buildAboutRestaurantURL(item));
	};

	componentDidUpdate = (/*prevProps*/) => {
		if (!this.props.data) {
			return;
		}
		const { data } = this.props,
			isMarkerActive = data.activePin === data.id,
			isCardActive = isMarkerActive && data.mapCardDisplayed;
		// oldData = prevProps.data,
		// wasMarkerActive = oldData.activePin === oldData.id,
		// wasCardActive = wasMarkerActive && oldData.mapCardDisplayed;

		if (isCardActive) {
			this.positionMapCard();
			if (this.state.isActive !== true) {
				this.setState({ isActive: true });
			}
		} else {
			if (this.state.isActive !== false) {
				this.setState({ isActive: false });
			}
		}
	};

	positionMapCard = () => {
		if (this.state.isActive === true) {
			return;
		}
		// clear pos so we get accurate bounds data.
		this.card.style.left = '';
		this.card.style.top = '';
		// x = left to right
		// y = top to bottom
		let map = document.getElementById(this.props.mapId),
			mapBounds = map.getBoundingClientRect(),
			cardBounds = this.card.getBoundingClientRect(),
			fallsRight = cardBounds.x + cardBounds.width > mapBounds.x + mapBounds.width,
			fallsLeft = cardBounds.x - cardBounds.width < mapBounds.x,
			fallsTop = cardBounds.y - cardBounds.height < mapBounds.y,
			fallsBottom = cardBounds.y + cardBounds.height > mapBounds.y + mapBounds.height;

		let iconWidth = isSV ? 25 : 25;
		let iconHeight = isSV ? 35 : 35;
		let iconPad = 10;

		if (fallsLeft && fallsRight) {
			this.card.style.left = `-${(this.card.offsetWidth + iconWidth) / 2}px`; // + 25 to compensate for marker Icon image.
			if (!fallsTop && !fallsBottom) {
				this.card.style.top = `${iconHeight}px`;
			}
		} else if (fallsLeft) {
			this.card.style.left = ``; // + 25 to compensate for marker Icon image.
		} else if (fallsRight) {
			this.card.style.left = `-${this.card.offsetWidth + iconWidth}px`; // + 25 to compensate for marker Icon image.
		}

		if (fallsTop) {
			this.card.style.top = `${iconHeight}px`;
		}

		if (fallsBottom) {
			this.card.style.top = `${(this.card.offsetHeight + iconPad) * -1}px`;
		}
	};

	render() {
		const { data, IMAGE_BASE_URL, className } = this.props;
		if (!data) {
			return null;
		}
		const isMarkerActive = data.activePin === data.id;
		const isCardActive = isMarkerActive && data.mapCardDisplayed;
		const classProps = classNames(
			'simple-map-marker',
			isMarkerActive && 'active',
			isCardActive && 'card-active',
			className
		);

		const darylImgSrc = 'https://s3.amazonaws.com/profile_photos/846257478776138.MNmnB1CRfcJRufF05maJ_huge.jpeg',
			mapMarkerSrc = `${IMAGE_BASE_URL}/icons/icon-map-pin-${isMarkerActive ? '' : 'in'}active.svg`,
			imgSrc = this.props.isDarylSearch ? darylImgSrc : mapMarkerSrc,
			imgClassProps = this.props.isDarylSearch ? 'daryl marker' : 'marker';

		if (!data.name) {
			console.error('oops! the restaurant marker name is undefined', this.props);
		}

		return (
			<div className={classProps} data-cy={isMarkerActive ? 'simple-map-marker-active' : 'simple-map-marker'}>
				<img
					key={`marker-img-${data.id}`}
					ref={c => (this.icon = c)}
					className={imgClassProps}
					src={imgSrc}
					onClick={this.onMarkerClickHandler}
					data-marker-zone
				/>
				<MapCard
					isActive={isMarkerActive}
					key={`map-card-${data.id}`}
					refSetter={c => (this.card = c)}
					data={data}
					onTransition={this.onMapCardClick.bind(this, data)}
				/>
				<div key={`marker-name-${data.id}`} className="restaurant-name">
					{data.name}
				</div>
			</div>
		);
	}

	onMarkerClickHandler = event => {
		const { lat, lng, data, onMarkerClick, isRestaurant } = this.props;
		onMarkerClick({ event, childProps: { lat, lng, isRestaurant, ...data } });
	};
}

SimpleMapMarker.defaultProps = {
	className: '',
	isRestaurant: true
};

SimpleMapMarker.propTypes = {
	className: PropTypes.string,
	IMAGE_BASE_URL: PropTypes.string,
	data: PropTypes.object,
	isDarylSearch: PropTypes.bool,
	isSV: PropTypes.bool,
	onMarkerClick: PropTypes.func.isRequired,
	lat: PropTypes.number,
	lng: PropTypes.number,
	mapId: PropTypes.string.isRequired, // The DOM el ID of the map this marker belongs to.  Requird to determine where to position marker card in realtion to the marker icon.
	isRestaurant: PropTypes.bool,
	saveSearchState: PropTypes.func.isRequired
};

export default SimpleMapMarker;
