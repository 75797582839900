import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { TabContent, TabPane } from 'reactstrap';
import { SEARCH_TYPES } from 'components/molecules/SearchTabs';

const SearchTabContent = props => {
	const { className, activeTab } = props;
	const classProps = classNames('search-tab-content', className);

	return (
		<TabContent activeTab={activeTab} className={classProps}>
			{SEARCH_TYPES.map((searchType, index) => {
				const Tab = <searchType.component isActive={activeTab === searchType.key} />;

				return (
					<TabPane tabId={searchType.key} key={index}>
						{Tab}
					</TabPane>
				);
			})}
		</TabContent>
	);
};

SearchTabContent.defaultProps = {
	className: ''
};

SearchTabContent.propTypes = {
	className: PropTypes.string,
	activeTab: PropTypes.string.isRequired
};

export default SearchTabContent;
