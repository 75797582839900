import SearchDropdown from './ui-component';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { getZones, setAddress, setShowNoServiceNotice } from 'actions/catering';
import { setBusinessSearch, setSearchSuggestion } from 'actions/home';
import { getLocation } from 'actions/geo_location';
import { isLoading } from 'actions/general';
import { setBusinessSearchNear } from 'actions/new_business_search_filter';

const mapStateToProps = state => ({
	geoLocation: { lat: state.geoLocation.lat, lng: state.geoLocation.lng },
	geoLocationServicesDenied: state.geoLocation.wasDenied,
	hasAutoCompleteResults: state.map.foundPlaces.length > 0,
	findBusinessSearch: state.businessSearchFilter.findSearch,
	translate: getTranslate(state.locale)
});

const mapDispatchToProps = dispatch => ({
	getLocation: async () => await getLocation(dispatch),
	getZones: location => getZones(location, dispatch),
	isLoading: (loading, message) => dispatch(isLoading(loading, message)),
	setBusinessSearch: value => dispatch(setBusinessSearch(value)),
	setNearBusinessSearch: near => dispatch(setBusinessSearchNear(near)),
	setSearchSuggestion: value => dispatch(setSearchSuggestion(value)),
	setAddress: address => dispatch(setAddress(address)),
	setShowNoServiceNotice: shouldShow => dispatch(setShowNoServiceNotice(shouldShow))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SearchDropdown);
