/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

export default ChildComponent => {
	class ComposedComponent extends React.Component {
		constructor(props) {
			super(props);
			this.state = {
				redirect: queryString.parse(this.props.location.search).redirect
			};
		}
		componentDidMount() {
			this.shouldNavigateAway();
		}

		componentDidUpdate() {
			this.shouldNavigateAway();
		}

		shouldNavigateAway() {
			if (this.props.auth) {
				!this.state.redirect && this.props.history.push('/');
			}
		}

		render() {
			return <ChildComponent {...this.props} />;
		}
	}

	function mapStateToProps(state) {
		return { auth: state.users.authenticated };
	}
	return connect(mapStateToProps)(ComposedComponent);
};
