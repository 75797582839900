export const INIT_BUSINESS_FILTERS = 'INIT_BUSINESS_FILTERS';
export const ADD_BUSINESS_FILTER = 'ADD_BUSINESS_FILTER';
export const REMOVE_BUSINESS_FILTER = 'REMOVE_BUSINESS_FILTER';
export const REVERT_TO_TEMP_BUSINESS_FILTERS = 'REVERT_TO_TEMP_BUSINESS_FILTERS';
export const SET_TEMP_BUSINESS_FILTERS = 'SET_TEMP_BUSINESS_FILTERS';
export const SET_BUSINESS_SEARCH_RESULTS = 'SET_BUSINESS_SEARCH_RESULTS';
export const SET_BUSINESS_SEARCH_FIND = 'SET_BUSINESS_SEARCH_FIND';
export const SET_BUSINESS_SEARCH_NEAR = 'SET_BUSINESS_SEARCH_NEAR';
export const SAVE_SEARCH_PAGE_STATE = 'SAVE_SEARCH_PAGE_STATE';
export const HYDRATE_FROM_SEARCH_PAGE_SAVE_STATE = 'HYDRATE_FROM_SEARCH_PAGE_SAVE_STATE';
export const CLEAR_SEARCH_PAGE_SAVE_STATE = 'CLEAR_SEARCH_PAGE_SAVE_STATE';
