import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Title } from 'components/atoms';
import { startChangeStep } from 'actions/registration';
import { outsideEvent } from 'actions/general';

class PaymentNotConnected extends Component {
	constructor(props) {
		super(props);

		this.gotoConnect = this.gotoConnect.bind(this);
	}

	render() {
		const { className, translate } = this.props,
			classProps = classNames('payment-not-connected', className);

		return (
			<div className={classProps}>
				<Title size="h2" transform="none">
					{translate('Profile.payment.notConnected.title')}
				</Title>
				<Link to="'/users/connect'" onClick={this.gotoConnect} className="btn-wrapper">
					<Button color="primary">{translate('Profile.payment.notConnected.connectBtn')}</Button>
				</Link>
			</div>
		);
	}

	gotoConnect(e) {
		e.preventDefault();
		this.props.outsideEvent({ category: 'PROFILE', action: 'PROFILE_CONNECT' });
		this.props.startChangeStep(2);
		this.props.history.push('/users/connect?redirect=/profile');
	}
}

PaymentNotConnected.defaultProps = {
	className: ''
};

PaymentNotConnected.propTypes = {
	className: PropTypes.string,
	startChangeStep: PropTypes.func,
	match: PropTypes.object,
	history: PropTypes.object,
	outsideEvent: PropTypes.func,
	translate: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	translate: getTranslate(state.locale)
});

const mapDispatchToProps = dispatch => ({
	startChangeStep: step => dispatch(startChangeStep(step)),
	outsideEvent: event => dispatch(outsideEvent(event))
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(PaymentNotConnected)
);
