import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { PageHeaderTitle } from 'components/atoms';

class Body extends React.Component {
	render() {
		// prepare all classes
		const { className, minWidth, maxWidth } = this.props;
		const componentClasses = classNames('container', className);
		const style = { minWidth: minWidth, maxWidth: maxWidth };

		return (
			<div className={componentClasses}>
				<div className="row">
					<div className="col">
						<header className="header-bar" style={style}>
							<div className="col-2">{this.props.leftPageHeaderLinks}</div>
							<div className="col-7">
								<PageHeaderTitle>{this.props.title}</PageHeaderTitle>
							</div>
							{this.props.help && <div className="col-2 help">{this.props.help}</div>}
							{(this.rightPageHeaderLinks || !this.helpComponent) && (
								<div className="col-2">{this.props.rightPageHeaderLinks}</div>
							)}
						</header>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<div className="body" style={style}>
							{this.props.bodyContent}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

Body.defaultProps = {
	className: '',
	bannerImg: null,
	minWidth: undefined,
	maxWidth: 1440
};

Body.propTypes = {
	className: PropTypes.string,
	minWidth: PropTypes.number,
	maxWidth: PropTypes.number,
	leftPageHeaderLinks: PropTypes.node,
	title: PropTypes.node,
	help: PropTypes.node,
	rightPageHeaderLinks: PropTypes.node,
	bodyContent: PropTypes.node
};

const mapStateToProps = store => ({
	config: store.config
});

export default connect(mapStateToProps)(Body);
