import * as t from './types';
import { isLoading } from '../general';
import { basePath, token } from 'actions/utils';
import { hasProp } from 'utils/object';
import axios from 'axios';

/**
 * Sets if to show catering zone service unavailable message.
 * @param {boolean} payload If to show the modal.*
 */
export const setShowNoServiceNotice = payload => ({ type: t.SET_SHOW_NO_SERVICE, payload });

/**
 * Sets catering service type.
 * @param {string} payload The service type to set.
 */
export const setServiceType = payload => ({ type: t.SET_SERVICE_TYPE, payload });

/**
 * Sets address to send catering to.
 * @param {string} payload Address string. NOTE: Is this a string or a google address object?
 */
export const setAddress = payload => ({ type: t.SET_ADDRESS, payload });

/**
 * Sets the date to deliver the catering order.
 * @param {object} payload Date object.
 */
export const setDate = payload => ({ type: t.SET_DATE, payload });

/**
 * Sets loaded flag to true.
 */
export const setScriptsLoaded = () => ({ type: t.SET_SCRIPT_LOADED });

/**
 * Sets catering display flag.
 * @param {object} payload Boolean object.
 */
export const setCateringDisplay = payload => ({ type: t.SET_CATERING_DISPLAY, payload });

// Placeholder Funcs
// TODO: Complete or remove from components.
export const getPoints = () => {
	// console.log('action getPoints is incomplete');
	return {};
};

/**
 * Axios call to fetch zones from api.
 * @param  {object}   location A {lat: number, lng: number} object.
 * @return {Promise}  Returns promise that resolves with axios fetch response object.
 */
const fetchZonesFromAPI = async location => {
	let URL = `${basePath()}/catering/zones?location=`;

	if (location) {
		URL += `${location.lat},${location.lng}`;
	}

	return await axios.get(URL, {
		headers: {
			Authorization: token(),
			'Content-type': 'application/json'
		}
	});
};

/**
 * Get catering zones for a particular lat/lng
 * @param  {object}   location A {lat: number, lng: number} object.
 * @param  {function} dispatch Redux dispatch function
 */
export const getZones = (location, dispatch) => {
	// loading screen
	/*eslint-disable no-async-promise-executor*/
	return new Promise(async (resolve, reject) => {
		/*eslint-enable no-async-promise-executor*/
		try {
			// fetch zones from API.
			const zones = await fetchZonesFromAPI(location);
			const zonesFound = hasProp(zones, 'data.cateringZones') ? zones.data.cateringZones.length : false;

			if (zonesFound) {
				if (!location) {
					// if no location, this is all results, save them to redux state so we don't have to fetch again.
					dispatch({ type: t.FETCH_ZONE_SUCCESS, payload: zones.data.cateringZones });
				}

				console.log('zones.data.cateringZones', zones.data.cateringZones);

				resolve(zones.data.cateringZones);
			} else {
				// no zones found for area.
				resolve({ code: 204, message: 'No zones found for this address' });
			}
		} catch (e) {
			// API error.
			if (e.response) {
				reject(new Error({ error: e.response.data.response.code, message: e.response.data.response.code }));
			} else {
				reject(new Error({ error: 500, message: 'API error' }));
			}
		} finally {
			// close loading screen
			dispatch(isLoading(false));
		}
	});
};
