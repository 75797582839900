import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'third-party/mdbreact/dist/mdbreact.js';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { PageError } from 'components/molecules';

class ShareBox extends React.Component {
	state = {
		copied: false,
		message: this.props.translate('ReferACoWorker.copybutton_copy')
	};

	toggleAlert = () => {
		this.trackEvent({ category: `${this.props.parent} - REFER-A-COWORKER PAGE`, action: 'Copy Link' });
		this.setState({
			copied: true,
			message: this.props.translate('ReferACoWorker.copybutton_success')
		});
	};

	trackEvent = event => {
		this.props.outsideEvent(event);
	};

	render() {
		const { className, isLoadingLink, link, translate, showLearnMore } = this.props;
		const classProps = classNames('share-box', className);
		const showError = !isLoadingLink && !link;

		return (
			<div className={classProps}>
				<Row className={'mt-3 mb-3'}>
					<Col className={'text-center darker'}>{translate('ReferACoWorker.sharelink')}</Col>
				</Row>
				{showLearnMore && (
					<Link to="/refer/details" className="learn-more">
						{translate('common.learnMoreBtn')}
					</Link>
				)}
				{link && (
					<Row>
						<Col md="6" xs="6" className="d-flex justify-content-center align-items-center link">
							{link}
						</Col>
						<Col md="6" xs="6" className="d-flex justify-content-center align-items-center copy">
							<CopyToClipboard text={link} onCopy={this.toggleAlert}>
								<Button className="z-depth-0">{this.state.message}</Button>
							</CopyToClipboard>
						</Col>
					</Row>
				)}
				{showError && <PageError type="warning" message={translate('errors.missing_link')} />}
			</div>
		);
	}
}

ShareBox.defaultProps = {
	isLoadingLink: false,
	showLearnMore: false
};

ShareBox.propTypes = {
	className: PropTypes.string,
	link: PropTypes.string,
	translate: PropTypes.func,
	outsideEvent: PropTypes.func,
	parent: PropTypes.string,
	isLoadingLink: PropTypes.bool,
	showLearnMore: PropTypes.bool
};

export default ShareBox;
