import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Modal, ModalBody, ModalHeader, ModalFooter, Row, Col } from 'reactstrap';
import { Button } from 'reactstrap';
import { toggleModal } from 'actions/modal';

class ModalPage extends React.Component {
	onPrimaryClick = () => {
		this.onToggle();
		this.props.primaryClick && this.props.primaryClick();
	};

	onSecondaryClick = () => {
		this.onToggle();
		this.props.secondaryClick && this.props.secondaryClick();
	};

	onToggle = () => {
		const isOpen = !this.props.isOpen;
		this.props.toggleModal(isOpen);
	};

	render() {
		const {
			modal: { body, isOpen, header, primaryButtonText, secondaryButtonText, tertiaryButtonText }
		} = this.props;
		let largeColWidth = this.hasSecondButton ? '6' : '12';

		return (
			<Modal isOpen={isOpen} toggle={this.toggle} fade={true} centered>
				{header && <ModalHeader toggle={this.toggle}>{header}</ModalHeader>}
				<ModalBody>{body}</ModalBody>
				<ModalFooter>
					<Container>
						<Row>
							<Col sm="12" lg={largeColWidth}>
								<Button block className="z-depth-0" onClick={this.onPrimaryClick} color="primary">
									{primaryButtonText}
								</Button>
							</Col>
							<Col sm="12" lg={largeColWidth}>
								{secondaryButtonText && (
									<Button block className="z-depth-0" onClick={this.onSecondaryClick}>
										{secondaryButtonText}
									</Button>
								)}
							</Col>
							{tertiaryButtonText && (
								<Col sm="12" lg={largeColWidth}>
									<Button block className="z-depth-0" onClick={this.onTertiaryClick}>
										{tertiaryButtonText}
									</Button>
								</Col>
							)}
						</Row>
					</Container>
				</ModalFooter>
			</Modal>
		);
	}
}

ModalPage.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	modal: PropTypes.object.isRequired,
	primaryClick: PropTypes.func.isRequired,
	secondaryClick: PropTypes.func.isRequired,
	toggleModal: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
	toggleModal: isOpen => dispatch(toggleModal(isOpen))
});

const mapStateToProps = state => ({
	modal: state.modal
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ModalPage);
