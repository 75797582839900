import moment from 'moment';

let month = moment().month() + 1;
let year = moment().format('YYYY');

const mockTransactions = [
	{
		id: '131672',
		type: { id: 5, type: 'RAF', description: 'Refer A Friend' },
		date: '2019-09-26T15:53:50.715Z',
		spend: {},
		points: '499',
		venue: { name: 'Refer A Friend' },
		mydinovaProcessedDate: null,
		notes: null
	},
	{
		id: '131638',
		type: { id: 11, type: 'InNetwork', description: 'In Network' },
		date: '2019-09-01T00:00:00.000Z',
		spend: { amount: '106.31', currency: null },
		points: '107',
		venue: {
			id: '811a58db-6a6b-5265-96bc-b5a15c12c32e',
			name: 'Rock Bottom Restaurant & Brewery ',
			city: 'Denver',
			state: 'CO',
			brand: {
				id: '6275d6f7-f5f6-58dc-83b4-34ea15749d1c',
				name: 'Rock Bottom Restaurant & Brewery'
			}
		},
		mydinovaProcessedDate: null,
		notes: 'Test For Jenna'
	}
];

export default { [`${year}-${month}`]: mockTransactions };
