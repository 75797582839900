import * as t from '../actions/map/types';

/**
 * Redux_MapState
 * @interface Redux_MapState
 * @property {string}        [keywords='']
 * @property {string}        [nearLocation='']
 * @property {object}        [searchReqData=null]
 * @property {object}        [searchResData=null]
 * @property {object}        [location=null]
 * @property {object}        [searchErrMsg=null]
 * @property {string|number} [searchErrType=null]
 * @property {array}         [foundPlaces=[]]
 * @property {object}        [googleLocation=null]
 */
const mapDefaultState = {
	keywords: '',
	nearLocation: '',

	// map search
	searchReqData: null,
	searchResData: null,
	location: null,
	searchErrMsg: null,
	searchErrType: null,

	// place autocomplete
	foundPlaces: [],

	// last location that google response to user request on getLocation action
	googleLocation: null,

	// filter stuff
	activeFilterTab: 'cuisineTab'
};

export default (state = mapDefaultState, action) => {
	switch (action.type) {
		case t.MAP_SEARCH: {
			if (action.status === 'success') {
				return {
					...state,
					keywords: action.response.queryData.keywords || '',
					nearLocation: action.response.queryData.near || '',
					searchReqData: action.response.queryData,
					searchResData: action.response.resData,
					location: action.response.location,
					searchErrMsg: null,
					searchErrType: null
				};
			} else {
				return {
					...state,
					keywords: '',
					nearLocation: '',
					searchReqData: null,
					searchResData: null,
					location: null,
					searchErrMsg: action.error || null,
					searchErrType: action.errType || null
				};
			}
		}
		case t.PLACE_AUTOCOMPLETE: {
			if (action.status === 'success') {
				return {
					...state,
					foundPlaces: action.foundPlaces
				};
			} else {
				return {
					...state,
					foundPlaces: []
				};
			}
		}
		case t.GET_LOCATION: {
			if (action.status === 'success') {
				return {
					...state,
					googleLocation: action.location
				};
			} else {
				return {
					...state,
					googleLocation: null
				};
			}
		}
		case t.SET_ACTIVE_FILTER_TAB: {
			return { ...state, activeFilterTab: action.value };
		}
		default:
			return state;
	}
};
