import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';

const LayoutMetaTags = ({ config }) => {
	const { imageBaseUrl } = config;
	return (
		<MetaTags>
			<link rel="shortcut icon" href={`${imageBaseUrl}/logos/Dinova-favicon.png`} type="image/png" />
			<link rel="bookmark icon" href={`${imageBaseUrl}/logos/Dinova-favicon.png`} type="image/png" />
			<link rel="icon" href={`${imageBaseUrl}/logos/Dinova-favicon.png`} type="image/png" />
			<link rel="apple-touch-icon" sizes="57x57" href={`${imageBaseUrl}/logos/Dinova-favicon.png`} />
			<link rel="apple-touch-icon" sizes="76x76" href={`${imageBaseUrl}/logos/Dinova-favicon.png`} />
			<link rel="apple-touch-icon" sizes="120x120" href={`${imageBaseUrl}/logos/Dinova-favicon.png`} />
			<link rel="apple-touch-icon" sizes="152x152" href={`${imageBaseUrl}/logos/Dinova-favicon.png`} />
			<link rel="apple-touch-icon" sizes="167x167" href={`${imageBaseUrl}/logos/Dinova-favicon.png`} />
			<link rel="apple-touch-icon" sizes="180x180" href={`${imageBaseUrl}/logos/Dinova-favicon.png`} />
			<link rel="icon" sizes="192x192" href={`${imageBaseUrl}/logos/Dinova-favicon.png`} />
			<link rel="icon" sizes="128x128" href={`${imageBaseUrl}/logos/Dinova-favicon.png`} />
		</MetaTags>
	);
};

LayoutMetaTags.defaultProps = {};

LayoutMetaTags.propTypes = {
	// Required
	config: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	config: state.config
});

export default connect(mapStateToProps)(LayoutMetaTags);
