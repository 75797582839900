import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import supercluster from 'supercluster';
import { find, has, isEqual, some } from 'lodash';
import withSizes from 'react-sizes';
import { isSV, isMV, isLV } from 'utils/sizes';
import { LoadingBase } from 'components/atoms';
import { setRecenterToMapMarker } from 'actions/business_search_map';

export const DEFAULT_CENTER = { lat: 0, lng: 0 };
// export const DEFAULT_ZOOM = 13;
const createMapOptions = (/*maps*/) => ({
	center: DEFAULT_CENTER,
	disableDefaultUI: true,
	zoomControl: false,
	gestureHandling: 'greedy',
	styles: [
		{
			featureType: 'poi',
			elementType: 'labels',
			stylers: [{ visibility: 'off' }]
		},
		{
			featureType: 'transit',
			stylers: [{ visibility: 'off' }]
		}
	]
});

class GoogleMap extends Component {
	constructor(props) {
		super(props);
		this.clusters = this.initCluster(props);

		this.map = null;
		this.maps = null;
		this.state = {
			clusters: [],
			mapInitialized: false,
			isZooming: false,
			center: props.center,
			zoom: props.zoom
		};
	}

	componentDidUpdate = async prevProps => {
		if (this.props.centerToGeoLocation) {
			this.setState({ center: this.props.geoLocation });
			this.map.setCenter(this.props.geoLocation);
			this.props.reCenterToGeoLocation(false);
		}

		const hasNewPin = this.props.activePin && this.props.activePin !== prevProps.activePin;
		const isOldPin = this.props.activePin && this.props.activePin === prevProps.activePin;

		if (
			hasNewPin ||
			(isOldPin && this.props.centerToMapMarker && this.props.centerToMapMarker !== prevProps.centerToMapMarker)
		) {
			await this.updatActivePin();
		}
		await this.updateStateFromPrevProps(prevProps);

		if (this.props.centerToMapMarker) {
			await this.props.setRecenterToMapMarker(false);
		}
	};

	updateStateFromPrevProps = async prevProps => {
		let newState = {};
		let whiteList = ['zoom', 'center'];

		whiteList.forEach(prop => {
			if (!isEqual(prevProps[prop], this.props[prop])) {
				newState[prop] = this.props[prop];
			}
		});

		if (this.props.centerToMapMarker && !isEqual(this.state.center, this.props.center)) {
			newState.center = this.props.center;
		}

		if (Object.keys(newState).length) {
			this.setState({ ...newState });
		}
	};

	updatActivePin = async () => {
		let parentCluster = this.getPinParentCluster(this.props.activePin);
		let result = find(this.props.markerData, { id: this.props.activePin });

		if (!parentCluster || !parentCluster.id) {
			await this.props.onCenter({ lat: result.location.lat, lng: result.location.lon });
		} else {
			let zoom = this.clusters.getClusterExpansionZoom(parentCluster.id);

			while (parentCluster && parentCluster.properties && parentCluster.properties.cluster && zoom < 16) {
				zoom = this.clusters.getClusterExpansionZoom(parentCluster.id);

				if (zoom) {
					this.map.setZoom(zoom);
					await this.props.onCenter({ lat: result.location.lat, lng: result.location.lon });
				}
				parentCluster = this.getPinParentCluster(this.props.activePin, this.state.zoom);
			}

			if (zoom) {
				if (zoom === 16 && parentCluster.properties.cluster) {
					zoom += 1;
				}
				this.map.setZoom(zoom);
			}
			await this.props.onCenter({ lat: result.location.lat, lng: result.location.lon });
		}
	};

	getPinParentCluster = pinId => {
		const clusters = this.getClusters({
			bounds: {
				nw: {
					lat: 90,
					lng: -180
				},
				se: {
					lat: -90,
					lng: 180
				}
			},
			zoom: this.map.getZoom(),
			center: this.map.getCenter()
		});
		const me = this;
		let parentCluster;

		clusters.forEach(c => {
			if (c.properties.cluster) {
				let points = me.clusters.getLeaves(c.id, Infinity);
				points.forEach(p => {
					if (p.properties.uid === pinId) {
						parentCluster = c;
					}
				});
			} else {
				if (c.properties.uid === pinId) {
					parentCluster = c;
				}
			}
		});

		return parentCluster;
	};

	initCluster = props => {
		const clusterOptions = props.clusterOptions || {
			minZoom: 3,
			maxZoom: 16,
			radius: 200
		};

		return new supercluster(clusterOptions);
	};

	googleMapsApiLoaded = (map, maps) => {
		this.map = map;
		this.maps = maps;

		if (this.props.mapRefSetter) {
			this.props.mapRefSetter(map);
			window[this.props.id] = map;
		}

		this.setState({ mapInitialized: true });
	};

	getClusters = boundsData => {
		if (boundsData !== null && this.state.mapInitialized) {
			const { markerData } = this.props;

			if (markerData.length) {
				let clusterData = markerData.map(this.props.transformResultToCluster);
				this.clusters.load(clusterData);
				let bbox = [
					boundsData.bounds.nw.lng,
					boundsData.bounds.se.lat,
					boundsData.bounds.se.lng,
					boundsData.bounds.nw.lat
				];

				let clusters = this.clusters.getClusters(bbox, boundsData.zoom);

				return clusters;
			}
		}
	};

	getBoundsData = () => {
		if (!this.map) {
			return;
		}

		return {
			bounds: {
				nw: {
					lat: this.map
						.getBounds()
						.getNorthEast()
						.lat(),
					lng: this.map
						.getBounds()
						.getSouthWest()
						.lng()
				},
				se: {
					lat: this.map
						.getBounds()
						.getSouthWest()
						.lat(),
					lng: this.map
						.getBounds()
						.getNorthEast()
						.lng()
				}
			},
			zoom: this.map.getZoom(),
			center: this.map.getCenter()
		};
	};

	setIsZooming = isZooming => {
		this.setState({ isZooming });
	};

	renderMarkers = () => {
		let { clusters } = this.state;
		if ((!clusters || !clusters.length) && this.props.markerData.length) {
			let boundsData = this.getBoundsData();
			clusters = this.getClusters(boundsData);
		}

		if (clusters && clusters.length) {
			return clusters.map((item, index) => {
				let result = find(this.props.markerData, { id: item.properties.uid });
				const lng = item.geometry.coordinates[0],
					lat = item.geometry.coordinates[1];

				const isCluster = item.properties.cluster,
					ClusterMarker = this.props.clusterMarker,
					SimpleMarker = this.props.simpleMarker;

				return isCluster ? (
					<ClusterMarker
						lat={lat}
						lng={lng}
						key={`cluster-${index}`}
						id={item.properties.uid}
						name={item.properties.point_count}
						activeCluster={this.isActiveCluster(item.properties.cluster_id)}
						clusterId={item.properties.cluster_id}
						onClusterClick={this.onMarkerClick}
					/>
				) : (
					<SimpleMarker
						lat={lat}
						lng={lng}
						key={`simple-marker-${index}`}
						id={item.properties.uid}
						mapId={this.props.id}
						name={item.properties.name}
						data={this.props.simpleMarkerDataMap(item, result)}
						onMarkerClick={this.onMarkerClick}
					/>
				);
			});
		} else {
			return null;
		}
	};

	isActiveCluster = clusterId => {
		if (!this.props.activePin) {
			return false;
		}

		let pins = [];
		try {
			pins = this.clusters.getLeaves(clusterId, Infinity);
		} catch (e) {
			console.warn(e, `invalid cluster_id: ${clusterId}`);
			return false;
		}

		return some(pins, ['properties.uid', this.props.activePin]);
	};

	onMarkerClick = ({ childProps, listView }) => {
		if (this.props.handleMarkerClick) {
			this.props.handleMarkerClick(childProps, listView);
		}
		// TODO: set map card displayed in Redux
		if (childProps.isUserLocation) {
			return;
		}

		if (childProps.isCluster) {
			this.handleClusterClick(childProps);
		} else {
			this.handleSimpleMarkerClick(childProps, listView);
		}
	};

	handleClusterClick = childProps => {
		// zoom in to all markers under a cluster
		let markers = this.clusters.getLeaves(childProps.clusterId, Infinity),
			newBounds = new this.maps.LatLngBounds();

		markers.forEach(marker => {
			let lng = marker.geometry.coordinates[0],
				lat = marker.geometry.coordinates[1],
				latLng = new this.maps.LatLng(lat, lng);
			newBounds.extend(latLng);
		});

		this.map.fitBounds(newBounds);
		if (this.props.handleClusterClick) {
			this.props.handleClusterClick(childProps);
		}
		if (this.props.onZoom) {
			this.props.onZoom(this.map.zoom);
		}
	};

	handleSimpleMarkerClick = (childProps, listView) => {
		this.props.setActivePin(childProps.id);

		if (listView) {
			this.state.clusters.forEach(pin => {
				if (has(pin, 'properties.uid') && pin.properties.uid === childProps.id) {
					return false;
				}
			});
		}

		if (this.props.handleSimpleMarkerClick) {
			this.props.handleSimpleMarkerClick(childProps, listView);
		}
	};

	onMapClick = args => {
		const event = args.event;
		let elem = event.target;
		let zoomNotClicked = !elem.hasAttribute('data-marker-zone') || elem.getAttribute('data-marker-zone') !== 'true';

		if (zoomNotClicked && !this.state.isZooming) {
			if (this.props.onMapClick) {
				this.props.onMapClick(args);
			}
		}
	};

	onMapChange = args => {
		if (this.props.onMapChange) {
			this.props.onMapChange(args, this.state.mapInitialized);
		}
	};

	render() {
		const { API_KEY, children } = this.props;

		return this.state.center.lat ? (
			<GoogleMapReact
				id={this.props.id}
				bootstrapURLKeys={{ key: API_KEY }}
				center={this.state.center}
				zoom={this.state.zoom}
				onClick={this.onMapClick}
				onChange={this.onMapChange}
				onGoogleApiLoaded={({ map, maps }) => this.googleMapsApiLoaded(map, maps)}
				onZoomAnimationStart={this.setIsZooming.bind(this, true)}
				onZoomAnimationEnd={this.setIsZooming.bind(this, false)}
				options={createMapOptions}
				resetBoundsOnResize
				yesIWantToUseGoogleMapApiInternals
			>
				{this.renderMarkers()}
				{children}
			</GoogleMapReact>
		) : (
			<LoadingBase />
		);
	}
}

GoogleMap.defaultProps = {
	markers: [],
	markerData: []
};

GoogleMap.propTypes = {
	id: PropTypes.string.isRequired,
	API_KEY: PropTypes.string.isRequired,
	activePin: PropTypes.string,
	center: PropTypes.shape({
		lat: PropTypes.number,
		lng: PropTypes.number
	}).isRequired,
	centerToGeoLocation: PropTypes.bool, // if true triggers reCenterToGeoLocation
	centerToMapMarker: PropTypes.bool,
	children: PropTypes.node,
	clusterMarker: PropTypes.any.isRequired,
	clusterOptions: PropTypes.shape({
		minZoom: PropTypes.number.isRequired,
		maxZoom: PropTypes.number.isRequired,
		radius: PropTypes.number.isRequired
	}),
	geoLocation: PropTypes.shape({
		lat: PropTypes.number,
		lng: PropTypes.number
	}),
	handleClusterClick: PropTypes.func,
	handleMarkerClick: PropTypes.func,
	handleSimpleMarkerClick: PropTypes.func,
	isSV: PropTypes.bool.isRequired,
	isMV: PropTypes.bool.isRequired,
	isLV: PropTypes.bool.isRequired,
	mapRefSetter: PropTypes.func,
	markers: PropTypes.array,
	markerData: PropTypes.array,
	onCenter: PropTypes.func.isRequired,
	onMapChange: PropTypes.func,
	onMapClick: PropTypes.func,
	onZoom: PropTypes.func.isRequired,
	reCenterToGeoLocation: PropTypes.func, // used with centerToGeoLocation bool
	setActivePin: PropTypes.func.isRequired,
	setRecenterToMapMarker: PropTypes.func.isRequired,
	simpleMarker: PropTypes.any.isRequired,
	simpleMarkerDataMap: PropTypes.func.isRequired,
	transformResultToCluster: PropTypes.func.isRequired,
	zoom: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
	API_KEY: state.config.apiKey,
	centerToMapMarker: state.businessSearchMap.centerToMapMarker,
	geoLocation: { lat: state.geoLocation.lat, lng: state.geoLocation.lng }
});

const mapDispatchToProps = dispatch => ({
	setRecenterToMapMarker: async shouldCenter => await setRecenterToMapMarker(dispatch, shouldCenter)
});

const mapSizesToProps = sizes => ({
	isSV: isSV(sizes),
	isMV: isMV(sizes),
	isLV: isLV(sizes)
});

export default withSizes(mapSizesToProps)(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(GoogleMap)
);
