import RecommendationsThankYouModal from './ui-component';
import { connect } from 'react-redux';
import { toggleThankYou } from 'actions/recommendations_modal';
import { getTranslate } from 'react-localize-redux';

const mapStateToProps = state => ({
	show: state.recommendationsModal.showThankYou,
	translate: getTranslate(state.locale)
});

const mapDispatchToProps = dispatch => ({
	toggleThankYou: show => dispatch(toggleThankYou(show))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(RecommendationsThankYouModal);
