import React from 'react';
import PropTypes from 'prop-types';
import LoadingBase from '../LoadingBase';

const Loader = ({ message }) => (
	<div className="overlay">
		<div className="dnLoader">
			<LoadingBase message={message} />
		</div>
	</div>
);

Loader.defaultProps = {
	message: 'Loading'
};

Loader.propTypes = {
	message: PropTypes.string
};

export default Loader;
