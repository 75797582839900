import React, { Component } from 'react';
// import classnames from 'classnames';
// import PropTypes from 'prop-types';
import { Provider } from 'react-redux';

import { Layout } from 'components/ecosystems';
import { STORE } from 'components/environment/App/redux_store.js';
import { Loader } from 'components/atoms';
import isNodeEnv from 'utils/isNodeEnv';
import { getProfile } from 'actions/users';
import { loadConfig } from 'actions/config';
import { initialize } from 'react-localize-redux';
import { startFetchStrings } from 'actions/general';

// import './style.scss';
class App extends Component {
	constructor(props) {
		super(props);
		this.languages = ['en'];

		this.state = {
			isInitialized: false
		};

		if (isNodeEnv('development')) {
			if (window.Cypress) {
				window['store'] = STORE;
			}
		}
	}

	componentDidMount = () => {
		const me = this;
		// init redux store with config vars and langauges
		STORE.dispatch([loadConfig(), initialize(this.languages)]);
		// fetch langauge translations
		STORE.dispatch(startFetchStrings())
			.then(() => {
				// if the user is authed and no user in state, fetch user
				if (STORE.getState().users.authenticated && !STORE.getState().users.user) {
					const isProfilePage = window.location.pathname.indexOf('profile') > -1;
					const showLoading = isProfilePage;
					STORE.dispatch(getProfile(showLoading));
				}
			})
			.then(() => {
				me.setState({ isInitialized: true });
			});
	};

	render() {
		return <Provider store={STORE}>{this.state.isInitialized ? <Layout /> : <Loader />}</Provider>;
	}
}

App.defaultProps = {};

App.propTypes = {};

export default App;
