import React, { Component } from 'react';
// import classNames from 'classnames';
import PropTypes from 'prop-types';

/**
 * Image element with ref set to this.img.  Ref can be used in an HOC to manipulate image element.
 * @extends Component
 * @param {ImageProps} props
 */
class Image extends Component {
	render() {
		const { className, onLoad, src } = this.props;
		return <img src={src} className={className} onLoad={onLoad} ref={c => (this.img = c)} />;
	}
}

Image.defaultProps = {
	className: '',
	onLoad: () => {},
	src: ''
};

/**
 * Image component props shape.
 * @interface Props_Image
 * @property {string} [className=null] Additional className to add to the image element.
 * @property {string} [src=""]       The image src.
 * @property {func}   [onLoad=() => {}]    On image load handler.
 */
Image.propTypes = {
	className: PropTypes.string,
	onLoad: PropTypes.func,
	src: PropTypes.string
};

export default Image;
