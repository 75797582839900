import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'reactstrap';
import { loadRecentSearches } from 'utils/search';

import { chain, isFunction, isUndefined } from 'lodash';

class RecentSearches extends Component {
	render() {
		const { className, translate } = this.props,
			classProps = classNames('recent-searches', className),
			list = this.generateList();

		return (
			list.length > 0 && (
				<div className={classProps}>
					<span className="label">{translate('Home.searchDropdown.recentLabel')}</span>
					<div className="list">{list}</div>
				</div>
			)
		);
	}

	generateList = () => {
		const { hasGeolocation, recent, type } = this.props;

		const recentSearches = loadRecentSearches(hasGeolocation, recent, type);

		return chain(recentSearches)
			.map((item, key) => {
				let keywords = '',
					near = '',
					msg = '';
				switch (recent) {
					case 'keywords':
						msg = keywords = item['keywords'];
						break;
					case 'near':
						msg = near = item['near'];
						break;
					case 'all':
					default:
						keywords = item['keywords'];
						near = item['near'];
						msg = item['message'];
						break;
				}

				return (
					<Button
						key={key}
						className="item"
						color="link"
						data-key={keywords}
						data-near={unescape(near)}
						onClick={this.handleRecentSearches}
					>
						{unescape(msg)}
					</Button>
				);
			})
			.value();
	};

	handleRecentSearches = event => {
		let data = {},
			key = event.target.dataset.key;

		if (!isUndefined(key) && key !== 'undefined') {
			if (key === 'NearByCatering' || key === 'NearByPrivateDining' || key === 'NearByAll') {
				data.keywords = '';
				data.near = '';
				data.suggestions = key;
			} else {
				data.keywords = event.target.dataset.key || '';
				data.near = event.target.dataset.near || '';
				data.suggestions = '';
			}
		}

		this.props.setSearchSuggestion(data.suggestions);

		if (isFunction(this.props.recentCallback)) {
			this.props.recentCallback(data);
		}
	};
}

RecentSearches.defaultProps = {
	className: '',
	recent: 'all'
};

RecentSearches.propTypes = {
	className: PropTypes.string,
	setSearchSuggestion: PropTypes.func,
	hasGeolocation: PropTypes.bool,
	recent: PropTypes.oneOf(['keywords', 'near', 'all']),
	recentCallback: PropTypes.func,
	translate: PropTypes.func.isRequired,
	type: PropTypes.oneOf(['catering', 'business'])
};

export default RecentSearches;
