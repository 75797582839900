import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { applyURLParams } from 'utils/url';
import { TabPanelBody, TabPanelHeader } from 'components/molecules';
import { withLastLocation } from 'react-router-last-location';

class TabPanel extends React.Component {
	constructor(props) {
		super(props);
		if (props.baseRouteRedirect.from) {
			this.baseRouteRedirect = this.configureBaseRouteRedirect(props);
		}
	}

	componentDidMount = () => {
		this.handleBaseRouteRedirect();
		this.handlePreviousRouteRedirect();
	};

	handleBaseRouteRedirect = () => {
		const {
			props: {
				match: { url },
				history
			},
			baseRouteRedirect: { from, to }
		} = this;

		if (from === url) {
			history.replace(to);
		}
	};

	handlePreviousRouteRedirect = () => {
		const { history, lastLocation, previousRouteRedirects } = this.props;

		if (previousRouteRedirects && lastLocation) {
			const validPreviousRoute = this.isValidPreviousRoute();
			if (validPreviousRoute) {
				history.replace(validPreviousRoute);
			}
		}
	};

	isValidPreviousRoute = () => {
		const {
			previousRouteRedirects,
			lastLocation: { pathname }
		} = this.props;

		return previousRouteRedirects[pathname];
	};

	configureBaseRouteRedirect = props => {
		props = props || this.props;
		let [from, to] = applyURLParams(props.match, [props.baseRouteRedirect.from, props.baseRouteRedirect.to]);

		return {
			from,
			to
		};
	};

	render() {
		const { bodyClass, headerClass, match, tabs, tabStyle } = this.props;
		return (
			<div className="tab-panel">
				{/*match.url === this.baseRouteRedirect.from && <Redirect replace to={this.baseRouteRedirect.to} />*/}
				<TabPanelHeader tabs={tabs} match={match} tabStyle={tabStyle} className={headerClass} />
				<TabPanelBody tabs={tabs} match={match} className={bodyClass} />
			</div>
		);
	}
}

TabPanel.defaultProps = {
	baseRouteRedirect: {},
	tabs: []
};

TabPanel.propTypes = {
	baseRouteRedirect: PropTypes.shape({
		from: PropTypes.string.isRequired,
		to: PropTypes.string.isRequired
	}),
	bodyClass: PropTypes.string,
	headerClass: PropTypes.string,
	history: PropTypes.object.isRequired, // react-router history object
	lastLocation: PropTypes.object, //
	match: PropTypes.object.isRequired, //react router match object
	previousRouteRedirects: PropTypes.object,
	tabs: PropTypes.array,
	tabStyle: PropTypes.string
};

export default withRouter(withLastLocation(TabPanel));
export const TabPanelInstance = TabPanel;
