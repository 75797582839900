/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { InputField } from 'components/organisms';
import { some } from 'lodash';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { getCardType } from 'utils/card';

class CCTextBox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			ccClass: undefined,
			value: props.value
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value) {
			this.setState({
				ccClass: getCardType(this.props.value)
			});
		}
	}

	onChange = e => {
		this.setState({ value: e.target.value });
		this.setState({
			ccClass: getCardType(e.target.value)
		});
	};

	onFocus = e => {
		this.setState({
			ccClass: getCardType(e.target.value)
		});
	};

	onBlur = e => {
		if (e.target.value !== '') {
			const ccIsValid = this.validateCard(e.target.value);
			const isAccepted = this.props.cardTypes.includes(this.state.ccClass);
			let errorText = '';
			const hasLetters = !/\d/.test(e.target.value);
			// TODO maybe don't need it
			if (e.target.value.length > 16 || hasLetters) {
				errorText = this.props.translate('ConnectToPoints.inputs.creditcard.invalidCardNumber');
			} else if (e.target.value.length <= 16 && isAccepted && !ccIsValid) {
				errorText = this.props.translate('ConnectToPoints.inputs.creditcard.invalidCardNumber');
			} else if (e.target.value.length < 16 && !isAccepted) {
				errorText = this.props.translate('ConnectToPoints.inputs.creditcard.invalidCardType');
			} else if (e.target.value.length === 16 && !isAccepted) {
				errorText = this.props.translate('ConnectToPoints.inputs.creditcard.invalidCardType');
			}

			this.props.onBlur(e, ccIsValid, isAccepted, true, errorText);
		}
	};

	isAcceptedCardType = value => {
		let acceptedCreditCards = {
			visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
			mastercard: /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/,
			amex: /^3[47][0-9]{13}$/,
			discover: /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/,
			diners_club: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
			jcb: /^(?:2131|1800|35[0-9]{3})[0-9]{11}$/
		};

		// loop through the keys (visa, mastercard, amex, etc.)
		return some(acceptedCreditCards, cardRegex => cardRegex.test(value));
	};

	validateCard = value => {
		// if has letters
		if (!/\d/.test(value)) {
			return false;
		}
		// remove all non digit characters
		value = value.replace(/\D/g, '');
		let sum = 0,
			shouldDouble = false;
		// loop through values starting at the rightmost side
		for (let i = value.length - 1; i >= 0; i--) {
			let digit = parseInt(value.charAt(i));

			if (shouldDouble) {
				if ((digit *= 2) > 9) digit -= 9;
			}

			sum += digit;
			shouldDouble = !shouldDouble;
		}

		let valid = sum % 10 === 0,
			accepted = this.isAcceptedCardType(value);

		return valid && accepted;
	};

	render() {
		const { className } = this.props;
		const classProps = classnames(className, this.state.ccClass);

		return (
			<InputField
				className={classProps}
				onFocus={this.props.onFocus}
				onBlur={this.onBlur.bind(this)}
				onChange={this.onChange}
				name={this.props.name}
				id={this.props.id}
				error={this.props.errorText}
				value={this.state.value}
				label={this.props.label}
				help={this.props.help}
				example={this.props.example}
				hasError={!this.props.isValid}
			/>
		);
	}
}

CCTextBox.defaultProps = {
	className: '',
	mark: 'asterisk',
	example: ''
};

CCTextBox.propTypes = {
	className: PropTypes.string,
	mark: PropTypes.oneOf(['none', 'asterisk', 'cross']),
	example: PropTypes.string,
	translate: PropTypes.func
};

const mapStateToProps = state => ({
	translate: getTranslate(state.locale)
});

export default connect(mapStateToProps)(CCTextBox);
