import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import {
	setLocationAutocompleteSearch,
	setLocationAutocompleteSuggestions,
	setLocationAutocompleteLoading,
	setLocationAutocompleteReady
} from 'actions/location_autocomplete';

// import '../style.scss';

// transform snake_case to camelCase
const formattedSuggestion = structured_formatting => ({
	mainText: structured_formatting.main_text,
	secondaryText: structured_formatting.secondary_text
});

class LocationAutocomplete extends React.Component {
	constructor(props) {
		super(props);

		if (props.value) {
			this.props.setLocationAutocompleteSearch(props.value);
		}

		this.debouncedFetchPredictions = debounce(this.fetchPredictions, 300);
	}

	componentDidMount() {
		const { googleCallbackName } = this.props;
		if (googleCallbackName) {
			if (!window.google) {
				window[googleCallbackName] = this.init;
			} else {
				this.init();
			}
		} else {
			this.init();
		}
	}

	componentDidUpdate = prevProps => {
		if (prevProps.value !== this.props.value) {
			this.props.setLocationAutocompleteSearch(this.props.value);
		}
	};

	componentWillUnmount() {
		const { googleCallbackName } = this.props;
		if (googleCallbackName && window[googleCallbackName]) {
			delete window[googleCallbackName];
		}
	}

	init = () => {
		if (!window.google) {
			throw new Error(
				'[react-places-autocomplete]: Google Maps JavaScript API library must be loaded. See: https://github.com/kenny-hibino/react-places-autocomplete#load-google-library'
			);
		}

		if (!window.google.maps.places) {
			throw new Error(
				'[react-places-autocomplete]: Google Maps Places library must be loaded. Please add `libraries=places` to the src URL. See: https://github.com/kenny-hibino/react-places-autocomplete#load-google-library'
			);
		}

		this.autocompleteService = new window.google.maps.places.AutocompleteService();
		this.autocompleteOK = window.google.maps.places.PlacesServiceStatus.OK;
		if (!this.props.ready) {
			this.props.setLocationAutocompleteReady(true);
		}
	};

	handleChange = e => {
		const { value } = e.target;
		this.props.setLocationAutocompleteSearch(value);

		if (this.props.onChange) {
			this.props.onChange(e);
		}

		if (!value) {
			this.clearSuggestions();
			return;
		}

		this.debouncedFetchPredictions();
	};

	handleSelect = address => {
		this.props.setLocationAutocompleteSearch(address);
		this.clearSuggestions();
	};

	fetchPredictions = () => {
		const value = this.props.address;
		if (value.length) {
			this.props.setLocationAutocompleteLoading(true);
			this.autocompleteService.getPlacePredictions(
				{
					input: value
				},
				this.autocompleteCallback
			);
		}
	};

	autocompleteCallback = (predictions, status) => {
		this.props.setLocationAutocompleteLoading(false);

		if (status !== this.autocompleteOK) {
			return;
		}

		this.props.setLocationAutocompleteSuggestions(
			predictions.map((p, idx) => ({
				id: p.id,
				description: p.description,
				placeId: p.place_id,
				active: false, //highlightFirstSuggestion && idx === 0 ? true : false,
				index: idx,
				formattedSuggestion: formattedSuggestion(p.structured_formatting),
				matchedSubstrings: p.matched_substrings,
				terms: p.terms,
				types: p.types
			}))
		);
	};

	clearActive = () => {
		this.props.setLocationAutocompleteSuggestions(
			this.props.suggestions.map(suggestion => ({
				...suggestion,
				active: false
			}))
		);
	};

	clearSuggestions = () => {
		this.props.setLocationAutocompleteSuggestions([]);
	};

	inputProps = () => {
		const defaultInputProps = {
			type: 'text',
			autoComplete: 'off',
			role: 'combobox',
			'aria-autocomplete': 'list',
			'aria-expanded': this.getIsExpanded(),
			'aria-activedescendant': this.getActiveSuggestionId(),
			disabled: !this.props.ready
		};

		return {
			...defaultInputProps,
			onKeyDown: this.handleInputKeyDown,
			onBlur: this.handleInputOnBlur
		};
	};

	getIsExpanded = () => {
		return this.props.suggestions.length > 0;
	};

	getActiveSuggestionId = () => {
		const activeSuggestion = this.getActiveSuggestion();
		return activeSuggestion ? `${activeSuggestion.placeId}` : undefined;
	};

	getActiveSuggestion = () => {
		return this.props.suggestions.find(suggestion => suggestion.active);
	};

	selectActiveAtIndex = index => {
		this.setActiveAtIndex(index);
	};

	handleEnterKey = () => {
		const activeSuggestion = this.getActiveSuggestion();
		if (activeSuggestion === undefined) {
			this.handleSelect(this.props.address, null);
		} else {
			this.handleSelect(activeSuggestion.description, activeSuggestion.placeId);
		}
	};

	handleDownKey = () => {
		if (this.props.suggestions.length === 0) {
			return;
		}

		const activeSuggestion = this.getActiveSuggestion();
		if (activeSuggestion === undefined) {
			this.selectActiveAtIndex(0);
		} else if (activeSuggestion.index === this.props.suggestions.length - 1) {
			this.selectUserInputValue();
		} else {
			this.selectActiveAtIndex(activeSuggestion.index + 1);
		}
	};

	handleUpKey = () => {
		if (this.props.suggestions.length === 0) {
			return;
		}

		const activeSuggestion = this.getActiveSuggestion();
		if (activeSuggestion === undefined) {
			this.selectActiveAtIndex(this.props.suggestions.length - 1);
		} else if (activeSuggestion.index === 0) {
			this.selectUserInputValue();
		} else {
			this.selectActiveAtIndex(activeSuggestion.index - 1);
		}
	};

	selectUserInputValue = () => {
		this.clearActive();
	};

	handleInputKeyDown = event => {
		/* eslint-disable indent */
		switch (event.key) {
			case 'Enter':
				event.preventDefault();
				// this.handleEnterKey();
				break;
			case 'ArrowDown':
				event.preventDefault(); // prevent the cursor from moving
				this.handleDownKey();
				break;
			case 'ArrowUp':
				event.preventDefault(); // prevent the cursor from moving
				this.handleUpKey();
				break;
			case 'Escape':
				this.clearSuggestions();
				break;
		}
		/* eslint-enable indent */
	};

	handleInputOnBlur = () => {
		if (!this.mousedownOnSuggestion) {
			this.clearSuggestions();
		}
	};

	setActiveAtIndex = index => {
		this.props.setLocationAutocompleteSuggestions(
			this.props.suggestions.map((suggestion, idx) => {
				if (idx === index) {
					return { ...suggestion, active: true };
				} else {
					return { ...suggestion, active: false };
				}
			})
		);
	};

	render() {
		const { hasGeoLocation, translate } = this.props;

		return (
			<input
				className="search-input"
				{...this.inputProps()}
				onChange={this.handleChange}
				value={this.props.address}
				placeholder={
					hasGeoLocation
						? translate('Home.cateringSearch.locationInput.placeholder')
						: translate('Home.businessSearch.nearLabel')
				}
			/>
		);
	}
}

const mapStateToProps = state => {
	return {
		hasGeoLocation: state.geoLocation.hasGeoLocation,
		translate: getTranslate(state.locale),
		...state.locationAutocomplete
	};
};

const mapDistpatchToProps = dispatch => {
	return {
		setLocationAutocompleteSearch: value => dispatch(setLocationAutocompleteSearch(value)),
		setLocationAutocompleteSuggestions: value => dispatch(setLocationAutocompleteSuggestions(value)),
		setLocationAutocompleteLoading: value => dispatch(setLocationAutocompleteLoading(value)),
		setLocationAutocompleteReady: value => dispatch(setLocationAutocompleteReady(value))
	};
};

LocationAutocomplete.propTypes = {
	hasGeoLocation: PropTypes.bool.isRequired,
	onChange: PropTypes.func,
	translate: PropTypes.func.isRequired,
	setLocationAutocompleteSearch: PropTypes.func.isRequired,
	setLocationAutocompleteSuggestions: PropTypes.func.isRequired,
	setLocationAutocompleteLoading: PropTypes.func.isRequired,
	setLocationAutocompleteReady: PropTypes.func.isRequired,
	address: PropTypes.string,
	value: PropTypes.string,
	loading: PropTypes.bool,
	suggestions: PropTypes.array,
	ready: PropTypes.bool,
	googleCallbackName: PropTypes.string // google api func name .
};

export default connect(
	mapStateToProps,
	mapDistpatchToProps
)(LocationAutocomplete);
