import React from 'react';
import PropTypes from 'prop-types';
import { IconError, Text } from 'components/atoms';

// import './styles/main.scss';

/**
 * Input Error - shows an error message for an input.
 * @param {InputErrorProps} props
 */
const InputError = ({ dataCy, text }) => (
	<React.Fragment>
		<IconError dataCy={dataCy} />
		<Text dataCy={dataCy} className="input-error-text">
			{text}
		</Text>
	</React.Fragment>
);

InputError.defaultProps = {
	text: 'Error text'
};

/**
 * InputError Props
 * @interface InputError_Props
 * @property {string} text The error message.
 */
InputError.propTypes = {
	dataCy: PropTypes.string,
	text: PropTypes.string
};

export default InputError;
