import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'components/atoms';

const CTANav = ({ links }) => {
	return links.length > 0 ? (
		<div className="horizontal-nav">
			{links.map((l, i) => {
				return (
					<div key={`navlink-${i}-${l.href}`} onClick={l.action.bind(this, l)} className="nav-item">
						<Text weight="bold" size="xs" transform="uppercase">
							{l.title}
						</Text>
					</div>
				);
			})}
		</div>
	) : null;
};

CTANav.defaultProps = {
	links: []
};

CTANav.propTypes = {
	links: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
			action: PropTypes.func
		})
	)
};

export default CTANav;
