import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { getTranslate } from 'react-localize-redux';
import { FILTER_TYPES } from 'components/molecules/FilterTabs';

const FilterTypes = props => {
	const { className, onSelectTab, activeTab, translate } = props;

	const classProps = classNames('filter-types', className);

	return (
		<Nav tabs className={classProps}>
			{FILTER_TYPES.map((filterType, index) => {
				return (
					<NavItem
						key={index}
						className={classNames({ active: activeTab === filterType.key }, 'btn btn-secondary')}
					>
						<NavLink onClick={() => onSelectTab(filterType.key)}>
							<span className="filter-tab-name">{translate(`Search.filterTypes.${filterType.key}`)}</span>
							{filterType.count > 0 && <span className="filter-tab-count">({filterType.count})</span>}
						</NavLink>
					</NavItem>
				);
			})}
		</Nav>
	);
};

FilterTypes.defaultProps = {
	className: ''
};

FilterTypes.propTypes = {
	className: PropTypes.string,
	activeTab: PropTypes.string.isRequired,
	onSelectTab: PropTypes.func.isRequired,
	translate: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	translate: getTranslate(state.locale)
});

export default connect(mapStateToProps)(FilterTypes);
