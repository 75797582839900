import React from 'react';
import PropTypes from 'prop-types';
import ICONS from './social-icons';

/**
 * Displays a restaurant's avialble social links. Ex: Facebook, Twitter.
 * @param {SocialProps} props
 */
const Social = ({ socials }) => {
	return (
		<div className="social-wrapper flex pt-1">
			{Object.keys(socials).map((key, i) => {
				return ICONS[key] ? (
					<a key={`social-${i}`} href={socials[key]} target="_blank" rel="noopener noreferrer">
						<img className="social-icon" src={ICONS[key]} alt={key} key={`social-icon-${i}-${key}`} />
					</a>
				) : null;
			})}
		</div>
	);
};

Social.defaultProps = {
	socials: {}
};

/**
 * {@link Social} Props
 * @interface Props_Social
 * @property {object} socials Ex: { twitter: 'twitterFolowLink.example' }
 */
Social.propTypes = {
	// Optional
	socials: PropTypes.object
};

export default Social;
