import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Text } from 'components/atoms';

/**
 * Dumb container to be used with a parent component that extends WithDropdown.  When CollapseWithClose is provided a height and openSpeed prop, it will open and close with a smooth animation.
 * @constructor
 * @param {CollapseWithClose_Props} props
 */
const CollapseWithClose = ({ content, height, iconBaseURL, isOpen, onClick, openSpeed, refSetter }) => {
	const classProps = classnames('collapse-with-close', isOpen ? 'open' : 'closed');

	const styles = {
		height: height + 15, // 15 to compensate for negative top margin to allow for top arrow.
		transition: openSpeed
	};

	const num = Math.floor(Math.random() * 101);

	return (
		<div className={classProps} style={styles} key={`wrapper-${num}`}>
			<div className="inner-wrapper" key={`inner-wrapper-${num}`}>
				<img
					src={`${iconBaseURL}/icon-close-green.svg`}
					alt="green close button"
					className="close-btn"
					onClick={onClick}
					key={`img-${num}`}
				/>
				<div className="content" ref={refSetter} key={`content-${num}`}>
					<Text key={`content-text-${num}`}>{content}</Text>
				</div>
			</div>
		</div>
	);
};

CollapseWithClose.defaultProps = {
	content: 'Lorem...',
	height: 'auto',
	iconBaseURL: '',
	isOpen: false,
	onClick: () => {},
	openSpeed: 'height .3s ease',
	refSetter: () => {}
};

/**
 * [propTypes description]
 * @interface CollapseWithClose_Props
 * @param {string}  content     The text to show the body of this component.
 * @param {number}  height      The height (in pixles) of the text content.
 * @param {string}  iconBaseURL Redux store - state.config.cdn_basePath + '/icons'
 * @param {Boolean} isOpen      If the content is expanded or not.
 * @param {func}    onClick     Close icon click handler.
 * @param {string}  openSpeed   The height transition animation.
 * @param {func}    refSetter   Parent component ref setter.
 */
CollapseWithClose.propTypes = {
	content: PropTypes.string,
	height: PropTypes.number,
	iconBaseURL: PropTypes.string,
	isOpen: PropTypes.bool,
	onClick: PropTypes.func,
	openSpeed: PropTypes.string,
	refSetter: PropTypes.func
};

const mapStateToProps = state => ({
	// TODO: Add this to config?
	iconBaseURL: state.config.cdn_basePath + '/icons'
});

export default connect(mapStateToProps)(CollapseWithClose);
