import { createReducer } from 'redux-act';
import { cloneDeep } from 'lodash';
// actions
import { loadConfig } from 'actions/config';

const initialState = {};

export default createReducer(
	{
		[loadConfig]: (state, payload) => {
			return cloneDeep(payload);
		}
	},
	initialState
);
