import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Asterisk = ({ className }) => {
	const classProps = classNames('asterisk', className);

	return <span className={classProps}>*</span>;
};

Asterisk.defaultProps = {
	className: ''
};

Asterisk.propTypes = {
	className: PropTypes.string
};

export default Asterisk;
