import Promo from './ui-component';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

const mapStateToProps = state => ({
	translate: getTranslate(state.locale),
	IMAGE_BASE_URL: state.config.cdn_basePath
});

export default connect(mapStateToProps)(Promo);
