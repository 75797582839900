import React from 'react';
import { BREAK_POINTS, getCurrentBreakPoint } from 'utils/breakPoints';

/**
 * Wraps an image tag inside of a div with a set width and height.  The image is then styled to maintian it's ratio and stay within the bounds of the div wrapper.
 * @param  {Object} WrappedComponent An image tag element.
 * @param  {object|function} config  An object with height and width or a funciton that is passed the image's default height and width and the current view port type then returns a configured height and width.
 */
function withImageSizeBounds(WrappedComponent, config, imageSizes) {
	class WithImageSizeBounds extends React.Component {
		constructor(props) {
			super(props);
			this.state = {
				bounds: getCurrentBreakPoint(BREAK_POINTS)
			};
		}

		componentDidMount() {
			this.resizeListener = window.addEventListener('resize', this.handleResize);
		}

		componentWillUnmount() {
			window.removeEventListener('resize', this.resizeListener);
		}

		/**
		 * Determine if viewport has entered new breakpoint.  If so, reconfigure logo image size for new viewport width.
		 */
		handleResize = () => {
			const newBounds = getCurrentBreakPoint(BREAK_POINTS);

			// If viewport has gone from mobile to tablet or tablet to mobile or...
			if (this.state.bounds.tag !== newBounds.tag) {
				this.setState({ bounds: newBounds }, () => {
					// reconfigure image size for new view port width.
					this.configureSize();
				});
			}
		};

		/**
		 * Applies height, width, and left styles as well as the "loaded" className to this classes wrapper div component.
		 */
		configureSize = () => {
			if (config) {
				const height = this.img.img.offsetHeight;
				const width = this.img.img.offsetWidth;

				let style;
				if (typeof config === 'function') {
					style = config({ height, width }, this.state.bounds, imageSizes);
				} else {
					// keep default image size.
					style.height = config.height;
					style.width = config.width;
				}

				this.imgWrapper.style.height = style.height;
				this.imgWrapper.style.width = style.width;
				this.imgWrapper.style.left = `calc(50% - ${parseInt(style.width) / 2}px)`;
				if (style.whiteSpace) {
					this.img.img.style.padding = style.whiteSpace;
				}

				this.imgWrapper.className += ' loaded';
			}
		};

		render() {
			return (
				<div className="logo-img-wrapper" ref={c => (this.imgWrapper = c)}>
					<WrappedComponent {...this.props} onLoad={this.configureSize} ref={c => (this.img = c)} />
				</div>
			);
		}
	}

	return WithImageSizeBounds;
}

export default withImageSizeBounds;
