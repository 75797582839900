import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { getTranslate } from 'react-localize-redux';
import metaTagConfig from './meta-tags';
import { MetaTags } from 'components/molecules';
import { logout } from 'actions/users';
import { pageView, updateHeader } from 'actions/general';
import { hasProp } from 'utils/object';

export class Completion extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount = async () => {
		if (this.props.location.pathname === '/users/register') {
			await this.props.logout();
		}

		this.props.updateHeader(true, true);
		const path = this.props.location.pathname === '/users/register' ? 'register' : 'connect';
		this.props.pageView({
			page: `/users/${path}/complete`,
			location: `/users/${path}/complete`,
			title: 'myDinova'
		});
	};

	componentWillUnmount() {
		this.props.updateHeader(true, false);
	}

	render() {
		const { translate, location, connectedViaReferral, className } = this.props;
		const classProps = classNames('completion', className);

		const isConnectPage = location.pathname === '/users/connect';
		const hasConnection =
			hasProp(this.props.users, 'user.connections') && this.props.users.user.connections.length > 0;

		let descId;
		if (isConnectPage) {
			descId = 'Complete.body_header_registered';
		} else if (connectedViaReferral) {
			if (hasConnection) {
				descId = 'Complete.body_header_referred';
			} else {
				descId = 'Complete.body_header_referred_no_cc';
			}
		} else {
			descId = 'Complete.body_header';
		}

		// let descId = isConnectPage
		// 	? 'Complete.body_header_registered'
		// 	: connectedViaReferral
		// 	? 'Complete.body_header_referred'
		// 	: 'Complete.body_header';

		return (
			<div className={classProps}>
				<MetaTags tags={metaTagConfig} />

				<div className="inner-content">
					<h3 className="title">{translate(hasConnection ? 'Complete.title' : 'Complete.title_no_cc')}</h3>
					<p className="desc">{translate(descId)}</p>
					<Button color="primary" className="z-depth-0 continue-btn" onClick={this.props.onClick}>
						{location.pathname === '/users/connect'
							? translate('Complete.button_registered')
							: translate('Complete.button')}
					</Button>

					{location.pathname === '/users/register' && [
						<span className="download-text" key={0}>
							{translate('Complete.body_footer')}
						</span>,
						<div className="stores" key={1}>
							<Button // block
								color="transparent"
								className="z-depth-0 appleBadge"
								onClick={this.gotoAppStore}
							/>
							<Button // block
								color="transparent"
								className="z-depth-0 playBadge"
								onClick={this.gotoGooglePlay}
							/>
						</div>
					]}
				</div>
			</div>
		);
	}

	gotoAppStore = () => {
		window.open('https://itunes.apple.com/us/app/dinova-restaurant-marketplace/id829424959?mt=8&at=10l6Xd');
	};

	gotoGooglePlay = () => {
		window.open(
			'https://play.google.com/store/apps/details?id=com.dinova&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
		);
	};
}

Completion.propTypes = {
	className: PropTypes.string,
	connectedViaReferral: PropTypes.bool.isRequired,
	location: PropTypes.object.isRequired, // react router
	logout: PropTypes.func.isRequired,
	onClick: PropTypes.func.isRequired,
	pageView: PropTypes.func.isRequired,
	translate: PropTypes.func.isRequired,
	updateHeader: PropTypes.func.isRequired,
	users: PropTypes.object
};

const mapDispatchToProps = dispatch => ({
	logout: async () => await logout(dispatch),
	pageView: location => dispatch(pageView(location)),
	updateHeader: (useMyDinovaLogo, showMenu) => dispatch(updateHeader(useMyDinovaLogo, showMenu))
});

const mapStateToProps = state => ({
	translate: getTranslate(state.locale),
	users: state.users
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Completion);
