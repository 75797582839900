import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, CardBody } from 'third-party/mdbreact/dist/mdbreact.js';
import { Button } from 'reactstrap';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'redux';
import metaTagConfig from './meta_tags/login-meta-tags';
import { MetaTags } from 'components/molecules';
import { Text } from 'components/atoms';
import queryString from 'query-string';
import PageError from '../utilities/PageError';
import { login, clearError, getProfile } from '../../../actions/users';
import requireAnon from '../../../utils/requireAnon';
import { updateHeader, outsideEvent } from 'actions/general';
import ModalPage from '../inline/ModalPage';
import { setModal, toggleModal } from 'actions/modal';
import { InputField } from 'components/organisms';
import { toggleShowModal } from 'actions/recommendations_modal';

class Login extends React.Component {
	constructor(props) {
		super(props);
		// query string will ignore everything past &
		// replace it with something unique then, when setting state, replace the unique string with '%26'. '%26' being the URL encoded symbol for '&'
		const search = this.props.location.search.replace('&', 'and_dinova_keeper'),
			redirect = queryString.parse(search).redirect
				? encodeURI(queryString.parse(search).redirect.replace('and_dinova_keeper', '%26'))
				: '';

		this.state = {
			hasErrors: false,
			hasErrorsUsername: false,
			hasErrorsPassword: false,
			username: undefined,
			password: undefined,
			rememberMe: false,
			active: true,
			testing: queryString.parse(this.props.location.search),
			// replace unique string with '%26'
			redirect: redirect,
			action: queryString.parse(this.props.location.search).action
		};
		this.props.setModal({
			id: this.props.translate('login.modals.cancel.id'),
			header: this.props.translate('login.modals.cancel.header'),
			body: this.props.translate('login.modals.cancel.body'),
			primaryButtonText: this.props.translate('login.modals.cancel.primaryButtonText'),
			secondaryButtonText: this.props.translate('login.modals.cancel.secondaryButtonText')
		});
		this.handleClick = this.handleClick.bind(this);
	}
	componentDidMount() {
		this.props.updateHeader(true, false);
		document.addEventListener('keydown', this.onKeyPress);
		this.props.clearError();
		console.log('redirect', queryString.parse(this.props.location.search).redirect);
	}

	componentWillUnmount() {
		this.props.updateHeader(false, true);
		this.props.clearError();
		document.removeEventListener('keydown', this.onKeyPress);
	}

	onKeyPress = e => {
		// e.preventDefault()
		switch (e.key) {
			case 'Enter':
				this.onSubmit({ preventDefault: () => {} });
				break;
			case 'Escape':
				e.preventDefault();
				this.onToggle();
				break;
			default:
				break;
		}
	};

	onChange = e => {
		this.setState({
			hasErrors: false,
			hasErrorsUsername: false,
			hasErrorsPassword: false,
			[e.target.name]: e.target.value
		});
	};

	onSubmit = e => {
		e.preventDefault();

		var u = this.state.username;

		if (u === undefined || u === '') {
			this.setState({ hasErrors: true, hasErrorsUsername: true });
		} else {
			u = this.state.username.replace(/^\s+|\s+$/gm, '');
		}

		var p = this.state.password;
		if (p === undefined || p === '') {
			this.setState({ hasErrors: true, hasErrorsPassword: true });
		} else {
			p = this.state.password.replace(/^\s+|\s+$/gm, '');
		}

		{
			if (!this.state.hasErrors) {
				var user = {
					userName: u,
					password: p,
					testing: this.state.testing.test_api ? true : false,
					campaign: {},
					company: {}
				};
				this.props.general.campaignID && (user.campaign.id = this.props.general.campaignID);
				this.props.general.companyCode && (user.company.id = this.props.general.companyCode);
				this.props.login(user, response => {
					switch (response.code) {
						case 200:
							if (window.Android) {
								this.props.history.push('/mydinova');
							} else if (this.state.redirect && this.state.redirect !== 'undefined') {
								let redirect = this.state.redirect;
								if (window.location.hash) {
									redirect += window.location.hash;
								}
								this.props.history.push(decodeURI(redirect));
							} else {
								this.props.history.push('/');
							}

							break;
						// case 410:
						// case 401:
						case 412:
							this.props.history.push('/change');
							break;
						default:
							break;
					}
				});
			}
		}
	};

	onCancel = e => {
		e.preventDefault();
		this.onToggle();
	};

	handleClick() {
		this.setState({
			active: !this.state.active
		});
	}

	onToggle = () => {
		const isOpen = !this.props.modal.isOpen;
		this.props.toggleModal(isOpen);
	};

	onSecondaryClick = () => {
		let redirect = this.state.redirect.slice();
		if (redirect.indexOf('/#') === -1) {
			if (redirect[redirect.length - 1] === '/') {
				redirect += '#/';
			} else if (redirect[redirect.length - 1] !== '/') {
				redirect += '/#/';
			}
		}

		this.props.toggleShowRecommendationModal();

		this.state.redirect && this.state.redirect !== 'undefined'
			? this.props.history.push(decodeURI(redirect))
			: this.props.history.push('/');
	};

	render() {
		return (
			<div className="Di_SubHead Di_HomePic login">
				<MetaTags tags={metaTagConfig} />
				<ModalPage
					isOpen={this.props.modal.isOpen}
					primaryClick={this.onPrimaryClick}
					secondaryClick={this.onSecondaryClick}
				/>{' '}
				<Container className="Di_HeadSpace">
					<form onSubmit={this.onSubmit} className="Di_Form">
						<Row>
							<div className="Di_PageLimit mx-auto float-none animated fadeInUp">
								<CardBody>
									<Container>
										<Row>
											{this.props.isRecommendFlow && (
												<Text size="sm" align="center" className="notice">
													{this.props.translate('login.members-only-recommendations')}
												</Text>
											)}
											<Col md="6">
												<h1>{this.props.translate('login.headline')}</h1>
											</Col>
											<Col md="6">
												<div className="Di_RequiredHeader">
													<span>*</span>
													{this.props.translate('login.required')}
												</div>
											</Col>
										</Row>

										{/* {this.state.hasErrors ? (
											<PageError message={this.props.translate('login.PageError')} />
										) : null} */}
										{this.props.users.errorCode ? (
											<PageError
												message={this.props.translate(
													`errors.codes.${this.props.users.errorCode}.message`
												)}
											/>
										) : null}
										{/* Username */}
										<Row>
											<Col xs="12">
												<InputField
													className="username"
													dataCy="login-username"
													required
													label={this.props.translate('login.username')}
													onChange={this.onChange}
													name="username"
													id="username"
													value={this.state.username}
													error={this.props.translate('login.usernameerror')}
													hasError={this.state.hasErrorsUsername}
												/>
												{/* Password */}
												<InputField
													className="password"
													dataCy="login-password"
													required
													type="password"
													label={this.props.translate('login.password')}
													onChange={this.onChange}
													name="password"
													id="password"
													value={this.state.password}
													error={this.props.translate('login.passworderror')}
													hasError={this.state.hasErrorsPassword}
												/>
											</Col>

											<Col xs="12" className="Di_ActionBttns">
												<Button
													color="primary"
													className="z-depth-0 "
													type="submit"
													id="login-submit"
												>
													{this.props.translate('login.bttn1')}
												</Button>

												<Button
													className="z-depth-0 "
													onClick={this.onCancel}
													id="login-cancel"
												>
													{this.props.translate('login.bttn2')}
												</Button>
											</Col>

											<Col xs="12">
												<Link
													to="/forgotten"
													id="login-forgot"
													className="Di_LoginDoLink"
													onClick={() => {
														this.props.outsideEvent({
															category: 'LOGIN PAGE',
															action: 'FORGOT_USERNAME'
														});
													}}
												>
													{this.props.translate('login.forgotuser')}
												</Link>
											</Col>

											<Col xs="12">
												<Link
													to={`/users/register${
														this.state.redirect ? `?redirect=${this.state.redirect}` : null
													}`}
													id="login-register"
													className="Di_LoginDoLink"
													onClick={() => {
														this.props.outsideEvent({
															category: 'LOGIN PAGE',
															action: 'REGISTER'
														});
													}}
												>
													{this.props.translate('login.donthaveaccount')}
												</Link>
											</Col>
										</Row>
									</Container>
								</CardBody>
							</div>
						</Row>
					</form>
				</Container>
			</div>
		);
	}
}

Login.propTypes = {
	clearError: PropTypes.func.isRequired,
	general: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	login: PropTypes.func.isRequired,
	modal: PropTypes.object.isRequired,
	outsideEvent: PropTypes.func.isRequired,
	setModal: PropTypes.func.isRequired,
	toggleModal: PropTypes.func.isRequired,
	toggleShowRecommendationModal: PropTypes.func.isRequired,
	translate: PropTypes.func.isRequired,
	updateHeader: PropTypes.func.isRequired,
	users: PropTypes.object.isRequired,
	isRecommendFlow: PropTypes.bool
};

const mapDispatchToProps = dispatch => ({
	setModal: modal => dispatch(setModal(modal)),
	toggleModal: toggle => dispatch(toggleModal(toggle)),
	login: (user, callback) => dispatch(login(user, callback)),
	clearError: () => dispatch(clearError()),
	updateHeader: (useMyDinovaLogo, showMenu) => dispatch(updateHeader(useMyDinovaLogo, showMenu)),
	outsideEvent: event => dispatch(outsideEvent(event)),
	getProfile: () => dispatch(getProfile()),
	toggleShowRecommendationModal: () => dispatch(toggleShowModal(false))
});

const mapStateToProps = state => ({
	modal: state.modal,
	translate: getTranslate(state.locale),
	currentLanguage: getActiveLanguage(state.locale).code,
	users: state.users,
	config: state.config,
	general: state.general,
	isRecommendFlow: state.recommendationsModal.show
});

export default compose(
	requireAnon,
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(Login);
