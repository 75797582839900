const _ = require('lodash');
const commonConfig = require('./config.common');

// eslint-disable-next-line no-unused-vars
module.exports = function(options) {
	console.log('zuppler config');
	return _.merge({}, commonConfig(options), {
		// dev config here
		api_basePath_myDinova: 'https://dev.dinova.com/api-mydinova/v1',
		api_basePath_restaurant: 'https://dev.dinova.com/api-restaurant/v1/',
		api_basePath_zuppler: 'http://localhost:8080',
		zuppler_dataChannelPath: 'http://api.zuppler.com/v3/channels/conshymenus.json',
		cdn_basePath: 'https://dinova-images-dev.imgix.net',
		imageBaseUrl: 'https://dinova-images-dev.imgix.net',
		content_paths: [
			'https://s3.amazonaws.com/dinova-public-assets-dev/mydinova/mydinova_copy.json',
			'https://s3.amazonaws.com/dinova-public-assets-dev/mydinova/search_dinova_copy.json'
		], // translation strings
		web_basePath: 'https://web.dev.dinova.com/',
		currentEnv: 'dev',
		color: 'green',
		ga_key: 'UA-47151707-6',
		gtm_key: 'GTM-NWZCBVD', // -- from head (feature/merge-web)
		api_key: 'AIzaSyATqkHo5uKUghS2bcvTt0phbi2J8mM2xqI',
		header_logo_link: 'https://www.dinova.com/'
		// gtm_key: 'GTM-576P6RK' -- same as prod - from master
	});
};
