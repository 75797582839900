import { asyncComponent } from 'hoc';

const LazyDetailsAboutPage = asyncComponent(() => {
	return import(
		/* webpackChunkName: "DetailsAboutPage" */
		/* webpackMode: "lazy" */
		'./'
	).then(module => module.default);
});

export default LazyDetailsAboutPage;
