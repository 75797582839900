import React from 'react';
import { SubHeader } from 'components/molecules';

const links = [
	{
		to: '/mydinova',
		titleKey: 'common.home'
	},
	{
		to: '/history',
		titleKey: 'common.history'
	},
	{
		to: '/rewards',
		titleKey: 'common.redemption'
	},
	{
		to: '/recommendations',
		titleKey: 'Profile.recommendations.title'
	}
];

const DashboardHeader = () => {
	return <SubHeader titleKey={'Rewards.navHeaderTitle'} links={links} />;
};

DashboardHeader.defaultProps = {};

DashboardHeader.propTypes = {};

export default DashboardHeader;
