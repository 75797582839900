import React from 'react';
import PropTypes from 'prop-types';
import { Text, CTAItem } from 'components/atoms';
import { history } from 'utils/router';

const POSITIVE = 'positive',
	NEGATIVE = 'negative';

class RecommendationEndorsement extends React.Component {
	toggleModal = type => {
		const { isAuthed, showModal, restaurantId } = this.props;
		if (isAuthed) {
			showModal(true, type, restaurantId);
		} else {
			history.push(`/login?redirect=${window.location.pathname + window.location.hash}`);
			showModal(true, type, restaurantId);
		}
	};

	onClick = type => {
		const { fetchOnButtonClick, fetchRecommendationsOptions, restaurantId } = this.props;
		if (fetchOnButtonClick) {
			fetchRecommendationsOptions(restaurantId);
		}
		this.toggleModal(type);
	};

	sv = () => {
		const { translate } = this.props;
		return (
			<React.Fragment>
				<Text dataCy="recommend-question" size="xs">
					{translate('Recommendations.recommendQuestion')}
				</Text>
				<div className="flex justify-center">
					<CTAItem
						dataCy="recommend-pos"
						className="secondary"
						config={{ text: 'Yes', action: this.onClick.bind(this, POSITIVE) }}
					>
						{translate('common.yes')}
					</CTAItem>
					<CTAItem
						dataCy="recommend-neg"
						className="secondary"
						config={{ text: 'No', action: this.onClick.bind(this, NEGATIVE) }}
					>
						{translate('common.no')}
					</CTAItem>
				</div>
			</React.Fragment>
		);
	};

	mv = () => {
		const { translate } = this.props;
		return (
			<div className="flex justify-center align-center">
				<Text dataCy="recommend-question" size="xs">
					{translate('Recommendations.recommendQuestion')}
				</Text>
				<CTAItem
					dataCy="recommend-pos"
					className="secondary"
					config={{ text: 'Yes', action: this.onClick.bind(this, POSITIVE) }}
				>
					{translate('common.yes')}
				</CTAItem>
				<CTAItem
					dataCy="recommend-neg"
					className="secondary"
					config={{ text: 'No', action: this.onClick.bind(this, NEGATIVE) }}
				>
					{translate('common.no')}
				</CTAItem>
			</div>
		);
	};

	render() {
		const { isSV } = this.props;

		return (
			<div className="endorse">
				{isSV && this.sv()}
				{!isSV && this.mv()}
			</div>
		);
	}
}

RecommendationEndorsement.defaultProps = {
	fetchOnButtonClick: false
};

RecommendationEndorsement.propTypes = {
	fetchOnButtonClick: PropTypes.bool.isRequired,
	fetchRecommendationsOptions: PropTypes.func, // required if fetchOnButtonClick
	restaurantId: PropTypes.string,
	isAuthed: PropTypes.bool.isRequired,
	showModal: PropTypes.func.isRequired,
	translate: PropTypes.func.isRequired,
	isSV: PropTypes.bool.isRequired
};

export default RecommendationEndorsement;
