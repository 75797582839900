import CateringUnavailableModal from './ui-component';
import { connect } from 'react-redux';
import withSizes from 'react-sizes';
import { isSV } from 'utils/sizes';
import { generateLinksFromState } from 'utils/catering';
import { setShowNoServiceNotice } from 'actions/catering';
import { getZones } from 'actions/catering';
import { getTranslate } from 'react-localize-redux';
import { hasProp } from 'utils/object';
import { setBusinessSearchNear } from 'actions/new_business_search_filter';

const mapStateToProps = state => ({
	address: state.catering.address.formatted_address,
	links: generateLinksFromState(state).links,
	translate: getTranslate(state.locale),
	location: {
		lat: hasProp(state, 'catering.address.geometry.location.lat')
			? state.catering.address.geometry.location.lat()
			: 0,
		lng: hasProp(state, 'catering.address.geometry.location.lng')
			? state.catering.address.geometry.location.lng()
			: 0
	}
});

const mapDispatchToProps = dispatch => ({
	getZones: () => getZones(null, dispatch), // null to get all zones
	setNearBusinessSearch: near => dispatch(setBusinessSearchNear(near)),
	setShowNoServiceNotice: shouldShow => dispatch(setShowNoServiceNotice(shouldShow))
});

const mapSizesToProps = sizes => ({
	isSV: isSV(sizes)
});

export default withSizes(mapSizesToProps)(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(CateringUnavailableModal)
);
