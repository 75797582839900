import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Row } from 'third-party/mdbreact/dist/mdbreact.js';
import { Col } from 'reactstrap';
import { IconError, IconWarning } from 'components/atoms';

const PageError = props => {
	const { className, type, message } = props;
	const classProps = classNames('page-error align-items-center justify-content-center', className);

	return (
		<Row className={classProps}>
			<Col className="error-box">
				<div className="error-icon">
					{type === 'warning' && <IconWarning width="30" height="30" fill="#e10600" />}
					{type !== 'warning' && <IconError width="30" height="30" fill="#e10600" />}
				</div>
				<div className="error-text">
					<p className="message">{message}</p>
				</div>
			</Col>
		</Row>
	);
};

PageError.defaultProps = {
	className: ''
};

PageError.propTypes = {
	className: PropTypes.string,
	type: PropTypes.string,
	message: PropTypes.string
};

export default PageError;
