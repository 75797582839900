import React from 'react';
import PropTypes from 'prop-types';
import { OptionsList } from 'components/organisms';
import { connect } from 'react-redux';

/**
 * List of available dietary preferences.  Used in {@link DetailsPage}.
 * @param {DietaryPreferencesProps} props
 */
const DietaryPreferences = ({ dietaryPrefs }) => {
	return dietaryPrefs.length ? <OptionsList options={dietaryPrefs} /> : null;
};

DietaryPreferences.defaultProps = {
	dietaryPrefs: []
};

/**
 * {@link DietaryPreferences} Props
 * @interface Props_DietaryPreferences
 * @property {string[]} [dietaryPrefs=[]]
 */
DietaryPreferences.propTypes = {
	// Optional
	dietaryPrefs: PropTypes.array
};

const mapStateToProps = state => ({
	dietaryPrefs: state.details.dietaryPrefs
});

export default connect(mapStateToProps)(DietaryPreferences);
