import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Slider from 'react-slick';
import { range } from 'lodash';

class Recommendations extends Component {
	render() {
		const { className, IMAGE_BASE_URL, translate, isSV } = this.props;
		const classProps = classNames('recommendations-slider', className);

		const count =
			parseInt(
				translate('Home.recommendations.count', null, {
					showMissingTranslationMsg: false
				})
			) || 0;

		let slidesCount = isSV ? (count > 1 ? 1.5 : 1) : count < 3 ? count : 3;

		let settings = {
			dots: !isSV,
			infinite: false,
			speed: 500,
			slidesToShow: slidesCount,
			slidesToScroll: slidesCount,
			className: 'slides',
			key: 0,
			arrows: !isSV
		};

		return (
			<div className={classProps}>
				<span className="title">{translate('Home.recommendations.title')}</span>
				<Slider {...settings}>
					{range(count).map((number, index) => {
						const img =
							IMAGE_BASE_URL +
							translate(`Home.recommendations.items.${number + 1}.img`, null, {
								showMissingTranslationMsg: false
							});
						const title = translate(`Home.recommendations.items.${number + 1}.title`, null, {
							showMissingTranslationMsg: false
						});
						const link = translate(`Home.recommendations.items.${number + 1}.url`, null, {
							showMissingTranslationMsg: false
						});

						return (
							<a key={index} className="slide" href={link} target="_blank" rel="noopener noreferrer">
								<img src={img} className="img" />
								<span className="legend">{title}</span>
							</a>
						);
					})}
				</Slider>
			</div>
		);
	}
}

Recommendations.defaultProps = {
	className: ''
};

Recommendations.propTypes = {
	className: PropTypes.string,
	translate: PropTypes.func,
	IMAGE_BASE_URL: PropTypes.string,
	isSV: PropTypes.bool
};

export default Recommendations;
