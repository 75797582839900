import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Text } from 'components/atoms';
import { CheckButton } from 'components/atoms';
import { camelCase, isEqual, startCase } from 'lodash';
import { isIE } from 'utils/browser';

class SearchFilter extends Component {
	constructor(props) {
		super(props);

		const tabState = this.generateTabs(props).reduce(
			(accum, tab) => {
				this[`${camelCase(tab.name)}Tab`] = React.createRef();
				this[`${camelCase(tab.name)}Filter`] = React.createRef();
				return {
					...accum,
					top: {
						...accum.top,
						[`${camelCase(tab.name)}Tab`]: 0
					},
					left: {
						...accum.left,
						[`${camelCase(tab.name)}Filter`]: 0
					},
					carets: {
						...accum.carets,
						[`${camelCase(tab.name)}Carets`]: 0
					}
				};
			},
			{ top: {}, left: {}, carets: {} }
		);

		this.state = {
			activeTab: props.isSV ? 'cuisine' : '',
			tempFilters: props.filters,
			tops: tabState.top,
			lefts: tabState.left,
			carets: tabState.carets
		};
	}

	componentDidMount = () => {
		this.setPosition();
		window.addEventListener('resize', this.setPosition, false);
		window.addEventListener('click', this.handleClick, false);
	};

	componentDidUpdate = prevProps => {
		this.screenResizeSetActiveTab(prevProps);
		this.setPosition();
	};

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.setPosition, false);
		window.removeEventListener('click', this.handleClick, false);
	};

	handleClick = e => {
		if (!this.props.isSV) {
			let refs = ['cuisine', 'amenities', 'dietaryPreferences', 'recommendations'];

			let isOutsideClick = [];

			refs.forEach(f => {
				let filter = this[`${f}Filter`];
				let tab = this[`${f}Tab`];
				if (filter && filter.current !== null && tab.current !== null) {
					let isFilterClick = filter.contains(e.target);
					let isTabClick = tab.contains(e.target);
					isOutsideClick.push(!isFilterClick && !isTabClick);
				} else {
					isOutsideClick.push(true);
				}
			});

			if (isOutsideClick.indexOf(false) === -1 && this.state.activeTab !== '') {
				this.setState({ activeTab: '' });
			}
		}
	};

	screenResizeSetActiveTab = prevProps => {
		const { isSV } = this.props,
			sizeChange = prevProps.isSV !== isSV,
			sizedUp = prevProps.isSV && this.props.isMV,
			hasActiveTab = this.state.activeTab !== '';

		if (sizeChange && isSV && hasActiveTab) {
			this.setState({ activeTab: this.state.activeTab });
		} else if (sizedUp && hasActiveTab) {
			this.setState({ activeTab: '' });
		}
	};

	setPosition = () => {
		this.setTabTops();
		this.setFilterLefts();
		this.setCaretLefts();
	};

	setTabTops = () => {
		let tops = {};
		Object.keys(this.state.tops).forEach(key => {
			const ref = this[key];
			if (ref) {
				const top = ref.offsetHeight + ref.offsetTop;
				tops[key] = top;
			}
		});
		if (Object.keys(tops).length && !isEqual(tops, this.state.tops)) {
			this.setState({ tops });
		}
	};

	setFilterLefts = () => {
		let lefts = {};
		// let makeChange = false;
		Object.keys(this.state.lefts).forEach(key => {
			const ref = this[key.replace('Filter', 'Tab')];
			if (ref) {
				let tabLeft = ref.offsetLeft;

				if (key === 'recommendationsFilter' && !isIE()) {
					const tabWidth = ref.clientWidth,
						filterRef = this[key];
					let filterWidth = filterRef ? filterRef.clientWidth : 100;
					tabLeft = tabLeft + tabWidth - filterWidth + 2;
				}
				lefts[key] = tabLeft;
			}
		});

		if (Object.keys(lefts).length && !isEqual(lefts, this.state.lefts)) {
			this.setState({ lefts });
		}
	};

	setCaretLefts = () => {
		let carets = {};
		Object.keys(this.state.carets).forEach(key => {
			const ref = this[key.replace('Carets', 'Tab')];
			if (ref) {
				let width = ref.clientWidth;
				if (key === 'recommendationsCarets' && !isIE()) {
					carets[key] = `calc(100% - ${width}px / 2 - 8px)`;
				} else {
					carets[key] = width / 2 - 8 + 'px'; // 8 = half of caret width
				}
			}
		});
		if (Object.keys(carets).length && !isEqual(carets, this.state.carets)) {
			this.setState({ carets });
		}
	};

	setActiveTab = tab => {
		if (this.props.isSV) {
			this.setState({ activeTab: camelCase(tab) });
		} else {
			if (camelCase(tab) === this.state.activeTab) {
				this.setState({ activeTab: '' });
			} else {
				this.setState({ activeTab: camelCase(tab) });
			}
		}
	};

	toggleFilter = async filter => {
		if (this.props.useTempFilters) {
			this.toggleTempFilter(filter);
		} else {
			filter = { ...filter, selected: !filter.selected };

			if (filter.selected) {
				await this.props.addFilter(filter);
			} else {
				await this.props.removeFilter(filter);
			}

			if (this.props.onFilterUpdate) {
				this.props.onFilterUpdate(filter);
			}
		}
	};

	buildFilterTabPanels = () => {
		let tabData = this.buildFilterComps();
		return Object.entries(tabData).map(([key, data]) => {
			const isActive = this.state.activeTab === key;
			const classProps = classnames('filter-tab-panel', isActive && 'active');

			return (
				<div className={classProps} key={`tab-panel-${key}`}>
					{data}
				</div>
			);
		});
	};

	buildFilterComps = () => {
		return Object.entries(this.props.filters).reduce((accum, [key, value]) => {
			if (Object.keys(value).length === 1 && Object.keys(value)[0] === 'activeCount') {
				return accum;
			}

			if (!accum[key]) {
				accum[key] = [];
			}

			let filters = Object.entries(value)
				.sort((a, b) => {
					return a[0] < b[0] ? -1 : a[0] > b[0] ? 1 : 0;
				})
				.reduce((childAccum, [cKey, cValue]) => {
					if (cKey === 'activeCount') {
						return childAccum;
					}
					const label = `${startCase(cKey)} (${cValue.resultsCount})`;
					childAccum.push(
						<CheckButton
							id={`${key}-${cKey}`}
							label={label}
							checked={cValue.selected}
							onClick={this.toggleFilter.bind(this, cValue)}
							small
						/>
					);
					return childAccum;
				}, []);

			return {
				...accum,
				[key]: [...accum[key], ...filters]
			};
		}, {});
	};

	buildFloatingFilter = (filters, key) => {
		if (Object.keys(filters).length === 1 && Object.keys(filters)[0] === 'activeCount') {
			return null;
		} else {
			const classProps = classnames('floating-filter', key);
			const caretLeft = this.state.carets[`${camelCase(key)}Carets`];

			return (
				<div
					className={classProps}
					style={{
						top: this.state.tops[`${camelCase(key)}Tab`],
						left: this.state.lefts[`${camelCase(key)}Filter`]
					}}
					ref={c => (this[`${camelCase(key)}Filter`] = c)}
				>
					<div className="caret" style={{ left: caretLeft }} />
					{Object.entries(filters)
						.sort((a, b) => {
							return a[0] < b[0] ? -1 : a[0] > b[0] ? 1 : 0;
						})
						.reduce((childAccum, [cKey, cValue]) => {
							if (cKey === 'activeCount' || cValue.resultCount === 0) {
								return childAccum;
							}
							const label = `${startCase(cKey)} (${cValue.resultsCount || 0})`;
							childAccum.push(
								<CheckButton
									id={`${key}-${cKey}`}
									label={label}
									checked={cValue.selected}
									onClick={this.toggleFilter.bind(this, cValue)}
									small
								/>
							);
							return childAccum;
						}, [])}
				</div>
			);
		}
	};

	desktopHeader = () => {
		const tabs = this.generateTabs();
		//.filter(tab => Object.keys(this.props.filters[camelCase(tab.name)]).length > 1);

		return (
			<div className="filter-header">
				<Text size="sm" weight="bold">
					Filter by:
				</Text>
				<div className="filter-tabs">
					{tabs.map(tab => {
						const tabTitle = `${tab.name}${tab.activeCount > 0 ? ` (${tab.activeCount})` : ''}`,
							isActive = this.state.activeTab === camelCase(tab.name);

						let isDisabled = Object.keys(this.props.filters[camelCase(tab.name)]).length === 1;
						// if filterType has selected filters but the selections result in 0 results, disable the filter tab so we don't see (0) results.
						let allZero = true;
						Object.entries(this.props.filters[camelCase(tab.name)]).forEach(([key, value]) => {
							if (key !== 'activeCount' && allZero) {
								allZero = value.resultCount === 0;
							}
						});
						if (allZero) isDisabled = true;
						//////////////

						const classProps = classnames('filter-tab', isActive && 'active', isDisabled && 'disabled');

						let filters = this.buildFloatingFilter(
							this.props.filters[camelCase(tab.name)],
							camelCase(tab.name)
						);

						return (
							<div
								ref={c => (this[`${camelCase(tab.name)}Tab`] = c)}
								className={classProps}
								key={tab.name}
								onClick={this.setActiveTab.bind(this, tab.name)}
								data-cy={tab.name}
							>
								<Text size="sm">{tabTitle}</Text>
								{filters}
							</div>
						);
					})}
				</div>
			</div>
		);
	};

	mobileHeader = () => {
		const tabs = this.generateTabs();

		return (
			<div className="filter-header">
				<Text size="md" weight="bold">
					FILTERS
				</Text>
				<div className="filter-tabs">
					{tabs.map(tab => {
						const tabTitle = `${tab.name}${tab.activeCount > 0 ? ` (${tab.activeCount})` : ''}`,
							isActive = this.state.activeTab === camelCase(tab.name),
							classProps = classnames('filter-tab', isActive && 'active');

						return (
							<div
								data-cy={tab.name}
								className={classProps}
								key={tab.name}
								onClick={this.setActiveTab.bind(this, tab.name)}
							>
								<Text size="sm">{tabTitle}</Text>
							</div>
						);
					})}
				</div>
			</div>
		);
	};

	generateTabs = props => {
		props = props || this.props;
		const tabs = Object.entries(props.filters).reduce((accum, [key, value]) => {
			return [...accum, { name: startCase(key), activeCount: value.activeCount }];
		}, []);

		return [tabs[1], tabs[2], tabs[0], tabs[3]]; // order tabs in "Cuisine", "Dietary Prefs", "Amenities", "Recommendations"
	};

	render() {
		const { isSV } = this.props;
		const classProps = classnames('filter-wrapper', this.props.className);

		return this.props.hasFilters ? (
			<div className={classProps}>
				{isSV ? this.mobileHeader() : this.desktopHeader()}
				{isSV && this.buildFilterTabPanels()}
			</div>
		) : null;
	}
}

SearchFilter.defaultProps = {};

SearchFilter.propTypes = {
	addFilter: PropTypes.func.isRequired,
	onFilterUpdate: PropTypes.func,
	useTempFilters: PropTypes.bool,
	applyTempFilters: PropTypes.func,
	cancelTempFilters: PropTypes.func,
	className: PropTypes.string,
	filters: PropTypes.shape({
		amenities: PropTypes.object,
		cuisine: PropTypes.object,
		dietaryPreferences: PropTypes.object
	}),
	hasFilters: PropTypes.bool.isRequired,
	isSV: PropTypes.bool.isRequired,
	isMV: PropTypes.bool.isRequired,
	removeFilter: PropTypes.func.isRequired
};

export default SearchFilter;
