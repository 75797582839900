/**
 * LogoImageSizes - an object full of {@link Size} objects.
 */
export const logoImageSizes = {
	mobile: {
		tallRect: {
			height: '120px',
			width: '50px',
			whiteSpace: '10px'
		},
		wideRect: {
			height: '50px',
			width: '120px',
			whiteSpace: '10px'
		},
		square: {
			height: '120px',
			width: '120px',
			whiteSpace: '10px'
		}
	},
	tablet: {
		tallRect: {
			height: '150px',
			width: '63px',
			whiteSpace: '15px'
		},
		wideRect: {
			height: '63px',
			width: '150px',
			whiteSpace: '15px'
		},
		square: {
			height: '150px',
			width: '150px',
			whiteSpace: '15px'
		}
	},
	desktop: {
		tallRect: {
			height: '180px',
			width: '75px',
			whiteSpace: '18px'
		},
		wideRect: {
			height: '75px',
			width: '180px',
			whiteSpace: '18px'
		},
		square: {
			height: '180px',
			width: '180px',
			whiteSpace: '18px'
		}
	}
};
