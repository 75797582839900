import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { handleNavLinkClick } from 'utils/router';

const NavItem = ({ children, disabled, hidden, item, isActive, key }) => {
	if (hidden) {
		return null;
	}

	const wrapperClass = classNames('nav-item', disabled && 'disabled');

	const className = classNames('nav-link', isActive && 'active', item.upperCase === false && 'normal-text'),
		title = (isActive ? '>' : '') + (item.mobileTitle || item.title);

	return (
		<li className={wrapperClass}>
			{item.fullUrl === true ? (
				<a href={item.url} className={className} key={key} onClick={handleNavLinkClick.bind(this, item.url)}>
					{title}
				</a>
			) : (
				<Link to={item.url} className={className} key={key} onClick={handleNavLinkClick.bind(this, item.url)}>
					{title}
				</Link>
			)}
			{children}
		</li>
	);
};

NavItem.defaultProps = {
	hidden: false,
	item: {
		fullUrl: false,
		mobileTitle: null,
		title: 'Title',
		url: '/',
		upperCase: false
	},
	isActive: false
};

NavItem.propTypes = {
	// Required
	key: PropTypes.string,

	// Optional
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	disabled: PropTypes.bool,
	hidden: PropTypes.bool,
	item: PropTypes.object,
	isActive: PropTypes.bool
};

export default NavItem;
