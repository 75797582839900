import React from 'react';
import PropTypes from 'prop-types';
import { IconNavItem } from 'components/atoms';
import { isNavItemActive } from 'utils/router';

const RightIconNav = ({ config, menuItems, printIt }) => (
	<div className="Di_HeaderIconMenu">
		{menuItems.map((item, index) => {
			const isActive = isNavItemActive(item);

			let iconImg = config.cdn_basePath + (isActive ? item.activeIcon : item.icon),
				hidden = !printIt(item);

			return <IconNavItem icon={iconImg} hidden={hidden} item={item} isActive={isActive} key={index} />;
		})}
	</div>
);

RightIconNav.defaultProps = {
	menuItems: []
};

RightIconNav.propTypes = {
	// Required
	config: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	printIt: PropTypes.func.isRequired,
	// Optional
	menuItems: PropTypes.array
};

export default RightIconNav;
