import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import WithInput from './withInput';
import { InputLabel } from 'components/atoms';
import { InputError, InputFieldFocusBorder } from 'components/molecules';

// import './styles/main.scss';

/**
 * Common input component for text, textarea, email, and password.
 * @extends WithInput
 * @param {InputProps} props
 */
class Input extends WithInput {
	constructor(props) {
		super(props);
		this.input = React.createRef();
	}

	render() {
		const {
			className,
			error,
			dataCy,
			hasError,
			id,
			label,
			name,
			onChange,
			onKeyDown,
			required,
			value
		} = this.props;

		let type = this.props.type;
		let inputType = type;
		if (type === 'textarea') {
			type = 'text';
		}

		const classProps = classnames(
			type,
			type !== inputType && inputType,
			(type === 'password' || type === 'email' || type === 'typeahead') && 'text',
			(type === 'typeahead') & 'select',
			hasError && 'error',
			required && 'required',
			this.state.hasFocus && 'focused',
			value.length && 'has-value',
			className
		);

		const containerClassProps = classnames('input-container', classProps);

		const inputClassProps = classnames('input', classProps);

		return (
			<div data-cy={`${dataCy}-input-wrapper`} className={containerClassProps}>
				<InputFieldFocusBorder labelText={label} dataCy={dataCy} />
				{label && <InputLabel dataCy={dataCy} text={label} hasError={hasError} type={type} htmlFor={id} />}
				{inputType === 'textarea' ? (
					<textarea
						ref={this.input}
						data-cy={dataCy}
						className={inputClassProps}
						id={id}
						name={name}
						onBlur={this.onBlur}
						onChange={onChange}
						onFocus={this.onFocus}
						onKeyDown={onKeyDown}
						value={value}
					/>
				) : (
					<input
						ref={this.input}
						data-cy={dataCy}
						className={inputClassProps}
						id={id}
						name={name}
						onBlur={this.onBlur}
						onChange={onChange}
						onFocus={this.onFocus}
						onKeyDown={onKeyDown}
						type={type}
						autoComplete={type === 'password' ? 'new-password' : 'false'}
						value={value}
					/>
				)}
				{hasError && <InputError text={error} dataCy={`${dataCy}-error`} />}
			</div>
		);
	}
}

Input.defaultProps = {
	className: '',
	error: null,
	hasError: false,
	iconBaseURL: '',
	id: null,
	name: 'input',
	label: 'Label',
	onBlur: () => {},
	onChange: () => {},
	onFocus: () => {},
	onKeyDown: () => {},
	placeholder: 'First Name',
	required: false,
	type: 'text',
	value: ''
};

/**
 * Input Props
 * @interface Input_Props
 * @property {string}   className     Additional classname to add to the input wrapper.
 * @property {string}   error         The error message text.
 * @property {boolean}  hasError      If this input has an error.
 * @property {string}   id            The id for this input.
 * @property {string}   name          The name for this input.
 * @property {string}   label         The text to show in the label for this input.
 * @property {function} onBlur        Input blur handler.
 * @property {function} onChange      Input change handler.
 * @property {function} onFocus       Input focus handler.
 * @property {function} onKeyDown     Input keydown handler.
 * @property {boolean}  required      If this input is required.
 * @property {string}   type          The type of input.  Can be 'text', 'email', 'password', 'textarea'.
 * @property {string}   value         The current value of the input.
 */
Input.propTypes = {
	className: PropTypes.string,
	error: PropTypes.string,
	hasError: PropTypes.bool,
	id: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	onKeyDown: PropTypes.func,
	required: PropTypes.bool,
	type: PropTypes.string,
	value: PropTypes.string
};

const mapStateToProps = state => ({
	iconBaseURL: state.config.cdn_basePath + '/icons'
});

export default connect(mapStateToProps)(Input);
