import Promise from 'bluebird';
import { chain, filter, take } from 'lodash';

export const LOCAL_STORAGE_RECENT_SEARCH_KEY = '-recentSearches';
export const loadRecentSearches = (hasGeolocation, recent = 'all', localStorageKey) => {
	// load recent searches from localStorage
	let recentSearches =
		JSON.parse(window.localStorage.getItem(`${localStorageKey}${LOCAL_STORAGE_RECENT_SEARCH_KEY}`)) || [];

	// filter by recent
	if (recent !== 'all') {
		recentSearches = filter(recentSearches, item => {
			return item[recent] && item[recent].length > 0;
		});
	}

	recentSearches = chain(recentSearches)
		.reverse()
		.uniqBy(recent === 'all' ? 'message' : recent)
		.value();

	// get last 5 and filter it if not allow geolocation
	recentSearches = hasGeolocation
		? take(recentSearches, 5)
		: chain(recentSearches)
				.filter(item => {
					if (recent === 'near') {
						return item.near !== '';
					} else {
						return true;
					}
				})
				.take(5)
				.value();

	// reverse list
	return recentSearches;
};

export const getPosition = options => {
	return new Promise((resolve, reject) => {
		let positionOption = {};

		// only for IE11 and greater
		if (/Trident\/7.0/.test(window.navigator.userAgent)) {
			positionOption = {
				enableHighAccuracy: false,
				maximumAge: 50000,
				...positionOption
			};
		}

		positionOption = { ...positionOption, ...options };

		// original
		navigator.geolocation.getCurrentPosition(resolve, reject, positionOption);

		// uncomment this to simulation of the current location
		// resolve({ coords: { latitude: 34.029613, longitude: -84.238417 } });
	});
};
