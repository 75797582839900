import React from 'react';
import PropTypes from 'prop-types';

const TransactionListData = ({ transaction, translate }) => (
	<div>
		{translate('Transactions.listData.title')}: {transaction.description}
	</div>
);

TransactionListData.propTypes = {
	transaction: PropTypes.object,
	translate: PropTypes.func.isRequired
};

export default TransactionListData;
