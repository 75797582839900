import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Text } from 'components/atoms';

const NoResultsNotice = ({ className, translate }) => {
	const classProps = classnames('no-results', className);
	return (
		<div className={classProps}>
			<Text className="green-text" size="sm" align="center" dataCy="no-results-title">
				{translate('Search.resultMsgs.noResultsNotice')}
			</Text>
			<Text size="xs" align="center" dataCy="no-results-help">
				{translate('Search.resultMsgs.noResultsHelp')}
			</Text>
		</div>
	);
};

NoResultsNotice.defaultProps = {};

NoResultsNotice.propTypes = {
	className: PropTypes.string,
	translate: PropTypes.func.isRequired
};

export default NoResultsNotice;
