import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { EditProfileActions, RequiredFieldNote } from 'components/molecules';
import { InputField } from 'components/organisms';
import ProfileSectionList from 'components/organisms/ProfileSectionList';

class EditPaymentForm extends Component {
	constructor(props) {
		super(props);

		this.handleFocus = this.handleFocus.bind(this);
	}

	render() {
		const {
			className,
			cards,
			onChange,
			translate,
			onUpdate,
			onCancel,
			cardType,
			employeeId,
			employeeIdValid,
			companyName,
			email,
			connectionType,
			onDelete
		} = this.props;
		const classProps = classNames('edit-payment-form', className);
		const isEin = cardType === 'ein';

		let list = [];

		// only for legacy
		if (companyName) {
			list.push({
				key: 'company',
				value: companyName
			});
		}

		// only for email connection type
		if (connectionType === 3) {
			list.push({
				key: 'email',
				value: email
			});
		}

		return (
			<div className={classProps}>
				<RequiredFieldNote />
				<ProfileSectionList section="payment" list={list} className="mb-4" />

				{connectionType === 2 && (
					<InputField
						label={translate(`Profile.payment.labels.employeeId`)}
						name="employeeIdField"
						id="employee-id-field"
						hasError={!employeeIdValid}
						error={translate('EditPayment.errors.employeeId')}
						value={employeeId}
						onChange={e => onChange(e, 'employeeId')}
					/>
				)}

				{cards.map((item, index) => {
					let label = translate(`Profile.payment.labels.${cardType}Card`);
					if (isEin) {
						label += ' ' + translate(`EditPayment.last4`);
					}

					return (
						<InputField
							label={label}
							name={`cardField${index}`}
							id={`card-field-${index}`}
							hasError={!item.isValid}
							error={translate(`EditPayment.errors.${cardType}Card`)}
							value={item.value}
							onChange={e => onChange(e, 'card')}
							key={index}
						/>
					);
				})}
				<EditProfileActions onUpdate={onUpdate} onCancel={onCancel} />

				<div className="delete-payment-wrapper">
					<a className="delete-payment-btn" onClick={onDelete}>
						{translate('Profile.payment.deleteInfo')}
					</a>
				</div>
			</div>
		);
	}

	handleFocus(event) {
		event.target.select();
	}
}

EditPaymentForm.defaultProps = {
	className: '',
	cards: [],
	employeeId: ''
};

EditPaymentForm.propTypes = {
	className: PropTypes.string,
	onChange: PropTypes.func,
	translate: PropTypes.func,
	onUpdate: PropTypes.func,
	onCancel: PropTypes.func,
	cards: PropTypes.array,
	employeeId: PropTypes.string,
	cardType: PropTypes.oneOf([1, 2, 3]).isRequired,
	employeeIdValid: PropTypes.bool,
	preferredLabel: PropTypes.string,
	companyName: PropTypes.string,
	email: PropTypes.string,
	connectionType: PropTypes.number,
	onDelete: PropTypes.func.isRequired
};

export default EditPaymentForm;
