import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Button } from 'reactstrap';
import { getTranslate } from 'react-localize-redux';
import withSizes from 'react-sizes';
import { cloneDeep, has, map } from 'lodash';
import onClickOutside from 'react-onclickoutside';
import { isSV } from 'utils/sizes';
import { CheckButton } from 'components/atoms';

const COLUMN_WIDTH = 205;
const LIST_PADDING = 30;
const LIST_BORDER = 2;
const COUNT_LIMIT = 999;

class MultiselectDropdownBtn extends Component {
	static defaultProps = {
		className: '',
		show: false,
		title: '',
		columns: 4
	};

	static propTypes = {
		name: PropTypes.string.isRequired,
		className: PropTypes.string,
		translate: PropTypes.func,
		items: PropTypes.arrayOf(PropTypes.object).isRequired,
		onSelect: PropTypes.func.isRequired,
		title: PropTypes.string,
		forwardRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
		show: PropTypes.bool,
		columns: PropTypes.number,
		onToggle: PropTypes.func.isRequired,
		dropdownStyle: PropTypes.object
	};

	render() {
		const { className, items, show, forwardRef, title, columns, name, dropdownStyle } = this.props;
		const isStatic = has(dropdownStyle, 'positionStatic') && dropdownStyle.positionStatic === true;
		const classProps = classNames(
			'multiselect-dropdown-btn',
			show && 'show',
			isStatic && 'positionStatic',
			className
		);
		const toggleBtnClass = classNames('dropdown-toggle-btn', show && 'active');
		const listWidth = has(dropdownStyle, 'width')
			? dropdownStyle.width
			: COLUMN_WIDTH * columns + LIST_PADDING + LIST_BORDER;
		const listLeft = has(dropdownStyle, 'left') ? dropdownStyle.left : 0;

		return items.length ? (
			<div className={classProps} ref={forwardRef}>
				<Button className={toggleBtnClass} onClick={this.handleToggle}>
					{title}
				</Button>
				<div className="dropdown-list" style={{ width: listWidth, left: listLeft }}>
					{map(items, (item, index) => {
						let label = (
							<span className="label-wrapper" key={index}>
								<span className="label-value text-truncate" title={item.value}>
									{item.value}
								</span>
								{item.count > 0 && (
									<span className="label-count text-truncate" title={item.count}>
										({item.count > COUNT_LIMIT ? `${COUNT_LIMIT}+` : item.count})
									</span>
								)}
							</span>
						);

						return (
							<CheckButton
								id={`${name}-option-${item.key}`}
								className="sub-option"
								onClick={() => this.handleSelect(index)}
								label={label}
								checked={item.selected}
								small
							/>
						);
					})}
				</div>
			</div>
		) : null;
	}

	handleToggle = () => {
		const { onToggle, name } = this.props;
		onToggle(name);
	};

	handleSelect = index => {
		const { onSelect, name, items } = this.props;
		let newItems = cloneDeep(items);
		newItems[index].selected = !newItems[index].selected;
		onSelect(name, name + 'Items', newItems, index);
	};

	handleClickOutside = () => {
		const { onToggle, name, show } = this.props;

		if (show) {
			onToggle(name);
		}
	};
}

const mapStateToProps = state => ({
	translate: getTranslate(state.locale),
	serviceType: state.catering.serviceType
});

const mapSizesToProps = sizes => ({
	isSV: isSV(sizes)
});

export default withSizes(mapSizesToProps)(connect(mapStateToProps)(onClickOutside(MultiselectDropdownBtn)));
