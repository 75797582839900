import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// import './style.scss';

const Link = ({ children, className, dataCy, onClick }) => {
	const classProps = classnames('link', className, !onClick && 'no-click');

	return (
		<div className={classProps} onClick={onClick} data-cy={dataCy}>
			{children}
		</div>
	);
};

Link.defaultProps = {};

Link.propTypes = {
	children: PropTypes.string,
	className: PropTypes.string,
	dataCy: PropTypes.string,
	onClick: PropTypes.func
};

export default Link;
