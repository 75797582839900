import React from 'react';
import PropTypes from 'prop-types';

/**
 * InputFieldFocusBorder - a purely visual component.  Creates a top border for input fields that has a gap left to fit the label text into when the input is focused.
 * @param {InputFieldFocusBorder_Props} props
 */
const InputFieldFocusBorder = ({ dataCy, labelText }) => (
	<div className="top-border-wrapper" data-cy={`${dataCy}-focus-border`}>
		<div className="focus-border front" />
		<div className="transparent">{labelText}</div>
		<div className="focus-border back" />
	</div>
);

InputFieldFocusBorder.defaultProps = {
	labelText: 'Label'
};

/**
 * InputFieldFocusBorder Props
 * @interface InputFieldFocusBorder_Props
 * @property {string} labelText The text to use to create white space for the input label when the input is focused.
 */
InputFieldFocusBorder.propTypes = {
	dataCy: PropTypes.string,
	labelText: PropTypes.string
};

export default InputFieldFocusBorder;
