import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { TabContent, TabPane } from 'reactstrap';
import { capitalize, cloneDeep, map } from 'lodash';

import { FILTER_TYPES } from 'components/molecules/FilterTabs';
import { CheckButton } from 'components/atoms';

const COUNT_LIMIT = 999;

class FilterTabContent extends Component {
	componentDidMount() {
		this.props.onCopyFilters(true, false);
	}

	render() {
		const { className, activeTab } = this.props;
		const classProps = classNames('filter-tab-content', className);

		return (
			<TabContent activeTab={activeTab} className={classProps}>
				{FILTER_TYPES.map((filterType, index) => {
					const currentItemsStr = 'tmp' + capitalize(filterType.name) + 'Items';

					return (
						<TabPane tabId={filterType.key} key={index}>
							{map(this.props[currentItemsStr], (item, itemIndex) => {
								const label = (
									<span className="label-wrapper" key={index}>
										<span className="label-value text-truncate" title={item.value}>
											{item.value}
										</span>
										{item.count > 0 && (
											<span className="label-count text-truncate" title={item.count}>
												({item.count > COUNT_LIMIT ? `${COUNT_LIMIT}+` : item.count})
											</span>
										)}
									</span>
								);

								return (
									<div className="sub-option-wrapper">
										<CheckButton
											id={filterType.name + '-sub-option-' + itemIndex}
											label={label}
											checked={item.selected}
											onClick={() => this.handleSelect(filterType.name, itemIndex)}
											small
										/>
									</div>
								);
							})}
						</TabPane>
					);
				})}
			</TabContent>
		);
	}

	handleSelect = (filterName, filterIndex) => {
		const { onFilter } = this.props;
		const filterPath = filterName + 'Items';
		let newItems = cloneDeep(this.props[filterPath]);
		newItems[filterIndex].selected = !newItems[filterIndex].selected;

		onFilter(filterName, filterPath, newItems, filterIndex);
	};
}

FilterTabContent.defaultProps = {
	className: ''
};

FilterTabContent.propTypes = {
	className: PropTypes.string,
	activeTab: PropTypes.string.isRequired,
	onFilter: PropTypes.func.isRequired,
	cuisineItems: PropTypes.arrayOf(PropTypes.object),
	dietaryItems: PropTypes.arrayOf(PropTypes.object),
	amenityItems: PropTypes.arrayOf(PropTypes.object),
	tmpCuisineItems: PropTypes.arrayOf(PropTypes.object),
	tmpDietaryItems: PropTypes.arrayOf(PropTypes.object),
	tmpAmenityItems: PropTypes.arrayOf(PropTypes.object),
	parentCallback: PropTypes.func.isRequired,
	onCopyFilters: PropTypes.func.isRequired
};

export default FilterTabContent;
