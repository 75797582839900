import React from 'react';
import { Button, Row } from 'third-party/mdbreact/dist/mdbreact.js';
import { Col } from 'reactstrap';
import metaTagConfig from './meta-tags';
import { MetaTags } from 'components/molecules';
import { Text } from 'components/atoms';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ReferSharing } from 'components/organisms';

export class ReferaCoworker extends React.Component {
	componentDidMount() {
		const me = this;
		if (this.state.isLoadingLink === false) {
			this.setState({ isLoadingReferralLink: true });
		}
		return this.props.getProfile().then(res => {
			this.props.setReferralLink(res.payload.urls.referral);
			me.setState({ isLoadingReferralLink: false });
		});
	}

	state = {
		copied: false,
		isLoadingReferralLink: true,
		message: this.props.translate('ReferACoWorker.copybutton_copy')
	};

	onClick = () => {
		this.props.trackEvent({ category: `${this.props.parent} - REFER-A-COWORKER PAGE`, action: 'Complete' });
		this.props.onClick();
	};

	render() {
		const { className, translate, link, showCompleteButton, parent } = this.props;
		const classProps = classNames('refer-a-coworker', className);
		const subTitle = translate('ReferACoWorker.auth-sub-title');

		return (
			<div className={classProps}>
				<MetaTags tags={metaTagConfig} />

				<Row>
					<Col>
						{subTitle.indexOf('Missing') === -1 && (
							<Text size="sm" className="refer-body-content" dataCy="refer-sub-titles" align="center">
								{translate('ReferACoWorker.auth-sub-title')}
							</Text>
						)}
						<Text size="sm" weight="thin" className="refer-body-content" dataCy="refer-body-content">
							{translate('ReferACoWorker.body')}
						</Text>
					</Col>
				</Row>
				<ReferSharing link={link} parent={parent} isLoadingLink={this.state.isLoadingReferralLink} />
				{showCompleteButton && (
					<Row className="buttons">
						<Col md="12" xs="12" className="d-flex align-items-center justify-content-center">
							<Button color="primary" className="z-depth-0" onClick={this.onClick}>
								{this.props.translate('ReferACoWorker.completebutton')}
							</Button>
						</Col>
					</Row>
				)}
			</div>
		);
	}
}

ReferaCoworker.propTypes = {
	className: PropTypes.string,
	translate: PropTypes.func,
	general: PropTypes.object,
	config: PropTypes.object,
	outsideEvent: PropTypes.func,
	onClick: PropTypes.func,
	trackEvent: PropTypes.func,
	link: PropTypes.string,
	parent: PropTypes.string.isRequired,
	showCompleteButton: PropTypes.bool,
	getProfile: PropTypes.func,
	setReferralLink: PropTypes.func
};

export default ReferaCoworker;
