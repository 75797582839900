import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Input } from 'third-party/mdbreact/dist/mdbreact.js';

const RadioButton = ({ checked, className, disabled, hasError, id, label, onClick }) => {
	const classProps = classNames('radio-btn', className);
	const inputClass = classNames(hasError && 'error');

	return (
		<Input
			gap
			onClick={onClick}
			checked={checked}
			label={label}
			type="radio"
			id={id}
			containerClass={classProps}
			className={inputClass}
			disabled={disabled}
		/>
	);
};

RadioButton.defaultProps = {
	checked: false,
	className: '',
	disabled: false,
	hasError: false,
	id: 'radio-button',
	label: 'Label',
	onClick: () => {}
};

RadioButton.propTypes = {
	checked: PropTypes.bool,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	hasError: PropTypes.bool, // hasError prop added to allow storybook to show error state UI
	id: PropTypes.string,
	label: PropTypes.string,
	onClick: PropTypes.func
};

export default RadioButton;
