const generateContactInfoConfig = (sectionName, imageBaseUrl, translate) => [
	{
		icon: `${imageBaseUrl}/icons/${translate(`HelpFAQ.${sectionName}.moreHelp.emailUsIcon`)}`,
		label: translate('common.emailUs'),
		title: '',
		url: translate(`HelpFAQ.${sectionName}.moreHelp.emailUsLink`)
	},
	{
		icon: `${imageBaseUrl}/icons/${translate(`HelpFAQ.${sectionName}.moreHelp.callUsIcon`)}`,
		label: translate('common.callUs'),
		title: translate(`HelpFAQ.${sectionName}.moreHelp.callUsTitle`),
		url: translate(`HelpFAQ.${sectionName}.moreHelp.callUsLink`)
	}
];

export default (imageBaseUrl, translate) => {
	return {
		business: generateContactInfoConfig('businessFAQ', imageBaseUrl, translate),
		catering: generateContactInfoConfig('cateringFAQ', imageBaseUrl, translate),
		myDinova: generateContactInfoConfig('myDinovaFAQ', imageBaseUrl, translate),
		privateDining: generateContactInfoConfig('privateDiningFAQ', imageBaseUrl, translate)
	};
};
