import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { InputGroup, InputGroupAddon, Input, Button } from 'reactstrap';
import { getTranslate } from 'react-localize-redux';
import withSizes from 'react-sizes';
import qs from 'query-string';
import { isNull, isFunction, includes } from 'lodash';
import { LOCAL_STORAGE_RECENT_SEARCH_KEY } from 'utils/search';
import { getLocation } from 'actions/geo_location';
import { setBusinessSearchFind, setBusinessSearchNear } from 'actions/new_business_search_filter';
import { setDarylsEasterEgg } from 'actions/easteregg';
import { placeAutocomplete } from 'actions/map';
import { history } from 'utils/router';
import SearchDropdown from 'components/molecules/SearchDropdown';
import { setActivePin, setMapCardDisplayed } from 'actions/search_page';
import { isLV, isSV, isMV } from 'utils/sizes';

const LOCAL_STORAGE_KEY = `business${LOCAL_STORAGE_RECENT_SEARCH_KEY}`;

class BusinessSearchTab extends Component {
	state = {
		findDropdownOpened: false,
		nearDropdownOpened: false,
		error: false
	};

	componentDidMount = () => {
		document.addEventListener('mousedown', this.handleClickOutside);
		document.addEventListener('keydown', this.enterPress, false);
	};

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
		document.removeEventListener('keydown', this.enterPress, false);
	}

	updateRecentSearches = () => {
		let newSearch = {
			keywords: this.props.findBusinessSearch,
			near: this.props.nearBusinessSearch
		};
		let currentSearches = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY)) || [];
		currentSearches.push(newSearch);
		window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(currentSearches));
	};

	render() {
		const {
			className,
			translate,
			findBusinessSearch,
			nearBusinessSearch,
			setNearBusinessSearch,
			setFindBusinessSearch,
			findPlaceholder,
			geoLocationDenied,
			nearPlaceholder
		} = this.props;
		const { findDropdownOpened, nearDropdownOpened } = this.state;
		const classProps = classNames('search-tab business-search-tab', className);

		// find field
		const findPlaceholderProp = findDropdownOpened
			? ''
			: findPlaceholder || translate('Home.businessSearch.findPlaceholder');
		const findField = (
			<div className="find-input-wrapper">
				<span className="input-label">{translate('Home.businessSearch.findLabel')}</span>
				<SearchDropdown
					show={findDropdownOpened}
					className="find-field"
					recent="keywords"
					recentCallback={data => {
						setFindBusinessSearch(data.keywords);
						this.toggleDropdown();
					}}
				>
					<Input
						name="find"
						/*key="dropdown-find-input"*/
						className="find-input"
						placeholder={findPlaceholderProp}
						value={findBusinessSearch || ''}
						onChange={this.onFindChange}
						autoComplete="off"
					/>
				</SearchDropdown>
			</div>
		);

		// near field
		const nearPlaceholderProp = nearDropdownOpened
			? ''
			: nearPlaceholder || translate('Home.businessSearch.nearPlaceholder');

		const nearField = (
			<div
				className={
					geoLocationDenied && !nearBusinessSearch ? 'near-input-wrapper hasError' : 'near-input-wrapper'
				}
			>
				<span className="input-label">{translate('Home.businessSearch.nearLabel')}</span>
				<SearchDropdown
					show={nearDropdownOpened}
					className="near-field"
					recent="near"
					nearBySearch={this.props.nearBySearch}
					onSearch={this.toggleDropdown}
					recentCallback={data => {
						setNearBusinessSearch(data.near);
						this.toggleDropdown();
					}}
				>
					<span
						/*key="dropdown-near-input-wrapper"*/
						className={geoLocationDenied && !nearBusinessSearch ? 'Di_HasError' : ''}
					>
						<Input
							name="near"
							className="near-input"
							placeholder={nearPlaceholderProp}
							value={nearBusinessSearch}
							onChange={this.onNearChange}
							autoComplete="off"
						/>
						{geoLocationDenied && !nearBusinessSearch ? (
							<span>{this.props.translate('ModifyModal.provideLocationError')}</span>
						) : null}
					</span>
				</SearchDropdown>
			</div>
		);

		const searchBtnClassProps = classNames(geoLocationDenied && !nearBusinessSearch && 'disabled', 'search-btn');

		return (
			<div className={classProps}>
				{/*this.state.error && <PageError message={this.state.error} />*/}
				<div ref={this.setSearchRef} key={0} className="search-wrapper">
					<InputGroup className="search-group">
						{[findField, nearField]}
						<InputGroupAddon addonType="append" className="search-btn-wrapper">
							<Button
								id="business-tab-search-btn"
								color="primary"
								className={searchBtnClassProps}
								onClick={this.onSearch.bind(this)}
							>
								{translate('Home.businessSearch.searchBtn')}
							</Button>
						</InputGroupAddon>
					</InputGroup>
				</div>
			</div>
		);
	}

	/**
	 * Set the search input group ref
	 */
	setSearchRef = node => {
		this.searchRef = node;
	};

	handleClickOutside = event => {
		if (this.props.isActive) {
			if (
				this.searchRef &&
				(!this.searchRef.contains(event.target) || includes(event.target.className, 'search-btn'))
			) {
				this.toggleDropdown();
			} else {
				if (event.target.name) {
					this.toggleDropdown(`${event.target.name}DropdownOpened`, true);
				}
			}
		}
	};

	onSearch = async () => {
		this.updateRecentSearches();
		let isEasterEgg = false;
		if (this.props.nearBusinessSearch.toLowerCase() === 'dohort') {
			isEasterEgg = true;
			this.props.setDarylsEasterEgg(true);
			this.props.setNearBusinessSearch('');
		} else {
			this.props.setDarylsEasterEgg(false);
		}

		if (this.props.onSearch) {
			this.props.onSearch(isEasterEgg);
		} else {
			const { onSearch, findBusinessSearch, nearBusinessSearch } = this.props;
			this.props.setActivePin(null);
			this.props.setMapCardDisplayed(false);

			let location = null,
				near = nearBusinessSearch,
				keywords = findBusinessSearch,
				suggestions = '';

			if (nearBusinessSearch) {
				if (isFunction(onSearch)) {
					let callbackData = {
						keywords,
						near,
						suggestions
					};
					// close all dropdowns
					this.toggleDropdown();
					// perform a search
					onSearch(callbackData);
				} else {
					const url = 'search?',
						find = encodeURIComponent(findBusinessSearch),
						near = encodeURIComponent(nearBusinessSearch),
						query = {
							location: undefined,
							near: near || undefined,
							keywords: find || undefined
						};

					let queryString = qs.stringify(query);
					history.push(`${url}${queryString}/#/`);
				}
			} else {
				if (this.props.hasGeoLocation) {
					location = this.props.geoLocation;
				} else if (!this.props.geoLocationDenied && !this.props.hasGeoLocation) {
					try {
						const geoLoc = await this.props.getLocation();
						location = {
							lat: geoLoc.coords.latitude,
							lng: geoLoc.coords.longitude
						};
					} catch (err) {
						this.showNoLocationWarning();
						return;
					}
				} else {
					this.showNoLocationWarning();
					return;
				}
				const url = 'search?',
					find = encodeURIComponent(findBusinessSearch),
					query = {
						location: `${location.lat},${location.lng}`,
						near: undefined,
						keywords: find || undefined
					};

				let queryString = qs.stringify(query);
				history.push(`${url}${queryString}/#/`);
			}
		}
	};

	showNoLocationWarning = () => {
		this.setState({ error: this.props.translate('Catering.errors.geocode-failed-permissions') });
	};

	toggleDropdown = (dropdown = null, show = false) => {
		const resetAll = () => {
			this.setState({
				findDropdownOpened: false,
				nearDropdownOpened: false
			});
		};

		if (isNull(dropdown)) {
			resetAll();
		} else {
			resetAll();
			this.setState({ [dropdown]: show });
		}
	};

	onFindChange = event => {
		const newValue = event.target.value;
		this.props.setFindBusinessSearch(newValue);
		this.props.placeAutocomplete(newValue);
	};

	onNearChange = event => {
		const newValue = event.target.value;
		this.props.setNearBusinessSearch(newValue);
		this.props.placeAutocomplete(newValue);
	};

	enterPress = event => {
		if (this.props.isActive) {
			if (event.key === 'Enter') {
				// do a search
				this.onSearch();
			}
		}
	};
}

BusinessSearchTab.defaultProps = {
	className: ''
};

BusinessSearchTab.propTypes = {
	geoLocation: PropTypes.shape({
		lat: PropTypes.number,
		lng: PropTypes.number
	}),
	setActivePin: PropTypes.func.isRequired,
	setMapCardDisplayed: PropTypes.func.isRequired,
	nearBySearch: PropTypes.func,
	hasGeoLocation: PropTypes.bool,
	geoLocationLoading: PropTypes.bool,
	geoLocationDenied: PropTypes.bool,
	getLocation: PropTypes.func.isRequired,
	className: PropTypes.string,
	findBusinessSearch: PropTypes.string,
	isActive: PropTypes.bool,
	isLV: PropTypes.bool,
	isSV: PropTypes.bool,
	nearBusinessSearch: PropTypes.string,
	onSearch: PropTypes.func, // search callback instead of history.push
	placeAutocomplete: PropTypes.func,
	setDarylsEasterEgg: PropTypes.func,
	setFindBusinessSearch: PropTypes.func,
	setNearBusinessSearch: PropTypes.func,
	translate: PropTypes.func,
	findPlaceholder: PropTypes.string,
	nearPlaceholder: PropTypes.string
};

const mapStateToProps = state => ({
	translate: getTranslate(state.locale),
	findBusinessSearch: state.businessSearchFilter.findSearch,
	nearBusinessSearch: state.businessSearchFilter.nearSearch,
	hasGeoLocation: state.geoLocation.hasGeoLocation,
	geoLocationLoading: state.geoLocation.isLoading,
	geoLocationDenied: state.geoLocation.wasDenied,
	geoLocation: { lat: state.geoLocation.lat, lng: state.geoLocation.lng }
});

const mapDispatchToProps = dispatch => ({
	getLocation: async () => await getLocation(dispatch),
	setActivePin: pin => dispatch(setActivePin(pin)),
	setDarylsEasterEgg: isActive => dispatch(setDarylsEasterEgg(isActive)),
	setFindBusinessSearch: find => dispatch(setBusinessSearchFind(find)),
	setMapCardDisplayed: isDisplayed => dispatch(setMapCardDisplayed(isDisplayed)),
	setNearBusinessSearch: near => dispatch(setBusinessSearchNear(near)),
	placeAutocomplete: value => dispatch(placeAutocomplete(value))
});

const mapSizesToProps = sizes => ({
	isLV: isLV(sizes),
	isSV: isSV(sizes),
	isMV: isMV(sizes)
});

export default withSizes(mapSizesToProps)(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(BusinessSearchTab)
);
