import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from '../helpers';

import {
	setLocationAutocompleteSearch,
	setLocationAutocompleteSuggestions,
	setLocationAutocompleteLoading,
	setLocationAutocompleteReady
} from 'actions/location_autocomplete';

class LocationAutocomplete extends React.Component {
	handleSelect = (address, placeId) => {
		// console.log('address dropdown:', address);
		this.props.setLocationAutocompleteSearch(address);
		this.clearSuggestions();
		if (this.props.onSelect) {
			this.props.onSelect(address, placeId);
		}
	};

	clearActive = () => {
		this.props.setLocationAutocompleteSuggestions(
			this.props.suggestions.map(suggestion => ({
				...suggestion,
				active: false
			}))
		);
	};

	clearSuggestions = () => {
		this.props.setLocationAutocompleteSuggestions([]);
	};

	getActiveSuggestionId = () => {
		const activeSuggestion = this.getActiveSuggestion();
		return activeSuggestion ? `${activeSuggestion.placeId}` : undefined;
	};

	getActiveSuggestion = () => {
		return this.props.suggestions.find(suggestion => suggestion.active);
	};

	selectActiveAtIndex = index => {
		this.setActiveAtIndex(index);
	};

	setActiveAtIndex = index => {
		this.props.setLocationAutocompleteSuggestions(
			this.props.suggestions.map((suggestion, idx) => {
				if (idx === index) {
					return { ...suggestion, active: true };
				} else {
					return { ...suggestion, active: false };
				}
			})
		);
	};

	getSuggestionItemProps = (suggestion, options = {}) => {
		const handleSuggestionMouseEnter = this.handleSuggestionMouseEnter.bind(this, suggestion.index);
		const handleSuggestionClick = this.handleSuggestionClick.bind(this, suggestion);

		return {
			...options,
			key: suggestion.id,
			id: suggestion.id,
			className: `${options.className || ''} option ${
				suggestion.placeId === this.getActiveSuggestionId() ? 'active' : ''
			}`.trim(),
			role: 'option',
			onMouseEnter: compose(
				handleSuggestionMouseEnter,
				options.onMouseEnter
			),
			onMouseLeave: compose(
				this.handleSuggestionMouseLeave,
				options.onMouseLeave
			),
			onMouseDown: compose(
				this.handleSuggestionMouseDown,
				options.onMouseDown
			),
			onMouseUp: compose(
				this.handleSuggestionMouseUp,
				options.onMouseUp
			),
			onTouchStart: compose(
				this.handleSuggestionTouchStart,
				options.onTouchStart
			),
			onTouchEnd: compose(
				this.handleSuggestionMouseUp,
				options.onTouchEnd
			),
			onClick: compose(
				handleSuggestionClick,
				options.onClick
			)
		};
	};

	handleSuggestionMouseEnter = index => {
		this.setActiveAtIndex(index);
	};

	handleSuggestionMouseLeave = () => {
		this.mousedownOnSuggestion = false;
		this.clearActive();
	};

	handleSuggestionMouseDown = event => {
		event.preventDefault();
		this.mousedownOnSuggestion = true;
	};

	handleSuggestionTouchStart = () => {
		this.mousedownOnSuggestion = true;
	};

	handleSuggestionMouseUp = () => {
		this.mousedownOnSuggestion = false;
	};

	handleSuggestionClick = (suggestion, event) => {
		if (event && event.preventDefault) {
			event.preventDefault();
		}
		const { description, placeId } = suggestion;
		this.handleSelect(description, placeId);
		setTimeout(() => {
			this.mousedownOnSuggestion = false;
		});
	};

	render() {
		return (
			<div className="autocomplete-dropdown">
				{this.props.suggestions.map((s, i) => {
					return (
						<div
							key={`autocomplete-item-${i}`}
							onClick={this.handleSelect.bind(this, s)}
							{...this.getSuggestionItemProps(s)}
						>
							{s.description}
						</div>
					);
				})}
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		...state.locationAutocomplete
	};
};

const mapDistpatchToProps = dispatch => {
	return {
		setLocationAutocompleteSearch: value => dispatch(setLocationAutocompleteSearch(value)),
		setLocationAutocompleteSuggestions: value => dispatch(setLocationAutocompleteSuggestions(value)),
		setLocationAutocompleteLoading: value => dispatch(setLocationAutocompleteLoading(value)),
		setLocationAutocompleteReady: value => dispatch(setLocationAutocompleteReady(value))
	};
};

LocationAutocomplete.propTypes = {
	onSelect: PropTypes.func,
	setLocationAutocompleteSearch: PropTypes.func.isRequired,
	setLocationAutocompleteSuggestions: PropTypes.func.isRequired,
	setLocationAutocompleteLoading: PropTypes.func.isRequired,
	setLocationAutocompleteReady: PropTypes.func.isRequired,
	address: PropTypes.string,
	loading: PropTypes.bool,
	suggestions: PropTypes.array,
	ready: PropTypes.bool,
	googleCallbackName: PropTypes.string // google api func name .
};

export default connect(
	mapStateToProps,
	mapDistpatchToProps
)(LocationAutocomplete);
