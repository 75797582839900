import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'components/atoms';
import withAccordion from 'components/molecules/Accordion/withAccordion';

/**
 * An accordion who's title is the FAQ question and body content is the FAQ answer.
 * @constructor
 * @param {FAQProps} props
 */
const FAQ = ({ text, title }) => {
	return withAccordion(
		<Text align="left" paragraph>
			{text}
		</Text>,
		{
			title: title,
			expanded: false,
			canCollapse: true,
			className: 'faq',
			striped: true
		}
	);
};

FAQ.defaultProps = {
	text: 'This is an FAQ',
	title: 'FAQ'
};

/**
 * FAQ Props
 * @interface FAQProps
 * @property {string} title The question of the FAQ. Will render in the header of the accordion.
 * @property {string} text The answer to the FAQ. Will render in the body of the accordion.
 */
FAQ.propTypes = {
	text: PropTypes.string,
	title: PropTypes.string
};

export default FAQ;
