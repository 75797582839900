import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { LoadingBase } from 'components/atoms';
import { RewardCard } from 'components/molecules';

const RewardsList = props => {
	const { className, isLoading, rewards, translate, userPoints } = props;
	const classProps = classNames('container rewards-list', className);

	return (
		<div className={classProps}>
			{isLoading && <LoadingBase message={translate('Rewards.loadingMsg')} />}
			<div className="row">
				{rewards &&
					!isLoading &&
					rewards.map((reward, index) => {
						return (
							<div className="col-sm-12 col-md-4 col-lg-3 reward-wrapper" key={index.toString()}>
								<RewardCard reward={reward} userPoints={userPoints} />
							</div>
						);
					})}
			</div>
		</div>
	);
};

RewardsList.defaultProps = {
	className: '',
	rewards: []
};

RewardsList.propTypes = {
	className: PropTypes.string,
	isLoading: PropTypes.bool.isRequired,
	rewards: PropTypes.arrayOf(PropTypes.object),
	translate: PropTypes.func.isRequired,
	userPoints: PropTypes.number.isRequired
};

export default RewardsList;
