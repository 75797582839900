import * as t from './types';
import Promise from 'bluebird';
import axios from 'axios';
import { basePath, basePath_restaurant, token } from 'actions/utils';

export const toggleShowModal = (show, type, restaurantId) => ({
	type: t.TOGGLE_SHOW_RECOMMENDATIONS_MODAL,
	payload: { show, type, restaurantId }
});

export const toggleThankYou = show => ({
	type: t.TOGGLE_SHOW_RECOMMENDATIONS_TY_MODAL,
	payload: show
});

export const applyRecommendation = ({ dataKey, groupKey, value, type }) => ({
	type: t.APPLY_RECOMMENDATION,
	payload: {
		dataKey,
		groupKey,
		value,
		type
	}
});

export const showRecommendationsModalPrefilled = (dispatch, type, recommendation) => {
	return new Promise(resolve => {
		dispatch({
			type: t.SHOW_RECOMMENDATIONS_MODAL_PREFILLED,
			payload: { recommendation, type }
		});
		resolve();
	});
};

export const fetchRecommendationsOptions = async (dispatch /*, restaurantId*/) => {
	/*eslint-disable no-async-promise-executor*/
	return new Promise(async (resolve, reject) => {
		/*eslint-enable no-async-promise-executor*/
		dispatch({ type: t.FETCH_RECOMMENDATIONS_OPTIONS_START });
		const res = await axios.get(`${basePath_restaurant()}recommendations/categories`, {
			headers: {
				Authorization: token(),
				'Content-type': 'application/json'
			}
		});

		if (res.status === 200) {
			dispatch({
				type: t.FETCH_RECOMMENDATIONS_OPTIONS_SUCCESS,
				payload: res.data.recommendationCategories
			});

			resolve(res.data.recommendationCategories);
		} else {
			dispatch({
				type: t.FETCH_RECOMMENDATIONS_OPTIONS_FAILURE,
				payload: 'There was an error fetching recommendation options'
			});

			reject('There was an error fetching recommendation options');
		}
	});
};

const transformRecommendation = ({ form, type }) => {
	let negativeFeedback = '';
	const recommendedFor = form.reduce((accum, group) => {
		group.categories.forEach(opt => {
			if (opt.id === 'desc') {
				negativeFeedback = opt.value;
			} else if (opt.selected) {
				accum.push(opt.id);
			}
		});
		return accum;
	}, []);

	let recommendations = {
		recommended: type === 'positive',
		for: recommendedFor
	};

	if (type === 'negative') {
		recommendations.feedback = negativeFeedback;
	}

	return recommendations;
};

export const submitRecommendation = async (dispatch, { form, restaurantId, type }) => {
	dispatch({ type: t.SUBMIT_RECOMMENDATION_START });
	return new Promise(resolve => {
		let recommendations = transformRecommendation({ form, type });
		// If Cypress, don't actually submit the recommendation to the API
		if (window.Cypress) {
			const submitStub = require('./test_stubs/submit_recommendation_stub').default;
			return submitStub(dispatch, { restaurantId, recommendations }, resolve);
		} else {
			axios
				.post(`${basePath()}/users/recommendations/${restaurantId}`, JSON.stringify(recommendations), {
					headers: {
						Authorization: token()
					},
					timeout: 15000
				})
				.then(() => {
					dispatch({
						type: t.SUBMIT_RECOMMENDATION_SUCCESS,
						payload: { restaurantId, recommendations }
					});
					if (window.location.pathname.indexOf('history') === -1) {
						// Fire event so Recommendation Component can now when to hide itself. - after submitting a recommendation, remove the "Would you leave a recommendation Yes No" from UI.
						const event = new CustomEvent('recommendationSubmit', {
							detail: { restaurantId, recommendations }
						});
						window.dispatchEvent(event);
					}
					resolve();
				})
				.catch(err => {
					if (!err.response) {
						dispatch({
							type: t.SUBMIT_RECOMMENDATION_FAILURE,
							payload: { error: 'Oops something when wrong', code: 500 }
						});
					}

					let error = { error: err.response.data.response.msg, code: err.response.data.response.code };

					dispatch({
						type: t.SUBMIT_RECOMMENDATION_FAILURE,
						payload: error
					});
				});
		}
	});
};

export const setNoSelectionError = () => ({
	type: t.SUBMIT_NONE_SELECTED
});
