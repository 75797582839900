import NoResultsNotice from './ui-component';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

const mapStateToProps = state => ({
	config: state.config,
	translate: getTranslate(state.locale)
});

export default connect(mapStateToProps)(NoResultsNotice);
