import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import SearchTabContent from 'components/organisms/SearchTabContent';
import SearchTypes from 'components/organisms/SearchTypes';
import qs from 'query-string';
import { getLowerCasedParams } from 'utils/url';

class SearchTabs extends Component {
	componentDidMount = () => {
		let params = getLowerCasedParams();
		let tab = params.tab;
		if (tab) {
			tab = tab.toLowerCase();
			if (tab === 'dine') {
				tab = 'business';
			} else if (tab === 'cater') {
				tab = 'catering';
			}
			this.props.setActiveTab(`${tab}Tab`);
			delete params.tab;
		}

		this.props.history.replace({
			pathname: '/',
			search: !isEmpty(params) && `?${qs.stringify(params)}/`,
			hash: '#/'
		});
	};

	render() {
		const { activeTab, className } = this.props;
		const classProps = classNames('search-tabs', className, activeTab);

		return (
			<div className={classProps}>
				<SearchTypes activeTab={activeTab} onSelectTab={this.onSelectTab} />
				<SearchTabContent activeTab={activeTab} history={this.props.history} />
			</div>
		);
	}

	onSelectTab = tab => {
		if (this.props.activeTab !== tab) {
			this.props.setActiveTab(tab);
		}
	};
}

SearchTabs.defaultProps = {
	className: ''
};

SearchTabs.propTypes = {
	className: PropTypes.string,
	location: PropTypes.object,
	history: PropTypes.object,
	activeTab: PropTypes.string.isRequired,
	setActiveTab: PropTypes.func.isRequired
};

export default SearchTabs;
