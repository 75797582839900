import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Title } from 'components/atoms';
import { OptionsList } from 'components/organisms';
import { Button } from 'reactstrap';
import { history } from 'utils/router';

const ViewCateringDashboard = ({ authenticated, isSV, translate }) => {
	const titleAlign = isSV ? 'center' : 'left';

	const navigateToDashboard = () => {
		if (authenticated) {
			// history.replace('/catering/#/dashboard');
			window.zupplerBridge.setPage('/dashboard');
			// console.log('authed, redirect to dashboard');
			history.push('/catering/#/dashboard');
		} else {
			// console.log('anon, redirect to dashboard login');
			window.zupplerBridge.setPage('/dashboard');
			history.push('/catering/#/dashboard');
			// window.location.replace(`https://my.dev.dinova.com/login?redirect=${window.location.href}`);
		}
	};

	const optionsCount = translate('Catering.viewDashboard.options.count');
	let options = [];
	for (let i = 0; i <= optionsCount; i++) {
		options.push(translate(`Catering.viewDashboard.options.values.${i}`));
	}

	return (
		<div className="view-catering-dashboard">
			<Title transform="none" size="h5" align={titleAlign}>
				{translate('Catering.viewDashboard.title')}
			</Title>
			<OptionsList className="bullet" options={options} />
			{/* Use select styles even though this isn't a select*/}
			<div className="select flat">
				<Button className={classnames('item')} onClick={navigateToDashboard} color="link">
					{translate('Catering.viewDashboard.CTA')}
				</Button>
			</div>
		</div>
	);
};

ViewCateringDashboard.defaultProps = {};

ViewCateringDashboard.propTypes = {
	authenticated: PropTypes.bool,
	isSV: PropTypes.bool,
	translate: PropTypes.func
};

export default ViewCateringDashboard;
