import { asyncComponent } from 'hoc';

const LazyEditPreferencesPage = asyncComponent(() => {
	return import(
		/* webpackChunkName: "EditPreferencesPage" */
		/* webpackMode: "lazy" */
		'./'
	).then(module => module.default);
});

export default LazyEditPreferencesPage;
