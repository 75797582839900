import React from 'react';
import PropTypes from 'prop-types';
import { Caret } from 'components/atoms';
import { CollapseWithClose } from 'components/molecules';
import WithDropdown from 'components/molecules/Dropdown/withDropdown';

/**
 * TermsAndConditions - A Dropdown/CollapseWithClose used to show/hide Terms and Conditions to the user.
 * @extends WithDropdown
 * @param {TermsAndConditions_Props} props
 */
class TermsAndConditions extends WithDropdown {
	render() {
		const { translate } = this.props,
			{ isOpen } = this.state,
			text = translate(`common.${isOpen ? 'hide' : 'view'}`).toUpperCase();

		return (
			<React.Fragment>
				<div className="trigger" ref={c => (this.dropdown_trigger = c)} onClick={this.toggleDropdown}>
					<p className="link collapsible">{text}</p>
					<Caret isOpen={isOpen} />
				</div>
				<div className="trigger-panel-wrapper">
					<CollapseWithClose
						content={translate('Terms.terms')}
						height={this.state.height}
						isOpen={isOpen}
						onClick={this.toggleDropdown}
						openSpeed={this.state.openSpeed}
						refSetter={c => (this.dropdown = c)}
					/>
				</div>
			</React.Fragment>
		);
	}
}

TermsAndConditions.defaultProps = {};

/**
 * TermsAndConditions
 * @interface {TermsAndConditions_Props}
 * @property {function} translate - Redux localize
 */
TermsAndConditions.propTypes = {
	translate: PropTypes.func
};

export default TermsAndConditions;
