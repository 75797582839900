import * as t from './types';

/**
 * Kick off registration step change.
 * @param  {number} currentStep The step to set as the current step.
 */
export const startChangeStep = currentStep => ({ type: t.CHANGE_STEP, currentStep });

/**
 * Assign card type.
 * @param  {string|number} card The id of the card type being assigned.
 */
export const assignCard = card => ({ type: t.ASSIGN_CARD, card });

/**
 * Assign card details.
 * @param  {object} details The details of the card being assigned.
 */
export const assignCardDetails = details => ({ type: t.ASSIGN_CARD_DETAILS, details });

// NOTE: This func isn't used.
export const assignUser = user => ({ type: t.ASSIGN_USER, user });

/**
 * Reset registration to its default state.
 */
export const reset = () => ({ type: t.RESET });

/**
 * Reset registration card data to its default state.
 */
export const resetCard = () => ({ type: t.RESET_CARD });

/**
 * Reset registration card details data to its default state.
 */
export const resetCardDetails = () => ({ type: t.RESET_CARD_DETAILS });

/**
 * Set registration error code.
 * @param {string'number} error API response error code. Ex: 500, 404.
 */
export const setError = error => ({ type: t.SET_ERROR, payload: error });

/**
 * Set referralLink.
 * @param {string} payload The URL of the referralLink.
 */
export const setReferralLink = payload => ({ type: t.SET_REFERRAL, payload });
