import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

/**
 * Dropdown state management Component Class.  Extend this component when a UI component with dropdown funcitonality is required.
 * Extending this component will provide this.state = {isOpen: false, height: null, openSpeed: null}, init click event listeners to open/close dropdown, upon mount determine content height and openSpeed.
 * @extends Component
 * @example class DropdownComponent extends WithDropdown {
	render() {
		return (
			<div>
				<div ref={c => this.dropdown_trigger = c}>Trigger</div>
				<div className="list-wrapper" ref={c => this.dropdown = c}>
					<div>list items</div>
				</div>
			</div>
		)
	}
}
 */
class WithDropdown extends Component {
	constructor(props) {
		super(props);

		this.clickListener = null;
		this.dropdown = React.createRef();
		this.dropdown_trigger = React.createRef();

		this.state = {
			isOpen: false,

			height: null,
			openSpeed: null
		};
	}

	/**
	 * Initialize click listener.
	 */
	componentDidMount = () => {
		window.addEventListener('click', this.handleClick, false);
		window.addEventListener('resize', this.setHeight, false);

		this.setHeight();
	};

	componentDidUpdate = () => {
		if (this.state.height !== this.dropdown.clientHeight) {
			this.setHeight();
		}

		if (this.props.value && this.props.value !== this.state.value) {
			this.setState({ value: this.props.value });
		}
	};

	componentWillUnmount = () => {
		window.removeEventListener('click', this.handleClick, false);
	};

	setHeight = () => {
		if (this.dropdown && this.dropdown.clientHeight) {
			const height = this.dropdown.clientHeight;
			let openSpeed = height / 300;

			if (openSpeed > 0.7) {
				openSpeed = 0.7;
			}

			openSpeed = `${openSpeed}s`;

			this.setState({ height, openSpeed });
		}
	};

	/**
	 * Click handler for document click event listener.
	 * Determines if click was inside of the dropdown component.  If click was
	 * outside of the dropdown, close the dropdown.
	 * @param  {object} e Click event.
	 */
	handleClick = e => {
		if (this.dropdown.contains && this.dropdown_trigger.contains) {
			const isInsideClick = this.dropdown.contains(e.target),
				isTriggerClick = this.dropdown_trigger.contains(e.target),
				// If there is a title (left to the help icon) account for it being clicked on.
				isTitleClick = this.title && this.title.contains ? this.title.contains(e.target) : false,
				isOpen = this.state.isOpen;

			if (!isInsideClick && !isTriggerClick && !isTitleClick && isOpen === true) {
				this.closeDropdown();
			}
		}
	};

	/**
	 * Toggle dropdown open state.  If open, close.  If closed, open.
	 */
	toggleDropdown = () => {
		this.setState({ isOpen: !this.state.isOpen });
	};

	/**
	 * Set the dropdown open state to false.
	 */
	closeDropdown = () => {
		this.setState({ isOpen: false });
	};

	/**
	 * When a dropdown option is selected,
	 * close the dropdown,
	 * call this.props.onChange(item).
	 */
	onChange = (item, index) => {
		this.closeDropdown();
		if (this.props.onChange) {
			this.props.onChange(item, index);
		}
	};

	handleMouseEnter = () => {
		this.setState({ mouseenter: true });
	};

	handleMouseLeave = () => {
		this.setState({ mouseenter: false });
	};

	getWrapperClassProps = () => {
		const { className, hasError } = this.props;

		const { isOpen, value } = this.state;
		const dropdownClass = classnames(
			'dropdown',
			'animated',
			isOpen ? 'open' : 'closed',
			value ? 'has-value' : '',
			hasError && 'error',
			className
		);

		return dropdownClass;
	};

	getListWrapperClassProps = () => {
		return classnames('animated dropdown-list-wrapper', this.state.isOpen ? 'open' : 'closed');
	};

	getLabelClassProps = () => {
		const { required } = this.props;
		return classnames('label', required && 'required');
	};
}

WithDropdown.defaultProps = {
	className: null,
	hasError: false,
	required: false,
	onChange: () => {},
	value: null
};

/**
 * WithDropdown Props
 * @interface WithDropdown_Props
 * @property {string}   className Additional class name to add to dropdown wrapper.
 * @property {boolean}  hasError  If this dropdown is being used in a select input, if input has error.
 * @property {boolean}  isOpen    If this dropdown is open or closed.
 * @property {function} onChange  If this dropdown is being used in a select input, on option select handler.
 * @property {boolean}  required  If this dropdown is being used in a select input, if input is required.
 * @property {string}   value     If this dropdown is being used in a select input, the value of the select input.
 */
WithDropdown.propTypes = {
	className: PropTypes.string,
	hasError: PropTypes.bool,
	isOpen: PropTypes.bool,
	onChange: PropTypes.func,
	required: PropTypes.bool,
	value: PropTypes.string
};

export default WithDropdown;
