import { history } from 'utils/router';
import { isFunction } from 'lodash';
import { setBusinessSearch } from 'actions/home';
import { setAddress } from 'actions/catering';
import { geocodeByAddress, geocodeByPlaceId } from 'react-places-autocomplete';
import { STORE } from 'components/environment/App/redux_store.js';

/**
 * IZoneActionLinkConfig
 * @interface IZoneActionLinkConfig
 * @property {string} title   The display name for the link.
 * @property {string} address The string address for this zone link. Ex: 'Atlanta, GA'
 * @property {funcion} action The click handler for this ZoneActionLink.
 */

/**
 * Util that takes a string address, transforms said address into a google place object, sets that google place as the catering search address, redirects to catering page to perform search.
 * @param {IZoneActionLinkConfig} addressLink A zone link config. {@link IZoneActionLinkConfig}
 */
const setCateringParamsThenSearchCatering = async addressLink => {
	STORE.dispatch(setBusinessSearch(addressLink.address));
	let state = STORE.getState();
	await saveAddress(state);
	await saveService(state.catering.serviceType);
	await saveOrderDate(state.catering.date);

	geocodeByAddress(addressLink.address)
		.then(results => {
			STORE.dispatch(setAddress(results[0]));
			window.zupplerBridge.setPage(`/_`);
			history.push('/catering/#/');
		})
		.catch(error => console.error('Click Search Zone Error', error));
};

/**
 * Utility for setting Zuppler Search Address - duplicate of Catering Seaarch Tab
 * @param  {object}  state Entire Redxu store.
 * @return {Promise}       Returns a promise with an address.
 */
const saveAddress = state => {
	if (state.catering.address.geometry) {
		if (isFunction(state.catering.address.geometry.location.lat)) {
			return window.zupplerBridge.setAddressFromGoogle(state.catering.address);
		} else {
			return new Promise(function(resolve) {
				geocodeByPlaceId(state.catering.address.place_id).then(results => {
					resolve(window.zupplerBridge.setAddressFromGoogle(results[0]));
				});
			});
		}
	}
};

/**
 * Utility for setting Zuppler Service Type - duplicate of Catering Seaarch Tab
 * @param  {string} serviceType 'delivery' or 'pickup'.
 * @return {Promise}
 */
const saveService = serviceType => {
	return window.zupplerBridge.setServiceType(serviceType.toUpperCase(), 'Catering');
};

/**
 * Utility for setting Zuppler Service Date - duplicate of Catering Seaarch Tab
 * @param  {date} date
 * @return {Promise}
 */
const saveOrderDate = date => {
	return window.zupplerBridge.setOrderDate(date);
};

/**
 * Utility for setting user token on ZupplerBridte - duplicate of Catering Seaarch Tab
 * @param  {string} token 'dinova accessToken'.
 * @return {Promise}
 */
export const loginWithProviderAndToken = token => {
	return window.zupplerBridge.loginWithProviderAndToken('dinova', token);
};

/**
 * Create a CTA button for dinova catering zone location links.
 * @param  {object}                link A cateing zone object returned from Dinova API.
 * @return {IZoneActionLinkConfig} Returns an IZoneActionLinkConfig item object config. {@link IZoneActionLinkConfig}
 */
export const createLinkConfig = link => {
	return {
		title: link.name,
		address: link.location.address,
		action: setCateringParamsThenSearchCatering
	};
};

/**
 * Loop over state.catering.zones and create CTANav links array.
 * @param  {object} state Redux state
 * @return {array}  Returns an array of IZoneActionLinkConfig item object configs. {@link IZoneActionLinkConfig}
 */
export const generateLinksFromState = state => {
	let links = [];

	if (state.catering.zones) {
		links = state.catering.zones
			.filter(link => link.name && link.name.length > 0)
			.map(link => createLinkConfig(link));
	}
	return {
		links
	};
};
