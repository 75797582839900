import React from 'react';
import PropTypes from 'prop-types';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import { connect } from 'react-redux';
import metaTagConfig from './meta_tags/terms-meta-tags';
import { MetaTags } from 'components/molecules';

class Terms extends React.Component {
	onClick_GoBack = e => {
		e.preventDefault();
		this.props.history.goBack();
	};

	render() {
		const { translate } = this.props;
		return (
			<div>
				<MetaTags tags={metaTagConfig} />
				<div className="Di_SubHeadShort Di_HelpBanner" />
				<div className="Di_PageLimit-XLG container-fluid animated fadeInUp">
					<div className="Ri_HeadlineAndBack" style={{ display: 'flex', alignItems: 'baseline' }}>
						<a onClick={this.onClick_GoBack} href="" className="link-back" style={{ flex: 2 }}>
							&lt; Back
						</a>
						<h1 className="text-center title text-uppercase page-title" style={{ flex: 7 }}>
							{translate('Terms.header')}
						</h1>
						<span style={{ flex: 2 }} />
					</div>
					<div className="Di_TermsText">
						<p>{translate('Terms.terms')}</p>
					</div>
				</div>
			</div>
		);
	}
}

Terms.propTypes = {
	history: PropTypes.object.isRequired,
	translate: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	translate: getTranslate(state.locale),
	currentLanguage: getActiveLanguage(state.locale).code
});

export default connect(mapStateToProps)(Terms);
