import * as t from './types';

/**
 * Init business filters - use this after a new business search is performed.
 * @param  {Array}  [restaurants=[]] An array of restaurants returned from business search.
 * @param  {Object} [filters={}]     A filter set built off of restaurants.
 * @return {object}                  A redux action object.
 */
export const initBusinessFilters = ({ restaurants = [], filters = {} }) => ({
	type: t.INIT_BUSINESS_FILTERS,
	payload: { restaurants, filters }
});

export const addBusinessFilter = (dispatch, filter) => {
	return new Promise(resolve => {
		dispatch({ type: t.ADD_BUSINESS_FILTER, payload: filter });
		resolve();
	});
};

export const removeBusinessFilter = (dispatch, filter) => {
	return new Promise(resolve => {
		dispatch({ type: t.REMOVE_BUSINESS_FILTER, payload: filter });
		resolve();
	});
};

export const setTempBusinessFilter = filter => {
	return { type: t.SET_TEMP_BUSINESS_FILTERS, payload: filter };
};

export const revertToTempBusinessFilter = () => {
	return { type: t.REVERT_TO_TEMP_BUSINESS_FILTERS };
};

export const setBusinessSearchResults = results => {
	return {
		type: t.SET_BUSINESS_SEARCH_RESULTS,
		payload: results
	};
};

export const setBusinessSearchFind = find => {
	return {
		type: t.SET_BUSINESS_SEARCH_FIND,
		payload: {
			find
		}
	};
};

export const setBusinessSearchNear = near => {
	return {
		type: t.SET_BUSINESS_SEARCH_NEAR,
		payload: {
			near
		}
	};
};
