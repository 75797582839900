import RecommendationCountMessage from './ui-component';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { hasProp } from 'utils/object';

const mapStateToProps = state => {
	const didRecommend = hasProp(state, 'details.restaurant.user.recommendation')
		? state.details.restaurant.user.recommendation.length > 0
		: false;

	return {
		didRecommend,
		translate: getTranslate(state.locale)
	};
};

export default connect(mapStateToProps)(RecommendationCountMessage);
