import * as t from './types';
import axios from 'axios';
import { isLoading } from '../general';
import { has, includes, isNumber, isNull, isString, isUndefined } from 'lodash';
import Promise from 'bluebird';
import { sessionToken } from 'utils/user';
import { isEncodedURI } from 'utils/url';
import googleMaps from '@google/maps';
import Cookies from 'universal-cookie';

import { history } from 'utils/router';

const GEO_BASE_URL = 'https://maps.googleapis.com/maps/api/geocode/json';
const ZERO_RESULTS_TYPE = 'ZERO_RESULTS';
const GEOLOCATION_NOT_FOUND = 'GEOLOCATION_NOT_FOUND';
const cookies = new Cookies();

/**
 * Fetch google location object when nearby search triggered.
 * @param  {String} apiKey    Google API key.
 * @param  {String} [near=''] Near search param.
 */
export const getLocationFromGoogle = (apiKey, near = '') => {
	const zipRegex = new RegExp(/^\d{4,5}$/);
	let country = zipRegex.test(near.trim()) ? ',US' : '',
		encodedNear = isEncodedURI(near) ? near : encodeURIComponent(near),
		geoUrl = `${GEO_BASE_URL}?address=${encodedNear}${country}&key=${apiKey}`;
	return axios.get(geoUrl);
};

/**
 * Trigger a resturant search.
 * @param  {Object} [data={}] [description]
 */
export const mapSearch = (data = {}) => async (dispatch, getState) => {
	try {
		// loading screen
		dispatch(isLoading(true, 'Search'));

		// <!-- get Latitude and Longitude for search query
		let lat, lng;

		if (!isUndefined(data.near) && data.near.length > 0) {
			const geoResJson = await getLocationFromGoogle(getState().config.apiKey, data.near);
			console.log('--- geo response ---', geoResJson);

			// google api can't determine geolocation
			if (geoResJson.data.status === ZERO_RESULTS_TYPE) {
				return dispatch({ type: t.MAP_SEARCH, status: 'error', errType: GEOLOCATION_NOT_FOUND });
			}

			lat = geoResJson.data.results[0].geometry.location.lat;
			lng = geoResJson.data.results[0].geometry.location.lng;
		} else if (!isUndefined(data.location) && !isNull(data.location)) {
			if (isString(data.location)) {
				let [lat, lng] = data.location.split(',');
				data.location = {
					lat,
					lng
				};
			}
			lat = data.location.lat;
			lng = data.location.lng;

			// only around current location. if data already has radius it's "search this area" request
			if (!isNumber(data.radius)) {
				data.radius = 20;
				data.limit = 50;
			}
		} else {
			const posRes = await new Promise((resolve, reject) => {
				navigator.geolocation.getCurrentPosition(resolve, reject, { timeout: 15000 });
			});
			lat = posRes.coords.latitude;
			lng = posRes.coords.longitude;

			/*eslint-disable require-atomic-updates*/
			// only around current location
			data.radius = 20;
			data.limit = 50;
			/*eslint-enable require-atomic-updates*/
		}
		// -->

		// <!-- make search query
		const RESTAURANT_BASE_URL = getState().config.api_basePath_restaurant + 'restaurants/search';
		let encodedKeywords = isUndefined(data.keywords)
				? ''
				: isEncodedURI(data.keywords)
				? data.keywords
				: encodeURIComponent(data.keywords),
			uom = !isUndefined(data.uom) && includes(['m', 'km', 'mi', 'ft'], data.uom) ? data.uom : 'mi';

		let searchQuery = `${RESTAURANT_BASE_URL}?location=${lat},${lng}&uom=${uom}&keywords=${encodedKeywords}`;

		if (cookies.get('mceasy')) {
			searchQuery += `&network=mceasy`;
		}

		// add radius
		if (isNumber(data.radius) && data.radius > 0) {
			searchQuery += `&radius=${data.radius}`;
		}

		// add limit
		if (isNumber(data.limit) && data.limit > 0) {
			searchQuery += `&limit=${data.limit}`;
		}
		// -->

		// search
		const queryRes = await axios.get(searchQuery).catch(error => {
			throw error;
		});
		// console.log('--- query response ---', queryRes);

		let response = {
			queryData: data,
			resData: queryRes.data,
			location: { lat, lng }
		};
		return dispatch({ type: t.MAP_SEARCH, status: 'success', response });
	} catch (err) {
		// console.log('--- error ---', err);

		if ((err.response && err.response.status === 500) || err.request) {
			// The request was made and the server responded with a status code
			// that falls out of the range of 2xx
			// or
			// The request was made but no response was received
			// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
			// http.ClientRequest in node.js

			history.push('/unable-to-connect');
		}

		let errorMsg = has(err, 'response.data.response.msg') ? err.response.data.response.msg : err.message;
		return dispatch({ type: t.MAP_SEARCH, status: 'error', error: errorMsg });
	} finally {
		// !END loading screen
		dispatch(isLoading(false));
	}
};

/**
 * Sets input value for placeAutocomplete.
 * @param  {String} [input=''] The value to set as the input value.
 */
export const placeAutocomplete = (input = '') => async (dispatch, getState) => {
	if (input === '') {
		return dispatch({
			type: t.PLACE_AUTOCOMPLETE,
			status: 'success',
			foundPlaces: []
		});
	}

	try {
		// Create client with a Promise constructor
		const googleMapsClient = googleMaps.createClient({
			key: getState().config.apiKey,
			Promise: Promise // 'Promise' is the native constructor.
		});

		const token = sessionToken();
		const resJson = await googleMapsClient.placesAutoComplete({ sessiontoken: token, input }).asPromise();

		console.log('AUTOCOMPLETE', resJson);

		if (has(resJson, 'status') && resJson.status === 200) {
			return dispatch({
				type: t.PLACE_AUTOCOMPLETE,
				status: 'success',
				foundPlaces: resJson.json.predictions
			});
		} else {
			return dispatch({ type: t.PLACE_AUTOCOMPLETE, status: 'error', error: '' });
		}
	} catch (err) {
		const errMsg = err.message || err.json.error_message;
		console.error(errMsg);
		return dispatch({ type: t.PLACE_AUTOCOMPLETE, status: 'error', error: errMsg });
	}
};

/**
 * Get user's location.
 * @param  {String} [near=''] String indicating nearby search param.
 */
export const getLocation = (near = '') => async (dispatch, getState) => {
	try {
		// loading screen
		dispatch(isLoading(true, 'Search'));

		const geoResJson = await getLocationFromGoogle(getState().config.apiKey, near);
		// console.log('--- geo response ---', geoResJson);

		// google api can't determine geolocation
		if (geoResJson.data.status === ZERO_RESULTS_TYPE) {
			return dispatch({ type: t.GET_LOCATION, status: 'error', errType: GEOLOCATION_NOT_FOUND });
		}

		return dispatch({
			type: t.GET_LOCATION,
			status: 'success',
			location: { ...geoResJson.data.results[0].geometry.location }
		});
	} catch (err) {
		// console.log('--- error ---', err);

		if ((err.response && err.response.status === 500) || err.request) {
			// The request was made and the server responded with a status code
			// that falls out of the range of 2xx
			// or
			// The request was made but no response was received
			// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
			// http.ClientRequest in node.js

			history.push('/unable-to-connect');
		}

		let errorMsg = has(err, 'response.data.response.msg') ? err.response.data.response.msg : err.message;
		return dispatch({ type: t.GET_LOCATION, status: 'error', error: errorMsg });
	} finally {
		// !END loading screen
		dispatch(isLoading(false));
	}
};

/**
 * Set the active search tab.
 * @param {string} tab The tab to set active. Avialable tabs are "businessSearch", 'cateringSearch', and 'privateDining'.
 */
export const setActiveFilterTab = tab => {
	return { type: t.SET_ACTIVE_FILTER_TAB, value: tab };
};
