import { combineReducers } from 'redux';
// import reducers
import { localeReducer } from 'react-localize-redux';
import autocompleteReducer from './autocomplete';
import cateringReducer from './catering';
import configReducer from './config';
import detailsReducer from './details';
import generalReducer from './general';
import homeReducer from './home';
import mapReducer from './map';
import modalReducer from './modal';
import registrationReducer from './registration';
import rewardReducer from './reward';
import userReducer from './user';
import usersReducer from './users';
import locationAutocompleteReducer from './location_autocomplete';
import geoLocationReducer from './geo_location';
import businessSearchFilter from './new_business_search_filter';
import searchPage from './search_page';
import businessSearchMap from './business_search_map';
import recommendationsModal from './recommendations_modal';
import easterEggs from './easter_eggs';
import userRecommendations from './user_recommendations';

// combine to single object
export const reducers = {
	autocomplete: autocompleteReducer,
	catering: cateringReducer,
	config: configReducer,
	details: detailsReducer,
	general: generalReducer,
	home: homeReducer,
	locale: localeReducer,
	map: mapReducer,
	modal: modalReducer,
	registration: registrationReducer,
	reward: rewardReducer,
	user: userReducer,
	users: usersReducer,
	locationAutocomplete: locationAutocompleteReducer,
	geoLocation: geoLocationReducer,
	businessSearchFilter,
	searchPage,
	businessSearchMap,
	recommendationsModal,
	easterEggs,
	userRecommendations
};

// add factory reset for store
const makeRootReducer = reducers => {
	return (state, action) => {
		let storeState = state;
		storeState = action.type === 'FACTORY_RESET' ? undefined : state;

		if (state) {
			state.businessSearchFilter.recOptions = state.recommendationsModal.positive;
		}

		return combineReducers(reducers)(storeState, action);
	};
};

// define root reducer
const rootReducer = makeRootReducer(reducers);
export default rootReducer;
