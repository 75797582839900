import { asyncComponent } from 'hoc';

const LazyShowCookie = asyncComponent(() => {
	return import(
		/* webpackChunkName: "ShowCookie" */
		/* webpackMode: "lazy" */
		'./'
	).then(module => module.default);
});

export default LazyShowCookie;
