import ActiveFiltersBar from './ui-component';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
	filters: state.businessSearchFilter.userFilters,
	IMAGE_BASE_URL: state.config.cdn_basePath,
	selectedFilters: state.businessSearchFilter.selectedFilters
});

export default connect(mapStateToProps)(ActiveFiltersBar);
