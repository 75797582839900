import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CheckboxGroup, HelpButton, Input, RadioInput, SelectInput } from 'components/molecules';

// import './styles/main.scss';

/**
 * InputField - A common input component that can be configured to display any type of input.
 * @extends Component
 * @param {InputField_Props} props
 */
class InputField extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hasFocus: false
		};
	}

	toggleFocus = e => {
		let me = this,
			hasFocus = !this.state.hasFocus;

		if (!hasFocus && this.props.onBlur) {
			this.props.onBlur(e);
		} else if (hasFocus && this.props.onFocus) {
			this.props.onFocus(e);
		}
		me.setState({ hasFocus: !this.state.hasFocus });
	};

	inputs = {
		textarea: Input,
		text: Input,
		typeahead: Input,
		email: Input,
		password: Input,
		radio: RadioInput,
		checkbox: CheckboxGroup,
		select: SelectInput
	};

	render() {
		const {
			alignOptions,
			children,
			className,
			dataCy,
			error,
			example,
			hasError,
			help,
			id,
			inputAlign,
			label,
			name,
			onChange,
			onHelpClick,
			onKeyDown,
			options,
			required,
			showHelp,
			triggers
		} = this.props;
		let type = this.props.type;
		const { hasFocus } = this.state;
		let value = this.props.value || this.state.value;
		if (type === 'checkbox') {
			value = this.state;
		}
		const InputComponent = this.inputs[type];

		let Children = children;
		let filteredList = [];
		if (type === 'typeahead' && typeof children === 'function') {
			filteredList = options.filter(
				o => o.value.toLowerCase().indexOf(value ? value.toLowerCase() : null) !== -1
			);
			if (filteredList.length > 0) {
				Children = filteredList.map(o => children(o));
			}
		}

		const classProps = classnames(
			'input-wrapper',
			hasError && 'error',
			help && 'with-help',
			triggers.length && 'with-help',
			required && 'required',
			type === 'typeahead' && children && filteredList.length > 0 && 'open',
			hasFocus && 'focused',
			className,
			type === 'typeahead' && this.state.hasFocus && Children && 'open'
		);

		return (
			<div className={classProps}>
				<InputComponent
					dataCy={dataCy}
					alignOptions={alignOptions} // radio, checkbox
					type={type}
					hasError={hasError}
					error={error}
					label={label}
					id={id}
					inputAlign={inputAlign} // radio, checkbox
					name={name}
					options={options} // radio, checkbox, select
					onBlur={this.toggleFocus}
					onChange={onChange}
					onFocus={this.toggleFocus}
					onKeyDown={onKeyDown}
					required={required}
					value={value}
				/>
				{triggers.length > 0 &&
					triggers.map(t => {
						return t;
					})}
				{help && (
					<HelpButton
						help={help}
						id={`help-${id}-${name}-${label}`}
						onHelpClick={onHelpClick}
						isOpen={showHelp}
					/>
				)}
				{example && <span className="input-example">{example}</span>}
				{type !== 'typeahead' && Children && Children}
				{type === 'typeahead' && Children && Children}
			</div>
		);
	}
}

InputField.defaultProps = {
	alignOptions: null,
	children: null,
	error: null,
	example: null,
	hasError: false,
	help: null,
	id: null,
	inputAlign: null,
	label: null,
	name: 'input',
	options: [],
	required: false,
	triggers: [],
	type: 'text',
	onChange: () => {},
	onKeyDown: () => {},
	value: null
};

/**
 * InputField Props
 * @interface InputField_Props
 * @property {string}   baseImgURL                 state.config.cdn_basePath.
 * @property {string}   [alignOptions=null]        How to align label and input - for radio and checkbox and checkboxgroup - can be "horizontal", "vertical", or null.
 * @property {node}     [children=null]            Additional components to show inside of the input field wrapper.  Ex: Input requirements.
 * @property {string}   error                      The error message text.
 * @property {string}   example                    An example of how to fill out the input.
 * @property {boolean}  hasError                   If this input has an error.
 * @property {string}   help                       Text to show in the help CollapseWithClose.  If provided, will add a help trigger to the input.
 * @property {string}   id                         The id for this input.
 * @property {string}   [inputAlign=null]          How to align options - for radio, checkbox, checkboxgroup. Can be "inline" or null.
 * @property {string}   label                      The label text for this input.
 * @property {string}   name                       The name for this input.
 * @property {funciton} onChange                   Handler for when this input's value changes.
 * @property {function} onKeyDown                  Handler for when this input is focused and a key is pressed.
 * @property {array}    options                    An array of data config objects for this inputs options - used for radio, checkboxgroup, select, typeahead.
 * @property {boolean}  required                   If this is a required input.
 * @property {array}    triggers                   An array of trigger config objects.  Adds triggers to the right of this input.
 * @property {string}   [type='text']              The type of input.  Can be 'text', 'radio', 'checkbox', 'checkboxgroup', 'select', 'password', 'email', 'typeahead'.
 * @property {string}   value                      The current value of the input.
 */
InputField.propTypes = {
	// required
	baseImgURL: PropTypes.string.isRequired,
	// optional
	alignOptions: PropTypes.oneOf(['horizontal', 'vertical']),
	children: PropTypes.node,
	className: PropTypes.string,
	dataCy: PropTypes.string,
	error: PropTypes.string,
	example: PropTypes.string,
	hasError: PropTypes.bool,
	help: PropTypes.string,
	id: PropTypes.string,
	inputAlign: PropTypes.oneOf(['inline']), // for radio espcially, where we align label and options on same row.
	label: PropTypes.string,
	name: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	onHelpClick: PropTypes.func,
	onKeyDown: PropTypes.func,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			displayValue: PropTypes.string,
			id: PropTypes.string,
			name: PropTypes.string,
			value: PropTypes.string,
			checked: PropTypes.bool
		})
	),
	required: PropTypes.bool,
	showHelp: PropTypes.bool,
	triggers: PropTypes.array,
	type: PropTypes.oneOf(['text', 'radio', 'checkbox', 'select', 'password', 'email', 'typeahead']),
	value: PropTypes.string
};

export default InputField;
