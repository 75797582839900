import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { includes } from 'lodash';
import { NoResultsNotice, RestaurantListItemNew } from 'components/molecules';

const RestaurantList = props => {
	const {
		className,
		groupedByRestaurants,
		activePin,
		onSelectPin,
		onTransition,
		onSectionListToggle,
		expandedSections
	} = props;
	const classProps = classnames('restaurant-list', className);

	return (
		<div className={classProps}>
			{groupedByRestaurants.length === 0 && <NoResultsNotice />}
			{groupedByRestaurants.map((restaurants, index) => {
				let toggled = includes(expandedSections, restaurants[0].id);

				return (
					<RestaurantListItemNew
						cuisineName={restaurants[0].cuisine.name}
						menuSpecialties={restaurants[0].menuSpecialties}
						city={restaurants[0].address.city}
						distance={restaurants[0].distance.span}
						id={restaurants[0].id}
						image={restaurants[0].images.main}
						key={restaurants[0].id}
						name={restaurants[0].name}
						numberOfLocations={restaurants.length}
						stateAbbr={restaurants[0].address.state.abbreviation}
						restaurant={restaurants[0]}
						otherRestaurants={restaurants.slice(1)}
						activePin={activePin}
						onSelectPin={onSelectPin}
						onTransition={onTransition}
						index={index}
						onSectionListToggle={onSectionListToggle}
						toggled={toggled}
					/>
				);
			})}
		</div>
	);
};

RestaurantList.defaultProps = {
	className: ''
};

RestaurantList.propTypes = {
	className: PropTypes.string,
	groupedByRestaurants: PropTypes.array.isRequired,
	activePin: PropTypes.string.isRequired,
	onSelectPin: PropTypes.func.isRequired,
	onTransition: PropTypes.func.isRequired,
	onSectionListToggle: PropTypes.func.isRequired,
	expandedSections: PropTypes.arrayOf(PropTypes.bool).isRequired
};

export default RestaurantList;
