import React from 'react';
import { Row, Col } from 'third-party/mdbreact/dist/mdbreact.js';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const DashboardBanner = props => {
	const { className, onClick, onClose, config } = props;
	const classProps = classNames('dashboard-banner', className);

	return (
		<div className={classProps} onClick={onClick}>
			<Row className="no-gutters">
				<Col md="12" className="no-margin banner">
					<img src={`${config.cdn_basePath}/mydinova/Promotions/refer-sv.png`} />
					<Button className={'close'} onClick={onClose} />
				</Col>
			</Row>
		</div>
	);
};

DashboardBanner.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,
	onClose: PropTypes.func,
	config: PropTypes.object
};

export default DashboardBanner;
