import * as t from '../actions/home/types';
/**
 * Redux_HomeState
 * @interface Redux_HomeState
 * @property {string} [activeTab='businessTab']
 * @property {string} [businessSearch='']
 * @property {string} [findBusinessSearch=""]
 * @property {string} [nearBusinessSearch='']
 * @property {string} [searchSuggestion='']
 */

// let today = new Date();

const homeDefaultState = {
	activeTab: 'businessTab',
	businessSearch: '',
	findBusinessSearch: '',
	nearBusinessSearch: '',
	searchSuggestion: ''
};

export default (state = homeDefaultState, action) => {
	switch (action.type) {
		case t.SET_ACTIVE_TAB: {
			return { ...state, activeTab: action.value };
		}
		case t.SET_BUSINESS_SEARCH: {
			return { ...state, businessSearch: action.value };
		}
		case t.SET_FIND_BUSINESS_SEARCH: {
			return { ...state, findBusinessSearch: action.value };
		}
		case t.SET_NEAR_BUSINESS_SEARCH: {
			return { ...state, nearBusinessSearch: decodeURIComponent(action.value) };
		}
		case t.SET_SEARCH_SUGGESTION: {
			return { ...state, searchSuggestion: action.value };
		}
		default:
			return state;
	}
};
