import React, { Component } from 'react';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import classnames from 'classnames';
import moment from 'moment';
import { Title } from 'components/atoms';

// import './style.scss';

class DatePaginator extends Component {
	constructor(props) {
		super(props);
		let date = moment();
		if (props.selectedDate) {
			date = moment(props.selectedDate);
		}

		this.state = {
			date
		};
	}

	componentDidUpdate = () => {
		if (this.props.selectedDate && this.props.selectedDate !== this.state.date) {
			this.setState({ date: this.props.selectedDate });
		}
	};

	previous = () => {
		const { type } = this.props;
		const date = moment(this.state.date).subtract(1, `${type}s`);

		if (this.props.previousSelect) {
			this.props.previousSelect(date);
		} else if (this.props.onDateChange) {
			this.props.onDateChange(date);
		} else {
			this.setState({ date });
		}
	};

	next = () => {
		const { type } = this.props;
		const date = moment(this.state.date).add(1, `${type}s`);

		if (this.props.nextSelect) {
			this.props.nextSelect(date);
		} else if (this.props.onDateChange) {
			this.props.onDateChange(date);
		} else {
			this.setState({ date });
		}
	};

	monthString = () => moment(this.state.date).format('MMMM');

	yearString = () => moment(this.state.date).format('YYYY');

	currentMonthSelected = () => (this.state.date ? new Date().getMonth() === moment(this.state.date).month() : true);
	currentYearSelected = () => (this.state.date ? new Date().getFullYear() === moment(this.state.date).year() : true);

	render() {
		const { className, IMAGE_BASE_URL, type } = this.props,
			classProps = classnames('date-paginator', className, type),
			nextClassProps = classnames(
				'next',
				this.currentMonthSelected() && this.currentYearSelected() && 'invisible'
			);

		return (
			<div className={classProps}>
				<p className="prev" onClick={this.previous} data-cy="month-prev">
					<img src={`${IMAGE_BASE_URL}/graphics/icon-arrow-left.svg`} />
				</p>
				<Title size="h2" dataCy="month-string">
					{type === 'month' && this.monthString() + ' '}
					{this.yearString()}
				</Title>
				<p className={nextClassProps} onClick={this.next} data-cy="month-next">
					<img src={`${IMAGE_BASE_URL}/graphics/icon-arrow-right.svg`} />
				</p>
			</div>
		);
	}
}

DatePaginator.defaultProps = {
	className: ''
};

DatePaginator.propTypes = {
	className: PropTypes.string,
	IMAGE_BASE_URL: PropTypes.string.isRequired,
	nextSelect: PropTypes.func,
	onDateChange: PropTypes.func,
	previousSelect: PropTypes.func,
	selectedDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), momentPropTypes.momentObj]),
	type: PropTypes.oneOf(['month', 'year'])
};

export default DatePaginator;
