import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

const SubHeader = ({ links, title, titleKey, translate }) => {
	return (
		<div className="container dashboard sub-header">
			<h3 className="sub-title center">{titleKey ? translate(titleKey) : title}</h3>
			<div className="sub-nav">
				{links.map((link, i) => {
					return (
						<NavLink key={`sub-nav-${i}`} to={link.to}>
							{link.titleKey ? translate(link.titleKey) : link.title ? link.title : ''}
						</NavLink>
					);
				})}
			</div>
		</div>
	);
};

SubHeader.defaultProps = {
	links: []
};

SubHeader.propTypes = {
	links: PropTypes.arrayOf(
		PropTypes.shape({
			to: PropTypes.string.isRequired,
			titleKey: PropTypes.string,
			title: PropTypes.string
		})
	),
	title: PropTypes.string,
	titleKey: PropTypes.string,
	translate: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	translate: getTranslate(state.locale)
});

export default connect(mapStateToProps)(SubHeader);
