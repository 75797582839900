/* eslint-disable no-case-declarations */
import * as t from '../actions/reward/types';

/**
 * Redux_RewardState
 * @interface Redux_RewardState
 * @property {array}  [availableRewards=[]]
 * @property {object} [selectedReward={}]
 * @property {object} [redeemedReward=undefined]
 */
const rewardsDefaultState = {
	availableRewards: [],
	selectedReward: {},
	redeemedReward: undefined
};

export default (state = rewardsDefaultState, action) => {
	switch (action.type) {
		case t.LOAD_REWARDS:
			return { availableRewards: action.payload };
		case t.LOAD_REWARD:
			return { ...state, selectedReward: state.availableRewards.find(x => x.brand_id == action.payload) };
		case t.REDEEMED_REWARD:
			return { ...state, redeemedReward: action.payload };
		default:
			return state;
	}
};
