import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { EditProfileActions, RequiredFieldNote } from 'components/molecules';
import { InputField } from 'components/organisms';

const EditPersonalForm = props => {
	const { className, data, onChange, translate, onUpdate, onCancel } = props;
	const classProps = classNames('edit-personal-form', className);

	// TODO fix asterisk, add validation, change handler and error text
	return (
		<div className={classProps}>
			<RequiredFieldNote />

			{data.map((item, index) => {
				const isPhone = item.key === 'phone';

				return (
					<InputField
						dataCy={item.key}
						label={translate(`Profile.personal.labels.${item.key}`) + `${item.mark === '✝︎' ? ' ✝︎' : ''}`}
						name={`${item.key}Field`}
						id={`${item.key}-field`}
						hasError={!item.isValid}
						error={translate(`EditPersonal.errors.${item.key}`)}
						className={classNames('Di_NoSideIcon field', !item.isValid && 'Di_HasError')}
						value={item.value}
						onChange={e => onChange(e, item.key)}
						key={index}
						required={item.mark === 'asterisk'}
						example={isPhone ? translate('EditPersonal.numberExample') : ''}
					/>
				);
			})}

			<EditProfileActions onUpdate={onUpdate} onCancel={onCancel} />
			<p className="note form-note">
				<span className="cross-mark">&#10013;&#xFE0E;</span>
				{translate('EditPersonal.note')}
			</p>
		</div>
	);
};

EditPersonalForm.defaultProps = {
	className: '',
	data: {}
};

EditPersonalForm.propTypes = {
	className: PropTypes.string,
	onChange: PropTypes.func,
	translate: PropTypes.func,
	data: PropTypes.object,
	onUpdate: PropTypes.func,
	onCancel: PropTypes.func
};

export default EditPersonalForm;
