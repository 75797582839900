import React from 'react';
import PropTypes from 'prop-types';

const DetailsLocationMapMarker = ({ title }) => {
	const icon_url = encodeURI('https://dinova-images-dev.imgix.net/icons/icon-selected-map-marker-64.png');

	return (
		<div className="map-marker" text="My Marker">
			<img src={icon_url} />
			<p className="map-marker-label">{title}</p>
		</div>
	);
};

DetailsLocationMapMarker.defaultProps = {
	// title: "Resturant Name"
};

DetailsLocationMapMarker.propTypes = {
	title: PropTypes.string
};

export default DetailsLocationMapMarker;
