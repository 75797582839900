import SearchPageViewToggles from './ui-component';
import { connect } from 'react-redux';
import withSizes from 'react-sizes';
import { isSV } from 'utils/sizes';
import { getTranslate } from 'react-localize-redux';
import { toggleShowFilter, toggleShowList } from 'actions/search_page';

const mapStateToProps = state => ({
	IMAGE_BASE_URL: state.config.cdn_basePath,
	selectedFilters: state.businessSearchFilter.selectedFilters,
	showFilter: state.searchPage.showFilter,
	showList: state.searchPage.showList,
	translate: getTranslate(state.locale)
});

const mapDispatchToProps = dispatch => ({
	toggleShowFilter: show => dispatch(toggleShowFilter(show)),
	toggleShowList: show => dispatch(toggleShowList(show))
});

const mapSizesToProps = sizes => ({
	isSV: isSV(sizes)
});

export default withSizes(mapSizesToProps)(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(SearchPageViewToggles)
);
