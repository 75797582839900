export const getCardType = number => {
	let re = new RegExp('^4');
	if (number.match(re) != null) return 'visa';

	re = new RegExp('^(34|37)');
	if (number.match(re) != null) return 'amex';

	re = new RegExp('^5[1-5]');
	if (number.match(re) != null) return 'mastercard';

	re = new RegExp('^6011');
	if (number.match(re) != null) return 'discover';

	return '';
};

export const getCardId = type => {
	switch (type) {
		case 'amex':
			return 1;
		case 'visa':
			return 2;
		case 'mastercard':
			return 3;
		default:
			return 0;
	}
};

export const preferredCCTypeNormalized = preferredCCType => {
	switch (preferredCCType) {
		case 1:
			return 'amex';
		case 2:
			return 'visa';
		case 3:
			return 'mastercard';
		default:
			break;
	}
};
