import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Header Level navigate button.  Goes back one step in history.
 * @extends Component
 *
 * @param {BackButtonProps} props
 */
class BackButton extends Component {
	/**
	 * Go back one step in react-router history stack.
	 * If history stack length is less than one, go back to this.props.baseRoute.
	 * @param  {Object} e Back button click event.
	 */
	back = e => {
		e.preventDefault();

		const { baseRoute } = this.props;

		let lastLocation = `${this.props.lastLocation.pathname}${this.props.lastLocation.hash}`;
		// if came from a catering page
		if (
			lastLocation.indexOf('/catering/#') > -1 &&
			lastLocation !== '/catering/#/' &&
			lastLocation !== '/catering' &&
			lastLocation !== '/catering'
		) {
			// highjack our back button and fake zuppler back behavior
			window.zupplerBridge && window.zupplerBridge.setPage('#/');
			this.props.history.push('/catering/#/');
			return;
		}

		if (this.props.history.length > 1) {
			this.props.history.goBack();
		} else {
			this.props.history.push(baseRoute);
		}
	};

	render() {
		if (this.props.lastLocation === null) {
			return null;
		}

		if (this.props.useZupplerBack) {
			return <div id="zuppler-navigation-back" className="zuppler-dinova-link" />;
		}

		return (
			<a className="back-link" onClick={this.back}>
				{this.props.translate('Details.back')}
			</a>
		);
	}
}

BackButton.defaultProps = {
	baseRoute: '/',
	useZupplerBack: false
};

/**
 * {@link BackButton} Props
 * @interface Props_BackButton
 * @property {Object} history React Router history object.
 * @property {String} [baseRoute] The "home" route. Base route will be used as back button goTo route if history.length < 1.
 */
BackButton.propTypes = {
	// Required
	history: PropTypes.object.isRequired,
	translate: PropTypes.func.isRequired,
	lastLocation: PropTypes.object.isRequired,
	// Optional
	baseRoute: PropTypes.string,
	useZupplerBack: PropTypes.bool
};

export default BackButton;
