import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'components/atoms';

/**
 * Displays a restaurant's special offers.
 * @param {SpecialOffersProps} props
 */
const SpecialOffers = ({ offers }) => {
	return offers.length ? (
		<div className="special-offers">
			{offers.map((offer, i) => {
				return (
					<Text key={`special-offer-${i}`} paragraph={true}>
						{offer.description}
					</Text>
				);
			})}
		</div>
	) : null;
};

SpecialOffers.defaultProps = {
	offers: []
};

/**
 * {@link SpecialOffers} Props
 * @interface Props_SpecialOffers
 * @property {array} offers
 */
SpecialOffers.propTypes = {
	// Optional
	offers: PropTypes.array
};

export default SpecialOffers;
