import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import MetaTags from './MetaTags';
import SmartBanner from 'react-smartbanner';
import Cookies from 'universal-cookie';
import IdleTimer from 'react-idle-timer';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import queryString from 'query-string';
import ScrollToTop from 'utils/scrollToTop';
import NotFound from 'components/old-pattern/containers/NotFound';
import Home from 'components/old-pattern/containers/Home';
import Login from 'components/old-pattern/containers/Login';
import Forgotten from 'components/old-pattern/containers/Forgotten';
import Reset from 'components/old-pattern/containers/Reset';
import LazyHelpFAQ from 'components/ecosystems/HelpFAQ/lazy-container.js';
import Terms from 'components/old-pattern/containers/Terms';
import ResetPassword from 'components/old-pattern/containers/ResetPassword';
import Expired from 'components/old-pattern/containers/Expired';
import { Catering, RecommendationsModal } from 'components/organisms';
import { LastLocationProvider } from 'react-router-last-location';
import { Loader } from 'components/atoms';
import classnames from 'classnames';
import { isIE, isFireFox, isEdge } from 'utils/browser';
import { Completion } from 'components/molecules';
// import { setExistingLocationPermissions } from 'actions/geo_location';
import { LazyHomePage } from 'components/ecosystems';

import {
	LazyDashboardPage,
	LazyDetailsPage,
	LazyEditPaymentPage,
	LazyEditPersonalPage,
	LazyEditPreferencesPage,
	LazyEditSecurity,
	LazyFooter,
	LazyHeader,
	// HomePage,
	LazyLogoutPage,
	LazyNoResponsePage,
	LazyOrderPage,
	LazyOrderReceiptPage,
	LazyPrivateRoute,
	LazyProfilePage,
	LazyRecommendationsPage,
	LazyReferPage,
	LazyRegistrationPage,
	LazyResetRoute,
	LazyRewardErrorPage,
	LazyRewardsPage,
	LazyRewardViewPage,
	LazySearchPage,
	LazyShowCookie,
	LazyTransactionsPage,
	LazyUnsubscribePage
} from 'components/ecosystems';

import { toggleMobileNav, setAndroid } from 'actions/general';
import { history } from 'utils/router';
import { fixMissingHashURL } from 'utils/url';

class Layout extends Component {
	constructor(props) {
		super(props);
		this.idleTimer = null;
		this.state = {
			timedOut: false,
			banner: {
				title: this.props.translate('Banner.title'),
				author: this.props.translate('Banner.author'),
				price: {
					ios: this.props.translate('Banner.price.ios'),
					android: this.props.translate('Banner.price.android'),
					windows: this.props.translate('Banner.price.windows'),
					kindle: this.props.translate('Banner.price.kindle')
				},
				button: this.props.translate('Banner.button'),
				storeText: {
					ios: this.props.translate('Banner.storeText.ios'),
					android: this.props.translate('Banner.storeText.android'),
					windows: this.props.translate('Banner.storeText.windows'),
					kindle: this.props.translate('Banner.storeText.kindle')
				},
				icon: `${props.IMAGE_BASE_URL}/logos/Dinova-favicon.png`
			}
		};
	}

	componentDidMount = async () => {
		fixMissingHashURL();
		// if (window.location.pathname.indexOf('search') === -1) {
		// if not on a page that requests geolocation on mount
		// await this.props.setExistingLocationPermissions();
		// }
		queryString.parse(window.location.search).applicationAuth && this.props.setAndroid(true);
	};

	componentDidUpdate() {
		// fixMissingHashURL();
		// this.redirectFromDeniedGeolocation();
		this.idleTimer && this.idleTimer.reset();
	}

	onClick = () => {
		if (this.props.general.mobileNavOpen) {
			this.props.toggle();
		}
	};

	onActive = () => {
		this.setState({
			timedOut: false
		});
	};

	onIdle = () => {
		this.setState({
			timedOut: true
		});
	};

	reset = () => {
		this.idleTimer.reset();
	};

	redirect = (location = '/logout') => {
		// console.log('You are being redirected');
		this.setState({
			timedOut: false
		});
		return <Redirect to={`${location}/#/`} />;
	};

	render() {
		const {
			// analytics,
			general,
			translate,
			users
		} = this.props;

		const classProps = classnames('app-wrapper', isIE() && 'ie', isFireFox() && 'ff', isEdge() && 'edge');

		return (
			<Router history={history}>
				<LastLocationProvider>
					<ScrollToTop>
						{/* update meta tags */}
						<MetaTags />
						{!general.androidVersion && <SmartBanner {...this.state.banner} key={0} />}
						<div onClick={this.onClick} className={classProps}>
							<div className="app-content">
								<RecommendationsModal />
								{this.state.timedOut && users.authenticated && this.redirect()}
								{general.isBusy && <Loader message={general.message || 'Please wait'} />}
								{!general.androidVersion && <LazyHeader general={general} users={users} />}
								<Switch>
									{/* <Route
										path="/:url*"
										exact
										strict
										render={props => <Redirect to={`${props.location.pathname}/`} />}
									/> */}

									<Route
										path="/mydinova"
										component={users.authenticated ? LazyDashboardPage : Home}
										exact
									/>

									<Route
										exact
										path="/search"
										render={({ match, location }) => {
											const parsed = queryString.parse(location.search);
											if (parsed.network === 'mceasy') {
												const cookies = new Cookies();
												cookies.set('mceasy', true, { maxAge: 2147483647 });
											}
											/*analytics={analytics}*/
											return <LazySearchPage match={match} location={location} />;
										}}
									/>
									<Route
										exact
										path="/restaurants/:id/:restaurantName-:city-:state/"
										render={({ match, location, history }) => {
											/*analytics={analytics}*/
											return (
												<LazyDetailsPage match={match} location={location} history={history} />
											);
										}}
									/>
									<Route
										path="/restaurants/:id/:restaurantName-:city-:state/about_us"
										render={({ match, location, history }) => {
											/*analytics={analytics}*/
											return (
												<LazyDetailsPage match={match} location={location} history={history} />
											);
										}}
									/>
									<Route
										path="/restaurants/:id/:restaurantName-:city-:state/catering"
										render={({ match, location, history }) => {
											/*analytics={analytics}*/
											return (
												<LazyDetailsPage match={match} location={location} history={history} />
											);
										}}
									/>
									<Route
										path="/restaurants/:id/:restaurantName-:city-:state/private_dining"
										render={({ match, location, history }) => {
											/*analytics={analytics}*/
											return (
												<LazyDetailsPage match={match} location={location} history={history} />
											);
										}}
									/>
									<Route
										path="/catering"
										render={({ match, location, history }) => {
											/*analytics={analytics}*/
											return (
												<LazyDetailsPage match={match} location={location} history={history} />
											);
										}}
									/>
									<Route exact path="/unable-to-connect" component={LazyNoResponsePage} />
									<Route path="/login" component={Login} exact />
									<Route path="/logout" component={LazyLogoutPage} />
									<Route path="/forgotten" component={Forgotten} exact />
									<Route path="/users/register" component={LazyRegistrationPage} exact />
									<Route path="/users/connect" component={LazyRegistrationPage} exact />
									<Route path="/reset" component={Reset} exact />
									<LazyResetRoute path="/change" component={Reset} />
									<Route path="/helpfaq" exact component={LazyHelpFAQ} />
									<Route path={'/helpfaq/business'} component={LazyHelpFAQ} exact />
									<Route path={'/helpfaq/catering'} component={LazyHelpFAQ} exact />
									<Route path={'/helpfaq/rewards'} component={LazyHelpFAQ} exact />
									<Route path="/terms" component={Terms} exact />
									<Route path="/resetpassword" component={ResetPassword} exact />
									<Route path="/expired" component={Expired} exact />
									<Route path="/users/register/complete" component={Completion} exact />
									<Route path="/refer" component={LazyReferPage} exact />
									<Route path="/unsubscribe" component={LazyUnsubscribePage} exact />
									<LazyPrivateRoute path="/refer/details" component={LazyReferPage} exact />
									<LazyPrivateRoute
										path="/recommendations"
										component={LazyRecommendationsPage}
										exact
									/>
									<LazyPrivateRoute path="/rewards" component={LazyRewardsPage} exact />
									<LazyPrivateRoute path="/rewards/error" component={LazyRewardErrorPage} />
									<LazyPrivateRoute path="/rewards/order/:id" component={LazyOrderPage} />
									<LazyPrivateRoute path="/rewards/receipt" component={LazyOrderReceiptPage} />
									<LazyPrivateRoute path="/rewards/view/:id" component={LazyRewardViewPage} />
									<LazyPrivateRoute path="/history" component={LazyTransactionsPage} exact />
									<LazyPrivateRoute path="/profile" component={LazyProfilePage} exact />
									<LazyPrivateRoute path="/profile/personal" component={LazyEditPersonalPage} />
									<LazyPrivateRoute path="/profile/payment" component={LazyEditPaymentPage} />
									<LazyPrivateRoute path="/profile/preferences" component={LazyEditPreferencesPage} />
									<LazyPrivateRoute path="/profile/security" component={LazyEditSecurity} />
									<Route
										exact
										path="/"
										render={({ match, location, history }) => {
											// redirect to search page
											const parsed = queryString.parse(location.search);
											if (parsed.network === 'mceasy') {
												const cookies = new Cookies();
												cookies.set('mceasy', true, { maxAge: 2147483647 });
											}
											if (parsed.location || parsed.find || parsed.near || parsed.keywords) {
												return <Redirect to={`/search${location.search}`} />;
											}

											// TODO: Make sure that the above captures all of the potential entries for search
											// if (
											// 	location.search.indexOf('tab') === -1 &&
											// 	location.search !== '' &&
											// 	location.search !== '?network=mceasy'
											// ) {

											// }

											return (
												/*analytics={analytics}*/
												<LazyHomePage match={match} location={location} history={history} />
											);
										}}
									/>
									<Route component={NotFound} />
								</Switch>
								{!general.androidVersion && <Catering />}
							</div>
							{!general.androidVersion && (
								<LazyFooter general={general} authenticated={users.authenticated} />
							)}
							{!general.androidVersion && <LazyShowCookie />}
							{this.props.users.user && (
								<IdleTimer
									key={'idle-timer'}
									ref={ref => {
										this.idleTimer = ref;
									}}
									element={document}
									onActive={this.onActive}
									onIdle={this.onIdle}
									timeout={1000 * 60 * Number.parseInt(translate('common.timeoutInMinutes'))}
									startOnLoad
									reset={this.reset}
								/>
							)}
						</div>
					</ScrollToTop>
				</LastLocationProvider>
			</Router>
		);
	}
}

Layout.propTypes = {
	general: PropTypes.object,
	users: PropTypes.object,
	toggle: PropTypes.func,
	setAndroid: PropTypes.func,
	translate: PropTypes.func,
	config: PropTypes.object,
	location: PropTypes.object,
	IMAGE_BASE_URL: PropTypes.string,
	hasGeoLocation: PropTypes.bool.isRequired,
	geoLocationLoading: PropTypes.bool.isRequired,
	geoLocatoinServicesDenied: PropTypes.bool.isRequired
	// setExistingLocationPermissions: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
	toggle: () => dispatch(toggleMobileNav()),
	setAndroid: () => dispatch(setAndroid())
	// setExistingLocationPermissions: () => setExistingLocationPermissions(dispatch)
});

const mapStateToProps = state => ({
	hasGeoLocation: state.geoLocation.hasGeoLocation,
	geoLocationLoading: state.geoLocation.isLoading,
	geoLocatoinServicesDenied: state.geoLocation.wasDenied,
	general: state.general,
	users: state.users,
	translate: getTranslate(state.locale),
	currentLanguage: getActiveLanguage(state.locale).code,
	config: state.config,
	IMAGE_BASE_URL: state.config.imageBaseUrl
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Layout);
