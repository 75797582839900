import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const StepTitle = ({ className, stepTitle }) => {
	const classProps = classNames('step-title', className);

	return <div className={classProps}>{stepTitle}</div>;
};

StepTitle.defaultProps = {
	className: '',
	stepTitle: 'Step Title'
};

StepTitle.propTypes = {
	className: PropTypes.string,
	stepTitle: PropTypes.string
};

export default StepTitle;
