import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SearchFilter from '../SearchFilter';
import { Button } from 'reactstrap';

class BusinessSearchFilter extends Component {
	cancel = () => {
		this.props.revertToTempBusinessFilter();
		this.props.toggleShowFilter(false);
	};

	render() {
		const { isSV, toggleShowFilter } = this.props;
		const classProps = classnames('business-search-filter-wrapper', this.props.className);
		return (
			<div className={classProps}>
				<SearchFilter
					className="business-search-filter"
					addFilter={this.props.addFilter}
					removeFilter={this.props.removeFilter}
					onFilterUpdate={this.props.onFilterUpdate}
					filters={this.props.filters}
					hasFilters={this.props.hasFilters}
				/>
				{isSV && (
					<div className="business-search-filter-footer">
						<Button active onClick={toggleShowFilter.bind(this, false)}>
							APPLY
						</Button>
						<Button onClick={this.cancel}>CANCEL</Button>
					</div>
				)}
			</div>
		);
	}
}

BusinessSearchFilter.defaultProps = {};

BusinessSearchFilter.propTypes = {
	addFilter: PropTypes.func.isRequired,
	className: PropTypes.string,
	filteredResults: PropTypes.array,
	filters: PropTypes.shape({
		amenities: PropTypes.object,
		cuisine: PropTypes.object,
		dietaryPreferences: PropTypes.object
	}),
	hasFilters: PropTypes.bool.isRequired,
	initBusinessFilters: PropTypes.func.isRequired,
	isSV: PropTypes.bool.isRequired,
	onFilterUpdate: PropTypes.func,
	removeFilter: PropTypes.func.isRequired,
	revertToTempBusinessFilter: PropTypes.func.isRequired,
	toggleShowFilter: PropTypes.func.isRequired
};

export default BusinessSearchFilter;
