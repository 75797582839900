import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Text } from 'components/atoms';

const DateGridItem = ({ date, className }) => {
	const classProps = classnames('date col-sm-2', className && className);

	return (
		<div className={classProps}>
			<Text size="sm" align="center">
				{moment(date).format('MM/DD')}
			</Text>
		</div>
	);
};

DateGridItem.defaultProps = {};

DateGridItem.propTypes = {
	className: PropTypes.string,
	date: PropTypes.string
};

export default DateGridItem;
