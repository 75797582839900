/* eslint-disable no-case-declarations */
import * as t from '../actions/users/types';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

/**
 * Redux_UsersState
 * @interface Redux_UsersState
 * @property {boolean}  authenticated
 * @property {date}     [authenticated_time=undefined]
 * @property {boolean}  [resetPassword=false]
 * @property {boolean}  [migrated=false]
 * @property {boolean}  [expired=false]
 * @property {string}   [errorCode='']
 * @property {object}   [user=undefined]
 * @property {boolean}  [refreshingUser=false]
 */
const usersDefaultState = {
	authenticated: cookies.get('token') ? cookies.get('token') : '',
	authenticated_time: undefined,
	resetPassword: false,
	migrated: false,
	expired: false,
	errorCode: '',
	user: undefined,
	refreshingUser: false
};

export default (state = usersDefaultState, action) => {
	switch (action.type) {
		case t.AUTH_USER: {
			if (action.payload === '') {
				return {
					...usersDefaultState,
					authenticated: action.payload
				};
			} else {
				return { ...state, authenticated: action.payload };
			}
		}
		case t.REFRESH_USER:
			return { ...state, authenticated: action.payload };
		case t.SET_USER:
			return { ...state, user: action.payload };
		case t.SET_USER_TRANSACTIONS:
			let user = state.user;
			if (window.Cypress) {
				// Return Test Data if in Cypress Mode
				const cypressStub = require('./test_data/users/transactions/stub').default;
				return cypressStub(state, user);
			}

			return {
				...state,
				user: {
					...user,
					transactions: {
						...user.transactions,
						[Object.keys(action.payload)[0]]: action.payload[Object.keys(action.payload)[0]]
					}
				}
			};
		case t.FETCH_USER_ERROR:
		case t.AUTH_ERROR:
			return { ...state, errorCode: action.payload };
		case t.MIGRATE_PASSWORD:
			return { ...state, resetPassword: true, migrated: true, user: action.payload };
		case t.RESET_PASSWORD:
			return { ...state, resetPassword: true, migrated: false, user: action.payload };
		case t.CLEAR_ERROR:
			return { ...state, errorCode: '' };
		case t.REFRESH_USER_RUNNING:
			return { ...state, refreshingUser: action.payload };
		case t.REFRESH_TIME:
			let moment = undefined;
			if (action.payload) {
				moment = action.payload;
			}
			return { ...state, authenticated_time: moment };
		default:
			return state;
	}
};
