import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { last, startCase } from 'lodash';

const ActiveFiltersBar = ({ IMAGE_BASE_URL, filters, removeFilter, selectedFilters }) => {
	const classProps = classnames('active-filter-bar', selectedFilters && selectedFilters.length > 0 && 'active'),
		// sf = selectedFilter
		isUnavailableSelected = sf => {
			let filter = filters[sf.dataAccr.split('.')[0]][sf.dataAccr.split('.')[1]],
				isUnavailable = filter ? filter.resultCount === 0 : true;

			return isUnavailable;
		},
		itemClassProps = sf => classnames('active-filter-item', isUnavailableSelected(sf) && 'unavailable');

	return (
		<div className={classProps}>
			{selectedFilters && selectedFilters.length > 0 && (
				<React.Fragment>
					<div className="text sm">Filters: </div>
					<div className="active-filter-list-wrapper">
						{selectedFilters.map(f => {
							let name = startCase(last(f.dataAccr.split('.')));

							return (
								<div key={name} className={itemClassProps(f)} onClick={removeFilter.bind(this, f)}>
									{name}
									{isUnavailableSelected(f) ? (
										<div className="grey-x">
											<div className="before" />
											<div className="after" />
										</div>
									) : (
										<img src={`${IMAGE_BASE_URL}/icons/icon-close-red.svg`} />
									)}
								</div>
							);
						})}
					</div>
				</React.Fragment>
			)}
		</div>
	);
};

ActiveFiltersBar.defaultProps = {};

ActiveFiltersBar.propTypes = {
	selectedFilters: PropTypes.arrayOf(
		PropTypes.shape({
			dataAccr: PropTypes.string.isRequired
		})
	),
	filters: PropTypes.arrayOf(
		PropTypes.shape({
			dataAccr: PropTypes.string.isRequired
		})
	),
	IMAGE_BASE_URL: PropTypes.string.isRequired,
	removeFilter: PropTypes.func
};

export default ActiveFiltersBar;
