import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'reactstrap';
import { loadRecentSearches } from 'utils/search';
import { history } from 'utils/router';
import { RecentSearches } from 'components/molecules';
import { PlaceAutocomplete } from 'components/organisms';
import { fetchReverseGeoLocation } from 'utils/reverse_geo_location';

class SearchDropdown extends Component {
	constructor(props) {
		super(props);

		this.setRef = this.setRef.bind(this);
		this.handleOutsideClick = this.handleOutsideClick.bind(this);

		this.state = {
			dropdownFocused: false
		};
	}

	componentDidMount = () => {
		window.addEventListener('click', this.handleOutsideClick);
		window.addEventListener('keyup', this.handleKeyUp);
	};

	componentWillUnmount = () => {
		window.removeEventListener('click', this.handleOutsideClick);
		window.removeEventListener('keyup', this.handleKeyUp);
	};

	handleOutsideClick = e => {
		if (this.wrapper) {
			if (this.wrapper.contains(e.target)) {
				// is inside click
				this.setDropdownFocus(true);
			} else {
				// is outside click
				this.setDropdownFocus(false);
			}
		}
	};

	handleKeyUp = e => {
		if (e.key === 'Enter' && this.state.dropdownFocused) {
			this.setDropdownFocus(false);
		}
	};

	types = {
		business: 'NearByAll',
		private: 'NearByPrivateDining',
		catering: 'NearByCatering'
	};

	setDropdownFocus = isFocused => {
		this.setState({ dropdownFocused: isFocused });
	};

	setRef = c => {
		this.wrapper = c;
		if (this.props.forwardRef) {
			this.props.forwardRef(c);
		}
	};

	render() {
		const {
			autoComplete,
			className,
			children,
			geoLocationServicesDenied,
			show,
			type,
			recent,
			hasGeoLocation,
			hasAutoCompleteResults,
			recentCallback,
			translate
		} = this.props;

		const recentSearches = loadRecentSearches(hasGeoLocation, recent, type);
		const showDropdown =
			this.state.dropdownFocused &&
			((className === 'near-field' && !geoLocationServicesDenied) || // only apply geolocation if is near input
			hasAutoCompleteResults ||
			(autoComplete && autoComplete.inputValue && autoComplete.inputValue.length) ||
			recentSearches.length
				? true
				: false);

		const classProps = classNames('search-dropdown', show && showDropdown && 'show', className);

		return (
			<div className={classProps} ref={this.setRef}>
				{type !== 'catering' && children}
				{type === 'catering' && (
					<autoComplete.input value={autoComplete.inputValue} onChange={autoComplete.onChange} />
				)}
				{showDropdown && (
					<div className={'dropdown-list'}>
						<PlaceAutocomplete ref={c => (this.autoComplete = c)} key="place-autocomplete" />
						{type === 'catering' && (
							<autoComplete.dropdown onSelect={autoComplete.onSelect} key="props-autocomplete-cmp" />
						)}
						{!(type === 'business' && recent === 'keywords') && !geoLocationServicesDenied && (
							<Button
								key="nearby-btn"
								className="nearby-btn"
								block
								outline
								onClick={type === 'catering' ? this.handleCateringSearch : this.handleSearch}
							>
								{translate('Home.searchDropdown.nearbyBtn')}
							</Button>
						)}
						<RecentSearches
							recent={recent}
							recentCallback={recentCallback}
							type={type}
							key="recent-searches"
						/>
					</div>
				)}
			</div>
		);
	}

	handleCateringSearch = async () => {
		let geoLocation = {
			coords: {
				latitude: this.props.geoLocation.lat,
				longitude: this.props.geoLocation.lng
			}
		};
		if (!this.props.geoLocation.lat) {
			try {
				geoLocation = await this.props.getLocation();
			} catch (err) {
				geoLocation = false;
			}
		}

		if (geoLocation) {
			const { latitude, longitude } = geoLocation.coords;
			const pos = { lat: latitude, lng: longitude };
			const zones = await this.props.getZones(pos);
			if (zones.length > 0) {
				const address = await fetchReverseGeoLocation(pos, (results, status) => {
					if (status === 'OK') {
						if (results[0]) {
							return results[0].formatted_address;
						}
					}
				});

				if (address) {
					this.props.setShowNoServiceNotice(false);
					this.props.setAddress(address);
					history.push('/catering/#/');
				}
			} else {
				this.props.setShowNoServiceNotice(true);
			}
		}
	};

	handleSearch = async () => {
		if (this.props.nearBySearch) {
			this.props.setNearBusinessSearch('');
			await this.props.nearBySearch();
		} else {
			let geoLocation = {
				coords: {
					latitude: this.props.geoLocation.lat,
					longitude: this.props.geoLocation.lng
				}
			};
			if (!this.props.geoLocation.lat) {
				try {
					geoLocation = await this.props.getLocation();
				} catch (err) {
					geoLocation = false;
				}
			}

			if (geoLocation) {
				const { setSearchSuggestion, type, findBusinessSearch } = this.props;

				setSearchSuggestion(this.types[type]);

				let URL = `/search?location=${geoLocation.coords.latitude},${geoLocation.coords.longitude}`;

				if (findBusinessSearch && findBusinessSearch.length) {
					URL += `&keywords=${findBusinessSearch}`;
				}
				history.push(URL);
			}
			if (this.props.onSearch) {
				this.props.onSearch();
			}
		}
	};

	handleClear = () => {
		const { setBusinessSearch, setSearchSuggestion } = this.props;
		setBusinessSearch();
		setSearchSuggestion();
	};
}

SearchDropdown.defaultProps = {
	className: '',
	show: false,
	type: 'business',
	recent: 'all'
};

SearchDropdown.propTypes = {
	autoComplete: PropTypes.object,
	className: PropTypes.string,
	hasAutoCompleteResults: PropTypes.bool,
	geoLocationServicesDenied: PropTypes.bool,
	nearBySearch: PropTypes.func, // requried if business tab
	setError: PropTypes.func, // required if business tab
	getLocation: PropTypes.func, // required if business tab
	hasGeoLocation: PropTypes.bool,
	geoLocation: PropTypes.shape({
		lat: PropTypes.number,
		lng: PropTypes.number
	}),
	isLoading: PropTypes.func.isRequired,
	setNearBusinessSearch: PropTypes.func, // required if using props.nearBySearch. new biz search
	setBusinessSearch: PropTypes.func, // legacy biz seach home.nearSearch
	setSearchSuggestion: PropTypes.func,
	getZones: PropTypes.func,
	findBusinessSearch: PropTypes.string,
	children: PropTypes.node,
	show: PropTypes.bool,
	forwardRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
	type: PropTypes.oneOf(['business', 'private', 'catering']),
	recent: PropTypes.oneOf(['keywords', 'near', 'all']),
	recentCallback: PropTypes.func,
	handleGetLocation: PropTypes.func,
	onSearch: PropTypes.func,
	setAddress: PropTypes.func,
	setShowNoServiceNotice: PropTypes.func,
	translate: PropTypes.func.isRequired
};

export default SearchDropdown;
