import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { getTranslate } from 'react-localize-redux';
import { SEARCH_TYPES } from 'components/molecules/SearchTabs';

const SearchTypes = props => {
	const { className, onSelectTab, activeTab, translate } = props;

	const classProps = classNames('search-types', className);

	return (
		<Nav tabs className={classProps}>
			{SEARCH_TYPES.map((searchType, index) => {
				return (
					<NavItem key={index} className={classNames({ active: activeTab === searchType.key })}>
						<NavLink onClick={() => onSelectTab(searchType.key)}>
							{translate(`Home.searchTypes.${searchType.key}`)}
							{searchType.title}
						</NavLink>
					</NavItem>
				);
			})}
		</Nav>
	);
};

SearchTypes.defaultProps = {
	className: ''
};

SearchTypes.propTypes = {
	className: PropTypes.string,
	activeTab: PropTypes.string.isRequired,
	onSelectTab: PropTypes.func.isRequired,
	translate: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	translate: getTranslate(state.locale)
});

export default connect(mapStateToProps)(SearchTypes);
