import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const HamburgerIcon = ({ className, config, handleClick, translate }) => (
	<div className={`${className || ''} Di_HeaderHambMenu`}>
		<Link onClick={e => handleClick(e, 'Mobile_Main')} to="/">
			<img
				className="hamburger-icon"
				src={`${config.cdn_basePath}/icons/icon-hamburger.svg`}
				alt={translate('header.dinova-alt')}
			/>
		</Link>
	</div>
);

HamburgerIcon.propTypes = {
	className: PropTypes.string,
	// Required
	config: PropTypes.object.isRequired,
	handleClick: PropTypes.func.isRequired,
	translate: PropTypes.func.isRequired
};

export default HamburgerIcon;
