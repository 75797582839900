import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import { getTranslate } from 'react-localize-redux';
import { translationExists } from 'utils/localize';

const translateOptions = {
	showMissingTranslationMsg: false
};

class DinovaMetaTags extends Component {
	titleMeta = (meta, index) => {
		return translationExists(meta.translateKey) ? (
			<title key={`title-${index}`}>{this.props.translate(meta.translateKey, null, translateOptions)}</title>
		) : null;
	};

	meta = (meta, index) => {
		return (
			<meta
				key={`meta-${index}`}
				name={meta.name}
				content={this.props.translate(meta.translateKey, null, translateOptions)}
				property={meta.property || null}
			/>
		);
	};

	render() {
		return (
			<MetaTags>
				{this.props.tags.map((meta, i) => {
					if (meta.type === 'title') {
						return this.titleMeta(meta, i);
					} else if (meta.type === 'meta') {
						return this.meta(meta, i);
					}
				})}
			</MetaTags>
		);
	}
}

DinovaMetaTags.defaultProps = {
	tags: []
};

/**
 * MetaConfig - Config object for generating a meta tag.
 * @type {Object} MetaConfig
 * @property {string} type         The type of meta tag, can be "title" or "meta".
 * @property {string} translateKey The data accessor for the translated/localized value of this meta tag.
 * @property {string} [name]       The name of the meta tag.  Note: This is only required if type = 'meta'.
 * @property {string} [property]   The property of the meta tag.
 */

/**
 * DinovaMetaTags Props
 * @interface Props_DinovaMetaTags
 * @property {MetaConfig[]} tags
 * @property {function}     translate
 */
DinovaMetaTags.propTypes = {
	tags: PropTypes.arrayOf(
		PropTypes.shape({
			type: PropTypes.string.isRequired,
			translateKey: PropTypes.string.isRequired,
			name: PropTypes.string,
			property: PropTypes.string
		})
	),
	translate: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	translate: getTranslate(state.locale)
});

export default connect(mapStateToProps)(DinovaMetaTags);
