import React from 'react';
import PropTypes from 'prop-types';
import { Text, Title } from 'components/atoms';
import { CTANav } from 'components/molecules';

class CateringServiceNotice extends React.Component {
	componentDidMount = async () => {
		if (!this.props.links.length) {
			await this.props.getZones();
		}
	};

	render() {
		const { links, translate } = this.props;

		return (
			<div className="service-notice catering">
				<Title transform="none">{translate('Catering.serviceNotice.title')}</Title>
				<Text paragraph size="sm" align="center">
					{translate('Catering.serviceNotice.description')}
				</Text>
				<Title transform="none" size="h5">
					{translate('Catering.serviceNotice.subTitle')}
				</Title>
				{links.length > 0 && <CTANav links={links} />}
			</div>
		);
	}
}

CateringServiceNotice.defaultProps = {
	getZones: () => {
		console.log('default props: getZones()');
	},
	links: []
};

CateringServiceNotice.propTypes = {
	getZones: PropTypes.func.isRequired,
	links: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
			href: PropTypes.string
		})
	),
	translate: PropTypes.func.isRequired
};

export default CateringServiceNotice;
