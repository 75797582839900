import SearchFilter from './ui-component';
import withSizes from 'react-sizes';
import { isSV, isMV } from 'utils/sizes';

const mapSizesToProps = sizes => ({
	isSV: isSV(sizes),
	isMV: isMV(sizes)
});

export default withSizes(mapSizesToProps)(SearchFilter);
