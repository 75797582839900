import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'third-party/mdbreact/dist/mdbreact.js';

const DashboardPromotions = props => {
	const { translate } = props;

	return (
		<div>
			{(translate('Dashboard.numberOfPromotions') === '3' ||
				translate('Dashboard.numberOfPromotions') === '1') && (
				<Row>
					<Col md="12">
						<img src={`${this.props.config.cdn_basePath}/mydinova/Promotions/one-lv.png`} />
					</Col>
				</Row>
			)}
			{(translate('Dashboard.numberOfPromotions') === '3' ||
				translate('Dashboard.numberOfPromotions') === '2') && (
				<Row className="no-gutters">
					<Col md="6" className="no-margin">
						<img src={`${this.props.config.cdn_basePath}/mydinova/Promotions/two-lv.png`} />
					</Col>
					<Col md="6" className="no-margin">
						<img src={`${this.props.config.cdn_basePath}/mydinova/Promotions/three-lv.png`} />
					</Col>
				</Row>
			)}
		</div>
	);
};

DashboardPromotions.propTypes = {
	className: PropTypes.string,
	translate: PropTypes.func.isRequired
};

export default DashboardPromotions;
