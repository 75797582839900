import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Input } from 'third-party/mdbreact/dist/mdbreact.js';

const SearchModify = ({ className, onClick, locationCount, searchResultMessage, isLV, isSV, translate }) => {
	const classProps = classNames('search-modify', className);
	const searchValue = locationCount + searchResultMessage;
	const modifyLabel = 'Search.modifySearch' + (!isLV ? 'Short' : '');

	return (
		<div className={classProps}>
			{isLV && <span className="search-label">{translate('Search.results')}</span>}
			<div className="search-input-wrapper">
				<i className="search-icon fa fa-fw fa-search" aria-hidden="true" />
				<Input
					containerClass="search-input"
					readOnly
					onClick={onClick}
					value={searchValue}
					data-modal-id="modify"
				/>
			</div>
			{!isSV && (
				<Button className="modify-btn" color="primary" onClick={onClick} data-modal-id="modify">
					{translate(modifyLabel)}
				</Button>
			)}
		</div>
	);
};

SearchModify.defaultProps = {
	className: ''
};

SearchModify.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func.isRequired,
	locationCount: PropTypes.number.isRequired,
	searchResultMessage: PropTypes.string.isRequired,
	isLV: PropTypes.bool,
	isSV: PropTypes.bool,
	translate: PropTypes.func.isRequired
};

export default SearchModify;
