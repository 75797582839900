import PlaceAutocomplete from './ui-component';
import { connect } from 'react-redux';
import { setBusinessSearch, setSearchSuggestion } from 'actions/home';

const mapStateToProps = state => ({
	foundPlaces: state.map.foundPlaces
});

const mapDispatchToProps = dispatch => ({
	setBusinessSearch: value => dispatch(setBusinessSearch(value)),
	setSearchSuggestion: value => dispatch(setSearchSuggestion(value))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PlaceAutocomplete);
