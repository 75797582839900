import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { toggleBodyClassName } from 'utils/DOM';

class BasicModal extends React.Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	componentDidMount() {
		if (this.props.show) {
			toggleBodyClassName('basic-modal-open', true);
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.show !== prevProps.show) {
			if (this.props.show) {
				toggleBodyClassName('basic-modal-open', true);
			} else {
				toggleBodyClassName('basic-modal-open', false);
			}
		}
	}

	componentWillUnmount() {
		toggleBodyClassName('basic-modal-open', false);
	}

	render() {
		const { children, className, iconBaseURL, onClose, show, showClose } = this.props,
			classProps = classnames('basic-modal', className);

		return show ? (
			<div className={classProps}>
				<div className="basic-modal-content-wrapper">
					{showClose && (
						<div className="close-wrapper">
							<img
								src={`${iconBaseURL}/icon-close-black.svg`}
								alt="green close button"
								className="close-btn"
								onClick={onClose}
							/>
						</div>
					)}
					<div className="basic-modal-content">{children}</div>
				</div>
			</div>
		) : null;
	}
}

BasicModal.defaultProps = {
	showClose: true
};

BasicModal.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	iconBaseURL: PropTypes.string.isRequired,
	onClose: PropTypes.func,
	showClose: PropTypes.bool,
	show: PropTypes.bool.isRequired
};

export default BasicModal;
