import React from 'react';
import PropTypes from 'prop-types';
import { Text, Title } from 'components/atoms';
import { Link } from 'react-router-dom';

const DashboardConnectMessage = ({ translate }) => {
	return (
		<div className="dashboard-connect-message">
			<Title size="h6" transform="none">
				{translate('Rewards.connectQ')}
			</Title>
			<div className="content">
				<Text paragraph>{translate('Rewards.connectMsg')}</Text>
				<Link to="/users/connect" className="btn btn-primary">
					{translate('Rewards.morePoints')}
				</Link>
			</div>
		</div>
	);
};

DashboardConnectMessage.defaultProps = {
	translate: () => {}
};

DashboardConnectMessage.propTypes = {
	translate: PropTypes.func.isRequired
};

export default DashboardConnectMessage;
