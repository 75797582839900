import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Displays keywords associated with a restaurant. Used in {@link DetailsPage}.
 * @param {KeywordsSectionProps} props
 */
const KeywordsSection = ({ className, keywords, translate }) => {
	const classProps = classNames('keywords-section', className);

	return (
		<div className={classProps}>
			<span className="label">{translate('Details.keywords')}</span> <span className="value">{keywords}</span>
		</div>
	);
};

KeywordsSection.defaultProps = {
	className: '',
	keywords: ''
};

/**
 * {@link KeywordsSection} Props
 * @interface Props_KeywordsSection
 * @property {string} [className=""] Additional className to add to {@link KeywordsSection} wrapper.
 * @property {string} [keywords=""] Restaurant keywords.
 */
KeywordsSection.propTypes = {
	className: PropTypes.string,
	keywords: PropTypes.string,
	translate: PropTypes.func.isRequired
};

export default KeywordsSection;
