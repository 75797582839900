import React from 'react';
import PropTypes from 'prop-types';

const CenterMapBtn = ({ centerMapButtonCallback }) => (
	<button className="center-map-btn" onClick={centerMapButtonCallback} />
);

CenterMapBtn.propTypes = {
	centerMapButtonCallback: PropTypes.func.isRequired
};

export default CenterMapBtn;
