import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { LoadingBase } from 'components/atoms';

const List = ({ className, items, itemComponent, listId, onItemClick }) => {
	const ItemComponent = itemComponent;
	const classProps = classnames('list', className);

	return (
		<div className={classProps}>
			{items.length === 0 ? (
				<LoadingBase />
			) : (
				items.map((item, index) => (
					<ItemComponent item={item} key={`${listId}-${index}`} onItemClick={onItemClick} />
				))
			)}
		</div>
	);
};

List.defaultProps = {
	items: []
};

List.propTypes = {
	className: PropTypes.string,
	items: PropTypes.array.isRequired,
	itemComponent: PropTypes.node.isRequired,
	listId: PropTypes.string.isRequired,
	onItemClick: PropTypes.func
};

export default List;
