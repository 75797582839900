import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Modal, ModalBody, ModalHeader, ModalFooter, Row, Col } from 'reactstrap';
import { Button } from 'reactstrap';
import { Autocomplete, RequiredFieldNote } from 'components/molecules';

class AutoCompleteModal extends Component {
	state = {
		error: undefined,
		item: undefined,
		accordion: undefined,
		showHelp: false
	};

	onPrimaryClick = () => {
		if (!this.state.item || this.state.item === '') {
			this.setState({
				error: this.props.modal.error
			});
		} else {
			this.setState({ error: undefined });
			this.onToggle();
			this.props.primaryClick && this.props.primaryClick(this.state.item);
		}
	};

	onSecondaryClick = () => {
		//this.onToggle();
		this.setState({ accordion: `accordion-${this.props.textId}` });
		this.props.secondaryClick && this.props.secondaryClick(this.state.item);
	};

	onTertiaryClick = () => {
		this.onToggle();
		this.props.tertiaryClick && this.props.tertiaryClick();
	};

	onToggle = () => {
		const isOpen = !this.props.isOpen;
		this.props.toggleModal(isOpen);
	};

	onDismiss = () => {
		this.setState({ visible: false });
	};

	onChoice = val => {
		console.log('value:', val);
		this.setState({
			item: val,
			error: null
		});
	};

	onFocus = () => {
		this.setState({ error: undefined });
		this.props.onFocus();
	};

	onHelpClick = (/*accordion*/) => {
		this.setState({ showHelp: !this.state.showHelp });
	};

	footer = {};

	render() {
		const {
			onCancel,
			modal,
			completions,
			autocomplete,
			languageNode,
			onBlur,
			onChange,
			name,
			textId,
			config,
			loading
		} = this.props;

		return (
			<Modal isOpen={modal.autoCompleteIsOpen} toggle={onCancel} fade={true} centered className="autoComplete">
				{modal.header && (
					<ModalHeader>
						{modal.header}
						{onCancel && <button className="close-btn" onClick={onCancel} title={modal.closeTitle} />}
					</ModalHeader>
				)}
				<ModalBody>
					<Row>
						<Col className="bodyText">{modal.body}</Col>
					</Row>
					<Row>
						<Col>
							<RequiredFieldNote />
							<Autocomplete
								filteredSuggestions={completions}
								showSuggestions={autocomplete.completions.length > 0}
								languageNode={languageNode}
								color="primary"
								styleName="ReqIcons"
								onFocus={this.onFocus}
								onBlur={onBlur}
								onChange={onChange}
								onChoice={this.onChoice}
								name={name}
								id={textId}
								errorText={this.state.error}
								value={this.state.item}
								label={modal.label}
								help={modal.help}
								config={config}
								onHelpClick={this.onHelpClick}
								showHelp={this.state.showHelp}
								accordion={this.state.accordion}
								loading={loading}
							/>
						</Col>
					</Row>
				</ModalBody>

				<ModalFooter>
					<Container>
						<Row>
							<Col sm="12">
								<Button
									block
									className="z-depth-0"
									onClick={this.onPrimaryClick.bind(this)}
									color="primary"
								>
									{modal.primaryButtonText}
								</Button>
							</Col>
						</Row>
						{modal.secondaryButtonText && (
							<Row>
								<Col sm="12">
									{modal.secondaryButtonText && (
										<Button block className="z-depth-0" onClick={this.onHelpClick.bind(this)}>
											{modal.secondaryButtonText}
										</Button>
									)}
								</Col>
							</Row>
						)}
						{modal.tertiaryButtonText && (
							<Row>
								<Col sm="12">
									<Button
										block
										className="z-depth-0"
										onClick={this.onTertiaryClick.bind(this)}
										color="link"
									>
										{modal.tertiaryButtonText}
									</Button>
								</Col>
							</Row>
						)}
					</Container>
				</ModalFooter>
			</Modal>
		);
	}
}

AutoCompleteModal.propTypes = {
	modal: PropTypes.object.isRequired,
	primaryClick: PropTypes.func.isRequired,
	secondaryClick: PropTypes.func.isRequired,
	tertiaryClick: PropTypes.func.isRequired,
	toggleModal: PropTypes.func.isRequired,
	onFocus: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	textId: PropTypes.string.isRequired,
	isOpen: PropTypes.bool.isRequired,
	completions: PropTypes.array.isRequired,
	autocomplete: PropTypes.object.isRequired,
	languageNode: PropTypes.object.isRequired,
	onBlur: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	config: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired
};

export default AutoCompleteModal;
