import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col } from 'reactstrap';
import { Container, Row, CardBody } from 'third-party/mdbreact/dist/mdbreact.js';
import { Button } from 'reactstrap';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import queryString from 'query-string';
import FormFieldRequirements from '../utilities/FormFieldRequirements';
import metaTagConfig from './meta_tags/reset-meta-tags';
import { MetaTags } from 'components/molecules';
import { GDPR } from 'components/organisms';
import { login, forgotPassword } from '../../../actions/users';
import ModalPage from 'components/old-pattern/inline/ModalPage';
import { setModal, toggleModal } from '../../../actions/modal';
import { InputField } from 'components/organisms';
import PageError from 'components/old-pattern/utilities/PageError';

class Reset extends React.Component {
	adminInitiated = this.props.location.pathname === '/change';
	migrated = this.props.users.migrated;

	constructor(props) {
		super(props);
		this.handleTextInputFocus = this.handleTextInputFocus.bind(this);
		this.handleTextInputBlur = this.handleTextInputBlur.bind(this);

		this.state = {
			input_password: '',
			hasErrors: false,
			formFields: {
				username: {
					isRequired: true,
					isValid: true,
					errorText: '',
					title: this.props.translate('Registration.requirement-boxes.username.title'),
					value: this.migrated ? this.props.users.user.userName : '',
					requirements: [
						{
							type: 'length',
							characters: 3,
							status: this.migrated ? 'valid' : 'invalid',
							text: this.props.translate('Registration.requirement-boxes.username.1')
						}
					]
				},
				password: {
					isRequired: true,
					isValid: true,
					errorText: '',
					title: this.props.translate('Registration.requirement-boxes.password.title'),
					value: '',
					requirements: [
						{
							type: 'length',
							characters: 8,
							status: 'invalid',
							text: this.props.translate('Registration.requirement-boxes.password.1')
						},
						{
							type: 'contains_number',
							status: 'invalid',
							text: this.props.translate('Registration.requirement-boxes.password.2')
						},
						{
							type: 'contains_lowercase',
							status: 'invalid',
							text: this.props.translate('Registration.requirement-boxes.password.3')
						},
						{
							type: 'contains_uppercase',
							status: 'invalid',
							text: this.props.translate('Registration.requirement-boxes.password.4')
						}
					],
					passwordIsVisible: true
				}
			},
			isPasswordVisible: false,
			gdpr: {}
		};

		this.props.setModal({
			id: this.props.translate('Registration.modals.cancel.id'),
			header: this.props.translate('Registration.modals.cancel.header'),
			body: this.props.translate('Registration.modals.cancel.body'),
			primaryButtonText: this.props.translate('Registration.modals.cancel.primaryButtonText'),
			secondaryButtonText: this.props.translate('Registration.modals.cancel.secondaryButtonText')
		});
	}

	handleTextInputFocus(event) {
		this.setState({
			[event.target.name]: 'focused'
		});
	}

	onSubmit = e => {
		e.preventDefault();
		var valid = true;

		this.checkRequirements()
			.then(this.checkRequirementStatus)
			.then(this.checkGDPR)
			.catch(e => {
				valid = false;
				console.log(e);
				this.setState(() => ({ hasErrors: true }));
			})
			.finally(() => {
				if (valid) {
					if (this.migrated) {
						const user = {
							userName: this.props.users.user.userName,
							password: this.state.formFields.password.value,
							passwordResetToken: this.props.users.user.passwordResetToken,
							preferences: {
								notification: {
									monthlySummary: this.state.gdpr.optIn.email.value === 'yes' ? 'email' : 'none',
									specialOffers: this.state.gdpr.optIn.offers.value === 'yes' ? 'email' : 'none'
								}
							},
							acceptedTC: true
						};

						this.props.login(user, (/*response*/) => {
							this.showModal();
						});
					} else {
						this.props.forgotPassword(
							{
								token: queryString.parse(this.props.location.search).token.replace('/', ''),
								password: this.state.formFields.password.value,
								email: this.state.formFields.username.value
							},
							(/*response*/) => {
								this.showModal();
							}
						);
					}
				}
			});
	};

	updateGDPR = gdprState => {
		this.setState({
			...this.state,
			gdpr: {
				...gdprState
			}
		});
	};

	onCancel = e => {
		e.preventDefault();
		this.onToggle();
	};

	showModal = () => {
		console.log('showing modal');
		this.props.setModal({
			id: this.props.translate('reset.modals.ok.id'),
			header: this.props.translate('reset.modals.ok.title'),
			body: this.props.translate('reset.modals.ok.body'),
			primaryButtonText: this.props.translate('reset.modals.ok.primaryButtonText'),
			secondaryButtonText: undefined
		});
		this.onToggle();
	};

	setPasswordVisiblityForDesktop() {
		if (window.matchMedia('(min-width: 1024px)').matches) {
			this.setState({
				formFields: {
					...this.state.formFields,
					password: {
						...this.state.formFields.password,
						passwordIsVisible: !this.state.formFields.password.passwordIsVisible
					}
				}
			});
		}
	}

	handleTextChange = e => {
		const value = e.target.value.replace(/^\s+|\s+$/g, '');
		const targetNode = this.state.formFields[e.target.id.replace('input-', '')];
		targetNode.value = value;

		this.setState(() => ({
			targetNode
		}));

		if (targetNode.requirements.length > -1) {
			targetNode.requirements.forEach(requirement => {
				if (requirement.type === 'length') {
					const length = requirement.characters;
					requirement.status = value.length >= length ? 'valid' : 'invalid';
				}
				if (requirement.type === 'contains_number') {
					requirement.status = value.match(/\d/) ? 'valid' : 'invalid';
				}
				if (requirement.type === 'contains_lowercase') {
					requirement.status = value.match(/[a-z]/) ? 'valid' : 'invalid';
				}
				if (requirement.type === 'contains_uppercase') {
					requirement.status = value.match(/[A-Z]/) ? 'valid' : 'invalid';
				}
				this.setState(() => ({ requirement }));
			});
		}
	};

	handleTextInputBlur = e => {
		if (e.target.value.length === 0) {
			this.setState({
				[e.target.name]: 'blurred'
			});
		}
		const value = e.target.value.replace(/^\s+|\s+$/g, '');
		const targetNode = this.state.formFields[e.target.id.replace('input-', '')];
		targetNode.value = value;
		this.setState(() => ({ targetNode }));
	};

	checkRequirements = () => {
		return new Promise((resolve /*reject*/) => {
			var isValid = true;
			Object.entries(this.state.formFields).forEach(field => {
				const formFieldDefinition = field[1];
				this.setCleanState(formFieldDefinition);
				if (formFieldDefinition.isRequired && formFieldDefinition.value.replace(/^\s+|\s+$/g, '') === '') {
					if (formFieldDefinition.errorText === '') {
						formFieldDefinition.errorText = `${field[0].capitalizeFirstLetter()} is required`;
					}
					this.setErrorState(formFieldDefinition);
					isValid = false;
				} else if (formFieldDefinition.requirements.length > -1) {
					formFieldDefinition.requirements.forEach(requirement => {
						if (requirement.type === 'email') {
							if (!this.isValidEmail(formFieldDefinition.value)) {
								if (formFieldDefinition.errorText === '') {
									formFieldDefinition.errorText = 'Please enter a valid email address';
								}
								this.setErrorState(formFieldDefinition);
								isValid = false;
							} else {
								requirement.status = 'valid';
							}
						}
					});
				}
			});
			if (isValid) {
				resolve('Valid state');
			} else {
				resolve(Error('Error state'));
			}
		});
	};

	checkGDPR = () => {
		return new Promise((resolve, reject) => {
			console.log('checking gdpr');
			let isValid = true;
			if (this.state.gdpr && this.migrated) {
				console.log('inside');
				isValid = this.state.gdpr.isValid;
				this.setState(() => ({ hasErrors: this.state.gdpr.isValid }));
			}
			console.log('valid:', isValid);
			if (isValid) {
				resolve('Valid state');
			} else {
				reject(Error('Error state'));
			}
		});
	};

	checkRequirementStatus = () => {
		return new Promise((resolve, reject) => {
			var isValid = true;
			Object.entries(this.state.formFields).forEach(field => {
				field[1].requirements
					.slice()
					.reverse()
					.forEach(requirement => {
						if (requirement.type === 'email') {
							if (!this.isValidEmail(field[1].value)) {
								if (field[1].errorText === '') {
									field[1].errorText = 'Please enter a valid email address';
								}
								this.setErrorState(field[1]);
								isValid = false;
							}
						}
						if (requirement.status === 'invalid') {
							if (field[1].errorText === '') {
								field[1].errorText = requirement.text;
							}
							this.setErrorState(field[1]);
							isValid = false;
						}
						return !isValid;
					});
			});
			if (isValid) {
				resolve('Valid state');
			} else {
				reject(Error('Error state'));
			}
		});
	};

	setErrorState = targetNode => {
		const hasErrors = true;
		targetNode.isValid = false;
		this.setState(() => ({ hasErrors, targetNode }));
	};

	setCleanState = targetNode => {
		const hasErrors = false;
		targetNode.isValid = true;
		targetNode.errorText = '';
		this.setState(() => ({ hasErrors, targetNode }));
	};

	onToggle = () => {
		const isOpen = !this.props.modal.isOpen;
		this.props.toggleModal(isOpen);
	};

	onToggle2 = () => {
		const isOpen = !this.props.modal.isOpen;
		this.props.toggleModal2(isOpen);
	};

	onPrimaryClick = () => {
		// eslint-disable-next-line no-undef
		this.props.modal.id === 'cancel' ? this.onToggle() : this.props.history.push('/');
	};

	onSecondaryClick = () => {
		this.props.history.push('/');
	};

	onCancel = e => {
		e.preventDefault();
		this.props.setModal({
			id: this.props.translate('reset.modals.cancel.id'),
			header: this.props.translate('reset.modals.cancel.header'),
			body: this.props.translate('reset.modals.cancel.body'),
			primaryButtonText: this.props.translate('reset.modals.cancel.primaryButtonText'),
			secondaryButtonText: this.props.translate('reset.modals.cancel.secondaryButtonText')
		});
		this.onToggle();
	};

	componentDidMount() {
		this.setPasswordVisiblityForDesktop();
	}

	render() {
		let basePath = 'reset';
		if (this.adminInitiated && this.migrated) {
			basePath = 'migration-reset';
		} else if (this.adminInitiated && !this.migrated) {
			basePath = 'forced-reset';
		}
		return (
			<div className="Di_SubHead Di_HomePic">
				<MetaTags tags={metaTagConfig} />
				<ModalPage
					isOpen={this.props.modal.isOpen}
					primaryClick={this.onPrimaryClick}
					secondaryClick={this.onSecondaryClick}
				/>

				<form onSubmit={this.onSubmit}>
					<Container className="container-fluid Di_HeadSpace">
						<Row>
							<div className="Di_PageLimit mx-auto float-none animated fadeInUp">
								<CardBody>
									{this.state.hasErrors || this.props.general.error ? (
										<PageError
											message={
												(this.props.general.error &&
													this.props.translate(
														`errors.codes.${this.props.general.error}.message`
													)) ||
												this.props.translate('errors.page_level_message')
											}
										/>
									) : null}
									<Container>
										<Row>
											<Col xs="12">
												<h1>{this.props.translate(`${basePath}.headline`)}</h1>
											</Col>
										</Row>
										<Row>
											<Col xs="12">{this.props.translate(`${basePath}.body`)}</Col>
										</Row>
										<Row>
											<Col xs="12">
												<div className="Di_RequiredHeader">
													<span>*</span>
													{this.props.translate('reset.required')}
												</div>
											</Col>
										</Row>
										{/*Username */}
										{!this.migrated && (
											<div>
												<Row id="Fld_Username" className={this.state.input_username}>
													<Col
														xs="12"
														className={
															!this.state.formFields.username.isValid
																? 'Di_HasSideIcon Di_HasError'
																: 'Di_HasSideIcon'
														}
													>
														<InputField
															type="text"
															label={this.props.translate('reset.inputs.username.label')}
															error={this.state.formFields.username.errorText}
															hasError={this.state.hasErrors}
															value={this.state.formFields.username.value}
															id="input-username"
															onChange={this.handleTextChange}
															required
														/>
													</Col>
												</Row>
											</div>
										)}

										{/*Password */}
										<Row id="Fld_Password" className={this.state.input_password}>
											<Col xs="12">
												<InputField
													type="password"
													label={this.props.translate('reset.inputs.password.label')}
													error={this.state.formFields.password.errorText}
													hasError={this.state.hasErrors}
													value={this.state.formFields.password.value}
													id="input-password"
													onChange={this.handleTextChange}
													required
												>
													<FormFieldRequirements
														formFieldText={this.state.formFields.password}
													/>
												</InputField>
											</Col>
										</Row>

										{this.migrated && (
											<GDPR
												onClick={() =>
													this.setState({ acceptedTerms: !this.state.acceptedTerms })
												}
												showWarning={!this.state.acceptedTerms && this.state.hasErrors}
												updateGDPR={state => this.updateGDPR(state)}
											/>
										)}
										<Row>
											<Col xs="12" className="Di_ActionBttns">
												<Button type="submit" className="z-depth-0" color="primary">
													{this.props.translate('reset.bttn1')}
												</Button>

												<Button onClick={this.onCancel} className="z-depth-0">
													{this.props.translate('reset.bttn2')}
												</Button>
											</Col>
										</Row>
									</Container>
								</CardBody>
							</div>
						</Row>
					</Container>
				</form>
			</div>
		);
	}
}

Reset.propTypes = {
	forgotPassword: PropTypes.func.isRequired,
	general: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	login: PropTypes.func.isRequired,
	modal: PropTypes.object.isRequired,
	setModal: PropTypes.func.isRequired,
	toggleModal: PropTypes.func.isRequired,
	toggleModal2: PropTypes.func.isRequired,
	translate: PropTypes.func.isRequired,
	users: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	translate: getTranslate(state.locale),
	currentLanguage: getActiveLanguage(state.locale).code,
	modal: state.modal,
	users: state.users,
	general: state.general,
	config: state.config
});

const mapDispatchToProps = dispatch => ({
	login: (user, callback) => dispatch(login(user, callback)),
	setModal: modal => dispatch(setModal(modal)),
	toggleModal: toggle => dispatch(toggleModal(toggle)),
	forgotPassword: (user, callback) => dispatch(forgotPassword(user, callback))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Reset);
