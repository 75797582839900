import React from 'react';
import PropTypes from 'prop-types';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import { connect } from 'react-redux';

const Expired = ({ translate }) => (
	<div>
		<div className="Di_PageLimit-LG container-fluid animated fadeInUp text-center">
			<img src={`${this.props.config.cdn_basePath}/icons/icon-clock-black.svg`} className="Di_ExpiredGRX" />
			<h1 className="text-center">{translate('expired.title')}</h1>
			<p className="text-center">{translate('expired.subtitle')}</p>
			<a href="/forgotten" className="z-depth-0 btn btn-primary">
				{translate('expired.primary_button')}
			</a>
		</div>
	</div>
);

Expired.propTypes = {
	translate: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	translate: getTranslate(state.locale),
	currentLanguage: getActiveLanguage(state.locale).code,
	config: state.config
});

export default connect(mapStateToProps)(Expired);
