import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { pageView } from 'actions/general';

class ScrollToTop extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount(prevProps) {
		this._onChangeRoute(prevProps);
	}

	componentDidUpdate(prevProps) {
		this._onChangeRoute(prevProps);
	}

	render() {
		return this.props.children;
	}

	_onChangeRoute(prevProps) {
		if (!prevProps || this.props.location.pathname !== prevProps.location.pathname) {
			window.scrollTo(0, 0);
			this.props.pageView(this.props.location);
		}
	}
}

ScrollToTop.propTypes = {
	location: PropTypes.object,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	pageView: PropTypes.func,
	history: PropTypes.object
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
	pageView: pathname => dispatch(pageView(pathname))
});

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(ScrollToTop)
);
