// Reset MetaTags config
// @type MetaConfig[]
export default [
	{
		type: 'title',
		translateKey: 'reset.meta-title'
	},
	{
		type: 'meta',
		translateKey: 'reset.meta-description',
		name: 'description'
	},
	{
		type: 'meta',
		translateKey: 'reset.meta-keywords',
		name: 'keywords'
	},
	{
		type: 'meta',
		translateKey: 'reset.og-image',
		name: 'og:image'
	}
];
