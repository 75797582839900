import SpecialOffers from './ui-component';
import { connect } from 'react-redux';
import { hasProp } from 'utils/object';

const mapStateToProps = state => {
	const offers = hasProp(state, 'details.restaurant.specials.offers') ? state.details.restaurant.specials.offers : [];

	return { offers };
};

export default connect(mapStateToProps)(SpecialOffers);
