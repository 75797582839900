import React from 'react';
import PropTypes from 'prop-types';
import { IconNavItem } from 'components/atoms';

const RightNav = ({ location, menuItems, config, printIt }) => {
	return (
		<div className="mobile-right">
			{menuItems.map((item, index) => {
				let iconImg = config.cdn_basePath + (location.pathname === item.url ? item.activeIcon : item.icon),
					isActive = location.pathname === item.url,
					hidden = !printIt(item, true) || item.mobileRight === false;

				return <IconNavItem icon={iconImg} hidden={hidden} item={item} isActive={isActive} key={index} />;
			})}
		</div>
	);
};

RightNav.defaultProps = {
	menuItems: []
};

RightNav.propTypes = {
	// Required
	config: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	printIt: PropTypes.func.isRequired,
	// Optional
	menuItems: PropTypes.array
};

export default RightNav;
