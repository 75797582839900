/**
 * BreakPoint
 * @interface IBreakPoint
 * @param {string} tag      The name of the breakpoint.
 * @param {number} minWidth The lower bound of this breakpoint.
 * @param {number} maxWidth The upper bound of this breakpoint.
 */
export const BREAK_POINTS = [
	{ tag: 'mobile', minWidth: 0, maxWidth: 767 },
	{ tag: 'tablet', minWidth: 768, maxWidth: 1023 },
	{ tag: 'desktop', minWidth: 1024, maxWidth: 999999999999999 }
];

/**
 * Determines the breakpoint the window width currently fits into.
 * @param  {IBreakPoint[]} breakPoints An array of {@link IBreakPoint} objects.
 * @return {IBreakPoint}
 */
export const getCurrentBreakPoint = breakPoints => {
	const windowWidth = window.innerWidth;
	let currentBounds = { tag: 'mobile' };

	breakPoints.forEach(bound => {
		if (windowWidth >= bound.minWidth && windowWidth <= bound.maxWidth) {
			currentBounds = bound;
		}
	});

	return currentBounds;
};
