import AutoCompleteModal from './ui-component';
import { connect } from 'react-redux';
import { toggleAutoCompleteModal } from 'actions/modal';

const mapDispatchToProps = dispatch => ({
	toggleModal: isOpen => dispatch(toggleAutoCompleteModal(isOpen))
});

const mapStateToProps = state => ({ modal: state.modal, autocomplete: state.autocomplete, config: state.config });

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AutoCompleteModal);
