import { createReducer } from 'redux-act';

// NOTE fake data
const initialState = {
	userName: 'alex',
	name: {
		first: 'Alex',
		last: 'Soon'
	},
	email: {
		primary: 'asoon@company.com',
		alternate: 'asoon@personal.com'
	},
	points: 1525,
	preferences: {
		notification: {
			monthlySummary: true,
			specialOffers: true
		}
	},
	connections: [
		{
			id: 1,
			type: 1,
			last4: 9999,
			employeeId: '123456'
		}
	]
};

export default createReducer({}, initialState);
