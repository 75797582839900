import RecommendationEndorsement from './ui-component';
import { connect } from 'react-redux';
import { fetchRecommendationsOptions, toggleShowModal } from 'actions/recommendations_modal';
import { getTranslate } from 'react-localize-redux';
import withSizes from 'react-sizes';
import { isSV /*, isMV, isLV*/ } from 'utils/sizes';

const mapStateToProps = state => ({
	isAuthed: state.users.authenticated.length > 0,
	translate: getTranslate(state.locale)
});

const mapDispatchToProps = dispatch => ({
	fetchRecommendationsOptions: async restaurantId => fetchRecommendationsOptions(dispatch, restaurantId),
	showModal: (show, type, restaurantId) => dispatch(toggleShowModal(show, type, restaurantId))
});

const mapSizesToProps = sizes => ({
	// isLV: isLV(sizes),
	isSV: isSV(sizes)
	// isMV: isMV(sizes)
});

export default withSizes(mapSizesToProps)(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(RecommendationEndorsement)
);
