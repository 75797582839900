import React from 'react';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const NotFound = ({ translate }) => (
	<div>
		<div className="Di_SubHeadShort Di_BrokenPic" />
		<div className="Di_PageLimit-XLG container-fluid animated fadeInUp">
			<h1 className="text-center">
				<span className="Di_ErrorPage">{translate('NotFound.header1')}</span>
				<br />
				<span className="">{translate('NotFound.header2')}</span>
			</h1>
			<br />
			<h2 className="text-center">{translate('NotFound.header3')}</h2>
			<p className="text-center">{translate('NotFound.text1')}</p>
			<p className="text-center">{translate('NotFound.text2')}</p>
		</div>
	</div>
);

NotFound.propTypes = {
	translate: PropTypes.func
};

const mapStateToProps = state => ({
	translate: getTranslate(state.locale),
	currentLanguage: getActiveLanguage(state.locale).code
});

export default connect(mapStateToProps)(NotFound);
