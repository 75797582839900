import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

/**
 * List of links that provide access to a restaurant's avialble CTAs. Ex: 'Call', 'Website', 'Order'.
 * @param {CTAMenuProps} props
 */
const CTAMenu = ({ actions, className }) => {
	const classProps = classNames('restaurant-cta-menu', className);
	return (
		<div className={classProps}>
			{actions.map((action, i) => {
				return action.titleShort === 'Catering' ? null : (
					<a
						className="cta-btn align-center justify-center"
						href={action.url}
						target="_blank"
						rel="noopener noreferrer"
						key={`restaurant-cta-menu-action-${i}`}
					>
						<img src={action.icon} />
						{action.titleShort}
					</a>
				);
			})}
		</div>
	);
};

CTAMenu.defaultProps = {
	actions: [],
	className: ''
};

/**
 * Action used in {@link CTAMenuProps} and {@link CTAMenu}
 * @interface IAction
 * @property {string} title The full text of the action title.
 * @property {string} titleShort A shorter version of the action title.
 * @property {string} url The url to go to when this action is initiated.
 * @property {string} icon The icon to show on the actions CTA button.
 */

/**
 * {@link CTAMenu} Props
 * @interface Props_CTAMenu
 * @property {Action[]} actions An array of Actions.
 * @property {string} className Additional className to apply to the {@link CTAMenu} wrapper.
 */
CTAMenu.propTypes = {
	// Optional
	actions: PropTypes.arrayOf(
		PropTypes.shape({
			titleShort: PropTypes.string,
			title: PropTypes.string,
			url: PropTypes.string,
			icon: PropTypes.string
		})
	),
	className: PropTypes.string
};

export default CTAMenu;
