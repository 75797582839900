import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { RecommendationCountMessage, Text } from 'components/atoms';
import { RecommendationEndorsement, RecommendationsIncentive } from 'components/molecules';
import { Col } from 'reactstrap';

class Recommendations extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			didSubmit: false
		};

		this.isDetailsPage = window.location.pathname.indexOf(`restaurants`) > -1;
		this.handleAfterSubmit = this.handleAfterSubmit.bind(this);
	}

	componentDidMount = () => {
		// Event is fired from submitRecommendation action handler success.
		window.addEventListener('recommendationSubmit', this.handleAfterSubmit);
	};

	handleAfterSubmit = event => {
		if (event.detail.restaurantId === this.props.restaurantId || this.isDetailsPage) {
			this.setState({ didSubmit: true });
		}
	};

	render() {
		// history page did submit replace component
		if (this.state.didRecommend && !this.isDetailsPage) {
			// history transaction page after submit recommendation
			return (
				<div
					data-cy="recommendations"
					id="recommendations-section"
					className="recommendations transaction-didSubmit"
				>
					<Col xs="8">
						<div className="flex justify-center">
							<Text size="xs" align="center" dataCy="transactions-list-ty">
								{this.props.translate('Recommendations.transactions-will-receive-points')}
							</Text>
						</div>
					</Col>
				</div>
			);
		}

		const {
			className,
			count,
			didRecommend,
			fetchOnButtonClick,
			incentiveKey,
			onCountClick,
			restaurantId,
			isSV
		} = this.props;
		const classProps = classnames('recommendations', onCountClick && 'has-count', className && className);

		// details page did submit remove leave recommendation component | keep recommended by component
		// NOTE: count + 1 | hardcode increase count so we don't have to fetch again.  Can remove if/when we ever create websockets.
		if (this.state.didSubmit && this.isDetailsPage) {
			const sv = (
				<div data-cy="recommendations" id="recommendations-section" className={classProps}>
					{onCountClick && <RecommendationCountMessage count={count} onClick={onCountClick} />}
				</div>
			);

			const mv = (
				<div data-cy="recommendations" id="recommendations-section" className={classProps}>
					<div className="flex justify-center align-center">
						{onCountClick && <RecommendationCountMessage count={count} onClick={onCountClick} />}
					</div>
				</div>
			);

			return isSV ? sv : mv;
		}

		const sv = (
			<div data-cy="recommendations" id="recommendations-section" className={classProps}>
				{onCountClick && <RecommendationCountMessage count={count} onClick={onCountClick} />}
				{!didRecommend && (
					<React.Fragment>
						<RecommendationEndorsement
							fetchOnButtonClick={fetchOnButtonClick}
							restaurantId={restaurantId}
						/>
						<RecommendationsIncentive incentiveKey={incentiveKey} />
					</React.Fragment>
				)}
			</div>
		);

		const mv = (
			<div data-cy="recommendations" id="recommendations-section" className={classProps}>
				<div className="flex justify-center align-center">
					{onCountClick && <RecommendationCountMessage count={count} onClick={onCountClick} />}
					{!didRecommend && (
						<RecommendationEndorsement
							fetchOnButtonClick={fetchOnButtonClick}
							restaurantId={restaurantId}
						/>
					)}
				</div>
				{!didRecommend && <RecommendationsIncentive incentiveKey={incentiveKey} />}
			</div>
		);

		return isSV || className === 'transactions' ? sv : mv;
	}
}

Recommendations.defaultProps = {};

Recommendations.propTypes = {
	className: PropTypes.string,
	incentiveKey: PropTypes.string,
	fetchOnButtonClick: PropTypes.bool,
	onCountClick: PropTypes.func,
	restaurantId: PropTypes.string,
	isSV: PropTypes.bool.isRequired,
	didRecommend: PropTypes.bool.isRequired,
	count: PropTypes.number,
	translate: PropTypes.func.isRequired
};

export default Recommendations;
