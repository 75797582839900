/* eslint-disable no-case-declarations */
import * as t from '../actions/autocomplete/types';

/**
 * Redux_AutocompleteState
 * @interface Redux_AutocompleteState
 * @property {Array} [completions=[]]
 */

const usersDefaultState = {
	completions: []
};

export default (state = usersDefaultState, action) => {
	switch (action.type) {
		case t.SET_COMPLETIONS:
			return {
				...state,
				completions: action.payload
			};
		default:
			return state;
	}
};
