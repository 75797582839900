import 'react-smartbanner/src/styles/style.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { Loader } from 'components/atoms';
import App from 'components/environment/App';
import isNodeEnv from 'utils/isNodeEnv';

if (isNodeEnv('production') || isNodeEnv('prod')) {
	require('styles/index.prod.scss');
} else {
	require('styles/index.dev.scss');
}

ReactDOM.render(<Loader />, document.getElementById('root'));

if (isNodeEnv('production')) {
	console.log = () => {};
}

document.addEventListener('DOMContentLoaded', function() {
	ReactDOM.render(<App />, document.getElementById('root'));
});
