import * as t from '../actions/general/types';
import * as newTypes from '../actions/geo_location/types';

/**
 * Redux_GeneralState
 * @interface Redux_GeneralState
 * @property {Object}  [strings={}]
 * @property {Boolean} [isBusy=true]
 * @property {String}  [message=""]
 * @property {Object}  [error=undefined]
 * @property {Boolean} [useMyDinovaLogo=true]
 * @property {Boolean} [showMenu=true]
 * @property {String}  [headertext=""]
 * @property {Boolean} [mobileNavOpen=false]
 * @property {String}  [pathname=null]
 * @property {Object}  [outsideEvent=null]
 * @property {Object}  [company=null]
 * @property {string}  [campaignID=undefined]
 * @property {string}  [companyCode=undefined]
 * @property {string}  [companyName=undefined]
 * @property {array}   [preferredCCType=[]]
 * @property {number}  [preferredEmployeeIDType=undefined]
 * @property {number}  [preferredConnectionID=null]
 * @property {string}  [feedType=undefined]
 * @property {Boolean} [androidVersion=false]
 */

const generalDefaultState = {
	strings: {},
	isBusy: true,
	message: '',
	error: undefined,
	useMyDinovaLogo: true,
	showMenu: true,
	headertext: '',
	mobileNavOpen: false,
	pathname: null,
	outsideEvent: null,
	company: null,
	campaignID: undefined,
	companyCode: undefined,
	companyName: undefined,
	preferredCCType: [],
	preferredEmployeeIDType: undefined,
	preferredConnectionID: null,
	feedType: undefined,
	androidVersion: false,
	geolocation: null,
	hasGeolocation: false
};

export default (state = generalDefaultState, action) => {
	switch (action.type) {
		case t.RESET_STATE:
			return {
				...state,
				campaignID: undefined,
				companyCode: undefined,
				companyName: undefined,
				preferredCCType: [],
				preferredEmployeeIDType: undefined
			};
		case t.IS_LOADING:
			return { ...state, isBusy: action.payload.loading, message: action.payload.message };
		case t.STRINGS_FETCHED_SUCCESS:
			return {
				...state,
				isBusy: false
			};
		case t.STRINGS_FETCHED_ERROR:
			return {
				...state,
				isBusy: false,
				error: action.error
			};
		case t.REQUEST_NAV:
		case t.REQUEST_STRINGS:
			return { ...state, isBusy: true };
		case t.STRINGS_FETCHED_DATA:
			return {
				...state,
				strings: action.strings
			};
		case t.UPDATE_HEADER:
			return {
				...state,
				useMyDinovaLogo: action.payload.useMyDinovaLogo,
				showMenu: action.payload.showMenu
			};
		case t.TOGGLE_NAV: {
			return {
				...state,
				mobileNavOpen: !state.mobileNavOpen
			};
		}
		case t.PAGE_VIEW: {
			return {
				...state,
				pathname: action.pathname,
				location: action.url
			};
		}
		case t.OUTSIDE_EVENT: {
			return {
				...state,
				outsideEvent: action.payload
			};
		}
		case t.SET_ERROR:
			return {
				...state,
				error: action.payload
			};
		case t.SET_CAMPAIGN_ID:
			return {
				...state,
				campaignID: action.payload
			};
		case t.SET_COMPANY:
			return {
				...state,
				company: action.payload
			};
		case t.SET_COMPANY_CODE:
			return {
				...state,
				companyCode: action.payload
			};
		case t.SET_COMPANY_NAME:
			return {
				...state,
				companyName: action.payload
			};
		case t.SET_PREFERRED_CC_TYPE:
			return {
				...state,
				preferredCCType: [...state.preferredCCType, action.payload]
			};
		case t.SET_PREFERRED_EMPID_TYPE:
			return {
				...state,
				preferredEmployeeIDType: action.payload
			};
		case t.SET_PREFERRED_CONNECTION_ID:
			return {
				...state,
				preferredConnectionID: action.payload
			};
		case t.SET_FEED_TYPE:
			return {
				...state,
				feedType: action.payload
			};
		case t.SET_ANDROID:
			return {
				...state,
				androidVersion: true
			};
		// TODO: remove all geolocation stuff from general, keep on geoLocation reducer.
		case newTypes.FETCH_GEO_LOCATION: {
			if (action.payload.coords) {
				return {
					...state,
					geolocation: {
						coords: { latitude: action.payload.coords.latitude, longitude: action.payload.coords.longitude }
					},
					hasGeolocation: true
				};
			} else {
				return {
					...state,
					geolocation: null,
					hasGeolocation: false
				};
			}
		}
		case t.GET_CURRENT_GEOLOCATION:
			return {
				...state,
				geolocation: action.geolocation,
				hasGeolocation: action.hasGeolocation
			};
		default:
			return state;
	}
};
