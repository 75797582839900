import React from 'react';
import PropTypes from 'prop-types';
import { OptionsList } from 'components/organisms';

class RestaurantRecommendations extends React.Component {
	componentDidMount = async () => {
		if (!this.hasRecommendationOptions() && this.props.restaurantId) {
			this.props.fetchRecommendationsOptions(this.props.restaurantId);
		}
	};

	hasRecommendations = () => this.props.recommendations.total > 0;

	hasRecommendationOptions = () => this.props.recommendationOptions.length > 0;

	render() {
		const { recommendations, recommendationOptions } = this.props;
		if (!this.hasRecommendationOptions() || !this.hasRecommendations()) {
			return null;
		}
		const recList = Object.entries(recommendations.categories).map(([recId, { total }]) => {
			let found = null;
			recommendationOptions.forEach(recGroup => {
				recGroup.categories.forEach(cat => {
					if (parseInt(cat.id) === parseInt(recId)) {
						found = `${cat.name} (${total})`;
					}
				});
			});

			return found;
		});

		return recList.length ? <OptionsList className="details-recommendations" options={recList} /> : null;
	}
}

RestaurantRecommendations.defaultProps = {
	recommendations: []
};

/**
 * {@link RestaurantRecommendations} Props
 * @interface Props_RestaurantRecommendations
 * @property {string[]} [recommendations=[]]
 */
RestaurantRecommendations.propTypes = {
	// Optional
	recommendations: PropTypes.array,
	recommendationOptions: PropTypes.object,
	fetchRecommendationsOptions: PropTypes.func.isRequired,
	restaurantId: PropTypes.string
};

export default RestaurantRecommendations;
