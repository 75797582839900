import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { getTranslate } from 'react-localize-redux';

const EditProfileActions = props => {
	const { className, onUpdate, onCancel, translate } = props;
	const classProps = classNames('edit-profile-actions', className);

	return (
		<div className={classProps}>
			<Button onClick={onUpdate} color="primary">
				{translate('common.updateBtn')}
			</Button>
			<Button onClick={onCancel}>{translate('common.cancelBtn')}</Button>
		</div>
	);
};

EditProfileActions.defaultProps = {
	className: ''
};

EditProfileActions.propTypes = {
	className: PropTypes.string,
	onUpdate: PropTypes.func,
	onCancel: PropTypes.func,
	translate: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	translate: getTranslate(state.locale)
});

export default connect(mapStateToProps)(EditProfileActions);
