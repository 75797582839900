import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { Button } from 'reactstrap';
import Truncate from 'react-truncate';

/**
 * Displays text description of a restaurant.  If text is more than 10 lines, text will be clipped, elippses added, "Read More" button rendered.
 * @extends Component
 * @param {DescriptionProps} props
 */
class Description extends Component {
	/**
	 * Initial state.
	 * @type {Object}
	 * @property {boolean} [isTextExpanded=false]
	 * @property {number} [visibleLines=10]
	 */
	state = {
		isTextExpanded: false,
		visibleLines: 10
	};

	maxLines = 10;

	/**
	 * "Read More" button handler. Shows all description text.
	 */
	expand = () => {
		this.setState({ isTextExpanded: !this.state.isTextExpanded, visibleLines: 99999 });
	};

	render() {
		const { text, translate } = this.props,
			classProps = classNames(
				'restaurant-description column',
				this.state.isTextExpanded ? 'expanded' : 'collapsed'
			),
			buttonText = this.state.isTextExpanded ? translate('Details.readLess') : translate('Details.readMore');

		// preservedText, keep line breaks
		let preservedText = text.split('\n').map((line, i, arr) => {
			line = <span key={i}>{line}</span>;

			if (i === arr.length - 1) {
				return line;
			} else {
				return [line, <br key={i + 'br'} />];
			}
		});

		return preservedText.length > 0 ? (
			<div className={classProps}>
				<Truncate
					lines={this.state.visibleLines}
					ellipsis={
						this.state.isTextExpanded ? null : (
							<React.Fragment>
								...
								<Button color="warning" onClick={this.expand}>
									{buttonText}
								</Button>
							</React.Fragment>
						)
					}
				>
					{preservedText}
				</Truncate>
			</div>
		) : null;
	}
}

Description.defaultProps = {
	text: 'Description'
};

/**
 * {@link Description} Props
 * @interface Props_Description
 * @property {function} translate
 * @property {string} [text="Description"]
 */
Description.propTypes = {
	// Required
	translate: PropTypes.func.isRequired,

	// Optional
	text: PropTypes.string
};

const mapStateToProps = state => ({
	translate: getTranslate(state.locale)
});

export default connect(mapStateToProps)(Description);
