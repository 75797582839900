import { asyncComponent } from 'hoc';

const LazyHome = asyncComponent(() => {
	return import(
		/* webpackChunkName: "Home" */
		/* webpackMode: "lazy" */
		'./'
	).then(module => module.default);
});

export default LazyHome;
