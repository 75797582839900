import * as t from '../actions/geo_location/types';
import * as legacy from '../actions/general/types';
/**
 * Redux_GeoLocationState
 * @interface Redux_GeoLocationState
 */

// let today = new Date();

const geoLocationDefaultState = {
	wasDenied: false,
	isLoading: false,
	hasGeoLocation: false,
	lat: 0,
	lng: 0
};

export default (state = geoLocationDefaultState, action) => {
	switch (action.type) {
		case t.FETCH_GEO_LOCATION: {
			if (action.payload.coords) {
				let newState = {
					hasGeoLocation: true,
					isLoading: false,
					lat: action.payload.coords.latitude,
					lng: action.payload.coords.longitude,
					wasDenied: false
				};
				// console.log('newState - success', newState);
				return newState;
			} else {
				const newState = {
					...geoLocationDefaultState,
					wasDenied: true
				};
				// console.log('fail - geoLocationDefaultState', newState);
				return newState;
			}
		}
		case t.FETCH_GEO_LOCATION_START: {
			let newState = {
				...state,
				isLoading: true,
				hasGeoLocation: false,
				wasDenied: false
			};
			// console.log('newState - start', newState);
			return newState;
		}
		case t.GEOLOCATION_SERVICES_PERMISSIONS_DENIED: {
			return {
				...geoLocationDefaultState,
				wasDenied: true
			};
		}
		case legacy.GET_CURRENT_GEOLOCATION: {
			// Legacy action from actions/general - used in search page
			const { hasGeolocation, geolocation } = action;
			if (hasGeolocation) {
				return {
					hasGeoLocation: true,
					isLoading: false,
					lat: geolocation.coords.latitude,
					lng: geolocation.coords.longitude,
					wasDenied: false
				};
			} else {
				return {
					...geoLocationDefaultState,
					wasDenied: true
				};
			}
		}
		default:
			return state;
	}
};
