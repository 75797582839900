import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Row } from 'third-party/mdbreact/dist/mdbreact.js';
import { EditProfileActions, ProfileSectionItem, RequiredFieldNote } from 'components/molecules';
import { InputField } from 'components/organisms';
import FormFieldRequirements from 'components/old-pattern/utilities/FormFieldRequirements';

const EditSecurityForm = props => {
	const { className, onChange, translate, onUpdate, onCancel, newPassword, username, currPassword } = props;
	const classProps = classNames('edit-security-form', className);

	const usernameItem = { key: 'username', value: username };

	return (
		<div className={classProps}>
			<RequiredFieldNote />
			<ProfileSectionItem item={usernameItem} section="security" className="mb-4" />
			<Row>
				<InputField
					label={translate(`Profile.security.labels.currPassword`)}
					name="currPasswordField"
					id="curr-password-field"
					dataCy="curr-password-field"
					hasError={!currPassword.isValid}
					error={currPassword.errorText}
					value={currPassword.value}
					onChange={e => onChange(e, 'currPassword')}
					type="password"
				/>

				<InputField
					label={translate(`Profile.security.labels.newPassword`)}
					name="newPasswordField"
					id="new-password-field"
					dataCy="new-password-field"
					hasError={!newPassword.isValid}
					error={newPassword.errorText}
					value={newPassword.value}
					onChange={e => onChange(e, 'newPassword')}
					type="password"
				/>

				<FormFieldRequirements formFieldText={newPassword} />
			</Row>

			<EditProfileActions onUpdate={onUpdate} onCancel={onCancel} />
		</div>
	);
};

EditSecurityForm.defaultProps = {
	className: ''
};

EditSecurityForm.propTypes = {
	className: PropTypes.string,
	onChange: PropTypes.func,
	translate: PropTypes.func,
	onUpdate: PropTypes.func,
	onCancel: PropTypes.func,
	currPassword: PropTypes.object,
	newPassword: PropTypes.object,
	username: PropTypes.string,
	config: PropTypes.object
};

export default EditSecurityForm;
