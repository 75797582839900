import { connect } from 'react-redux';
import CateringServiceNotice from './ui-component';
import { getZones } from 'actions/catering';
import { generateLinksFromState } from 'utils/catering';
import { getTranslate } from 'react-localize-redux';

const mapStateToProps = state => ({
	links: generateLinksFromState(state).links,
	translate: getTranslate(state.locale)
});

const mapDispatchToProps = dispatch => ({
	getZones: () => getZones(null, dispatch) // null to get all zones
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CateringServiceNotice);
