import DateGridItem from './ui-component';
// import withSizes from 'react-sizes';
// import { isSV } from 'utils/sizes';

// const mapSizesToProps = sizes => ({
// isSV: isSV(sizes)
// });

// export default withSizes(mapSizesToProps)(DateGridItem);
export default DateGridItem;
