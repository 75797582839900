import React from 'react';
import PropTypes from 'prop-types';
import { DropdownOption } from 'components/atoms';
import { Dropdown, InputError } from 'components/molecules';

// import './styles/main.scss';

/**
 * SelectInput - A dropdown of options with click/select handler.
 * @constructor
 * @param {SelectInput_Props} props
 */
const SelectInput = props => (
	<Dropdown className="input-container select" {...props} optionComponent={DropdownOption}>
		{props.hasError && <InputError text={props.error} />}
	</Dropdown>
);

SelectInput.defaultProps = {
	error: null,
	hasError: false,
	options: []
	// extends InputField props
};

/**
 * SelectInput Props
 * @interface SelectInput_Props
 * @property {string}  error    The error message text.
 * @property {boolean} hasError If this input has an error.
 * @property {array}   options  An array of data objects for the select options.
 */
SelectInput.propTypes = {
	error: PropTypes.string,
	hasError: PropTypes.bool,
	options: PropTypes.array
	// extends InputField props
};

export default SelectInput;
