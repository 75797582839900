import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { getTranslate } from 'react-localize-redux';

const NoResponseNoticeMsg = ({ className, translate }) => {
	const classProps = classNames('no-response-notice-msg', className);

	return <span className={classProps}>{translate('NoResponse.noticeMsg')}</span>;
};

NoResponseNoticeMsg.defaultProps = {
	className: ''
};

NoResponseNoticeMsg.propTypes = {
	className: PropTypes.string,
	translate: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	translate: getTranslate(state.locale)
});

export default connect(mapStateToProps)(NoResponseNoticeMsg);
