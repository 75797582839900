import React from 'react';
import PropTypes from 'prop-types';
import { Link, Text } from 'components/atoms';
import { DateGridItem } from 'components/molecules';
import classnames from 'classnames';
import { buildAboutRestaurantURL } from 'utils/url';
import { history } from 'utils/router';

const RecommendationItem = ({ isSV, item, options, showRecommendationsModalPrefilled, translate }) => {
	const recommendColClassProps = classnames('rec col-sm-2', item.recommended ? 'pos' : 'neg');

	const goToDetails = () => {
		let URL = buildAboutRestaurantURL({
			id: item.restaurant.id,
			name: item.restaurant.name,
			city: item.restaurant.address.city,
			state: item.restaurant.address.state.abbreviation
		});
		history.push(URL);
	};

	const recommendedFor = () => {
		let final = [];
		item.for.forEach(f => {
			options.forEach(opt => {
				if (opt.id === f) {
					final.push(opt.name);
				}
			});
		});

		return final.join(', ');
	};

	return (
		<div className="recommendation-item row">
			<div className={recommendColClassProps}>
				<Text size="sm" align="center">
					{item.recommended ? 'Yes' : 'No'}
				</Text>
			</div>

			<DateGridItem date={item.date} />

			{!isSV && (
				<div className="restaurant col-sm-3">
					<div>
						<Link size="sm" align="center" onClick={goToDetails}>
							<Text size="sm" align="center">
								{item.restaurant.name}
							</Text>
						</Link>
						<Text size="sm" align="center">
							{`${item.restaurant.address.street.join(', ')}`}
							<br />
							{` ${item.restaurant.address.city} ${item.restaurant.address.state.abbreviation}`}
						</Text>
					</div>
				</div>
			)}

			{!isSV && (
				<div className="details col-sm-3">
					<Text size="sm" align="center">
						{recommendedFor()}
					</Text>
				</div>
			)}

			{isSV && (
				<div className="details col-sm-6 col-md-3">
					<Link size="sm" align="center" onClick={goToDetails}>
						<Text size="sm" align="center">
							{item.restaurant.name}
						</Text>
					</Link>
					<Text size="xs" align="center">
						{`${item.restaurant.address.street.join(', ')} ${item.restaurant.address.city} ${
							item.restaurant.address.state.abbreviation
						}`}
					</Text>
				</div>
			)}

			<div className="edit col-sm-2">
				<Link
					onClick={() => {
						showRecommendationsModalPrefilled(item.recommended ? 'positive' : 'negative', item);
					}}
				>
					<Text size="sm" align="center">
						{translate('common.editBtn')}
					</Text>
				</Link>
			</div>
		</div>
	);
};
RecommendationItem.defaultProps = {};
RecommendationItem.propTypes = {
	isSV: PropTypes.bool.isRequired,
	item: PropTypes.object.isRequired,
	showRecommendationsModalPrefilled: PropTypes.func.isRequired,
	translate: PropTypes.func.isRequired,
	options: PropTypes.object
};

export default RecommendationItem;
