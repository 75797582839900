import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getTranslate } from 'react-localize-redux';
import { compose } from './helpers';
import { debounce } from 'lodash';

// transform snake_case to camelCase
const formattedSuggestion = structured_formatting => ({
	mainText: structured_formatting.main_text,
	secondaryText: structured_formatting.secondary_text
});

/**
 * Typeahead input that provides google maps [AutocompleteService](https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service) results in a dropdown.
 * @extends Component
 * @requires ReduxProvider
 * @param {LocationAutocompleteProps} props
 * @param {function} prop.translate Privided by redux store.
 * @param {function} [props.onChange=null] Additional handler for when input value changes.
 * @param {function} [props.onSelect=null] Additional handler for when option is selected.
 */
class LocationAutocomplete extends Component {
	constructor(props) {
		super(props);

		this.state = {
			address: '',
			loading: false,
			suggestions: [],
			ready: false
		};

		this.debouncedFetchPredictions = debounce(this.fetchPredictions, 300);
	}

	autocompleteService = null;
	autocompleteOK = null;

	componentDidMount() {
		this.init();
	}

	/**
	 * Initialize google maps [AutocompleteService](https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service).
	 * @return {[type]} [description]
	 */
	init = () => {
		if (!window.google) {
			throw new Error(
				'[react-places-autocomplete]: Google Maps JavaScript API library must be loaded. See: https://github.com/kenny-hibino/react-places-autocomplete#load-google-library'
			);
		}

		if (!window.google.maps.places) {
			throw new Error(
				'[react-places-autocomplete]: Google Maps Places library must be loaded. Please add `libraries=places` to the src URL. See: https://github.com/kenny-hibino/react-places-autocomplete#load-google-library'
			);
		}

		this.autocompleteService = new window.google.maps.places.AutocompleteService();
		this.autocompleteOK = window.google.maps.places.PlacesServiceStatus.OK;
		if (!this.state.ready) {
			this.setState({ ready: true });
		}
	};

	handleChange = e => {
		const { value } = e.target;
		this.setState({ address: value });

		if (!value) {
			this.clearSuggestions();
			return;
		}

		if (this.props.onChange) {
			this.props.onChange(e);
		}
		this.debouncedFetchPredictions();
	};

	handleSelect = address => {
		this.setState({ address });
		this.clearSuggestions();

		if (this.props.onSelect) {
			this.props.onSelect(address);
		}
	};

	/**
	 * Use google maps [AutocompleteService](https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service) to fetch autocomplete predictions.
	 */
	fetchPredictions = () => {
		const value = this.state.address;
		if (value.length) {
			this.setState({ loading: true });
			this.autocompleteService.getPlacePredictions(
				{
					input: value
				},
				this.autocompleteCallback
			);
		}
	};

	/**
	 * After google maps [AutocompleteService](https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service) has fetched restuls, set the results as this.state.suggestions.
	 * @param  {array} predictions [AutocompleteService](https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service) returned predictions.
	 * @param  {string} status     The state of google maps [AutocompleteService](https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service).
	 */
	autocompleteCallback = (predictions, status) => {
		this.setState({ loading: false });

		if (status !== this.autocompleteOK) {
			return;
		}
		console.log('predictions:', predictions);
		this.setState({
			suggestions: predictions.map((p, idx) => ({
				id: p.id,
				description: p.description,
				placeId: p.place_id,
				active: false, //highlightFirstSuggestion && idx === 0 ? true : false,
				index: idx,
				formattedSuggestion: formattedSuggestion(p.structured_formatting),
				matchedSubstrings: p.matched_substrings,
				terms: p.terms,
				types: p.types
			}))
		});
	};

	clearActive = () => {
		this.setState({
			suggestions: this.state.suggestions.map(suggestion => ({
				...suggestion,
				active: false
			}))
		});
	};

	clearSuggestions = () => {
		this.setState({ suggestions: [] });
	};

	/**
	 * Aggregate the props needed for LocationAutocomplete's Input element.
	 * @param {object} [options={}] Input prop overrides.  If option provided for onKeyDown or onBlur, both options.func and this.func will be called.
	 * @return {object} The props that will be handed to the LocationAutocomplete's Input element.
	 */
	getInputProps = (options = {}) => {
		const defaultInputProps = {
			type: 'text',
			autoComplete: 'off',
			role: 'combobox',
			'aria-autocomplete': 'list',
			'aria-expanded': this.getIsExpanded(),
			'aria-activedescendant': this.getActiveSuggestionId(),
			disabled: !this.state.ready
		};

		return {
			...defaultInputProps,
			...options,
			onKeyDown: compose(
				this.handleInputKeyDown,
				options.onKeyDown
			),
			onBlur: compose(
				this.handleInputOnBlur,
				options.onBlue
			)
		};
	};

	/**
	 * Aggregate the props needed for LocationAutocomplete's Input element.
	 * @param {object} suggestion The suggestion object to derive the returned props.key, props.id, and props.className from.
	 * @param {object} [options={}] Suggestions item prop overrides.  If option provided for onMouseEnter, onMouseLeave, onMouseDown, onMouseUp, onTouchStart, onTouchEnd, onClick, options.func and this.func will be called.
	 * @return {object} The props that will be handed to the LocationAutocomplete's Input element.
	 */
	getSuggestionItemProps = (suggestion, options = {}) => {
		const handleSuggestionMouseEnter = this.handleSuggestionMouseEnter.bind(this, suggestion.index);
		const handleSuggestionClick = this.handleSuggestionClick.bind(this, suggestion);

		return {
			...options,
			key: suggestion.id,
			id: suggestion.id,
			className: `${options.className || ''} option ${
				suggestion.placeId === this.getActiveSuggestionId() ? 'active' : ''
			}`.trim(),
			role: 'option',
			onMouseEnter: compose(
				handleSuggestionMouseEnter,
				options.onMouseEnter
			),
			onMouseLeave: compose(
				this.handleSuggestionMouseLeave,
				options.onMouseLeave
			),
			onMouseDown: compose(
				this.handleSuggestionMouseDown,
				options.onMouseDown
			),
			onMouseUp: compose(
				this.handleSuggestionMouseUp,
				options.onMouseUp
			),
			onTouchStart: compose(
				this.handleSuggestionTouchStart,
				options.onTouchStart
			),
			onTouchEnd: compose(
				this.handleSuggestionMouseUp,
				options.onTouchEnd
			),
			onClick: compose(
				handleSuggestionClick,
				options.onClick
			)
		};
	};

	getIsExpanded = () => {
		return this.state.suggestions.length > 0;
	};

	getActiveSuggestionId = () => {
		const activeSuggestion = this.getActiveSuggestion();
		return activeSuggestion ? `${activeSuggestion.placeId}` : undefined;
	};

	getActiveSuggestion = () => {
		return this.state.suggestions.find(suggestion => suggestion.active);
	};

	selectActiveAtIndex = index => {
		this.setActiveAtIndex(index);
	};

	setActiveAtIndex = index => {
		this.setState({
			suggestions: this.state.suggestions.map((suggestion, idx) => {
				if (idx === index) {
					return { ...suggestion, active: true };
				} else {
					return { ...suggestion, active: false };
				}
			})
		});
	};

	handleEnterKey = () => {
		const activeSuggestion = this.getActiveSuggestion();
		if (activeSuggestion === undefined) {
			this.handleSelect(this.state.address, null);
		} else {
			this.handleSelect(activeSuggestion.description, activeSuggestion.placeId);
		}
	};

	handleDownKey = () => {
		if (this.state.suggestions.length === 0) {
			return;
		}

		const activeSuggestion = this.getActiveSuggestion();
		if (activeSuggestion === undefined) {
			this.selectActiveAtIndex(0);
		} else if (activeSuggestion.index === this.state.suggestions.length - 1) {
			this.selectUserInputValue();
		} else {
			this.selectActiveAtIndex(activeSuggestion.index + 1);
		}
	};

	handleUpKey = () => {
		if (this.state.suggestions.length === 0) {
			return;
		}

		const activeSuggestion = this.getActiveSuggestion();
		if (activeSuggestion === undefined) {
			this.selectActiveAtIndex(this.state.suggestions.length - 1);
		} else if (activeSuggestion.index === 0) {
			this.selectUserInputValue();
		} else {
			this.selectActiveAtIndex(activeSuggestion.index - 1);
		}
	};

	selectUserInputValue = () => {
		this.clearActive();
	};

	handleInputKeyDown = event => {
		/* eslint-disable indent */
		switch (event.key) {
			case 'Enter':
				event.preventDefault();
				// this.handleEnterKey();
				break;
			case 'ArrowDown':
				event.preventDefault(); // prevent the cursor from moving
				this.handleDownKey();
				break;
			case 'ArrowUp':
				event.preventDefault(); // prevent the cursor from moving
				this.handleUpKey();
				break;
			case 'Escape':
				this.clearSuggestions();
				break;
		}
		/* eslint-enable indent */
	};

	handleInputOnBlur = () => {
		if (!this.mousedownOnSuggestion) {
			this.clearSuggestions();
		}
	};

	handleSuggestionMouseEnter = index => {
		this.setActiveAtIndex(index);
	};

	handleSuggestionMouseLeave = () => {
		this.mousedownOnSuggestion = false;
		this.clearActive();
	};

	handleSuggestionMouseDown = event => {
		event.preventDefault();
		this.mousedownOnSuggestion = true;
	};

	handleSuggestionTouchStart = () => {
		this.mousedownOnSuggestion = true;
	};

	handleSuggestionMouseUp = () => {
		this.mousedownOnSuggestion = false;
	};

	handleSuggestionClick = (suggestion, event) => {
		if (event && event.preventDefault) {
			event.preventDefault();
		}
		const { description, placeId } = suggestion;
		this.handleSelect(description, placeId);
		setTimeout(() => {
			this.mousedownOnSuggestion = false;
		});
	};

	render() {
		const { hasGeoLocation, translate } = this.props;

		return (
			<div className="location-autocomplete-wrapper">
				<input
					className="search-input"
					{...this.getInputProps()}
					onChange={this.handleChange}
					value={this.state.address}
					placeholder={
						hasGeoLocation
							? translate('Home.cateringSearch.locationInput.placeholder')
							: translate('Home.businessSearch.nearLabel')
					}
				/>
				<div className="autocomplete-dropdown">
					{this.state.suggestions.map((s, i) => {
						return (
							<div
								key={`autocomplete-item-${i}`}
								onClick={this.handleSelect.bind(this, s)}
								{...this.getSuggestionItemProps(s)}
							>
								{s.description}
							</div>
						);
					})}
				</div>
			</div>
		);
	}
}

LocationAutocomplete.defaultProps = {};

/**
 * @interface Props_LocationAutocomplete
 * @type {Object}
 * @property {function} translate Privided by redux store.
 * @property {function} [onChange=null] Additional handler for when input value changes.
 * @property {function} [onSelect=null] Additional handler for when option is selected.
 */
LocationAutocomplete.propTypes = {
	// Required
	translate: PropTypes.func.isRequired,
	hasGeoLocation: PropTypes.bool.isRequired,

	// Optional
	onChange: PropTypes.func,
	onSelect: PropTypes.func
};

const mapStateToProps = state => {
	return {
		hasGeoLocation: state.geoLocation.hasGeoLocation,
		translate: getTranslate(state.locale)
	};
};

export default connect(mapStateToProps)(LocationAutocomplete);
