import HelpFAQRouteTabPanel from './ui-component';
import { connect } from 'react-redux';
import { withLastLocation } from 'react-router-last-location';
import { withRouter } from 'react-router';
import { getTranslate } from 'react-localize-redux';

const mapStateToProps = state => ({
	translate: getTranslate(state.locale)
});

export default connect(mapStateToProps)(withRouter(withLastLocation(HelpFAQRouteTabPanel)));
