import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Promo = ({ className, number, translate, IMAGE_BASE_URL }) => {
	const classProps = classNames('promo', className);

	const img =
		IMAGE_BASE_URL +
		translate(`Home.promos.items.${number}.img`, null, {
			showMissingTranslationMsg: false
		});
	const link =
		translate(`Home.promos.items.${number}.url`, null, {
			showMissingTranslationMsg: false
		}) || '#';
	return (
		<a className={classProps} href={link} target="_blank" rel="noopener noreferrer">
			<img src={img} className="promo-img" />
			<span className="learn-more">{translate('Home.learnMore')}</span>
		</a>
	);
};

Promo.defaultProps = {
	className: ''
};

Promo.propTypes = {
	className: PropTypes.string,
	number: PropTypes.number.isRequired,
	translate: PropTypes.func,
	IMAGE_BASE_URL: PropTypes.string
};

export default Promo;
