import React from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { CollapseWithClose } from 'components/molecules';
import WithDropdown from 'components/molecules/Dropdown/withDropdown';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { hasProp } from 'utils/object';

/**
 * Info icon that, when clicked, triggers to show/hide a help modal.
 * @extends {@link WithDropdown}
 * @param {HelpButtonProps} props
 * @param {string} props.id The id for the help button modal.
 * @param {object} props.config Dinova Redux state.config.
 * @param {string} [props.help] The text to show in the help modal.
 * @param {function} [props.onHelpClick] On help icon click handler.
 *
 * @example <HelpButton id="help-btn-id" help="This is help text."/>
 */
class HelpButton extends WithDropdown {
	onHelpClick = e => {
		if (this.props.onHelpClick) {
			this.props.onHelpClick(e);
		} else {
			this.toggleDropdown();
		}
	};

	render() {
		const { id, config, translate, help, showHelpText, title } = this.props;
		const { height, openSpeed } = this.state;
		const isOpen = hasProp(this.props, 'isOpen') ? this.props.isOpen : this.state.isOpen;
		const collapseClassProps = classnames('help-panel-wrapper', showHelpText && 'big-pad');
		const icon = isOpen ? 'icon-help-green' : 'icon-help';

		return (
			<React.Fragment>
				{title && (
					<div ref={c => (this.title = c)} className="title" onClick={this.onHelpClick}>
						{title}
					</div>
				)}
				<div
					data-toggle="collapse"
					type="button"
					className="help-btn btn-flat"
					id={`accordion-${id}`}
					onClick={this.onHelpClick}
					ref={c => (this.dropdown_trigger = c)}
				>
					<img
						className="Di_SideHelpIcon"
						src={`${config.cdn_basePath}/icons/${icon}.svg`}
						alt={translate('common.helpBtn')}
					/>{' '}
					{showHelpText && <p className="Di_IconText">{translate('common.helpBtn')}</p>}
				</div>
				<div className={collapseClassProps}>
					<CollapseWithClose
						content={help}
						height={height}
						isOpen={isOpen}
						onClick={this.onHelpClick}
						openSpeed={openSpeed}
						refSetter={c => (this.dropdown = c)}
					/>
				</div>
			</React.Fragment>
		);
	}
}

HelpButton.defaultProps = {
	help: 'This is help text.'
};

/**
 * HelpButton Props
 * @interface Props_HelpButton
 * @property {string} id The id for the help button modal.
 * @property {object} config Dinova Redux state.config.
 * @property {function} translate Dinova Redux getTranslate(state.locale).
 * @property {string} [help] The text to show in the help modal.
 * @property {function} [onHelpClick] On help icon click handler.
 */
HelpButton.propTypes = {
	// Required
	id: PropTypes.string.isRequired,
	config: PropTypes.object.isRequired,
	translate: PropTypes.func.isRequired,
	// Optional
	help: PropTypes.string,
	onHelpClick: PropTypes.func,
	isOpen: PropTypes.bool
};

const mapStateToProps = state => ({
	config: state.config,
	translate: getTranslate(state.locale)
});

export default connect(mapStateToProps)(HelpButton);
