import { asyncComponent } from 'hoc';

const LazyOrderPage = asyncComponent(() => {
	return import(
		/* webpackChunkName: "OrderPage" */
		/* webpackMode: "lazy" */
		'./'
	).then(module => module.default);
});

export default LazyOrderPage;
